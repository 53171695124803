import React, { useContext } from 'react';
import { Form } from '../../components_staff';
import { UserContext } from '../../context/user';
// helpers
import submitForm from '../../helpers/staff/submitform';

export default function UserFormContainer({children, ...props}){

    const [ user, ] = useContext(UserContext)


    const errorHandler=(error, key)=>{
        if(error){
            
            if (error['email']){
                return error[key]
            }
        } else{
            return null
        }
    }

    return (
        <>
            <Form.HTMLForm type="submit" onSubmit={(e)=> submitForm(e, props) }>
                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Email</Form.InputName>
                        <Form.Input
                            name="Email"
                            onChange={e => props.setFormData({...props.formData, email: e.target.value})}
                            value={props.formData.email ? props.formData.email : ""}
                            type="email"
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "email")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>First Name</Form.InputName>
                        <Form.Input
                            name="FirstName"
                            onChange={e => props.setFormData({...props.formData, first_name: e.target.value})}
                            value={props.formData.first_name ? props.formData.first_name : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "first_name")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Last Name</Form.InputName>
                        <Form.Input
                            name="LastName"
                            onChange={e => props.setFormData({...props.formData, last_name: e.target.value})}
                            value={props.formData.last_name ? props.formData.last_name : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "last_name")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Phone Number</Form.InputName>
                        <Form.Input
                            name="PhoneNumber"
                            onChange={e => props.setFormData({...props.formData, phone_number: e.target.value})}
                            value={props.formData.phone_number ? props.formData.phone_number : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "phone_number")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Receive Email</Form.InputName>
                        <Form.Input
                            name="isReceiveEmail"
                            onChange={e => props.setFormData({...props.formData, is_receive_email: e.target.checked})}
                            value={props.formData.is_receive_email ? props.formData.is_receive_email : ""}
                            type="checkbox" 
                            width="10%"
                            checked={props.formData.is_receive_email ? props.formData.is_receive_email : null}
                            style={{ cursor: "pointer"}}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "is_receive_email")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Get Contacted</Form.InputName>
                        <Form.Input
                            name="isGetContacted"
                            onChange={e => props.setFormData({...props.formData, is_get_contacted: e.target.checked})}
                            value={props.formData.is_get_contacted ? props.formData.is_get_contacted : ""}
                            type="checkbox" 
                            width="10%"
                            checked={props.formData.is_get_contacted ? props.formData.is_get_contacted : null}
                            style={{ cursor: "pointer"}}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "is_get_contacted")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Is Active</Form.InputName>
                        <Form.Input
                            name="isGetContacted"
                            onChange={e => props.setFormData({...props.formData, is_active: e.target.checked})}
                            value={props.formData.is_active ? props.formData.is_active : ""}
                            type="checkbox" 
                            width="10%"
                            checked={props.formData.is_active ? props.formData.is_active : null}
                            style={{ cursor: "pointer"}}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "is_active")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Is Staff</Form.InputName>
                        <Form.Input
                            name="isStaff"
                            onChange={e => props.setFormData({...props.formData, is_staff: e.target.checked})}
                            value={props.formData.is_staff ? props.formData.is_staff : ""}
                            type="checkbox" 
                            width="10%"
                            checked={props.formData.is_staff ? props.formData.is_staff : null}
                            style={{ cursor: "pointer"}}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "is_staff")}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                {/* if the staff member is creating a user or edditing there own user then allow them to make changes to the password */}
                
                {/* || user.id === props.formData.id? */}
                {
                    props.formTitle === "Add User" ?
                    <>
                        <Form.InputFrame>
                            <Form.InputFrameTop>
                                <Form.InputName>Password</Form.InputName>
                                <Form.Input
                                    name="Password"
                                    onChange={e => props.setFormData({...props.formData, password: e.target.value})}
                                    value={props.formData.password ? props.formData.password : ""}
                                />
                            </Form.InputFrameTop>
                            <Form.InputFrameBottom>
                                <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "password")}</Form.ResponseText>
                            </Form.InputFrameBottom>
                        </Form.InputFrame>

                        <Form.InputFrame>
                            <Form.InputFrameTop>
                                <Form.InputName>ReType Password</Form.InputName>
                                <Form.Input
                                    name="Re Password"
                                    onChange={e => props.setFormData({...props.formData, re_password: e.target.value})}
                                    value={props.formData.re_password ? props.formData.re_password : ""}
                                />
                            </Form.InputFrameTop>
                            <Form.InputFrameBottom>
                                <Form.ResponseText color="#FF7276">{errorHandler(props.formErrors, "re_password")}</Form.ResponseText>
                            </Form.InputFrameBottom>
                        </Form.InputFrame>
                    </>
                    :
                    null
                }
                

                <Form.InputFrame>
                    <Form.InputFrameTop justifyContent="center">
                        <Form.SubmitButton>Submit</Form.SubmitButton>
                    </Form.InputFrameTop>
                </Form.InputFrame>
                    
                
            </Form.HTMLForm>
            {/* this child is the response to the request */}
            {children}
        </>
    )
}