import axios from 'axios';
import {URLS}  from '../services/config'; 


// gets all of the cook listings that are specifically marked isFeatured
export default async function getFeaturedListings(){
    
    try {
        const response = await axios.get(`${URLS.BASE_URL}/${URLS.LISTINGS}?isFeatured=true`)
        return response
    } catch (error){
        console.log(error)
        return error
    }
    

}
