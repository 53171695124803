import React from 'react';

import { TableFrame, HeaderFrame, CheckBox, HeaderText, DataTable, DataTableRow, DataTableText,
    DataRow, TableFooter
} from './styles/table'


export default function Table({children, ...restProps}){

    return <TableFrame {...restProps}>{children}</TableFrame>
}

Table.HeaderFrame = function TableHeaderFrame({children, ...restProps}){
    return <HeaderFrame {...restProps}>{children}</HeaderFrame>
}

Table.CheckBox = function TableCheckBox({children, ...restProps}){
    return <CheckBox {...restProps}>{children}</CheckBox>
}

Table.HeaderText = function TableHeaderText({children, ...restProps}){
    return <HeaderText {...restProps}>{children}</HeaderText>
}

Table.DataTable = function TableDataTable({children, ...restProps}){
    return <DataTable {...restProps}>{children}</DataTable>
}

Table.DataTableRow = function TableDataTableRow({children, ...restProps}){
    return <DataTableRow {...restProps}>{children}</DataTableRow>
}

Table.DataRow = function TableDataRow({children, ...restProps}){
    return <DataRow {...restProps}>{children}</DataRow>
}

Table.DataTableText = function TableDataTableText({children, ...restProps}){
    return <DataTableText {...restProps}>{children}</DataTableText>
}

Table.TableFooter = function TableTableFooter({children, ...restProps}){
    return <TableFooter {...restProps}>{children}</TableFooter>
}