import React from 'react'

// containers
import LoadingContainer from './loading';
import CardMarkup from './cardmarkup';


// this container acts as a proxy to pass props to the card markup 

export default function ListingCardContainer(props){

    if (props.listings){



        // if no connection error
        if (!props.connectionError){
            return <CardMarkup  
                        listings={props.listings} 
                        grid={props.grid} 
                        refresh={props.refresh}
                        manipulateIsFavorite={props.manipulateIsFavorite}
                        mobileWidth={props.mobileWidth}
                    />
        } else {
            return <h1>{props.connectionError}</h1>
        }
        
    // if the user is logged in then the search results will exist
    }  else {
        return props.connectionError ? <h1>{props.connectionError}</h1> : <LoadingContainer/>
    } 
}


