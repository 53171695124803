import React, {useState, useContext, useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import {Form, Info} from '../components';
import { UserContext } from '../context/user'
import Facebook from '../images/Facebook2.svg';
import Google from '../images/Google.svg';
//api
import logIn from '../api/login';
import emailPasswordReset from '../api/emailpasswordreset';
import continueWithGoogle from '../api/continuewithgoogle';
import continueWithFaceBook from '../api/continuewithfacebook';
//context
import { SignInSignUpContext } from '../context/signinsignup';

// containers
import SpinnerContainer from './spinner';

export default function LogInContainer(props) {
    
    const { errorMessage } = useContext(SignInSignUpContext);
    const [error, setError] = errorMessage


    let location = useLocation();
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [, setUser] = useContext(UserContext)
    const isInvalid = password === '' | emailAddress === '';
    const [ isLoginLoading, setIsLoginLoading ] = useState(false)
    const [resetResponse, setResetResponse] = useState(null)

    const handleSubmit = (e)=> {
        e.preventDefault();
        setIsLoginLoading(true)
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'submit'}).then(function(token) {
                logIn(emailAddress, password, handleExit, setUser, setError, setIsLoginLoading, setEmailAddress, setPassword, token)
            });
        });
         
    }

    const handleExit = () => {
        props.setIsSignInOpen(false);
        document.body.style.overflow = 'unset';
        setError(null)
    }

    const handlePasswordReset = () => {
        if (emailAddress){
            // need to check if email address exists within the system
            emailPasswordReset(emailAddress, setResetResponse)
        } else {
            setResetResponse('Enter email and try again')
        }
    }


    const [didMount, setDidMount] = useState(false);

    useEffect(() => {


        setDidMount(true);
        return () => setDidMount(false);
    }, [location])

    if(!didMount) {
        return null;
    }
    

    return (
        <>
            <Form  height='623px' medHeight='600px' lapHeight = '450px'>
                {
                    !isLoginLoading ?
                    <>
                        <Form.ExitFrame>
                            <Form.ExitBox onClick={handleExit}>
                                <Form.RightSlash></Form.RightSlash>
                                <Form.LeftSlash></Form.LeftSlash>
                            </Form.ExitBox>
                        </Form.ExitFrame>
                        <Form.Wrapper height='573px' method="POST" onSubmit={handleSubmit}>
                            <Form.Title>Log In</Form.Title>
                            <Form.Input
                                name='email'
                                type='email' 
                                placeholder='EMAIL'
                                value={emailAddress}
                                onChange={({target}) => setEmailAddress(target.value)}
                            />
                            
                            <Form.Input
                                name='password' 
                                placeholder='PASSWORD' 
                                type="password"
                                value={password}
                                onChange={({target}) => setPassword(target.value)}
                            />
                            { error ? <Info.ResponseText color='#FF7276' fontSize='14px'>{error}</Info.ResponseText> : null}
                            <Form.Button disabled = {isInvalid}>LOG IN</Form.Button>
                            <Form.Text>or</Form.Text>
                            <Form.SocialFrame background='#3778EA' margin='0 0 16px 0' onClick={continueWithFaceBook}>
                                <Form.SocialIcon src={Facebook}/>
                                <Form.SocialText color='#FFFFFF'>CONTINUE WITH FACEBOOK</Form.SocialText>
                            </Form.SocialFrame>
                            <Form.SocialFrame background='#EFEFEF' margin='0 0 22px 0' onClick={continueWithGoogle}>
                                <Form.SocialIcon src={Google}/>
                                <Form.SocialText color='#262626' >CONTINUE WITH GOOGLE</Form.SocialText>
                            </Form.SocialFrame>
                            <Form.ExtraFrame >
                                <Form.ExtraText color='#262626'>Forgot Your Password?</Form.ExtraText>
                                <Form.ExtraText onClick={handlePasswordReset} color='#6C8915' textDecoration='underline'>Reset your Password</Form.ExtraText>
                            </Form.ExtraFrame>

                            {
                                resetResponse ?
                                <Form.ExtraFrame marginTop={'20px'}>
                                    <Info.ResponseText color='#FF7276'>{resetResponse}</Info.ResponseText>
                                </Form.ExtraFrame>
                                :
                                null
                            }
                            
                        </Form.Wrapper>
                    </>
                    :
                    null
                }
                
                {
                    isLoginLoading ?
                    <>  
                        <Form.ExitFrame>
                            <Form.ExitBox onClick={handleExit}>
                                <Form.RightSlash></Form.RightSlash>
                                <Form.LeftSlash></Form.LeftSlash>
                            </Form.ExitBox>
                        </Form.ExitFrame>
                        <Form.Wrapper>
                            <Form.Title>Log In</Form.Title>
                            <SpinnerContainer/>
                        </Form.Wrapper>
                    </>
                    :
                    null 
                }
                
            </Form>
        
        </>
    )
}