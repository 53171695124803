import React from 'react';
import { WelcomeFrameWrapper, WelcomeFrame, WelcomeLeftFrame, LeftFrameText, LeftTextWrapper, 
    LeftFrameSubText, WelcomeRightFrame, Block, BlockText, LinkA, Chevron } from './styles/welcome'


export default function Welcome({children, ...restProps}){
    return <WelcomeFrameWrapper {...restProps}>{children}</WelcomeFrameWrapper>
}

Welcome.WelcomeFrame = function WelcomWelcomeFrame({children, ...restProps}){
    return <WelcomeFrame {...restProps}>{children}</WelcomeFrame>
}

Welcome.WelcomeLeftFrame = function WelcomWelcomeLeftFrame({children, ...restProps}){
    return <WelcomeLeftFrame {...restProps}>{children}</WelcomeLeftFrame>
}

Welcome.LeftFrameText = function WelcomLeftFrameText({children, ...restProps}){
    return <LeftFrameText {...restProps}>{children}</LeftFrameText>
}

Welcome.LeftTextWrapper = function WelcomLeftTextWrapper({children, ...restProps}){
    return <LeftTextWrapper {...restProps}>{children}</LeftTextWrapper>
}

Welcome.LeftFrameSubText = function WelcomLeftFrameSubText({children, ...restProps}){
    return <LeftFrameSubText {...restProps}>{children}</LeftFrameSubText>
}

Welcome.WelcomeRightFrame = function WelcomWelcomeRightFrame({children, ...restProps}){
    return <WelcomeRightFrame {...restProps}>{children}</WelcomeRightFrame>
}

Welcome.Block = function WelcomBlock({children, ...restProps}){
    return <Block {...restProps}>{children}</Block>
}

Welcome.BlockText = function WelcomBlockText({children, ...restProps}){
    return <BlockText {...restProps}>{children}</BlockText>
}

Welcome.Chevron = function WelcomChevron({children, ...restProps}){
    return (
        <>
            <LinkA to={restProps.to}>
                <Chevron {...restProps}>{children}</Chevron>
            </LinkA>

        </>
    )
}
    