import styled from 'styled-components'

export const FavoriteFrame = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 800px;
    background: #FFFFFF;
`


export const FavoriteFrameInner = styled.div`

    width: 90%;
    margin-top: 30px;
    margin-bottom: 100px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


    @media screen and (min-width: 1280px){
        width: 80%
    }
`;



export const FavoriteBox = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TitleFrame = styled.div`

    display: flex;
    justify-content: center;
    /* Grey / 2 */

    background: #F4F4F5;

    width: 100%;
`;

export const TitleTextFrame = styled.div`

    width: 95%

`;

export const TitleText = styled.h6`
    /* h6 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* black */

    color: #262626;
    margin-top: 16px;
    margin-bottom: 16px;

`;





export const FavoriteGridInner= styled.div`

        
        
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1280px)  {
        display: ${props => props.display ? props.display : 'grid'};
        flex-direction: row;
        justify-content: ${props => props.justifyContent ? props.justifyContent : null};
        align-items: ${props => props.alignItems ? props.alignItems : null};
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        width: 95%;
        margin-bottom: 30px;
        margin-top: 15px;
    }




`;