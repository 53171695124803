// This method is used in the getUserFavoriteListings
// This is called on the detail listing page

// method takes in the data as an array and itterates through. if the id matches any of the data[i] than isFavorite will be set to true
export default function checkIfDetailFavorite(data, id, setIsFavorite){

    let isFound = false


    for(let i=0; i < data.length; i++){

        if(data[i].listing_id === id){

            isFound = true
        } else{
            isFound = false
        }
    }

    
    setIsFavorite(isFound)

}