import React from 'react';
import { CookAuctionBackground, ItemContainer, Logo, Title, Paragraph, Link, LearnMoreDiv, LearnMoreText } from './styles/cookauction'


export default function CookAuction({children, ...restProps}){
    return <CookAuctionBackground {...restProps}>{children}</CookAuctionBackground>
}

CookAuction.ItemContainer = function CookAuctionItemContainer({children, ...restProps}) {
    return <ItemContainer {...restProps}>{children}</ItemContainer>
}

CookAuction.Logo = function CookAuctionLogo({children, ...restProps}) {
    return <Logo {...restProps}>{children}</Logo>
}

CookAuction.Title = function CookAuctionTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>
}

CookAuction.Paragraph = function CookAuctionParagraph({children, ...restProps}) {
    return <Paragraph {...restProps}>{children}</Paragraph>
}

CookAuction.Link = function CookAuctionLink({children, ...restProps}) {
    return <Link {...restProps}>{children}</Link>
}

CookAuction.LearnMoreDiv = function CookAuctionLearnMoreDiv({children, ...restProps}) {
    return (
            <LearnMoreDiv {...restProps}>{children}</LearnMoreDiv>

    )
}

CookAuction.LearnMoreText = function CookAuctionLearMoreText({children, ...restProps}) {
    return <LearnMoreText {...restProps}>{children}</LearnMoreText>
}

