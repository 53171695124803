import React from 'react';
import StaffWrapperContainer from '../../containers_staff/staffwrapper';
import StaffSideNaveContainer from '../../containers_staff/staffsidenav';
import StaffMainContainer from '../../containers_staff/staffmain';
import StaffTitleContainer from '../../containers_staff/stafftitle';
import StaffBlockContainer from '../../containers_staff/staffblock';
import ButtonsContainer from '../../containers_staff/buttons';
import TableContainer from '../../containers_staff/table';
import { UserTableRowContainer } from '../../containers_staff/tablerows';
import FormContainer from '../../containers_staff/form';
import UserFormContainer from '../../containers_staff/forms/userform';
import DeleteContainer from '../../containers_staff/delete';
import ResponseContainer from '../../containers_staff/forms/response';
import SuccessContainer from '../../containers_staff/success';
//hooks
import useData from '../../hooks/staff/usedata';
import useUser from '../../hooks/useuser';
import useForm from '../../hooks/staff/useform';

//images
import AllUsers from '../../images/allusers.svg'
import Profile from '../../images/Profile.svg';
import SocialUser from '../../images/socialuser.svg';

//services
import { ROUTES, URLS } from '../../services/config';
// static
import { createBlockData, listingHeaders} from '../../static/staffuserheaders';


export default function StaffUsers(){

    // this will initialize the form to contain this data
    const initialFormState = {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        is_receive_email: null,
        is_get_contacted: null,
        is_staff: null,
        is_active: null,
        password: null,
        re_password: null
    }

    const { user, history } = useUser()
    // all of the state and functions for the form
    
    const { isFormOpen, setIsFormOpen,
        isDeleteOpen, setIsDeleteOpen, 
        formTitle, setFormTitle,
        formData, setFormData, 
        postData, patchData, deleteData,
        requestStatus, setRequestStatus, 
        formErrors, setFormErrors 
        } = useForm(URLS.STAFFAPIS.USERS, initialFormState)

    const [ users, error, setUsers ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.USERS}`);
    const [ userStats, userStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.USERSTATS}`);


    // this is the block data for all of the stats
    const blockData = createBlockData(userStats, AllUsers, SocialUser, Profile)
    
    if(user && user.is_staff){
        return (
            
                <StaffWrapperContainer>
                    <StaffSideNaveContainer/>
                    <StaffMainContainer>
                        <StaffTitleContainer title = 'Manage Users'/>
                        <StaffBlockContainer blockData={blockData}/>

                        <ButtonsContainer 
                            setIsFormOpen={setIsFormOpen}
                            setIsDeleteOpen={setIsDeleteOpen}
                            setFormTitle={setFormTitle}
                            title={" User"}
                            formData={formData}
                            setFormData={setFormData}
                            initialFormState={initialFormState}
                            setRequestStatus={setRequestStatus}
                        />
                        <SuccessContainer requestStatus={requestStatus} title="User"/>
                        <TableContainer 
                            headers={listingHeaders}
                            tableData={users}
                            isFormOpen={isFormOpen}
                        >
                            <UserTableRowContainer
                                setFormData={setFormData}
                                initialFormState={initialFormState}
                            />
                        </TableContainer>

                        { isFormOpen ?
                                <FormContainer 
                                    isFormOpen={isFormOpen} 
                                    setIsFormOpen={setIsFormOpen}
                                    title={formTitle}
                                    setFormData={setFormData}
                                    setRequestStatus={setRequestStatus}
                                    setFormErrors={setFormErrors}
                                    initialFormState={initialFormState}
                                >

                                <UserFormContainer
                                    formTitle={formTitle}
                                    formData={formData}
                                    setFormData={setFormData}
                                    postData={postData}
                                    patchData={patchData}
                                    formErrors={formErrors}
                                    tableData={users}
                                    setTableData={setUsers}
                                    requestStatus={requestStatus}
                                    initialFormState={initialFormState}
                                >
                                    <ResponseContainer requestStatus={requestStatus} />
                                </UserFormContainer>
                                </FormContainer>
                                :
                                null    
                            }
                        {/* checking to see if both isdeleteopen and there is a listing clicked on */}
                        {
                            isDeleteOpen && formData.first_name?
                            <DeleteContainer
                                tableData={users}
                                setTableData={setUsers}
                                formData={formData}
                                setIsDeleteOpen={setIsDeleteOpen}
                                deleteData={deleteData}
                                requestStatus={requestStatus}
                                setRequestStatus={setRequestStatus}
                                setFormErrors={setFormErrors}
                                initialFormState={initialFormState}
                                item={`${formData.first_name} ${formData.last_name}`}
                                title={`User`} 
                            >
                                <ResponseContainer requestStatus={requestStatus} />
                            </DeleteContainer>
                            :
                            null
                        }
                            
                    </StaffMainContainer>
                    
                </StaffWrapperContainer>
            
            )
        }else {
            history.push(ROUTES.STAFFPAGES.STAFFLOGIN)
            return (
                null
            )
        } 
}