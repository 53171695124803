import React from 'react';
import { MainFrame, MainFrameInner} from './styles/main'


export default function Main({children, ...restProps}){
    
    return <MainFrame {...restProps}>{children}</MainFrame>
}

Main.MainFrameInner = function MainMainFrameInner({children, ...restProps}){
    return <MainFrameInner {...restProps}>{children}</MainFrameInner>
}