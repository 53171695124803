import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/user';

// import axios from '../../api/axios/post'
// import axios from 'axios'

import axiosInstance from '../../api/axios/axios';


import getCookie from '../../helpers/getcookie';

var FormData = require('form-data');

axiosInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosInstance.defaults.xsrfCookieName = "csrftoken";

export default function useForm(requestURL, initialFormState){


    // the initial form state will need to be passed into the hook so the submit function can properly handle data

    // user is needed because if there is a 401 unauthorized then the user needs to be logged out immediately
    const [ , setUser ] = useContext(UserContext)
    // this decides whether the form is open
    const [ isFormOpen, setIsFormOpen ] = useState()
    // this decides if the delete container should be open
    const [ isDeleteOpen, setIsDeleteOpen ] = useState()
    // this handles the title for when the form opens
    const [ formTitle, setFormTitle ] = useState()

    // this handles the state for the data itself
    const [ formData, setFormData ] = useState(initialFormState)
    // handles the status for the response
    const [ requestStatus, setRequestStatus ] = useState(
            {
                loading: null,
                success: null, 
                failure: null,
                data: null,
                status: null,
                clientMessage: null,
                
            }
        )
    // handles the form errors
    const [ formErrors, setFormErrors ] = useState(initialFormState);



    // on unmount then unset
    useEffect(()=>{
        return ()=> {
            if(requestStatus.status === true){
                document.body.style.overflow = 'unset'
            }
        };
    }, [])


    // function for handling the posting of the data
    const postData = async ()=>{

        setRequestStatus({loading: true})
        setFormErrors(initialFormState)

        let data = formatIntoForm(formData)

        try {

            const headers = {
                headers: {
                    "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
                    "X-CSRFTOKEN": getCookie('csrftoken'),
                    "Content-Type": 'multipart/form-data'
                }
            }
            
            const response = await axiosInstance.post(requestURL, data, headers)
            
            setRequestStatus(
                    {   
                        status: response.status, 
                        data: response.data,
                        loading: false, 
                        success: true,
                        failure: false,
                        clientMessage: "Success! Created",
                         
                    }
                )

            
            setIsFormOpen(false)
            // return true so then the other funcs can handle this and unset so the page can be scrolled
            return response.data

        } catch (error) {

            console.log(error.response)

            // if the status is 401 set the user to null
            if(error.response.status === 401){
                setUser(null)
                document.body.style.overflow = 'unset'
            }

            const { errorMessage, formErrors} = errorHandler(error)

            setFormErrors(formErrors)
            
            console.log(formErrors)

            setRequestStatus(
                {
                    status: error.response.status, 
                    data: error.response.data, 
                    loading: false,
                    success: false,
                    failure: true,
                    clientMessage: errorMessage,
                }
            )

        }
    
    }


    const patchData = async () =>{
        setRequestStatus({loading: true})
        setFormErrors(initialFormState)

        let data = formatIntoForm(formData)

        try {

            const headers = {
                headers: {
                    "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
                    "X-CSRFTOKEN": getCookie('csrftoken'),
                    "Content-Type": 'multipart/form-data'
                }
            }

            
            const response = await axiosInstance.patch(requestURL, data, headers)
            
            setRequestStatus(
                    {   
                        status: response.status, 
                        data: response.data,
                        loading: false, 
                        success: true,
                        failure: false,
                        clientMessage: "Success! Updated",
                         
                    }
                )
            
            
            setIsFormOpen(false)


            // return true so then the other funcs can handle this and unset so the page can be scrolled
            return response.data

        } catch (error) {
            
            // if the status is 401 set the user to null
            if(error.response.status === 401){
                setUser(null)
            }

            const { errorMessage, formErrors} = errorHandler(error)
            setFormErrors(formErrors)
            

            setRequestStatus(
                {
                    status: error.response.status, 
                    data: error.response.data, 
                    loading: false,
                    success: false,
                    failure: true,
                    clientMessage: errorMessage,
                }
            )

        }
    
        
    }

    const deleteData = async ()=>{


        let data = formatIntoForm(formData)

        try {

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
                    "X-CSRFTOKEN": getCookie('csrftoken'),
                    
                },
                data: data
            }

            const response = await axiosInstance.delete(requestURL, config)

            setRequestStatus(
                {   
                    status: response.status, 
                    data: response.data,
                    loading: false, 
                    success: true,
                    failure: false,
                    clientMessage: "Success! Deleted",
                     
                }
            )

            return true

        } catch (error){

            console.log(error)
            // if the status is 401 set the user to null
            if(error.response.status === 401){
                setUser(null)
            }

            const { errorMessage, formErrors} = errorHandler(error)
            setFormErrors(formErrors)


            setRequestStatus(
                {
                    status: error.response.status, 
                    data: error.response.data, 
                    loading: false,
                    success: false,
                    failure: true,
                    clientMessage: errorMessage,
                }
            )
        }
    }


    return { 
        
        isFormOpen, setIsFormOpen,
        isDeleteOpen, setIsDeleteOpen,
        formTitle, setFormTitle,
        formData, setFormData,
        requestStatus, setRequestStatus,
        formErrors, setFormErrors,
        postData, patchData, deleteData
    }

}


// this function takes in the data from the form creates a form data item to be passed to the server
function formatIntoForm(formData){
    let data = new FormData();

    // O(n) T
    // loops throught the form data and adds it to the data item for the request
    const keys = Object.keys(formData)
    keys.forEach((key) => {
        // this is for posting a display image...
        // if it is a display image than get the 0 index of that which is the image
        if (key === "DisplayImage" && formData[key]){
            data.append(key, formData[key]);
        // this checks to see if the form data at key is not null
        // we dont want to submit the form data if it contains a null item
        } else if (formData[key] !== null){
            data.append(key, formData[key]);
        }
        else{
            // data.append(key, formData[key]);
        }
    })


    return data
}





// this function is used to handle the response codes given
function errorHandler(error){

    let errorMessage;
    let formErrors;

    console.log(error.response)

    if (error.response.status === 400){
        formErrors = error.response.data
        errorMessage = `There were errors in your form`
    }

    if (error.response.status === 401){
        errorMessage= `Unauthorized Status: ${error.response.status}`
    }

    if (error.response.status === 404){
        errorMessage=`URL Not Found Status: ${error.response.status}`
    }
    
    if (error.response.status === 500){
        errorMessage=`Server Error Contact Developer(s) Status: ${error.response.status}`
    }




    return { errorMessage, formErrors}


}