import React from 'react';
import Select from 'react-select'

import { FormFrame, FormFrameInner, HTMLForm, ExitFrame, ExitBox, RightSlash, 
    LeftSlash, Title, InputFrame, InputName, InputFrameWrapper, Input, OrderInput, TextArea, SubmitButton, ResponseFrame, 
    ResponseText, InputFrameTop, InputFrameBottom, DeleteFrame, DeleteText  } from './styles/form'


export default function Form({children, ...restProps}){
    return <FormFrame {...restProps}>{children}</FormFrame>
}


Form.FormFrameInner = function FormFormFrameInner({children, ...restProps}){
    return <FormFrameInner {...restProps}>{children}</FormFrameInner>
}

Form.HTMLForm = function FormHTMLForm({children, ...restProps}){
    return <HTMLForm {...restProps}>{children}</HTMLForm>
}

Form.ExitFrame = function FormExitFrame({children, ...restProps}){
    return <ExitFrame {...restProps}>{children}</ExitFrame>
}

Form.ExitBox = function FormExitBox({children, ...restProps}){
    return <ExitBox {...restProps}>{children}</ExitBox>
}

Form.RightSlash = function FormRightSlash({children, ...restProps}){
    return <RightSlash {...restProps}>{children}</RightSlash>
}

Form.LeftSlash = function FormLeftSlash({children, ...restProps}){
    return <LeftSlash {...restProps}>{children}</LeftSlash>
}

Form.Title = function FormTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

Form.InputFrame = function FormInputFrame({children, ...restProps}){
    return <InputFrame {...restProps}>{children}</InputFrame>
}

Form.InputFrameTop = function FormInputFrameTop({children, ...restProps}){
    return <InputFrameTop {...restProps}>{children}</InputFrameTop>
}

Form.InputFrameBottom = function FormInputFrameBottom({children, ...restProps}){
    return <InputFrameBottom {...restProps}>{children}</InputFrameBottom>
}


Form.InputName = function FormInputName({children, ...restProps}){
    return <InputName {...restProps}>{children}</InputName>
}

Form.InputFrameWrapper = function FormInputFrameWrapper({children, ...restProps}){
    return <InputFrameWrapper {...restProps}>{children}</InputFrameWrapper>
}


Form.Input = function FormInput({children, ...restProps}){
    return <Input {...restProps}>{children}</Input>
}

Form.OrderInput = function FormOrderInput({children, ...restProps}){
    return <OrderInput {...restProps}>{children}</OrderInput>
}

Form.TextArea = function FormTextArea({children, ...restProps}){
    return <TextArea {...restProps}>{children}</TextArea>
}

Form.FormSelect = function FormSelecter({children, ...restProps}){

    let display = null
    if (restProps.display){
        display = 'none'
    } else {
        display = 'flex'
    }

    const color = restProps.typeError

    const customStyles = {

        // this controls the styling of the entire container
        container: (provided)=>({
            ...provided,
            width: '60%;',
            border: `2px solid ${color}`,
            fontFamily: "Museo Sans Cyrl"
        }),

        // this is the menu
        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            color: state.selectProps.menuColor,
            background: "#DEE2E6"
          }),

        input: (provided)=> ({
            ...provided,
            height: '50px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: "1rem",

        }),

        valueContainer: (provided, state)=> ({
            ...provided,
            height: '50px',

        }),

        

        dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
            height: "40px;",
            display: display,
            alignItems: 'center;',
            fontSize: "100px;"
        }),

        indicatorSeparator: () => ({
        }),

        control: (provided) => ({
            ...provided,
            border: 'none;'
        }),

        singleValue: (provided) => ({
            ...provided,
            color: "black",
            fontSize: '20px',
            fontWeight: "700"
        })
    
    }

            
    
    return <Select 
                {...restProps}
                styles={customStyles} 
            >
                {children}
            </Select>
}


Form.SubmitButton = function FormSubmitButton({children, ...restProps}){
    return <SubmitButton {...restProps}>{children}</SubmitButton>
}

Form.ResponseFrame = function FormResponseFrame({children, ...restProps}){
    return <ResponseFrame {...restProps}>{children}</ResponseFrame>
}

Form.ResponseText = function FormResponseText({children, ...restProps}){
    return <ResponseText {...restProps}>{children}</ResponseText>
}

Form.DeleteFrame = function FormDeleteFrame({children, ...restProps}){
    return <DeleteFrame {...restProps}>{children}</DeleteFrame>
}

Form.DeleteText = function FormDeleteText({children, ...restProps}){
    return <DeleteText {...restProps}>{children}</DeleteText>
}





