import React from 'react';

import { Form } from '../components_staff'


export default function FormContainer({children, ...props}){



    // on exit of the form there are certain operations that are performed
    // on exit removes form data and the url from state
    const handleExit = ()=> {
        props.setIsFormOpen(false)
        props.setRequestStatus(
            {
                loading: null,
                success: null, 
                failure: null,
                data: null,
                status: null,
                clientMessage: null,
            }
        )
        props.setFormErrors(props.initialFormState)
        
        document.body.style.overflow = 'unset';
    }



    if (props.isFormOpen){
        return (

            <Form>
                <Form.FormFrameInner>
                    <Form.ExitFrame>
                        <Form.ExitBox onClick={handleExit}>
                            <Form.LeftSlash/>
                            <Form.RightSlash/>
                        </Form.ExitBox>
                    </Form.ExitFrame>
                    <Form.Title>{props.title}</Form.Title>

                    {/* this child object is the actual form itself.
                    it contains the inputs and even the submit function to the server */}
                    {children}
                    
                </Form.FormFrameInner>
            </Form>
        )
    } else {
        return null;
    }
    
}