import styled from 'styled-components';


export const HomeSearchFrame = styled.div`
    
    display: flex;
    justify-content: space-around;
    margin: 64px 0 62px 0;
    
    width: 100%;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        height: 170px;


    }
`;

export const HomeSearchFrameInner = styled.div`
    width: 85%;
    display: flex;

    flex-direction: column;
    

    @media only screen and (min-width: 1280px)  {

        font-size: 56px;


    }
`


export const HomeSearchBar = styled.div`
    width: 100%;
    
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 32px 84px rgba(27, 26, 30, 0.12);

    @media only screen and (min-width: 1280px)  {
        height: 120px;
        flex-direction: row;
        justify-content: center;

    }
`;


export const InputFrame = styled.div`

    width: 100%;
    
    background-color: #FFFFFF;
    // margin:  0 5px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: Museo Sans Cyrl;
    color: #262626;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 1.8000001907348633px;
    text-align: left;

    @media only screen and (min-width: 1280px)  {
        width: 99.5%;
        height: 110px;
        flex-direction: row;


    }

`;



// Not currently being used
export const MagnifyGlass = styled.img`

    height: 2rem;
    cursor: pointer;
`;

export const VerticalBar = styled.div`

    height: 60px;
    border: 1px solid #DFDFDF;

    @media only screen and (min-width: 1280px)  {
        height: 60px;


    }
`;

export const MenuFrame = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 1280px)  {
        height: 110px;

        // change this back to 20% when add min and max price
        width: 40%;

    }
`;

export const HomeSearchButton = styled.button`

    background: #6C8915;
    width: 100%;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    height: 60px;
    cursor: pointer;

    @media only screen and (min-width: 1280px)  {
        width: 20%;
        height: 110px;

    }
`;