import React from 'react';
import {FormFrame, ExitFrame, ExitBox, RightSlash, LeftSlash, Wrapper, 
        Title, Input, Button, Text, SocialFrame, SocialIcon, SocialText, 
        ExtraFrame, ExtraTextLink, ExtraText, CookLogoIMG, TextBoxWrapper, 
        TextBox} from './styles/form'


export default function Form({children, ...restProps}){
    return <FormFrame {...restProps}>{children}</FormFrame>
}

Form.ExitFrame = function FormExitFrame({children, ...restProps}){
    return <ExitFrame {...restProps}>{children}</ExitFrame>
}

Form.ExitBox = function FormExitBox({children, ...restProps}){
    return <ExitBox {...restProps}>{children}</ExitBox>
}

Form.RightSlash = function FormRightSlash({children, ...restProps}){
    return <RightSlash {...restProps}>{children}</RightSlash>
}

Form.LeftSlash = function FormLeftSlash({children, ...restProps}){
    return <LeftSlash {...restProps}>{children}</LeftSlash>
}

Form.Wrapper = function FormWrapper({children, ...restProps}){
    return <Wrapper {...restProps}>{children}</Wrapper>
}

Form.Title = function FormTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

Form.Input = function FormInput({children, ...restProps}){
    return <Input {...restProps}>{children}</Input>
}

Form.Button = function FormButton({children, ...restProps}){
    return <Button {...restProps}>{children}</Button>
}

Form.Text = function FormText({children, ...restProps}){
    return <Text {...restProps}>{children}</Text>
}

Form.SocialFrame = function FormSocialFrame({children, ...restProps}){
    return <SocialFrame {...restProps}>{children}</SocialFrame>
}

Form.SocialIcon = function FormSocialIcon({children, ...restProps}){
    return <SocialIcon {...restProps}>{children}</SocialIcon>
}

Form.SocialText = function FormSocialText({children, ...restProps}){
    return <SocialText {...restProps}>{children}</SocialText>
}

Form.ExtraFrame = function FormExtraFrame({children, ...restProps}){
    return <ExtraFrame {...restProps}>{children}</ExtraFrame>
}

Form.ExtraText = function FormExtraText({children, ...restProps}){
    return <ExtraText {...restProps}>{children}</ExtraText>
}

Form.ExtraLinkText = function FormExtraText({children, ...restProps}){
    return (
    <>
        <ExtraTextLink to={restProps.to}>
            <ExtraText {...restProps}>{children}</ExtraText>
        </ExtraTextLink>
    </>
    )
}


Form.CookLogoIMG = function FormCookLogoIMG({children, ...restProps}){
    return <CookLogoIMG {...restProps}>{children}</CookLogoIMG>
}


Form.TextBoxWrapper = function FormTextBoxWrapper({children, ...restProps}){
    return <TextBoxWrapper {...restProps}>{children}</TextBoxWrapper>
}

Form.TextBox = function FormTextBox({children, ...restProps}){
    return <TextBox {...restProps}>{children}</TextBox>
}
