import React from 'react';
import {CookAuction} from '../components';
import CookAuctionLogo from '../images/CookAuctionLogo.svg';
import NewBack from '../images/NewBack.JPG'

import openInNewTab from '../helpers/openinnewtab'


export default function CookAuctionContainer(){


    return (
        <>
        
            <CookAuction url={NewBack}>
                <CookAuction.ItemContainer>
                    <CookAuction.Logo src={CookAuctionLogo}/>
                    <CookAuction.Title>COOK AUCTION</CookAuction.Title>
                    <CookAuction.Paragraph>Cook Auction Co has been providing quality used farm and industrial equipment to farmers across the country since 1958. From compact tractors to combines we have it all!</CookAuction.Paragraph>
                        <CookAuction.Link onClick={() => openInNewTab('https://www.cookauctionco.com/')}>
                            <CookAuction.LearnMoreDiv>
                                <CookAuction.LearnMoreText>LEARN MORE</CookAuction.LearnMoreText>
                            </CookAuction.LearnMoreDiv>
                        </CookAuction.Link>
                </CookAuction.ItemContainer>
            </CookAuction>
        
        
        </>
        )
}