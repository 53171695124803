
import axios from 'axios'
import getCookie from '../helpers/getcookie';
import { URLS } from '../services/config'



axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function favoriteTheListing(data){

    // if the favorite listing is an mls than convert to the abbreviated class
    if (data.Type === "MLS"){
        convertToClassAbbrev(data)
    }
    

    const csrf = getCookie('csrftoken')
    const headers = {
        headers: {
            "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
            "X-CSRFTOKEN": `${csrf}`
        }
    }




    axios.post(`${URLS.BASE_URL}/${URLS.FAVORITES}`, data, headers)

    .then(response => console.log(response.data))
    .catch(err => console.log(err))
}


function convertToClassAbbrev(data){

    if(data.Class === "RESIDENTIAL"){
        data.Class = "RE_1"
    } else if (data.Class === "LAND"){
        data.Class = "LD_2"
    } else if (data.Class === "COMMERCIAL/INDUSTRIAL"){
        data.Class = "CI_3"
    } else if (data.Class === "MULTIFAMILY"){
        data.Class = "MF_4"
    } else {
        return null
    }

}