import styled from 'styled-components';


export const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(0deg, rgba(64, 60, 54, 0.62), rgba(64, 60, 54, 0.62)), url(${props => props.url});
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    // background-position: center center;

    @media only screen and (min-width: 1280px)  {

        // background-position: center center;
        height 620px;

    }
`;


export const TitleFrame = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
    padding-top: 50px;
    

    @media only screen and (min-width: 1280px)  {

        padding-top: 229px;


    }
    
`;

export const TitleTextFrame = styled.div`

    
    width: 85%;

    @media only screen and (min-width: 1280px)  {

        width: 85%;


    }

`;



export const TitleText = styled.h1`

    /* h1 */
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 66px;
    /* or 118% */
    color: white;
    text-transform: uppercase;
    
    margin: 0;

    @media only screen and (min-width: 1280px)  {

        font-size: 56px;


    }
`;