import axios from 'axios'
import {URLS} from '../services/config'



export default function createContactUs(setFormSuccess, setFormErrors, firstName, lastName, phoneNumber, email, buy, sell, reCaptchaToken, contactTime, message, ){

    setFormErrors(null);
    setFormSuccess(null);


    if (firstName && lastName && phoneNumber && email){
        const data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: `+1${phoneNumber}`,
            email: email,
            buy: buy,
            sell: sell,
            contacttime: contactTime,
            message: message,
            recaptcha: reCaptchaToken
        }

    
        axios.post(`${URLS.BASE_URL}/${URLS.CONTACTUS}`, data)
        .then((response)=> {
            setFormSuccess(response.status)
            
            }
        )
        .catch(err=>{
            if(err.response.status === 403){
                setFormErrors([err.response.data]) 
            } else{
                // this will set form erros if the response data is not valid
                setFormErrors(err.response.data)  
            }
            
            
            }
        )
    } else{
        // this handles the front end validation for if the user does not supply all the necessarry data

        setFormErrors(handleErrors(firstName, lastName, phoneNumber, email))
    }
}


// used in the listing detail form
export const contactUsListingDetail = ( firstName, lastName, phoneNumber, email, message, detailedListing, setSuccess, setErrors, reCaptchaToken) => {

    if (firstName && lastName && phoneNumber && email && message){
        const data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: `+1${phoneNumber}`,
            email: email,
            message: message,
            listing_id: detailedListing.id,
            listing_name: detailedListing.Name,
            city: detailedListing.City,
            state: detailedListing.State,
            zip_code: detailedListing.ZipCode,
            recaptcha: reCaptchaToken
        }


        axios.post(`${URLS.BASE_URL}/${URLS.CONTACTUS}`, data)
        .then((response)=> {
            // setSuccess(response.status)
            setSuccess('Your Message has been Sent!')
            }
        )
        .catch(err=>{
            // this will set form erros if the response data is not valid
            if(err.response.status === 403){

                setErrors([err.response.data]) 
            } else{

                // this will set form erros if the response data is not valid
                setErrors(err.response.data)  
            }
            }
        )
    } else {

        setErrors(handleErrors(firstName, lastName, phoneNumber, email, message, true))

    }
}
 

function handleErrors(firstName, lastName, phoneNumber, email, message, is_message) {

    let itemArr
    if(is_message){
        console.log('message')
        itemArr = [firstName, lastName, phoneNumber, email, message]
    } else{
        console.log('no message')
        itemArr = [firstName, lastName, phoneNumber, email]
    }
    
    const errorArr = []

    for(let i =0; i < itemArr.length; i++){
        // if the variable passed is not inputted by user than it will be undefined and this will run
        if(!itemArr[i]){

            let fieldName
            let errorName 

            if(i === 0){
                errorName = 'firstName'
                fieldName = 'a First Name'
            } else if(i === 1) {
                errorName = 'lastName'
                fieldName = 'a Last Name'
            } else if (i===2) {
                errorName = 'phoneNumber'
                fieldName = 'a Phone Number'
            } else if(i===3) {
                errorName = 'email'
                fieldName = 'your Email'
            } else if (i===4 ){
                errorName = 'message'
                fieldName = 'a Message'
            }
            errorArr.push({field: errorName, error: `Please input ${fieldName}`})
        }
    }

    console.log(errorArr)
    
    return errorArr
    }
