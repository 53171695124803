
import { newListingTimeFrame } from '../services/config'


// IMPORTANT IF EVER WANT TO UTILIZE THIS FUNCTION FOR MLS GOING TO HAVE TO FILTER FOR ACTIVE STATUS

// function sorts the listings by new and returns a new array
// currently used for the home page
export default async function sortByNew(listings){

    // get todays current date in unix
    const today = Date.now()
    
    let newArray = []
    let availableArray = []
    let salePendingArray = []
    let soldArray = []

    for (let i = 0; i<listings.length; i++){
        // get the date of the listing in unix
        let listingDate = new Date(listings[i].DateListed)
        // subtract the dates and divide by milliseconds in day to get days since first listed 
        const timeElapsed = Math.floor(((today - listingDate.getTime()) / 86400000)) 

        // if the time elapsed and available and not the others than it is new and add to new array
        if (timeElapsed < newListingTimeFrame && listings[i].Status === "Available" && listings[i].Status !== "Sale Pending" && listings[i].Status !== "Sold"){
            listings[i].Status="New"
            newArray = [listings[i], ...newArray]
        // if available than add to available array
        } else if(listings[i].Status === "Available" && timeElapsed >= newListingTimeFrame){
            availableArray = [...availableArray, listings[i]]
        // if sale pending than add to sale pending array
        } else if(listings[i].Status === "Sale Pending"){
            salePendingArray = [...salePendingArray, listings[i]]
            // if sold then add to sold array
        } else if (listings[i].Status === "Sold"){
            soldArray = [...soldArray, listings[i]]
        }

    }

    // start with new array then available then sale pending then sold
    const outputArray = [].concat(newArray, availableArray, salePendingArray, soldArray) 

    return outputArray

}