import styled from 'styled-components'


export const ListFrame = styled.div`

    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    
`;

export const ListFrameInner = styled.div`
    width: 81.25%;
    margin-bottom: 40px;

    @media only screen and (min-width: 1280px)  {
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '100px'};
    }
`;


export const ListTitle = styled.h4`

    margin: 0;
    padding: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    /* black */

    color: #262626;

`

export const ListItemFrame = styled.div`

    margin: 30px 0;

`;

export const ListItemHeader = styled.h6`
    margin-left: 100px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* or 139% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;

    &&:before {
        content: "–";
        position: absolute;
        margin-left: -18px; 
      }
    
    margin: 0;
    padding: 0;


    @media only screen and (min-width: 1280px)  {
        margin-left: 24px;
    }

`

export const ListItemParagraph = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    /* or 139% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;
    margin: 0;
    padding: 0;
    margin-top: 16px;
`