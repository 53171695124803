import React, {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
//containers
import NavContainer from '../containers/nav';
import BuyerSellerBackgroundContainer from '../containers/buyersellerbackground'
import BuyerSellerContainer from '../containers/buyerseller';
import FooterContainer from '../containers/footer';
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';
// components
import { Wrapper } from '../components'
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
// helpers
import handleUser from '../helpers/handleuser'
// imgs
import CookLandRealty from '../images/CookLandRealty.svg'
import Background from '../images/Rectangle107.png'
// data
import BuyerHomeData from '../data/buyer/home/home.json'
import backgroundtext from '../data/buyer/home/backgroundtext.json'

export default function Buyer(props){

    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])

    return(
        
    
        <>
            <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>
                <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>
                <BuyerSellerBackgroundContainer Background={Background} text={backgroundtext[0]}/>
                <BuyerSellerContainer data={BuyerHomeData}/>
                
                <FooterContainer backgroundColor='#262626'/>
            </Wrapper>

            {
                isSignInOpen || isSignUpOpen ?
                <SignInSignUpProxyContainer 
            
                    isSignInOpen={isSignInOpen}
                    setIsSignInOpen={setIsSignInOpen}
                    isSignUpOpen={isSignUpOpen}
                    setIsSignUpOpen={setIsSignUpOpen}
                    isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                    setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                
                />
                :
                null
            }
            

        </>
        
        )

}

