import axios from 'axios';

//get request to fetch data
export default function fetchData(url){

    const accessToken = localStorage.getItem('accessToken')

    const headers = {
        // cancelToken: cancelToken.token,
        headers: {
            Authorization: `JWT ${accessToken}`
        }
    }

    const fetchedPromise = axios.get(url, headers)
    return fetchedPromise
    
}

