
import axios from 'axios'
import getCookie from '../helpers/getcookie';
import { URLS } from '../services/config'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function deleteFavorite(listingID){

    const csrf = getCookie('csrftoken')
    const token = localStorage.getItem('accessToken')
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${token}`,
            "X-CSRFTOKEN": `${csrf}`
        }, 
        data: {
            listing_id: listingID
        }
    }

    axios.delete(`${URLS.BASE_URL}/${URLS.FAVORITES}`, config)
    .then(response => console.log(response.data))
    .catch(err => console.log(err.response.data))


}