import axios from 'axios'
import {URLS} from '../services/config'

export default  function continueWithGoogle() {
    
    axios.get(`${URLS.BASE_URL}/${URLS.SOCIALAUTH}google-oauth2/?redirect_uri=${URLS.BASE_URL}`)
    .then(response => {
        window.location.replace(response.data.authorization_url);
    })  
};

