import React from 'react';
import { BuyerSeller } from '../components'



export default function BuyerSellerBackgroundContainer(props){

    return (
        <>
            <BuyerSeller url={props.Background}>
                <BuyerSeller.BackgroundIMGTextFrame >
                    <BuyerSeller.SmallText>{props.text.smallText}</BuyerSeller.SmallText>
                    <BuyerSeller.TitleText>{props.text.titleText}</BuyerSeller.TitleText>
                    <BuyerSeller.ContentText>{props.text.contentText}</BuyerSeller.ContentText>
                </BuyerSeller.BackgroundIMGTextFrame>
            </BuyerSeller>    
        </>
        
        
    )

}