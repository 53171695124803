import React from 'react';
import {Background, TitleText, TitleFrame, TitleTextFrame} from './styles/backgroundimg'

export default function BackgroundIMG({children, ...restProps}){
    return <Background {...restProps}>{children}</Background>
}

BackgroundIMG.TitleFrame= function BackgroundIMGTitleFrame({children, ...restProps}){
    return <TitleFrame {...restProps}>{children}</TitleFrame>
}

BackgroundIMG.TitleTextFrame = function BackgroundIMGTitleTextFrame({children, ...restProps}){
    return <TitleTextFrame {...restProps}>{children}</TitleTextFrame>
}

BackgroundIMG.TitleText = function BackgroundIMGTitleText({children, ...restProps}){
    return <TitleText {...restProps}>{children}</TitleText>
}
