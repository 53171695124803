import styled from 'styled-components';


export const BulletFrame = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;

`;

export const BulletFrameInner = styled.div`
    // border: 2px solid green;

    width: 90%;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '50px'};
    // margin-top: ${props => props.marginTop ? props.marginTop : null};
    display: flex;
    justify-content: space-between;

    @media only screen and (min-width: 1280px)  {
        width: 81.25%;
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '100px'};
    }
`;

export const VerticalBar = styled.div`
    
    width: .3%;
    background: #DFDFDF;

`;

export const ItemFrame = styled.div`
    // margin: 5px 0;


    width: 99%;

`;

export const ItemTitle = styled.h4`
    margin-left: 18px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */


    /* black */

    color: #262626;

`;

export const List = styled.ul`

    list-style-type: none;


`

export const ListItem = styled.li`
    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;
    margin-bottom: 12px;

    &&:before {
        content: "–";
        position: absolute;
        margin-left: -18px; 
      }
`