import React from 'react';
import { ListingDetailFrame, ListingDetailFrameInner, ImageFrame, HiddenImage, LargeImage, 
        AttributeFrame, AttributeFrameInner, Heart, Elipse, Arrow, InfoFrame, MainTitleFrame, 
        LeftTitleFrame, TitleText, ExtraFrame, TypeTextFrame, TypeText, Dot, RightTitleFrame, 
        RightText,Amount, HorizontalBar, FactsFrame, FactsFrameLeft, FactsDetailFrame,
        FactsText, FactsData, FactsFrameRight, FactsFrameRightInner, ContactTitle,
        ContactFrame, ContactFrameLeft, ContactImage, ContactFrameRight, ContactFrameName, 
        ContactFrameNumber, ContactFrameSocialFrame, ContactFrameSocialIcon, ContactButton,
        ContactButtonText, DescriptionFrame, DescriptionTitle, DescriptionP, MapFrame, 
        TopTitleFrame, BottomTitleFrame, LocationIcon
    } 
from './styles/listingdetail'

export default function ListingDetail({children, ...restProps}){
    return <ListingDetailFrame {...restProps}>{children}</ListingDetailFrame>
}

ListingDetail.ListingDetailFrameInner = function ListingDetailListingDetailFrameInner({children, ...restProps}){
    return <ListingDetailFrameInner {...restProps}>{children}</ListingDetailFrameInner>
}

ListingDetail.ImageFrame = function ListingDetailImageFrame({children, ...restProps}){
    return <ImageFrame {...restProps}>{children}</ImageFrame>
}

ListingDetail.HiddenImage = function ListingDetailHiddenImage({children, ...restProps}){
    return <HiddenImage {...restProps}>{children}</HiddenImage>
}

ListingDetail.LargeImage = function ListingDetailLargeImage({children, ...restProps}){
    return <LargeImage {...restProps}>{children}</LargeImage>
}

//
ListingDetail.AttributeFrame = function ListingAttributeFrame({children, ...restProps}){
    return <AttributeFrame {...restProps}>{children}</AttributeFrame>
}

ListingDetail.AttributeFrameInner = function ListingInfoFrame({children, ...restProps}){
    return <AttributeFrameInner {...restProps}>{children}</AttributeFrameInner>
}

ListingDetail.Heart = function ListingHeart({children, ...restProps}){
    return <Heart {...restProps}>{children}</Heart>
}

//

ListingDetail.Elipse = function ListingDetailElipse({children, ...restProps}){
    return <Elipse {...restProps}>{children}</Elipse>
}

ListingDetail.Arrow = function ListingDetailArrow({children, ...restProps}){
    return <Arrow {...restProps}>{children}</Arrow>
}

ListingDetail.InfoFrame = function ListingInfoFrame({children, ...restProps}){
    return <InfoFrame {...restProps}>{children}</InfoFrame>
}

ListingDetail.MainTitleFrame = function ListingMainTitleFrame({children, ...restProps}){
    return <MainTitleFrame {...restProps}>{children}</MainTitleFrame>
}

ListingDetail.LeftTitleFrame = function ListingLeftTitleFrame({children, ...restProps}){
    return <LeftTitleFrame {...restProps}>{children}</LeftTitleFrame>
}

ListingDetail.TitleText = function ListingTitleText({children, ...restProps}){
    return <TitleText {...restProps}>{children}</TitleText>
}

ListingDetail.ExtraFrame = function ListingExtraFrame({children, ...restProps}){
    return <ExtraFrame {...restProps}>{children}</ExtraFrame>
}

ListingDetail.RightTitleFrame = function ListingRightTitleFrame({children, ...restProps}){
    return <RightTitleFrame {...restProps}>{children}</RightTitleFrame>
}


ListingDetail.TypeText = function ListingTypeText({children, ...restProps}){
    return <TypeText {...restProps}>{children}</TypeText>
}

ListingDetail.TypeTextFrame = function ListingTypeTextFrame({children, ...restProps}){
    return <TypeTextFrame {...restProps}>{children}</TypeTextFrame>
}

ListingDetail.Dot = function ListingDot({children, ...restProps}){
    return <Dot {...restProps}>{children}</Dot>
}

ListingDetail.Amount = function ListingAmount({children, ...restProps}){
    return <Amount {...restProps}>{children}</Amount>
}

ListingDetail.RightText = function ListingRightText({children, ...restProps}){
    return <RightText {...restProps}>{children}</RightText>
}


ListingDetail.HorizontalBar = function ListingHorizontalBar({children, ...restProps}){
    return <HorizontalBar {...restProps}>{children}</HorizontalBar>
}

ListingDetail.FactsFrame = function ListingFactsFrame({children, ...restProps}){
    return <FactsFrame {...restProps}>{children}</FactsFrame>
}

ListingDetail.FactsFrameLeft = function ListingFactsFrameLeft({children, ...restProps}){
    return <FactsFrameLeft {...restProps}>{children}</FactsFrameLeft>
}

ListingDetail.FactsDetailFrame = function ListingFactsDetailFrame({children, ...restProps}){
    return <FactsDetailFrame {...restProps}>{children}</FactsDetailFrame>
}


ListingDetail.FactsText = function ListingFactsText({children, ...restProps}){
    return <FactsText {...restProps}>{children}</FactsText>
}


ListingDetail.FactsData = function ListingFactsData({children, ...restProps}){
    return <FactsData {...restProps}>{children}</FactsData>
}


ListingDetail.FactsFrameRight = function ListingFactsFrameRight({children, ...restProps}){
    return <FactsFrameRight {...restProps}>{children}</FactsFrameRight>
}


ListingDetail.FactsFrameRightInner = function ListingFactsFrameRightInner({children, ...restProps}){
    return <FactsFrameRightInner {...restProps}>{children}</FactsFrameRightInner>
}


ListingDetail.ContactTitle = function ListingContactTitle({children, ...restProps}){
    return <ContactTitle {...restProps}>{children}</ContactTitle>
}

ListingDetail.ContactFrame = function ListingContactFrame({children, ...restProps}){
    return <ContactFrame {...restProps}>{children}</ContactFrame>
}

ListingDetail.ContactFrameLeft = function ListingContactFrameLeft({children, ...restProps}){
    return <ContactFrameLeft {...restProps}>{children}</ContactFrameLeft>
}

ListingDetail.ContactImage = function ListingContactImage({children, ...restProps}){
    return <ContactImage {...restProps}>{children}</ContactImage>
}

ListingDetail.ContactFrameRight = function ListingContactFrameRight({children, ...restProps}){
    return <ContactFrameRight {...restProps}>{children}</ContactFrameRight>
}

ListingDetail.ContactFrameName = function ListingContactFrameName({children, ...restProps}){
    return <ContactFrameName {...restProps}>{children}</ContactFrameName>
}

ListingDetail.ContactFrameNumber = function ListingContactFrameNumber({children, ...restProps}){
    return <ContactFrameNumber {...restProps}>{children}</ContactFrameNumber>
}

ListingDetail.ContactFrameSocialFrame = function ListingContactFrameSocialFrame({children, ...restProps}){
    return <ContactFrameSocialFrame {...restProps}>{children}</ContactFrameSocialFrame>
}

ListingDetail.ContactFrameSocialIcon = function ListingContactFrameSocialIcon({children, ...restProps}){
    return <ContactFrameSocialIcon {...restProps}>{children}</ContactFrameSocialIcon>
}

ListingDetail.ContactButton = function ListingContactButton({children, ...restProps}){
    return <ContactButton {...restProps}>{children}</ContactButton>
}

ListingDetail.ContactButtonText = function ListingContactButtonText({children, ...restProps}){
    return <ContactButtonText {...restProps}>{children}</ContactButtonText>
}

ListingDetail.DescriptionFrame = function ListingDescriptionFrame({children, ...restProps}){
    return <DescriptionFrame {...restProps}>{children}</DescriptionFrame>
}

ListingDetail.DescriptionTitle = function ListingDescriptionTitle({children, ...restProps}){
    return <DescriptionTitle {...restProps}>{children}</DescriptionTitle>
}

ListingDetail.DescriptionP = function ListingDescriptionP({children, ...restProps}){
    return <DescriptionP {...restProps}>{children}</DescriptionP>
}

ListingDetail.MapFrame = function ListingMapFrame({children, ...restProps}){
    return <MapFrame {...restProps}>{children}</MapFrame>
}

/////////////
ListingDetail.TopTitleFrame = function ListingTopTitleFrame({children, ...restProps}){
    return <TopTitleFrame {...restProps}>{children}</TopTitleFrame>
}

ListingDetail.BottomTitleFrame = function ListingBottomTitleFrame({children, ...restProps}){
    return <BottomTitleFrame {...restProps}>{children}</BottomTitleFrame>
}

ListingDetail.LocationIcon = function ListingLocationIcon({children, ...restProps}){
    return <LocationIcon {...restProps}>{children}</LocationIcon>
}



