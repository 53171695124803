import React from 'react';

import { ButtonFrame, ButtonFrameInner, ButtonItem, ButtonText, ButtonFrameExtra } from './styles/buttons'


export default function Button({children, ...restProps}){
    return <ButtonFrame {...restProps}>{children}</ButtonFrame>
}


Button.ButtonFrameInner = function ButtonButtonFrameInner({children, ...restProps}){
    return <ButtonFrameInner {...restProps}>{children}</ButtonFrameInner>
}


Button.ButtonItem = function ButtonButtonItem({children, ...restProps}){
    return <ButtonItem {...restProps}>{children}</ButtonItem>
}

Button.ButtonText = function ButtonButtonText({children, ...restProps}){
    return <ButtonText {...restProps}>{children}</ButtonText>
}

Button.ButtonFrameExtra = function ButtonButtonFrameExtra({children, ...restProps}){
    return <ButtonFrameExtra {...restProps}>{children}</ButtonFrameExtra>
}