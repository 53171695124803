import queryString from 'query-string'

import checkUser from '../api/checkuser'
import { googleAuthenticate } from '../api/googleAuthenticate'
import { faceBookAuthenticate } from '../api/facebookAuthenticate'


export default function handleUser(location, setUser, setIsSignUpOpen, setErrorMessage){
    const values = queryString.parse(location.search)

    const state = values.state ? values.state : null;
    const code = values.code ? values.code : null;
    const scope = values.scope ? values.scope : null;

    if (state && code){
        if (scope && scope.includes('google')){
            // upon authentication need to figure out how to redirect to the previous page
            console.log('getting here')
            googleAuthenticate(state, code, setUser, setIsSignUpOpen, setErrorMessage)
        } else if (values.granted_scopes){
            // upon authentication need to figure out how to redirect to the previous page
            faceBookAuthenticate(state, code, setUser, setIsSignUpOpen, setErrorMessage)
            // console.log('facebook login')
        } else {
            // console.log('no social login')
            return null
        }   
    } else {
        // console.log('no state and code')
        checkUser(setUser)
    }
}


