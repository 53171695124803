import styled from 'styled-components';


export const InfoBackground = styled.div`

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;


    @media only screen and (min-width: 1280px)  {
        margin-top: 94px;
        margin-bottom: 100px;


    }
`


export const WrapperFrame = styled.div`

    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (min-width: 1280px)  {
        width: 81.31944444%;


    }
`;

export const TopFrame = styled.div`
    
    height: 455px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (min-width: 1280px)  {

        height: 405px;


    }
`;

export const IMGFrame = styled.div`

    
    display: none;
    width: ${props => props.width ? props.width : null}; 
    background: url(${props => props.url});
    background-repeat: no-repeat;
    -webkit-background-size: cover;

    @media only screen and (min-width: 1280px)  {

        display: flex;


    }
`;


export const AboutUSFrame = styled.div`
    height: 455px;
    width: 100%;
    background: #F4F4F5;
    margin: 5px 0;
    display: flex;
    justify-content:center;

    @media only screen and (min-width: 1280px)  {
        height: 405px;
        // display: initial;
        width: 50.29888984%;


    }
`;


export const AboutUsWrapper = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;

    @media only screen and (min-width: 1280px)  {

        margin-left: 50px;
        margin-right: 113px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }
    


    

`;

export const AboutUSTitle = styled.h2`

    /* h2 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    /* or 131% */

    text-align: left;
    text-transform: uppercase;
    
    /* black */

    color: #262626;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        margin-left: ${props => props.marginLeft ? props.marginLeft : null};
        font-size: 24px;

    }

    @media only screen and (min-width: 1920px)  {
        font-size: 36px;
        
    }

`;

export const TitleFrame = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media only screen and (min-width: 1280px)  {
        flex-direction: row;

    }
`;

export const AboutUSParagraph = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #595959;



`;

export const AboutUsHorizontal = styled.div`

    width: 100%;
    border: 1px solid #DFDFDF;
    background-color: #DFDFDF;

`;

export const AboutUSDetailWrapper = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    
`;

export const DetailIMG = styled.img`

    height: 17px;
    width: 17px;
    margin-right: 12px;
`;

export const AboutUSDetail = styled.p`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;

`;


export const BottomFrame = styled.div`


    
    display: flex;
    justify-content: space-between;
    width: ${props => props.mobileBottomWidth ? props.mobileBottomWidth : null};
    margin: 20px 0px;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        margin-top: ${props=> props.marginTop ? props.marginTop : '30px'};
        display: flex;
        width: ${props => props.width ? props.width : null};
        justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};

    }
`;

export const BottomLeftFrame = styled.div`

    width: 100%;
    background: #F4F4F5;
    

    display: flex;
    justify-content: center;

    @media only screen and (min-width: 1280px)  {

        display: initial;
        width: 66.60973572%;

    }
`;

export const ContactUsForm = styled.form`

    width: 90%;

@media only screen and (min-width: 1280px)  {
    width: 90%;

}
   
`;

export const ContactUSWrapper = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

   
    @media only screen and (min-width: 1280px)  {
        align-items: flex-start;    
        width: 100%;
        display: initial; 
        margin-left: 50px;
        margin-right: 49px;

    }

`; 


export const OuterInputDiv = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const InputDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    @media only screen and (min-width: 1280px)  {
        width: 90%;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : null};
        margin-left: 50px;

    }

    `;

export const Input = styled.input`
    height: 84px;
    width: 95%;
    background: #FFFFFF;
    border: none;

    margin: 20px 0px;

    font-size: 1rem;
    color: #262626;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-family: Museo Sans Cyrl;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 23px;
            margin-left: 2rem;
            display: flex;
            align-items: center;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            padding-left: 30px;
            /* black */

            color: #262626;

    }

    @media only screen and (min-width: 1280px)  {
        margin: 10px 0;
        height: 60px;
        width: 48%;

    }
`;

export const CheckMenu = styled.div`

    display: flex;
    flex-direction: column;
    width: 95%;

`;


export const CheckLink = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    
`;


export const CheckBox = styled.input`
    margin-right: 10px;


`;


export const SubmitButton = styled.button`
    height: 84px;
    width: 95%;
    color: white;
    background: #6C8915;
    border: none;
    cursor: pointer;
    font-size: 18px;

    margin: 10px 0;

    @media only screen and (min-width: 1280px)  {

        margin: 0;
        width: 45%;

    }
`;

export const ResponseTextFrame = styled.div`

    display: flex;
    flex-direction: column;

    margin-bottom: ${props => props.marginBottom ? props.marginBottom : null};

`;

export const ResponseText = styled.p`
    margin: 0;
    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* Green */

    color: ${props=> props.color ? props.color : null};

    @media only screen and (min-width: 1280px)  {

        font-size: ${props => props.fontSize ? props.fontSize : '18px'};

    }

`