import React, { Fragment } from 'react';
import Media from 'react-media';
import MobileDetailImageContainer from '../containers/mobiledetailimage'
import DetailImageContainer from '../containers/detailimage'




export default function DetailImageProxy(props){


    const queries={
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
      }


    return (
        <>
            <Media queries={queries}>
                {
                    matches => (
                        <Fragment>
                            {matches.small && 
                                <MobileDetailImageContainer
                                    listingPics={props.listingPics}
                                    setListingPics={props.setListingPics}
                                    detailedListing={props.detailedListing}
                                    setdetailedListing={props.setDetailedListing}
                                    picIndex={props.picIndex}
                                    setPicIndex={props.setPicIndex}
                                    totalPicCount={props.totalPicCount}
                                    shouldFetchMore={props.shouldFetchMore}
                                    setShouldFetchMore={props.setShouldFetchMore}
                                    picOffset={props.picOffset}
                                    setPicOffset={props.setPicOffset}
                                />
                            }

                            {matches.medium && 
                                <DetailImageContainer
                                    listingPics={props.listingPics}
                                    setListingPics={props.setListingPics}
                                    detailedListing={props.detailedListing}
                                    setdetailedListing={props.setDetailedListing}
                                    picIndex={props.picIndex}
                                    setPicIndex={props.setPicIndex}
                                    totalPicCount={props.totalPicCount}
                                    shouldFetchMore={props.shouldFetchMore}
                                    setShouldFetchMore={props.setShouldFetchMore}
                                    picOffset={props.picOffset}
                                    setPicOffset={props.setPicOffset}
                                />
                            }

                            {matches.large &&
                                <DetailImageContainer
                                    listingPics={props.listingPics}
                                    setListingPics={props.setListingPics}
                                    detailedListing={props.detailedListing}
                                    setdetailedListing={props.setDetailedListing}
                                    picIndex={props.picIndex}
                                    setPicIndex={props.setPicIndex}
                                    totalPicCount={props.totalPicCount}
                                    shouldFetchMore={props.shouldFetchMore}
                                    setShouldFetchMore={props.setShouldFetchMore}
                                    picOffset={props.picOffset}
                                    setPicOffset={props.setPicOffset}
                                />
                            }
                        </Fragment>
                    )
                } 
            </Media>
        </>
    )
}