import styled from 'styled-components';


export const FormFrame = styled.div`

    margin-top: 25px;

    width: ${props => props.width ? props.width : "75%"};
    
    background: ${props => props.background ? props.background : "#262626"};
    position: ${props => props.position ? props.position : "fixed"};
    border: 1px solid #DFDFDF;
    overflow-y: ${props => props.overFlowY ? props.overFlowY : "auto"};

    display: flex;
    // align-items: center;
    justify-content: center;
    height: 575px;
    
        @media screen and (min-width: 1920px){
            margin-top: ${props => props.marginTop ? props.marginTop : null};
            height: ${props => props.largeHeight ? props.largeHeight : "900px"};
        }
    `;


export const FormFrameInner = styled.div`
    width: 90%;


    margin: 40px 0;
    
`;

export const HTMLForm = styled.form`

    width: 100%;
    margin-bottom: 50px;

`

// WET ALSO IN FORM COMPONENT
export const ExitFrame = styled.div`

    display: flex;
    justify-content: flex-end;
    width: 100%;
`;
// WET ALSO IN FORM COMPONENT
export const ExitBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background: ${props => props.background ? props.background : "#FFFFFF"};
    cursor: pointer;

    @media only screen and (min-width: 1280px)  {
        height: 40px;
        width: 40px;

    }

    @media only screen and (min-width: 1920px)  {
        height: 50px;
        width: 50px;

    }
    
`;
// WET ALSO IN FORM COMPONENT
export const RightSlash = styled.div`

    position: absolute;
    background: ${props => props.background ? props.background : "#262626"};
    /* White */
    width: 50%;
    height: 1px; 
    // border: 1px solid #FFFFFF;
    transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    
`;
// WET ALSO IN FORM COMPONENT
export const LeftSlash = styled.div`

    position: absolute;
    background: ${props => props.background ? props.background : "#262626"};
    /* White */
    width: 50%;
    height: 1px;    
    // border: 1px solid #FFFFFF;
    transform: rotate(-45deg);
`;


// WET ALSO IN FORM COMPONENT
export const Title = styled.h4`

    
    text-align: center;
    letter-spacing: 1px;
    
    /* h4 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29px;
    margin: 0;
    padding: 0;

    /* black */

    color: ${ props => props.color ? props.color : '#FFFFFF'};
    
    margin-bottom: 8px;

    @media only screen and (min-width: 1280px)  {
        font-size: 2.5rem;
        margin-bottom: 30px;

    }
`;


export const InputFrame = styled.div`


    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

`;


export const InputFrameTop= styled.div`
    display: flex;
    
    justify-content: ${props => props.justifyContent ? props.justifyContent : null};
    align-items: center;

    width: ${props => props ? props.width : null};

`;

export const InputFrameBottom= styled.div`

    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0;

`;

export const InputName = styled.p`
    width: 25%;
    text-align: left;
    letter-spacing: .8px;

    /* h4 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    /* black */

    color: #FFFFFF;


    @media only screen and (min-width: 1280px)  {
        font-size: 1.25rem;

    }

    @media only screen and (min-width: 1920px)  {
        font-size: 1.75rem;

    }


`
export const InputFrameWrapper = styled.div`
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : null};

`

// WET ALSO IN FORM COMPONENT
export const Input = styled.input`

    width: ${props => props.width ? props.width : "60%"};
    background: ${props => props.background ? props.background : "#FFFFFF"};
    border: ${props => props.border ? props.border : "1px solid #DFDFDF"};
    color: ${props => props.color ? props.color : "black"};
    /* Grey / 3 */
    height: ${props => props.height ? props.height : '50px'};
    box-sizing: border-box;
    font-size: 1rem;
    
    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
        padding-left: 1.2rem;

        
    }

    

    @media only screen and (min-width: 1280px)  {
        height: 40px;
    }

    @media only screen and (min-width: 1600px)  {
        height: 50px;
    }
 
`;

export const TextArea = styled.textarea`

    width: ${props => props.width ? props.width : "60%"};
    background: ${props => props.background ? props.background : "#FFFFFF"};
    border: ${props => props.border ? props.border : "1px solid #DFDFDF"};
    color: ${props => props.color ? props.color : "black"};
    /* Grey / 3 */
    height: ${props => props.height ? props.height : '50px'};
    box-sizing: border-box;
    font-size: 1rem;
    
    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
        padding-left: 1.2rem;

        
    }

    

    @media only screen and (min-width: 1280px)  {
        height: 100px;
    }

    @media only screen and (min-width: 1600px)  {
        height: 120px;
    }


`;


// WET FROM FORM COMPONENT
export const SubmitButton = styled.button`
    

    height: ${props => props.height ? props.height : '50px'};
    background: #6C8915;
    //styleName: p / button;
    font-family: Museo Sans Cyrl;
    font-size: 24px;
    font-style: normal;
    color: #FFFFFF;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1.7999999523162842px;
    text-align: center;
    border: none;
    cursor: pointer;


    @media only screen and (min-width: 1280px)  {
        width: 35%;
        height: ${props => props.lapHeight ? props.lapHeight : '50px'};
        font-size: 18px;
    }

    @media only screen and (min-width: 1600px)  {
        height: 82px;
        font-size: 24px;
    }

    @media only screen and (min-width: 1920px)  {
        height: 82px;
        font-size: 24px;
    }


`;

export const ResponseFrame = styled.div`

    background: ${props => props.background ? props.background : null};

    display: flex;
    width: 30%;
    justify-content: center;
    align-items: center;

`;

export const ResponseText = styled.p`

    width: 100%;
    text-align: center;
    font-family: Museo Sans Cyrl;
    font-size: ${props => props.fontSize ? props.fontSize : "18px"};
    color: ${props => props.color ? props.color : "#FF7276"};
    font-weight: 700
    letter-spacing: 2px;
    margin: 0;
`

export const DeleteFrame = styled.div`

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // overflow-y: scroll;
    

`;

export const DeleteText = styled.p`

    text-align: center;
    letter-spacing: 1px;

    /* h4 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29px;
    margin: 0;
    padding: 0;

    /* black */

    color: ${ props => props.color ? props.color : '#FFFFFF'};

    margin-bottom: 65px;

    @media only screen and (min-width: 1280px)  {
        font-size: 2rem;
        margin-bottom: 100px;

    }


`

export const OrderInput = styled.input`

    width: 20px;
    height: 20px;



`;
