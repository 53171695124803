import React from 'react';
import { Bullet } from '../components';

export default function BulletContainer(props){


    return (
        <>

            <Bullet>
                <Bullet.BulletFrameInner marginBottom={props.marginBottom} mobileMarginBottom={props.mobileMarginBottom} marginTop={props.marginTop}>
                    <Bullet.VerticalBar />

                    <Bullet.ItemFrame>
                        <Bullet.ItemTitle>{props.bulletData.header}</Bullet.ItemTitle>
                        <Bullet.List>
                            {   
                                props.bulletData ?
                                    props.bulletData.data.map((item)=>(
                                        <Bullet.ListItem id={item.id}>{item.listItem}</Bullet.ListItem>
                                    ))
                                    :
                                    null
                            }
                        </Bullet.List>
                    
                    </Bullet.ItemFrame>

                </Bullet.BulletFrameInner>
            </Bullet>

        </>

    )
}