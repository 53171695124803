import React, {useContext, useEffect, useState} from 'react';
import NavContainer from '../containers/nav';
import BackgroundIMGContainer from '../containers/backgroundimg';
import HomeSearchContainer from '../containers/homesearch';
import ListingContainer from '../containers/listings';
import CookAuctionContainer from '../containers/cookauction';
import InfoContainer from '../containers/info';
import FooterContainer from '../containers/footer'
import { Wrapper } from '../components'
import RealtyLogo from '../images/DarkLogo4.jpeg' 
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';


//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
//
import { useLocation } from 'react-router-dom';
// helpers
import handleUser from '../helpers/handleuser'


export default function Home (props) {

    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])


    return (
        
        <>
            <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>

                <NavContainer 
                    backgroundColor={'#191919'} border={'2px solid #191919'} 
                    src={RealtyLogo} 
                    profileColor='#ffffff' 

                />
                <BackgroundIMGContainer>
                    <HomeSearchContainer/>
                </BackgroundIMGContainer>
                <ListingContainer/>
                <CookAuctionContainer/>
                <InfoContainer/>
                <FooterContainer backgroundColor={'#262626'}/>
            </Wrapper>

            <SignInSignUpProxyContainer 
            
                isSignInOpen={isSignInOpen}
                setIsSignInOpen={setIsSignInOpen}
                isSignUpOpen={isSignUpOpen}
                setIsSignUpOpen={setIsSignUpOpen}
                isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                
            />
            
        </>
        
    )
}