import React from 'react';
import { BuyerSeller } from '../components'
//config
import { ROUTES } from '../services/config'



export default function BuyerSellerContainer(props) {


    return (
        <>
            <BuyerSeller.BackgroundWrapper>
                <BuyerSeller.MainFrame>
                    {
                        props.data ?
                            props.data.map((item) => (
                                    <>
                                        {/* margin is applied... in the json file the second item will have margin */}
                                        <BuyerSeller.BlockFrame id={item.id} margin={item.margin ? item.margin : null}>
                                            <BuyerSeller.BlockItem>
                                                <BuyerSeller.BlockTextFrame>
                                                    <BuyerSeller.BlockTitle>{item.blockTitle}</BuyerSeller.BlockTitle>
                                                    <BuyerSeller.BlockMainText>{item.mainText}</BuyerSeller.BlockMainText>
                                                    <BuyerSeller.BlockLinkText to={`${item.route}`}>Read more</BuyerSeller.BlockLinkText>
                                                </BuyerSeller.BlockTextFrame>
                                            </BuyerSeller.BlockItem>
                                            {/* leaving this in here for when we decide to add more pages */}
                                            {/* <Buyer.BlockItem>
                                                <Buyer.BlockTextFrame>
                                                    <Buyer.BlockTitle>HOME WARRANTY</Buyer.BlockTitle>
                                                    <Buyer.BlockMainText>Title is sometimes defined as the means whereby an owner is enabled to maintain or assert his possession in enjoyment of property. Another definition is that title is the evidence of right which a person has to the possession of property. </Buyer.BlockMainText>
                                                    <Buyer.BlockLinkText>Read more</Buyer.BlockLinkText>
                                                </Buyer.BlockTextFrame>
                                            </Buyer.BlockItem> */}
                                        </BuyerSeller.BlockFrame>
                                    </>
                                )

                            )
                        :
                        null
                    }
                    <BuyerSeller.ContactButton to={`${ROUTES.CONTACTUS}`}>Contact</BuyerSeller.ContactButton>
                </BuyerSeller.MainFrame>
            </BuyerSeller.BackgroundWrapper>
        </>)
}