import styled from 'styled-components';

export const ListingDetailFrame = styled.div`
    background: #FFFFFF;
    display: flex;
`;

export const ListingDetailFrameInner = styled.div`
    width: 100%;
    margin: 35px 0 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
`;


export const ImageFrame = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 460px;

`;

export const HiddenImage = styled.div`
    width: 24.8197734%;
    height: 420px;

    background: linear-gradient(0deg, rgba(64, 60, 54, 0.59), rgba(64, 60, 54, 0.59)), url(${props => props.url}), #C4C4C4;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    
`;

// export const HiddenImageFrame = styled.div`

//     display: block;
//     width: 24.8197734%;
//     height: 420px;

//     background: linear-gradient(0deg, rgba(64, 60, 54, 0.59), rgba(64, 60, 54, 0.59)), url(${props => props.url}), #C4C4C4;
//     background-repeat: no-repeat;
//     -webkit-background-size: cover;

//     display: flex;
//     align-items: center;


// `;

export const LargeImage = styled.div`
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 50.35%;
    height: 460px;
    background: url(${props => props.url});
    filter: drop-shadow(0px 32px 84px rgba(27, 26, 30, 0.27));
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-position: center center;


`;


export const AttributeFrame = styled.div`

    width: 95%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

`;

export const AttributeFrameInner = styled.div`
    width: 20%;
    display: flex;
    justify-content: flex-end;


 

`

export const Heart = styled.img`

    width: 35px;
    height: 32px;
    cursor: pointer;

`


export const Elipse = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 2rem;
    box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: ${props => props.left ? props.left : null};
    margin-right: ${props => props.right ? props.right : null};
`

export const Arrow = styled.img``;


export const InfoFrame = styled.div`
    
    width: 90%;
    margin-top: 24px;

    @media only screen and (min-width: 1280px)  {

        width: 81.25%;
        margin-top: 55px;
    }
`;

export const MainTitleFrame = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1280px)  {
        
        justify-content: space-between;

    }

`;



export const TopTitleFrame = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;



    @media only screen and (min-width: 1280px)  {

        flex-direction: row;
        
        justify-content: space-between;

    }

`;


export const BottomTitleFrame = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;



export const LeftTitleFrame = styled.div`
    width: 100%; 
    display: flex;
    justify-content: space-between;
    flex-direction row;
    align-items: center;


    overflow: hidden;

    @media only screen and (min-width: 1280px)  {
        width: 60%;

    }
`;

export const TitleText = styled.h3`
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    // line-height: 38px;

    /* black */

    color: #262626;

    margin: 0;
    padding: 0;

    @media only screen and (min-width: 1280px)  {
        font-size: 32px;

    }


`;

export const ExtraFrame = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TypeTextFrame = styled.div`
    width: ${props => props.width ? props.width : null};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const TypeText = styled.h6`

    /* h6 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
    /* identical to box height, or 130% */

    text-transform: uppercase;

    /* Grey / 1 */

    color: #9A9A9A;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;

    @media only screen and (min-width: 1920px)  {

        
        font-size: 20px;

    }

`;

export const LocationIcon = styled.img`
    height: 12px;
    width: 12px;

    @media only screen and (min-width: 1280px)  {
        width: 12px;
        height: 16px;

    }
`;

export const Dot = styled.div`
    /* Ellipse 31 */
    position: static;
    width: 4px;
    height: 4px;
    left: 0px;
    top: 0px;

    background: #C4C4C4;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 5px;

    border-radius: 10px;
`;



export const RightTitleFrame = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

`;

export const Amount = styled.h2`

    /* h2 */
    
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    /* or 131% */

    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;

    color: #6C8915;

    margin-top: 12px;
    margin-bottom: 16px;
    padding: 0;

    @media only screen and (min-width: 1280px)  {

        margin-top: 12px;

    }

`;

export const RightText = styled.p`

    display: none;

    @media only screen and (min-width: 1280px)  {
        
        display: block;
        /* p / content */
        margin: 0;
        padding-bottom: 10px;
        font-family: Museo Sans Cyrl;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        /* identical to box height, or 128% */

        text-align: right;
        letter-spacing: 0.3px;

        /* black */

        color: #262626;

    }
    

    
`

export const HorizontalBar = styled.div`

    /* Grey / 3 */
    width: 100%;
    border: 1px solid #DFDFDF;
    margin: 18px 0 24px 0;

    @media only screen and (min-width: 1280px)  {

        margin: ${props => props.margin ? props.margin : '30px 0 55px 0'};
    }

`;

export const FactsFrame = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-between;
    
`;

export const FactsFrameLeft = styled.div`

    width: 100%;

    @media only screen and (min-width: 1280px)  {

        width: 53.032929%
    }
    

`;

export const FactsDetailFrame = styled.div`

    display: flex;
    justify-content: space-between;


    @media only screen and (min-width: 1280px)  {

        margin-left: 16px;
    }
`;


export const FactsText = styled.p`
    
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* or 144% */
    background: #F4F4F5;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    

    /* black */

    color: #262626;

    width: 40%;
    padding-top: 9px;
    padding-bottom: 6px;
    padding-left: 15px;

    @media only screen and (min-width: 1280px)  {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 4px;
        padding-left: 16px;
        width: 25%;
    }
`


export const FactsData = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 144% */
    background: #F4F4F5;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    /* black */
    
    color: #262626;
    width: 45%;

    padding-top: 9px;
    padding-bottom: 6px;
    padding-left: 15px;

    @media only screen and (min-width: 1280px)  {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 4px;
        padding-left: 16px;
        width: 65%;
    }
`;


export const FactsFrameRight = styled.div`


    display: none;

    @media only screen and (min-width: 1280px)  {
        // height: 100vh;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #FFFFFF;
        /* Grey / 3 */
    
        border: 1px solid #DFDFDF;
        box-sizing: border-box;  

    }

`;


export const FactsFrameRightInner = styled.div`


    dipslay: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 83.783784%;

`;

export const ContactTitle = styled.h6`

    /* h6 */
    margin: 28px 0 0 0;
    padding: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */

    text-transform: uppercase;

    /* black */

    color: #262626;

`;

export const ContactFrame = styled.div`

    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
`;

export const ContactFrameLeft = styled.div`

    display: flex;
    width: 30%;
`;

export const ContactImage = styled.img`

    width: 100%;

`;

export const ContactFrameRight = styled.div`

    width: 65%;
`;

export const ContactFrameName = styled.h4`


    /* h4 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    /* black */

    color: #262626;


`;

export const ContactFrameNumber = styled.p`
    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;


`;

export const ContactFrameSocialFrame = styled.div`
    display: flex;
    width: 35%;
    justify-content: space-evenly;

`;

export const ContactFrameSocialIcon = styled.img`
    height: 16px;
    width: 16px;
`;

export const ContactButton = styled.div`
    margin-top: 30px;
    background: #DD6A6B;
    width: 100;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

`;

export const ContactButtonText = styled.p`
/* p / button */
    
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    color: #FFFFFF;

`;


export const DescriptionFrame = styled.div`

    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;


    @media only screen and (min-width: 1280px)  {
        margin-top: 50px;
        width: 53.032929%;
    }
`;  


export const DescriptionTitle = styled.h6`

    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    /* h6 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */

    text-transform: uppercase;

    /* black */

    color: #262626;

    @media only screen and (min-width: 1280px)  {
        margin-bottom: 20px;
    }

`;

export const DescriptionP = styled.p`
    
    margin-top: 20px;
    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;


`;


export const MapFrame = styled.div`

    width: 100%;

    @media only screen and (min-width: 1280px)  {
        width: 81.25%;
    }
`;

