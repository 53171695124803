

export default function logout(user, setUser){

    const token = localStorage.getItem('accessToken')
    if(user){
        if(token){
            localStorage.removeItem('user')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refresh')
            setUser(false)
        } else {
            setUser(false)
        }
    }
    
}