
export default function displayCorrectOptions(propertyClass){

    // whatever the property class is that is passed the array for those choices will be returned


    switch(propertyClass) {

        case "RE_1":
            return residentialChoices
        
        case "LD_2":
            return landChoices
        
        case "CI_3":
            return commercialChoices

        case "MF_4":
            return multiFamilyChoices

        default:
            break
    }

}



export const residentialChoices = [
    {value: 'Single Family', label: 'Single Family'},
    {value: "Single Family > 5 Acres", label: "Single Family > 5 Acres"},
    {value: "Single Family <= 5 Acres", label: "Single Family < 5 Acres"},
    {value: "Condo/Townhouse", label: "Condo/Townhouse"},
    {value: "Manufactured w/o Land", label: "Manufactured w/o Land"},
    {value: "Manufactured w/ Land", label: "Manufactured w/ Land"},
]


export const landChoices = [
    {value: '0 - 40 AC with Manuf. Home', label: '0 - 40 AC with Manuf. Home'},
    {value: "0 - 40 Acres No Home", label: "0 - 40 Acres No Home"},
    {value: "0 - 40 Acres w/Home", label: "0 - 40 Acres w/Home"},
    {value: "More than One House", label: "More than One House"},
    {value: "40 Acres and Up w/Home", label: "40 Acres and Up w/Home"},
    {value: "Vacant Lot(s)", label: "Vacant Lot(s)"},
    {value: "Waterfront w/Home", label: "Waterfront w/Home"},
    {value: "Waterfront Lot(s)", label: "Waterfront Lot(s)"},
    {value: "300 Acres+ No Home", label: "300 Acres+ No Home"}
]


export const commercialChoices = [
    {value: 'Business Only', label: 'Business Only'},
    {value: "Campground/Resort", label: "Campground/Resort"},
    {value: "Commercial", label: "Commercial"},
    {value: "Mobile Home Park", label: "Mobile Home Park"},
    {value: "Office", label: "Office"},
    {value: "Vacant Ground", label: "Vacant Ground"},
]


export const multiFamilyChoices = [
    {value: 'Duplex', label: 'Duplex'},
    {value: "Fourplex", label: "Fourplex"},
    {value: "Mobile Home Park", label: "Mobile Home Park"},
    {value: "Other", label: "Other"},
    {value: "Sixplex", label: "Sixplex"},
    {value: "Triplex", label: "Triplex"},
]