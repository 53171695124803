import React, {useState, useEffect, useContext} from 'react'
import { useLocation } from 'react-router-dom';
import { Form, Privacy} from '../components'
import privacyPolicy from '../data/privacypolicy/privacypolicy.json'
// containers
import NavContainer from '../containers/nav';
import FooterContainer from '../containers/footer';
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';
// components
import { Wrapper } from '../components'
//images
import CookLandRealty from '../images/CookLandRealty.svg'
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
// helpers
import handleUser from '../helpers/handleuser'



/// THIS FILE IS NOT THE Terms and Conditions POPOUT...
/// THIS IS USED FOR GOOGLE AND FACEBOOK AUTH SO THEY HAVE A PAGE TO REFERENCE WHEN USING IN PRODCUTION


export default function PrivacyPolicy(){

    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])


    return (
        <>

            <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>
                <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>

                    
                    <Privacy>
                        <Privacy.PrivacyFrameInner>
                            <Form.Title>Privacy Policy</Form.Title>
                            <Privacy.UpdatedText>Last updated: March 31, 2021</Privacy.UpdatedText>
                            {
                                privacyPolicy ?
                                privacyPolicy.map((privacy)=>(
                                    <>
                                        <Privacy.PrivacyContentFrame>
                                            <Privacy.PrivacyTitle>{privacy.Title}</Privacy.PrivacyTitle>
                                            <Privacy.PrivacyParagraph>{privacy.Content}</Privacy.PrivacyParagraph>
                                            {privacy.Bullet?
                                                privacy.Bullet.map((bullet)=>(
                                                    <Privacy.PrivacyBullet>{bullet}</Privacy.PrivacyBullet>
                                                ))
                                                :
                                                null
                                            }
                                        </Privacy.PrivacyContentFrame>
                                    </>
                                ))
                                :
                                null
                            }
                        </Privacy.PrivacyFrameInner>

                    </Privacy>
                    <Privacy.PrivacyFrameBottom>
                        <Privacy.PrivacyBottomFrameInner>
                            
                        </Privacy.PrivacyBottomFrameInner>
                    </Privacy.PrivacyFrameBottom>


        
                <FooterContainer backgroundColor='#262626'/>
            </Wrapper>
            <SignInSignUpProxyContainer 
            
                isSignInOpen={isSignInOpen}
                setIsSignInOpen={setIsSignInOpen}
                isSignUpOpen={isSignUpOpen}
                setIsSignUpOpen={setIsSignUpOpen}
                isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                
            />





            

        </>
        
    )
}