import React, { useState, useEffect} from 'react';
import { MobileDetailImage }     from '../components'
import LoadingContainer from './loading';

// api
import { getUsersFavoriteListingsAndMatch } from '../api/userfavoritelistings';
import deleteFavorite from '../api/deletefavorite'
// helpers
import {handleImageChange} from '../helpers/handleimagechange'
import { mobileImageHandler } from '../helpers/imagehandler'
import handleTheFavoriteListing from '../helpers/handlethefavoritelisting'
// images
import Right from '../images/Right.svg';
import Left from '../images/Left.svg';
import Favorite from '../images/Favorite.png';
import PinkHeart from '../images/PinkHear.svg';

export default function MobileDetailImageContainer(props){

    const [ isFavorite, setIsFavorite ] = useState();


    useEffect(() => {
        setIsFavorite(false)
        if (localStorage.getItem('user')){
            getUsersFavoriteListingsAndMatch(parseInt(localStorage.getItem('listingID')) , setIsFavorite)
        }   
        // this works to reload the page but every time more images are called than the component reloads which provides a non optimal UX
        // need another solution
    }, [props.listingPics])

    if (props.listingPics  && props.detailedListing ){
        return (
            <>
    
                <MobileDetailImage>
                    <MobileDetailImage.ImageFrame url={props.listingPics ? mobileImageHandler(props.detailedListing, props.listingPics, props.picIndex) : null} alt='pic'>
                        <MobileDetailImage.ImageFrameInner>

                            <MobileDetailImage.ImageFrameInnerTop>
                                {
                                    isFavorite ?
                                    <MobileDetailImage.Heart 
                                    
                                        src={PinkHeart}
                                        onClick={()=>{
                                            setIsFavorite(false)
                                            deleteFavorite(props.detailedListing.id)
                                        }}

                                    
                                    
                                    />
                                    :
                                    <MobileDetailImage.Heart 
                                        src={Favorite}
                                        onClick={()=>{
                                            setIsFavorite(true)
                                            handleTheFavoriteListing(props.detailedListing.id, props.detailedListing.Type, props.detailedListing.Class)
                                        }}
                                    />
                                }
                                
                            </MobileDetailImage.ImageFrameInnerTop>


                            <MobileDetailImage.ImageFrameInnerBottom>

                                <MobileDetailImage.Elipse >
                                    <MobileDetailImage.Arrow src={Left} onClick={()=> handleImageChange('DECREMENT', props.picIndex, props.setPicIndex, props.listingPics, props.setListingPics, props.totalPicCount, props.shouldFetchMore, props.setShouldFetchMore, props.picOffset, props.setPicOffset)}/>
                                </MobileDetailImage.Elipse>

                                <MobileDetailImage.Elipse>
                                    <MobileDetailImage.Arrow src={Right} onClick={()=> handleImageChange('INCREMENT', props.picIndex, props.setPicIndex, props.listingPics, props.setListingPics, props.totalPicCount, props.shouldFetchMore, props.setShouldFetchMore, props.picOffset, props.setPicOffset)}/>
                                </MobileDetailImage.Elipse>

                            </MobileDetailImage.ImageFrameInnerBottom>
                        </MobileDetailImage.ImageFrameInner>
                    </MobileDetailImage.ImageFrame>
                </MobileDetailImage>
    
            </>
        )
    } else {
        return (
            <>
                <MobileDetailImage>
                    <LoadingContainer/>
                </MobileDetailImage>
            </>
        )
    }
   
}