



export default function validateForm(firstName, lastName, emailAddress, phoneNumber, password1, password2){

    let errors = {}

    let firstNameError = null
    let lastNameError = null
    let emailAddressError = null
    let phoneNumberError = null
    let password1Error = null
    let password2Error = null

    if(!firstName){
        firstNameError = "Must provide a first name"
        errors = {firstNameError: firstNameError}
    }
    if (!lastName){
        lastNameError = "Must provide a last name"
        errors = {...errors, lastNameError: lastNameError}
    }
    if(!emailAddress){
        emailAddressError = "Must provide an email address"
        errors = {...errors, emailAddressError: emailAddressError}
    }
    if(!phoneNumber){
        phoneNumberError = "Must provide an phone number"
        errors = {...errors, phoneNumberError: phoneNumberError}
    }
    if(!password1){
        password1Error = "Must provide a password"
        errors = {...errors, password1Error: password1Error}
    }
    if(!password2){
        password2Error = "Must Confirm password"
        errors = {...errors, password2Error: password2Error}
    }

    // check to make sure only letters in first and last name
    const nameRE = /^[a-zA-Z]+$/
    if(!nameRE.test(firstName) && firstName.length > 40){
        firstNameError = "First Name can only have letters"
        errors = {...errors, firstNameError: firstNameError}
    }
    if(!nameRE.test(lastName) && firstName.length > 50){
        lastNameError = "First Name can only have letters"
        errors = {...errors, lastNameError: lastNameError}
    }

    const phoneNumberRE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    if (!phoneNumberRE.test(phoneNumber)){
        phoneNumberError = "Incorrect phone number format"
        errors = {...errors, phoneNumberError: phoneNumberError}
    }

    const passwordRE = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/
    if (!passwordRE.test(password1)){
        password1Error = 'Password must have at least 8 characters, 1 number, 1 symbol, 1 lower, and 1 upper'
        errors = {...errors, password1Error: password1Error}
    }

    if (password2 !== password1){
        password2Error = "Passwords must match"
        errors = {...errors, password2Error: password2Error}
    }



    if (Object.keys(errors).length === 0){
        return false
    } else {
        return errors
    }
    


}