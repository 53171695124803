
import axios from 'axios'
import getCookie from '../helpers/getcookie';
import { URLS } from '../services/config'


axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function deleteUser(data, setSuccess, setFailure, setUser){


    const csrf = getCookie('csrftoken')
    const token = localStorage.getItem('accessToken')
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${token}`,
            "X-CSRFTOKEN": `${csrf}`
        }, 
        data
    }

    axios.delete(`${URLS.BASE_URL}/${URLS.USERDELETE}`, config)
    .then(response => {
        setSuccess('User Deleted')
        setUser(null)
        document.body.style.overflow = 'unset';
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refresh')

    })
    .catch(err => {
        setFailure('User Not Deleted')
    })

}