import React from 'react'
import { FavoriteFrame, FavoriteFrameInner, FavoriteBox, FavoriteGridInner, TitleFrame, TitleTextFrame, TitleText} from './styles/favorite'

export default function Favorite({children, ...restProps}){
    return <FavoriteFrame {...restProps}>{children}</FavoriteFrame>
}

Favorite.FavoriteFrameInner = function FavoriteFavoriteFrameInner({children, ...restProps}) {
    return <FavoriteFrameInner {...restProps}>{children}</FavoriteFrameInner>
}

Favorite.FavoriteBox = function FavoriteFavoriteBox({children, ...restProps}) {
    return <FavoriteBox {...restProps}>{children}</FavoriteBox>
}

Favorite.TitleFrame = function FavoriteTitleFrame({children, ...restProps}) {
    return <TitleFrame {...restProps}>{children}</TitleFrame>
}

Favorite.TitleTextFrame = function FavoriteTitleTextFrame({children, ...restProps}) {
    return <TitleTextFrame {...restProps}>{children}</TitleTextFrame>
}

Favorite.TitleText = function FavoriteTitleText({children, ...restProps}) {
    return <TitleText {...restProps}>{children}</TitleText>
}

Favorite.FavoriteGridInner = function FavoriteFavoriteGridInner({children, ...restProps}) {
    return <FavoriteGridInner {...restProps}>{children}</FavoriteGridInner>
}
