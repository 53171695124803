import { useState } from 'react'

import createContactUs, { contactUsListingDetail } from '../api/contactus'


export default function useContact(page, detailedListing){

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [contactTime, setContactTime] = useState();
    const [buy, setBuy] = useState(false);
    const [sell, setSell] = useState(false);
    const [message, setMessage] = useState();
    const [formSuccess, setFormSuccess] = useState(null);
    const [formErrors, setFormErrors] = useState(null);

    let handleSubmit

    switch(page){
        case "partialForm":
        
            handleSubmit = (e)=>{
                e.preventDefault();
                // createContactUs(setFormSuccess, setFormErrors, firstName, lastName, phoneNumber, email, buy, sell, contactTime, message)
                window.grecaptcha.ready(function() {
                    window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'submit'}).then(function(token) {
                      createContactUs(setFormSuccess, setFormErrors, firstName, lastName, phoneNumber, email, buy, sell, token, contactTime, message)
                    });
                });
        
        
                
            }
            break;
        
        case "fullForm":

            if (detailedListing){
                handleSubmit = (e)=>{
                    e.preventDefault();
            
                    window.grecaptcha.ready(function() {
                        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'submit'}).then(function(token) {
                            contactUsListingDetail(firstName, lastName, phoneNumber, email, message, detailedListing, setFormSuccess, setFormErrors, token)
                        });
                    });
                }
            }
            break;
        
        default:
            return null;
    }


    return { setFirstName, setLastName,  setEmail, setPhoneNumber, setContactTime, setBuy, setSell, setMessage, handleSubmit, buy, sell, formErrors, formSuccess}


}