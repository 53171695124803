import React from 'react';
import {BackGroundIMG} from '../components'
import BackgroundImage from '../images/Rectangle22.png' 


export default function BackGroundIMGContainer({children, ...restProps}) {

    return (
        <>
            <BackGroundIMG url={BackgroundImage}>
                <BackGroundIMG.TitleFrame>
                    <BackGroundIMG.TitleTextFrame>
                        <BackGroundIMG.TitleText>WELCOME TO</BackGroundIMG.TitleText>
                        <BackGroundIMG.TitleText>COOK LAND & REALTY</BackGroundIMG.TitleText>
                    </BackGroundIMG.TitleTextFrame>
                </BackGroundIMG.TitleFrame>
                {children}
            </BackGroundIMG>
            
        </>)



}