import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom'; 

export const CookAuctionBackground = styled.div`
    background: linear-gradient(0deg, rgba(64, 60, 54, 0.74), rgba(64, 60, 54, 0.74)), url(${props => props.url});
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        height: 625px;
        background: linear-gradient(0deg, rgba(64, 60, 54, 0.65), rgba(64, 60, 54, 0.65)), url(${props => props.url});
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-position: center center;
    }
`;

export const ItemContainer = styled.div`
    height: 492px;
    width: 100%;
    margin: 70px 0 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 2px solid yellow;
`;

export const Logo = styled.img`

    width: 40%;

    @media only screen and (min-width: 1280px)  {
        width: 8.3333333333%;
    }   
`;

export const Title = styled.h1`
    margin: 30px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 66px;
    /* or 118% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* White */
    color: #FFFFFF;

    @media only screen and (min-width: 1280px)  {
        font-size: 56px;
    }

`;


export const Paragraph = styled.p`

    /* p / content */
    margin: 0;
    padding: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    text-align: center;
    letter-spacing: 0.3px;

    /* White */

    color: #FFFFFF;

`;

export const Link = styled.a`
    width: 95%;
    margin-top: 42px;
    text-decoration: none;
    display: flex;
    justify-content: center;

`

export const LearnMoreDiv = styled.div`
    
    height: 80px;
    width: 95%;
    background: #6C8915;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #FFFFFF;
    cursor: pointer;

    @media only screen and (min-width: 1280px)  {
        width: 22.63888889%;
    }
`;

export const LearnMoreText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    
    letter-spacing: 4.8px;
    text-align: center;
    /* White */

    color: #FFFFFF;

`;