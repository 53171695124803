import React from 'react';
import { ListingDetail } from '../components';
import JeffAngold from '../images/Jeff.jpg';
import Twitter from '../images/Twitter.svg';
import Facebook from '../images/Facebook.svg';
import Instagram from '../images/Instagram.svg';
import Location from '../images/location.png'

// helpers
import openInNewTab from '../helpers/openinnewtab'


// if the data doesnt exist...
// along the vertical axis remove the certain items and only display if that data does exist
// add 

export default function DetailInfoContainer(props){

    // fucntion for handling the display of the listing agent
    function handleListingAgent(){
        if(props.detailedListing){
            if(props.detailedListing.Type === "Cook"){
                return "Jeff Angold"
            }
    
            if (props.detailedListing.Type === "MLS"){
                return `${props.detailedListing.AgentFirstName} ${props.detailedListing.AgentLastName}`
            }
        }
    }

    // function for handling the display of the listing office
    function handleListingOffice(){

        if(props.detailedListing){
            if(props.detailedListing.Type === "Cook"){
                return "Cook Land & Realty LLC"
            }
    
            if (props.detailedListing.Type === "MLS"){
                return `${props.detailedListing.ListingOffice}`
            }
        }
    }

    // functin for handling the display of the tour url provided by Cook backend or MLS
    function handleVideoURL(){

        if(props.detailedListing){
            if(props.detailedListing.TourURL){
                return <ListingDetail.FactsData style={{cursor: "pointer"}} onClick={()=>openInNewTab(props.detailedListing.TourURL)}>Click to see video</ListingDetail.FactsData>
            }
        }
        return <ListingDetail.FactsData>{null}</ListingDetail.FactsData>
    }

    return (

        <>

            <ListingDetail.InfoFrame>

                <ListingDetail.MainTitleFrame>


                    <ListingDetail.TopTitleFrame>
                        <ListingDetail.TitleText>{props.detailedListing ? props.detailedListing.Name.slice(0,65) : null}</ListingDetail.TitleText>
                        <ListingDetail.Amount>{props.detailedListing ? props.detailedListing.Status === "Sold" ? "Sold" : props.detailedListing.AskingPrice === "0.00" ? "Contact for Price" : `$${Math.round(props.detailedListing.AskingPrice).toLocaleString()}`: null}</ListingDetail.Amount>
                    </ListingDetail.TopTitleFrame>

                    <ListingDetail.BottomTitleFrame>

                        <ListingDetail.LeftTitleFrame>
                            <ListingDetail.TypeText>{ props.detailedListing ? props.detailedListing.Class : null}</ListingDetail.TypeText>
                            
                            {
                                props.detailedListing ?
                                props.detailedListing.Acres ?
                                <>
                                    <ListingDetail.Dot/>
                                    <ListingDetail.TypeText>{props.detailedListing.Acres ? `Acres ${props.detailedListing.Acres}` : null}</ListingDetail.TypeText>
                                </>
                                :
                                null
                                :
                                null
                            }
                            
                            
                            {   
                                props.detailedListing ? 
                                props.detailedListing.SquareFeet ?
                                <>
                                    <ListingDetail.Dot/>
                                    <ListingDetail.TypeText>{props.detailedListing.SquareFeet ? `Sqr Ft ${props.detailedListing.SquareFeet}` : null }</ListingDetail.TypeText>
                                </>
                                :
                                null
                                :
                                null
                            }
                            <ListingDetail.Dot/>
                            <ListingDetail.LocationIcon src={Location}/>
                            <ListingDetail.TypeText>Location</ListingDetail.TypeText>
                            
                        </ListingDetail.LeftTitleFrame>

                        <ListingDetail.RightText>{props.detailedListing ? props.detailedListing.Status === "Available" ? "Available" : props.detailedListing.Status === "Active" ? "Available" : props.detailedListing.Status === "Sold" ? "Sold" : props.detailedListing === "Sale Pending" ? "Sale Pending" : "Sale Pending" : null}</ListingDetail.RightText>

                    </ListingDetail.BottomTitleFrame>

                </ListingDetail.MainTitleFrame>

                <ListingDetail.HorizontalBar/>

                <ListingDetail.FactsFrame>
                    <ListingDetail.FactsFrameLeft>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Type</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing ? props.detailedListing.PropertyType : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Sub Type</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing ? props.detailedListing.Keyword : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Listing Status</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing === true ?  'Sold' : 'For Sale'}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Street Address</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing ? props.detailedListing.Address : null} </ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>City</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing ? props.detailedListing.City : null} </ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>State</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing ? props.detailedListing.State : null} </ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>County/Area</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{ props.detailedListing ? props.detailedListing.County: null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Acres</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{props.detailedListing ? props.detailedListing.Acres : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Square Feet</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{props.detailedListing ? props.detailedListing.SquareFeet ? `${props.detailedListing.SquareFeet} ft` : 'Contact for more info' : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Listing Agent</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{props.detailedListing ? handleListingAgent() : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Listing Office</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{props.detailedListing ? handleListingOffice() : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>Video URL</ListingDetail.FactsText>
                            {handleVideoURL()}
                        </ListingDetail.FactsDetailFrame>
                        <ListingDetail.FactsDetailFrame>
                            <ListingDetail.FactsText>MLS #</ListingDetail.FactsText>
                            <ListingDetail.FactsData>{props.detailedListing ? props.detailedListing.id : null}</ListingDetail.FactsData>
                        </ListingDetail.FactsDetailFrame>
                    </ListingDetail.FactsFrameLeft>

                    <ListingDetail.FactsFrameRight>
                        <ListingDetail.FactsFrameRightInner>
                            <ListingDetail.ContactTitle>CONTACT AGENT</ListingDetail.ContactTitle>
                            <ListingDetail.HorizontalBar margin='16px 0 30px 0'/>
                            <ListingDetail.ContactFrame>
                                <ListingDetail.ContactFrameLeft>
                                    <ListingDetail.ContactImage src={JeffAngold}/>
                                </ListingDetail.ContactFrameLeft>
                                <ListingDetail.ContactFrameRight>
                                    <ListingDetail.ContactFrameName>Jeff Angold</ListingDetail.ContactFrameName>
                                    <ListingDetail.ContactFrameNumber>+(913) 231-8292</ListingDetail.ContactFrameNumber>
                                    <ListingDetail.ContactFrameSocialFrame>
                                        <ListingDetail.ContactFrameSocialIcon src={Twitter}/>
                                        <ListingDetail.ContactFrameSocialIcon src={Facebook}/>
                                        <ListingDetail.ContactFrameSocialIcon src={Instagram}/>
                                    </ListingDetail.ContactFrameSocialFrame>
                                </ListingDetail.ContactFrameRight>
                            </ListingDetail.ContactFrame>
                            <ListingDetail.ContactButton 
                                onClick={()=>{
                                        props.setIsContactFormOpen(!props.isContactFormOpen)
                                        document.body.style.overflow = 'hidden';
                                        }
                                    }
                                >
                                <ListingDetail.ContactButtonText>CONTACT</ListingDetail.ContactButtonText>
                                
                            </ListingDetail.ContactButton>
                            
                        </ListingDetail.FactsFrameRightInner>
                    </ListingDetail.FactsFrameRight>
                </ListingDetail.FactsFrame>

                <ListingDetail.DescriptionFrame>
                    <ListingDetail.DescriptionTitle>DESCRIPTION:</ListingDetail.DescriptionTitle>
                    <ListingDetail.DescriptionP>
                        {props.detailedListing ? props.detailedListing.Description : null}
                    </ListingDetail.DescriptionP>

                    <ListingDetail.DescriptionTitle>LOCATION:</ListingDetail.DescriptionTitle>
                    <ListingDetail.DescriptionP>
                        {props.detailedListing ? props.detailedListing.Location : null}
                    </ListingDetail.DescriptionP>
                </ListingDetail.DescriptionFrame>

            </ListingDetail.InfoFrame>

            {props.children}

        </>
    )
}