import axios from 'axios'
import {URLS, ROUTES} from '../services/config'


export default function passwordReset(uid, token, passsword1, password2, history){

    axios.post(`${URLS.BASE_URL}/${URLS.PASSWORDRESETCONFIRM}`, {
        uid: uid,
        token: token,
        new_password: passsword1,
        re_new_password: password2
    })
    .then(response => {
        if(response.status === 204){
            history.push(ROUTES.HOME)
        }
    })
    .catch(err => console.log(err.response))
}


