import axios from 'axios';
import {URLS} from '../services/config'



export default function emailPasswordReset(emailAddress, setResetResponse){
    axios.post(`${URLS.BASE_URL}/${URLS.EMAILPASSWORDRESET}`,{
        email: emailAddress
    })
    .then(response => {
        if (response.status === 204){
            setResetResponse('A Link has been sent to your email')
        }
    })
}