import React from 'react'
import {HomeSearchFrame, HomeSearchFrameInner,  HomeSearchBar, InputFrame, MagnifyGlass, VerticalBar, MenuFrame, HomeSearchButton } from './styles/homesearch'
import Select from 'react-select'

export default function HomeSearch({children, ...restProps}){
    return <HomeSearchFrame {...restProps}>{children}</HomeSearchFrame>
}

HomeSearch.HomeSearchFrameInner = function HomeSearchHomeSearchFrameInner({children, ...restProps}){
    return <HomeSearchFrameInner {...restProps}>{children}</HomeSearchFrameInner>
}


HomeSearch.HomeSearchBar = function HomeSearchHomeSearchBar({children, ...restProps}){
    return <HomeSearchBar {...restProps}>{children}</HomeSearchBar>
}

HomeSearch.InputFrame = function HomeSearchInputFrame({children, ...restProps}){

    return <InputFrame {...restProps} type='HomeSearch' placeholder='Location'>{children}</InputFrame>
}

HomeSearch.MagnifyGlass = function HomeSearchMagnifyGlass({children, ...restProps}){

    return <MagnifyGlass {...restProps}>{children}</MagnifyGlass>
}

HomeSearch.VerticalBar = function HomeSearchVerticalBar({children, ...restProps}){

    return <VerticalBar {...restProps}>{children}</VerticalBar>
}

HomeSearch.MenuFrame = function HomeSearchMenuFrame({children, ...restProps}){

    return <MenuFrame {...restProps}>{children}</MenuFrame>
}

HomeSearch.MenuSelect = function HomeSearchMenuSelect({children, ...restProps}){

    const color = restProps.typeError

    const customStyles = {

        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            color: state.selectProps.menuColor,
            padding: 10,
          }),

        input: (provided)=> ({
            ...provided,
            height: '110px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: "18px",
   
        }),

        valueContainer: (provided, state)=> ({
            ...provided,
            height: '110px',
            
        }),

        container: (provided)=>({
            ...provided,
            width: '95%;',
            border: `2px solid ${color}`
        }),

        dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
            height: "80px;",
            display: 'flex;',
            alignItems: 'center;',
            fontSize: "100px;"
        }),

        indicatorSeparator: () => ({
        }),

        control: (provided) => ({
            ...provided,
            border: 'none;'
        }),

        singleValue: (provided) => ({
            ...provided,
            color: "black",
            fontSize: '20px',
            fontWeight: "700"
        })
    
    }

    const handleParamsChange = (e) => {



        let newParams;

        // checks to see the name prop
        // this helps distinguish which state to change in the context
        switch(restProps.name){
            
            case "Property Class":
                newParams = restProps.searchParams
                newParams.propertyClass = e.value
                restProps.setSearchParams(newParams)
                break;

            case "Location":
                newParams = restProps.searchParams
                newParams.location = e.value
                restProps.setSearchParams(newParams)
                break;
            
            default:

                return null;
        }
    }
    
    return <Select 
                defaultValue={restProps.defaultVal ? { label: restProps.defaultVal, value: restProps.defaultVal} : { label: restProps.name, value: '' }} 
                options={restProps.options} 
                styles={customStyles} 
                onChange={handleParamsChange}
            >
                {children}
            </Select>
}

HomeSearch.HomeSearchButton = function HomeSearchHomeSearchButton({children, ...restProps}){

    return <HomeSearchButton {...restProps}>{children}</HomeSearchButton>
}