import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';

// Context
import {UserContext} from './context/user';
import {SearchResultContext} from './context/searchresult';
import { SignInSignUpContext } from './context/signinsignup';
import { SearchParamsContext } from './context/searchparams'

// Pages of the app below
import Home from './pages/home';
import OurListings from './pages/ourlistings'
import Buyer from './pages/buyer';
import Seller from './pages/seller';
import ListingDetail from './pages/listingdetail';
import SearchResult from './pages/searchresult';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contactus';
import PasswordReset from './pages/passwordreset'
import VerifyAccount from './pages/verrifyaccount';
import Favorite from './pages/favorite';
import Settings from './pages/settings'
import ReadyToBuy from './pages/buyer/readytobuy'
import PrepareForClosing from './pages/buyer/prepareforclosing'
import TermsYouShouldKnow from './pages/buyer/termsyoushouldknow'
import ReadyToSell from './pages/seller/readytosell'
import MaximizeLandValue from './pages/seller/maximizelandvalue';
import WhatSetsUsApart from './pages/seller/whatsetsusapart';
import PrivacyPolicy from './pages/privacypolicy';
import BuyingVsRenting from './pages/buyer/buyingvsrenting';
import PreApproval from './pages/buyer/preapproval';
import TEN31 from './pages/seller/1031';

// Staff Pages Below
import StaffDashboard from './pages/staff/staffdashboard';
import StaffUsers from './pages/staff/staffusers';
import StaffListings from './pages/staff/stafflistings';
import StaffMessages from './pages/staff/staffmessages';
import StaffLogin from './pages/staff/stafflogin';


// Config
import {ROUTES} from './services/config'


//api
import checkUser from './api/checkuser'


function App() {

  // Global State Context
  const [user, setUser] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [ searchParams, setSearchParams ] = useState(sessionStorage.getItem('searchParms'))

  // State
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect( () => {
    
    // api that chekcs if the access token is valid and then sets the data accordingly
    checkUser(setUser)

    // Every time the app loads then this will run
    // Upon the first load of the browser session there will be no search param so the data will be set to the default searchParams
    let searchParamsData = sessionStorage.getItem('searchParams')
    if(!searchParamsData){

      const seachParamData = {
            
        location: '',
        propertyClass: 'RE_1', // default to this so when the user clicks on the MLS Search tab it sets this as the default search so when detail listing gets clicked it knows the class
        minPrice: '',
        maxPrice: '',
        pageCountIndex: 0,
        offset: 0
        
      }
      setSearchParams(seachParamData)
      sessionStorage.setItem('searchParams', JSON.stringify(seachParamData))
    } else {
      setSearchParams(JSON.parse(searchParamsData))
    }
      
    

    


    // anytime the app is loaded the app should check for searchParams and run that custom hook as necessaryy
    // if sessionStorage exist than do nothing else set searchParams

  }, [])


  return (
    <>
      <UserContext.Provider value={[user, setUser]}>
        <SearchParamsContext.Provider value={[searchParams, setSearchParams]}> 
            <SignInSignUpContext.Provider 
              value={{
                signIn: [isSignInOpen, setIsSignInOpen], 
                signUp: [isSignUpOpen, setIsSignUpOpen],
                errorMessage: [errorMessage, setErrorMessage]
              }}
            >
              <Router>

                <Switch>
                  <Home
                    exact 
                    path={ROUTES.HOME}
                  />
                </Switch>

                {/* this is in place so it routes to facebook page and then can log user in */}
                <Switch>
                  <Home
                    exact 
                    path={`/facebook`}
                  />
                </Switch>

                <Switch>
                  <OurListings
                    exact
                    path={ROUTES.OURLISTINGS}
                  />
                </Switch>

                <Switch>
                  <ListingDetail
                    exact
                    path={ROUTES.PROPERTY}
                  />
                </Switch>

                <Switch>
                  <SearchResult
                    exact
                    path={ROUTES.SEARCHRESULT}
                  />
                </Switch>


                <Switch>
                  <AboutUs
                    exact
                    path={ROUTES.ABOUTUS} 
                  />
                </Switch>

                <Switch>
                  <ContactUs
                    exact
                    path={ROUTES.CONTACTUS} 
                  />
                </Switch>


                <Switch>
                  <PasswordReset
                    exact
                    path={ROUTES.CONFIRMPASSWORDRESET} 
                  />
                </Switch>

                <Switch>
                  <VerifyAccount
                    exact
                    path={ROUTES.ACTIVATE} 
                  />
                </Switch>

                <Switch>
                  <Favorite
                    exact
                    path={ROUTES.FAVORITE} 
                  />
                </Switch>

                <Switch>
                  <Settings
                    exact
                    path={ROUTES.SETTINGS} 
                  />
                </Switch>

                <Switch>
                  <Buyer
                    exact 
                    path={ROUTES.BUYER}
                  />
                </Switch>

                <Switch>
                  <ReadyToBuy
                    exact
                    path={`${ROUTES.BUYER}${ROUTES.BUYERPAGES.AREYOUREADYTOBUY}`} 
                  />
                </Switch>

                <Switch>
                  <PrepareForClosing
                    exact
                    path={`${ROUTES.BUYER}${ROUTES.BUYERPAGES.PREPAREFORTHECLOSING}`} 
                  />
                </Switch>

                <Switch>
                  <TermsYouShouldKnow
                    exact
                    path={`${ROUTES.BUYER}${ROUTES.BUYERPAGES.REALESTATETERMSYOUSHOULDKNOW}`} 
                  />
                </Switch>

                {/* // */}
                <Switch>
                  <BuyingVsRenting
                    exact
                    path={`${ROUTES.BUYER}${ROUTES.BUYERPAGES.BUYINGVSRENTING}`} 
                  />
                </Switch>

                <Switch>
                  <PreApproval
                    exact
                    path={`${ROUTES.BUYER}${ROUTES.BUYERPAGES.PREAPPROVAL}`} 
                  />
                </Switch>

                <Switch>
                  <Seller
                    exact 
                    path={ROUTES.SELLER}
                  />
                </Switch>

                <Switch>
                  <ReadyToSell
                    exact 
                    path={`${ROUTES.SELLER}${ROUTES.SELLERPAGES.GETTINGPROPERTYREADYTOSELL}`}
                  />
                </Switch>

                <Switch>
                  <MaximizeLandValue
                    exact 
                    path={`${ROUTES.SELLER}${ROUTES.SELLERPAGES.MAXIMIZELANDVALUE}`}
                  />
                </Switch>

                <Switch>
                  <WhatSetsUsApart
                    exact 
                    path={`${ROUTES.SELLER}${ROUTES.SELLERPAGES.WHATSETSUSAPART}`}
                  />
                </Switch>
                <Switch>
                  <TEN31
                    exact 
                    path={`${ROUTES.SELLER}${ROUTES.SELLERPAGES.TEN31}`}
                  />
                </Switch>

                <Switch>
                  <PrivacyPolicy
                    exact 
                    path={`${ROUTES.PRIVACYPOLICY}`}
                  />
                </Switch>


                {/* Staff Pages */}

                <Switch>
                  <StaffLogin
                    exact 
                    path={`${ROUTES.STAFFPAGES.STAFFLOGIN}`}
                  />
                </Switch>

                <Switch>
                  <StaffDashboard
                    exact 
                    path={`${ROUTES.STAFFPAGES.STAFFDASHBOARD}`}
                  />
                </Switch>

                <Switch>
                  <StaffUsers
                    exact 
                    path={`${ROUTES.STAFFPAGES.STAFFUSERS}`}
                  />
                </Switch>

                <Switch>
                  <StaffListings
                    exact 
                    path={`${ROUTES.STAFFPAGES.STAFFLISTINGS}`}
                  />
                </Switch>

                <Switch>
                  <StaffMessages
                    exact 
                    path={`${ROUTES.STAFFPAGES.STAFFMESSAGES}`}
                  />
                </Switch>

              </Router>
            </SignInSignUpContext.Provider>
        </SearchParamsContext.Provider>
      </UserContext.Provider> 
    </>
  );
}

export default App;
