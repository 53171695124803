import { createGlobalStyle } from 'styled-components';
import MuseoFont from './fonts/MuseoSansCyrl.woff.woff'

export const GlobalStyles = createGlobalStyle`

    html, body{
        margin: 0;
        padding: 0;
        height: 100%;
    }

    @font-face {
        font-family: Museo Sans Cyrl;
        src: url(${MuseoFont});
    }


`;