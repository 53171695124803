import React from 'react'

import { Form } from '../components_staff';
import Button from '../components_staff/buttons';

export default function DeleteContainer({children, ...props}){

    // on exit of the form there are certain operations that are performed
    // on exit removes form data and the url from state
    const handleExit = ()=> {
        props.setIsDeleteOpen(false)
        // props.setFormURL(null)
        props.setRequestStatus(
            {
                loading: null,
                success: null, 
                failure: null,
                data: null,
                status: null,
                clientMessage: null,
            }
        )
        props.setFormErrors(props.initialFormState)
        
        document.body.style.overflow = 'unset';
    }

    // handles the deleteion of the item
    const handleDelete = async ()=>{

        // this is the delete api method from the hook
        const response = await props.deleteData()

        // if the response is successful
        if(response){
            // have to make a shallow copy here because cant mutate state
            let tableDataArray = [...props.tableData]
            // (0)n
            // loop through copy and if the selected id is in the state remove it... which it will
            for (let i = 0; i < tableDataArray.length; i++){
                if (props.formData.id === tableDataArray[i].id){
                    tableDataArray.splice(i,1)
                    props.setTableData(tableDataArray)
                }
            }
        }
    }


    return (

        <>
            <Form width="45%" largeHeight="600px" marginTop="200px" overFlowY={null}>
                <Form.FormFrameInner>
                    <Form.ExitFrame>
                        <Form.ExitBox onClick={handleExit}>
                            <Form.LeftSlash/>
                            <Form.RightSlash/>
                        </Form.ExitBox>
                    </Form.ExitFrame>
                    <Form.Title>Delete:</Form.Title>
                    <Form.DeleteFrame>
                        <Form.DeleteText>Are you sure you want to delete the {props.title}?</Form.DeleteText>
                        {/* <Form.DeleteText style={{margin: "10px 0"}}>This will permanently delete the listing with no recovery possible</Form.DeleteText> */}
                        <Form.DeleteText color='gray'>{props.item}</Form.DeleteText>
                        {/* if the success status has not been set then the delete button will appear */}
                        {
                            props.requestStatus.success ?
                            <Form.ResponseFrame >
                                <Form.ResponseText>{props.requestStatus.clientMessage}</Form.ResponseText>
                            </Form.ResponseFrame>
                            :
                            <Button.ButtonItem background='#FF7276'  onClick={handleDelete}>
                                <Button.ButtonText>Delete</Button.ButtonText>    
                            </Button.ButtonItem>
                        }
                        

                    </Form.DeleteFrame>
                    
                </Form.FormFrameInner>
            </Form>


        </>
    )
}