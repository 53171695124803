import axios from 'axios';
import {URLS} from '../services/config';
import {wrap} from '../helpers/wrap'

// Used in the Listing Detail
// This function will take in the count of the images returned by the initial response and then provide back 10 pictures to the client
export function fetchAndSetImages(setListingPics, setShouldFetchMore, listingsPics, pictureCount, offsetCount){
    setShouldFetchMore(false)   
    let imgCount = null;
    
    // if some of the listingPics exist and the pictureCount - length is less than 3
    // set imgcount so that way the server knows almost all pics are fetched for the item and to not send unecessary requests
    if(listingsPics){
        if (pictureCount - listingsPics.length < 3){
            imgCount = pictureCount - listingsPics.length

        }
    }
    

    axios.get(`${URLS.BASE_URL}/${URLS.MLSITEMIMAGES}?listingid=${localStorage.getItem('listingID')}&total=${pictureCount}&offsetcount=${offsetCount}&imgcount=${imgCount}`)
    .then(response=>{
        
        
        // if ther server responds with a 200 ok response then fetch more
        if(response.status === 200){
            setShouldFetchMore(true)
        }

        // if the listingPics array exists than add the data to the listing pics (called when the user is arrowing through the photos)
        if(listingsPics){
            
            let len = response.data.start.length

            for (let i = 0; i < len; i++){
                listingsPics.push(response.data.start[i])
                // sometimes the end data wont be fetched
                if(response.data.end){
                    listingsPics.push(response.data.end[i])
                }
                
            }

            let picArr = listingsPics.sort((a,b) => a.imageID - b.imageID)
            picArr = removeDuplicateObjectFromArray(picArr)
            // use proxy on array allowing for negative indexing
            picArr = wrap(picArr)

            setListingPics(picArr)


        
            // this condition is called upoon first initial fetch of photos
        } else { // if the listingPics do not exist than set the listing pics to be the intitial array (called upon use effect first initial load)
            let dataArr = []
            
            let len = response.data.start.length

            for (let i = 0; i < len; i++){
                dataArr.push(response.data.start[i])
                // sometimes the end data wont be fetched
                // if the end picure response contains more than 3 images than push
                if(response.data.end.length > 2){
                    dataArr.push(response.data.end[i])
                }
            }

            if(pictureCount < 4){
                dataArr = dataArr.sort((a,b) => a.imageID - b.imageID)
                dataArr = wrap(dataArr)

            } else {
                dataArr = dataArr.sort((a,b) => a.imageID - b.imageID)
                dataArr = removeDuplicateObjectFromArray(dataArr)
                dataArr = wrap(dataArr)

            }

            
            setListingPics(dataArr)



        }

        // listingsPics.sort((a,b) => a.imageID - b.imageID)

        

    })
}

// used to remove duplicate pics from the array
// array must be sorted from smallest to largest
function removeDuplicateObjectFromArray(array) {

    let returnArr = []

    let len = array.length 
    let _temp

    for (let i = 0; i < len; i++){
        if(array[i].imageID !== _temp){
            returnArr.push(array[i])
            _temp = array[i].imageID
        }
    }
    return returnArr

  }