import styled from 'styled-components';

export const SideNavFrame = styled.nav`
    position: fixed;
    width: 12rem;
    height: 100vh;
    
    display: flex;
    flex-direction: column;
    justify-content: space between;
    background: #262626;


    @media screen and (min-width: 1920px){
        width: 15rem;
    }
`;

export const LogoFrame = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #ffffff;
`;

export const Logo = styled.img`
    cursor: pointer;
    width: 20%;
`;

export const NavTitle = styled.h4`
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    text-align: center;

    color: #000000;

    @media screen and (min-width: 1920px){
        font-size: 34px;
    }

`;

export const PageLinkFrame = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px;


    @media screen and (min-width: 1920px){
        margin: 40px;
    }

`

export const PageLinkText = styled.p`
    cursor: pointer;
    font-family: Microsoft Sans Serif;
    font-size: 24px;
    margin: 28px 0;
    text-align: center;
    color: ${props => props.onPage ? "#6C8915" : '#FFFFFF'};

    @media screen and (min-width: 1920px){
        font-size: 30px;
        margin: 50px 0;
    }

`;

export const LogoutButtonFrame = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    @media screen and (min-width: 1920px){
        margin: 20px 0;
    }
`;

export const LogoutButton = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 0;
    background: #6C8915;
    height: 80px;
    cursor: pointer;
    
    &:hover {
        filter: brightness(0.8);
      }

    @media screen and (min-width: 1920px){
        margin: 20px 0;
    }
`;
