

// this function is an algo that adds is favorite to the listings if that occurs
// O(n^2)
// I wander if I could sort first and make this 0(logn)
export default function addIsFavorite(listings, favoriteLisingIds){
    
    // this is what will be returned
    // it will contain objects of listings combined with favoritelistingids
    let outPutArr = []

    let listingArr = [...listings]
    let listingIds = [...favoriteLisingIds]
    
    // loop through each listing
    for (let i = 0; i < listingArr.length; i++){

        // loop through each id and check if there is a match between the listing id and the favorite id
        // if so then set favorite to true
        for (let j = 0; j < listingIds.length; j++){

            if (listingArr[i].id.toString() === listingIds[j].listing_id.toString()){

                listingArr[i].is_favorite = true          
                break
                // add the pop method here to shorten the algo
            } else {
                listingArr[i].is_favorite = false
            }

            
        }

        // push to then new array
        outPutArr.push(listingArr[i])

        

    }


    return outPutArr
}