import axios from 'axios'
import {URLS} from '../services/config'

// this function sets gets and sets the transaction count
export default function getSearchCount(setListingCount){

    const searchParams = JSON.parse(sessionStorage.getItem('searchParams'))

    axios.get(`${URLS.BASE_URL}/${URLS.MLSSEARCHCOUNT}?location=${searchParams.location}&class=${searchParams.propertyClass}&minprice=${searchParams.minPrice}&maxprice=${searchParams.maxPrice}`)
    .then(response => {
        setListingCount(response.data)
    })
    .catch(err => console.log(err))
}