import React from 'react';
import {
    ListingBackground, ListingFrame, Blur, Card, CardLink, Picture, AttributeFrame, StatusFrame, Status, Heart, CardInfo, CardInfoWrapper, 
    TitleWraper, CardTitle, LocationBox, LocationIcon, LocationText, HorizontalBar, TypeFrame, TypeText, Dot, 
    Amount, MLSFrame, MLSMainFrame, MLSListingFrame, ListingGrid, SortFrame, Map, MapImage, SortFrameTextBox, 
    CountText, ResultsText, RightText, DownArrow, ListingPageNum, ListingPageNumInner, ListingPageNumText
    ,NoResultsFrame, NoResultsText
    } from './styles/listing'


export default function Listing({children, ...restProps}){
    return <ListingBackground {...restProps}>{children}</ListingBackground>
}

Listing.ListingFrame = function ListingListingFrame({children, ...restProps}){
    return <ListingFrame {...restProps}>{children}</ListingFrame>
}

Listing.Blur = function ListingBlur({children, ...restProps}){
    return <Blur {...restProps}>{children}</Blur>
}

Listing.Card = function ListingCard({children, ...restProps}){
    return( 
        <CardLink grid={restProps.grid} to={restProps.to} mobileWidth={restProps.mobileWidth}>
            <Card {...restProps} direction={restProps.direction}>{children}</Card>
        </CardLink> 
    )
}  

Listing.Picture = function ListingPicture({children, ...restProps}){
    return <Picture {...restProps}>{children}</Picture>
}

Listing.Status = function ListingStatus({children, ...restProps}){
    return <Status {...restProps}>{children}</Status>
}

Listing.Heart = function ListingHearts({children, ...restProps}){
    return <Heart {...restProps}>{children}</Heart>
}

Listing.AttributeFrame = function ListingAttributeFrame({children, ...restProps}){
    return <AttributeFrame {...restProps}>{children}</AttributeFrame>
}

Listing.StatusFrame = function ListingStatusFrame({children, ...restProps}){
    return <StatusFrame {...restProps}>{children}</StatusFrame>
}

Listing.CardInfo = function ListingCardInfo({children, ...restProps}){
    return <CardInfo {...restProps}>{children}</CardInfo>
}

Listing.CardInfoWrapper = function ListingCardInfoWrapper({children, ...restProps}){
    return <CardInfoWrapper {...restProps}>{children}</CardInfoWrapper>
}

Listing.TitleWraper = function ListingTitleWraper({children, ...restProps}){
    return <TitleWraper {...restProps}>{children}</TitleWraper>
}

Listing.CardTitle = function ListingCardTitle({children, ...restProps}){
    return <CardTitle {...restProps}>{children}</CardTitle>
}

Listing.LocationBox = function ListingLocationBox({children, ...restProps}){
    return <LocationBox {...restProps}>{children}</LocationBox>
}
Listing.LocationIcon = function ListingLocationIcon({children, ...restProps}){
    return <LocationIcon {...restProps}>{children}</LocationIcon>
}
Listing.LocationText = function ListingLocationText({children, ...restProps}){
    return <LocationText {...restProps}>{children}</LocationText>
}
Listing.HorizontalBar = function ListingHorizontalBar({children, ...restProps}){
    return <HorizontalBar {...restProps}>{children}</HorizontalBar>
}
Listing.TypeFrame = function ListingTypeFrame({children, ...restProps}){
    return <TypeFrame {...restProps}>{children}</TypeFrame>
}

Listing.TypeText = function ListingTypeText({children, ...restProps}){
    return <TypeText {...restProps}>{children}</TypeText>
}

Listing.Dot = function ListingDot({children, ...restProps}){
    return <Dot {...restProps}>{children}</Dot>
}

Listing.Amount = function ListingAmount({children, ...restProps}){
    return <Amount {...restProps}>{children}</Amount>
}

// The next few components are for the MLS listing
Listing.MLSFrame = function ListingMLSFrame({children, ...restProps}){
    return <MLSFrame {...restProps}>{children}</MLSFrame>
}


Listing.MLSMainFrame = function ListingMLSMainFrame({children, ...restProps}){
    return <MLSMainFrame {...restProps}>{children}</MLSMainFrame>
}

Listing.MLSListingFrame = function ListingMLSListingFrame({children, ...restProps}){
    return <MLSListingFrame {...restProps}>{children}</MLSListingFrame>
}


Listing.ListingGrid = function ListingListingGrid({children, ...restProps}){
    return <ListingGrid {...restProps}>{children}</ListingGrid>
}


Listing.SortFrame = function ListingSortFrame({children, ...restProps}){
    return <SortFrame {...restProps}>{children}</SortFrame>
}


Listing.ListingPageNum = function ListingListingPageNum({children, ...restProps}){
    return <ListingPageNum {...restProps}>{children}</ListingPageNum>
}


Listing.Map = function ListingMap({children, ...restProps}){
    return <Map {...restProps}>{children}</Map>
}

Listing.MapImage = function ListingMapImage({children, ...restProps}){
    return <MapImage {...restProps}>{children}</MapImage>
}

Listing.SortFrameTextBox = function ListingSortFrameTextBox({children, ...restProps}){
    return <SortFrameTextBox {...restProps}>{children}</SortFrameTextBox>
}


Listing.CountText = function ListingCountText({children, ...restProps}){
    return <CountText {...restProps}>{children}</CountText>
}


Listing.ResultsText = function ListingResultsText({children, ...restProps}){
    return <ResultsText {...restProps}>{children}</ResultsText>
}

Listing.RightText = function ListingRightText({children, ...restProps}){
    return <RightText {...restProps}>{children}</RightText>
}

Listing.DownArrow = function ListingDownArrow({children, ...restProps}){
    return <DownArrow {...restProps}>{children}</DownArrow>
}

Listing.ListingPageNum = function ListingListingPageNum({children, ...restProps}){
    return <ListingPageNum {...restProps}>{children}</ListingPageNum>
}

Listing.ListingPageNumInner = function ListingListingPageNumInner({children, ...restProps}){
    return <ListingPageNumInner {...restProps}>{children}</ListingPageNumInner>
}

Listing.ListingPageNum = function ListingListingPageNum({children, ...restProps}){
    return <ListingPageNum {...restProps}>{children}</ListingPageNum>
}

Listing.ListingPageNumText = function ListingListingPageNumText({children, ...restProps}){
    return <ListingPageNumText {...restProps}>{children}</ListingPageNumText>
}

Listing.NoResultsFrame = function ListingNoResultsFrame({children, ...restProps}){
    return <NoResultsFrame {...restProps}>{children}</NoResultsFrame>
}

Listing.NoResultsText = function ListingNoResultsText({children, ...restProps}){
    return <NoResultsText {...restProps}>{children}</NoResultsText>
}





















