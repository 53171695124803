import React, {useState} from 'react';


import { Table } from '../components_staff';
import handleClassDisplay from '../helpers/search/handleClassDisplay'

export function ListingTableRowContainer(props){

    const [checked, setChecked] = useState(false)


    // this function handles when the row is clicked it will be check marked so the user can edit
    const handleRowClick = ()=>{

        if (checked){
            props.setFormData(props.initialFormState)
            setChecked(false)
        } else{
            props.setFormData(props.data)
            setChecked(true)
        }
        
    }

    

    if (props.data){
        return(

            <>
                 
                <Table.DataTableRow key={props.data.id} onClick={handleRowClick}>
                    <Table.CheckBox type="checkbox" checked={checked} onChange={handleRowClick}/>
                    <Table.DataTableText width="16%" textAlign="left">{ props.data.isFeatured ? <span>&#11088;</span> : null}{props.data.Name}</Table.DataTableText>
                    <Table.DataTableText width="17%">{props.data.Address}</Table.DataTableText>
                    <Table.DataTableText width="10%">{props.data.City}</Table.DataTableText>
                    <Table.DataTableText width="10%">{handleClassDisplay({propertyClass: props.data.Class})}</Table.DataTableText>
                    <Table.DataTableText width="6%">{props.data.Status}</Table.DataTableText>
                    <Table.DataTableText width="10%">{getFormattedDate(props.data.DateListed)}</Table.DataTableText>
                    <Table.DataTableText width="10%">{`$${Math.round(props.data.AskingPrice).toLocaleString()}`}</Table.DataTableText>
                    <Table.DataTableText width="8%">{props.data.isAuction ? "True": "False"}</Table.DataTableText>
                </Table.DataTableRow>
             
            </>
        )
    } else {
        return null
    }

    
}

export function AllFavoritedListingTableRowContainer(props){

    return (
            <Table.DataTableRow key={props.data.id} onClick={()=>console.log('selecting row')}>
                <Table.CheckBox type="checkbox"/>
                <Table.DataTableText width="14%">{props.data.listing_name.slice(0,13)}...</Table.DataTableText>
                {/* the property class below is because the handleclass display requires it to be sent as an object -- need to refactor */}
                <Table.DataTableText width="10%">{handleClassDisplay({propertyClass:props.data.Class})}</Table.DataTableText>
                <Table.DataTableText>{props.data.Type}</Table.DataTableText>
                <Table.DataTableText width="15%">{props.data.user.first_name} {props.data.user.last_name}</Table.DataTableText>
                <Table.DataTableText width="15%">{props.data.user.phone_number}</Table.DataTableText>
                <Table.DataTableText width="17%">{props.data.user.email}</Table.DataTableText>
                <Table.DataTableText>{props.data.listing_id}</Table.DataTableText>
                <Table.DataTableText>{props.data.city}</Table.DataTableText>
                <Table.DataTableText>{props.data.state}</Table.DataTableText>
            </Table.DataTableRow> 
        )
    

}

export function UserTableRowContainer(props){

    const [checked, setChecked] = useState(false)


    // this function handles when the row is clicked it will be check marked so the user can edit
    const handleRowClick = ()=>{

        if (checked){
            props.setFormData(props.initialFormState)
            setChecked(false)
        } else{
            props.setFormData(props.data)
            setChecked(true)
        }
        
    }
    
    return(

        <>
             
            <Table.DataTableRow key={props.data.id} onClick={handleRowClick}>
                <Table.CheckBox type="checkbox" checked={checked} onChange={handleRowClick}/>
                <Table.DataTableText width="17%">{props.data.email}</Table.DataTableText>
                <Table.DataTableText>{props.data.first_name}</Table.DataTableText>
                <Table.DataTableText>{props.data.last_name}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.phone_number}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.date_joined ? getFormattedDate(props.data.date_joined) : null}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.last_login ? getFormattedDate(props.data.last_login) : null}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.is_active ? "Yes" : "No"}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.is_social ? "Yes" : "No"}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.is_receive_email ? "Yes" : "No"}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.is_get_contacted ? "Yes" : "No"}</Table.DataTableText>
                <Table.DataTableText>{props.data.is_staff ? "Yes" : "No"}</Table.DataTableText>
            </Table.DataTableRow>
         
        </>
    )

}

export function ContactMessageRowContainer(props){


    return (
        <>
              
            <Table.DataTableRow key={props.data.id}>
                <Table.CheckBox type="checkbox"/>
                <Table.DataTableText width="10%">{`${props.data.first_name} ${props.data.last_name}`}</Table.DataTableText>
                <Table.DataTableText width="12%">{props.data.phone_number}</Table.DataTableText>
                <Table.DataTableText width="12%">{props.data.email}</Table.DataTableText>
                <Table.DataTableText width="15%">{props.data.message.slice(0,13)}...</Table.DataTableText>
                <Table.DataTableText width="14%">{props.data.listing_name.slice(0,13)}...</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.buy ? "Buy" : "Not specified"}</Table.DataTableText>
                <Table.DataTableText width="8%">{props.data.sell ? "Sell": "Not specified"}</Table.DataTableText>
                <Table.DataTableText width="10%">{props.data.date ? getFormattedDate(props.data.date) : null}</Table.DataTableText>
                <Table.DataTableText width="6%">{props.data.contacttime ? props.data.contacttime : "..."}</Table.DataTableText>
            </Table.DataTableRow>
         
        </>
    )

}

function getFormattedDate(date) {

    date = new Date(date)

    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }