import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const FormFrame = styled.div`
    height: ${props => props.mobileHeight ? props.mobileHeight : '530px'} ;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => props.background ? props.background : '#FFFFFF'};
    border: 1px solid #DFDFDF;
    // box-sizing: border-box;
    box-shadow: 0px 32px 84px rgba(27, 26, 30, 0.12);
    transform: translate(-50%, -50%);
    overflow-y: ${props => props.error ? 'scroll' : null};

    // this is really an arbitrary value set like this to make sure the form is above the map container on the listing detail container
    z-index: 6;

    @media only screen and (min-width: 1280px)  {
        box-sizing: border-box;
        margin: 0;
        height: ${props => props.lapHeight ? props.lapHeight : '530px'};
        width: ${props => props.width ? props.width : '35.486111%'};
        
    }

    @media only screen and (min-width: 1600px)  {
        height: ${ props => props.medHeight ? props.medHeight : '900px'};
        width: ${props => props.medWidth ? props.medWidth : null};
    }

    @media only screen and (min-width: 1920px)  {
        height: ${props => props.height ? props.height : null};
    }
`;

export const ExitFrame = styled.div`

    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ExitBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background: #262626;
    cursor: pointer;

    @media only screen and (min-width: 1280px)  {
        height: 40px;
        width: 40px;

    }

    @media only screen and (min-width: 1920px)  {
        height: 50px;
        width: 50px;

    }
    
`;

export const RightSlash = styled.div`

    position: absolute;
    background: #FFFFFF;
    /* White */
    width: 50%;
    height: 1px; 
    // border: 1px solid #FFFFFF;
    transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);

`;
export const LeftSlash = styled.div`

    position: absolute;
    background: #FFFFFF;
    /* White */
    width: 50%;
    height: 1px;    
    // border: 1px solid #FFFFFF;
    transform: rotate(-45deg)

`;


export const Wrapper = styled.form`

    width: ${props => props.width ? props.width : '81.213307%'};
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    @media only screen and (min-width: 1280px)  {

        height: ${props => props.height ? props.height : null};

    }
`;

export const Title = styled.h4`

    /* h4 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29px;
    margin: 0;
    padding: 0;

    /* black */

    color: ${ props => props.color ? props.color : '#262626'};
    
    margin-bottom: 8px;

    @media only screen and (min-width: 1280px)  {
        font-size: 1.75rem;
        margin-bottom: 22px;

    }
`;

export const Input = styled.input`

    width: 100%;
    background: #FFFFFF;
    /* Grey / 3 */
    height: ${props => props.height ? props.height : '50px'};
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '16px'};
    font-size: 1rem;
    
    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
        padding-left: 1.2rem;

        
    }

    @media only screen and (min-width: 1280px)  {
        height: 40px;
        margin-bottom: 6px;
    }

    @media only screen and (min-width: 1600px)  {
        height: 50px;
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '16px'};
    }

`;


export const Button = styled.button`
    

    height: ${props => props.height ? props.height : '50px'};
    background: #6C8915;
    //styleName: p / button;
    font-family: Museo Sans Cyrl;
    font-size: 24px;
    font-style: normal;
    color: #FFFFFF;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1.7999999523162842px;
    text-align: center;
    border: none;
    cursor: pointer;
    margin: ${props => props.marginBottom ? props.marginBottom : null};


    @media only screen and (min-width: 1280px)  {
        height: ${props => props.lapHeight ? props.lapHeight : '50px'};
        font-size: 18px;
    }

    @media only screen and (min-width: 1600px)  {
        height: 82px;
        font-size: 24px;
    }

    @media only screen and (min-width: 1920px)  {
        height: 82px;
        font-size: 24px;
    }


`;

export const Text = styled.p`

    margin-top: 5px;
    margin-bottom: 5px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */
    color: #9A9A9A;
    text-align: center;


    @media only screen and (min-width: 1280px)  {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media only screen and (min-width: 1920px)  {
        font-size: 24px;
        margin-top: 16px;
        margin-bottom: 16px;

    }

`;

export const SocialFrame = styled.div`

    
    height: ${props => props.height ? props.height : '50px'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${props => props.background ? props.background : null};
    margin: ${props => props.margin ? props.margin : null};

    @media only screen and (min-width: 1280px)  {
        height: 30px;
        margin: ${props => props.laptopMargin ? props.laptopMargin : null};
    }

    @media only screen and (min-width: 1600px)  {
        height: 50px;

    }

    @media only screen and (min-width: 1920px)  {
        margin: ${props => props.margin ? props.margin : null};

    }
`;

export const SocialIcon = styled.img`

    height: 24px;
    width: 24px;
    margin-right: 10px;
`;


export const SocialText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    /* identical to box height, or 164% */

    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: ${props => props.color ? props.color : null};

    @media only screen and (min-width: 1280px)  {
        font-size: 14px;

    }
    
`;

export const ExtraFrame = styled.div`


    @media only screen and (min-width: 1280px)  {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 23px;

    }

    @media only screen and (min-width: 1600px){
        margin-top: ${props => props.marginTop ? props.marginTop : null};
    }

    @media only screen and (min-width: 1920px){
        margin-top: ${props => props.marginTop ? props.marginTop : null};
    }
`;

// this is wrapped around extra text and is causeing the "to" error
// need to fix later
export const ExtraTextLink = styled(ReactRouterLink)`

    text-decoration: none;


`


export const ExtraText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
    /* identical to box height, or 128% */

    text-align: center;
    letter-spacing: 0.3px;

    color: ${props => props.color ? props.color : null};
    text-decoration: ${props => props.textDecoration ? props.textDecoration : null};
    margin-top: 0;
    margin-bottom: 2px;

    margin-left: 10px;
    cursor: pointer;

    @media only screen and (min-width: 1280px){
        margin-bottom: 4px;

    }

    @media only screen and (min-width: 1600px){
        margin-top: 20px;
        margin-bottom: 4px;
        font-size: 20px;
    }

    @media only screen and (min-width: 1920px){
        font-size: 22px;
    }

`

export const CookLogoIMG = styled.img`
    cursor: ${ props => props.cursor ? props.cursor : null};
    margin-top: 30px;    
    margin-bottom: 30px;
    width: 30%;
`;


export const TextBoxWrapper = styled.div`

    width: 100%;
    /* White */
    background: #FFFFFF;
    /* Grey / 3 */
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    margin-bottom: 24px;


`;

export const TextBox = styled.input`

    height: 125px;
    width: 100%;
    border: none;
    color: black;
    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
        padding-left: 1.2rem;

        
    }

    @media screen and (min-width: 1280px){
        height: 75px;
    }

    @media screen and (min-width: 1920px){
        height: 125px;
    }


`