import React from 'react'

import { TitleFrame, TitleTextFrame, TitleTextFrame2, TitleText, ViewMoreWrapper, CircleFrame, Circle, ViewMoreLink, ViewMore } from './styles/listingtitle'


export default function ListingTitle({children, ...restProps}){
    return <TitleFrame {...restProps}>{children}</TitleFrame>
}

ListingTitle.TitleTextFrame = function ListingTitleTextFrame({children, ...restProps}){
    return <TitleTextFrame {...restProps}>{children}</TitleTextFrame>
}


ListingTitle.TitleTextFrame2 = function ListingTitleTextFrame2({children, ...restProps}){
    return <TitleTextFrame2 {...restProps}>{children}</TitleTextFrame2>
}

ListingTitle.TitleText = function ListingTitleText({children, ...restProps}){
    return <TitleText {...restProps}>{children}</TitleText>
}

ListingTitle.ViewMoreWrapper = function ListingViewMoreWrapper({children, ...restProps}){
    return <ViewMoreWrapper {...restProps}>{children}</ViewMoreWrapper>
}

ListingTitle.CircleFrame = function ListingCircleFrame({children, ...restProps}){
    return <CircleFrame {...restProps}>{children}</CircleFrame>
}

ListingTitle.Circle = function ListingCircle({children, ...restProps}){
    return <Circle {...restProps}>{children}</Circle>
}

ListingTitle.ViewMore = function ListingTitleViewMore({children, ...restProps}){


    return ( <ViewMoreLink to={restProps.to}>
                <ViewMore {...restProps}>{children}</ViewMore>
            </ViewMoreLink>
        )
}
