import styled from 'styled-components';


export const BlockGrid = styled.div`
    width: 95%;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
`;

export const BlockItem = styled.div`


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);

`;

export const ItemBlockFrame = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    width: 80%;

    @media screen and (min-width: 1920px){
        margin-top: 20px;
    }
`;


export const Icon = styled.img`

    width: 36px;
    height: 34px;


`;


export const ItemBlockBottomFrame = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 60px;
    margin-bottom: 10px;

    @media screen and (min-width: 1920px){
        margin: 0 80px;
        margin-bottom: 20px;
    }

    `;


export const ValueText = styled.h6`
    font-family: Microsoft Sans Serif;
    font-size: 24px;
    /* identical to box height */

    text-align: center;

    color: #232229;

    margin: 25px 0;

    @media screen and (min-width: 1920px){
        font-size: 30px;
        margin: 40px 0;
    }

`;

export const LabelText = styled.p`
    margin: 10px 0;
    font-family: Microsoft Sans Serif;
    font-size: 16px;
    text-align: center;

    color: #232229;

    @media screen and (min-width: 1920px){
        font-size: 18px;
    }



`
