import styled from 'styled-components';



export const ContactUsFrame = styled.div`

    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    background: #FFFFFF;
`;

export const ContactUsFrameInner = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 1280px)  {
        display: grid;
        align-items: stretch;
        width: 81.25%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-row-gap: 30px;
        grid-column-gap: 30px;
        grid-template-areas: 
        "InfoFrame ContentFrame ContentFrame"
        "MapFrame ContentFrame ContentFrame"
        "MapFrame ContentFrame ContentFrame"
        "MapFrame ContentFrame ContentFrame";
        margin-top: 60px;
        margin-bottom: 100px;
    }
`;


export const InfoFrame = styled.div`
    order: 2;

    margin: 0;
    margin-top: 40px;
    border: 1px solid #DFDFDF;

    width: 87%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media only screen and (min-width: 1280px)  {
        margin: 0;
        width: auto;
        grid-area: InfoFrame;
    }
`;

export const InfoWrapper = styled.div`

    width: 87%;

`;


// have to explicitly state the height or the react map wont render
export const MapFrame = styled.div`
    order: 3; 

    margin-top: 30px;
    margin-bottom: 30px;
    height: 471px;
    width: 90%;


    @media only screen and (min-width: 1280px)  {
        margin: 0;
        height: auto;
        width: auto;
        grid-area: MapFrame;   
    }
`;


export const ContentFrame = styled.div`
    order: 1;
    
    width: 90%;
    margin-top: 30px;
    background: #F4F4F5;
    display: flex;
    justify-content: center;


    @media only screen and (min-width: 1280px)  {
        margin: 0;
        height: auto;
        width: auto;
        grid-area: ContentFrame;   
    }

`;

export const InnerContentFrame = styled.div`
    width: 90%;
    margin-top: 30px;
    margin-bottom: 38px;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        height: auto;
    }

    @media only screen and (min-width: 1920px)  {
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    /* h2 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    /* or 131% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* black */

    color: #262626;
    
    @media only screen and (min-width: 1280px)  {
        margin-bottom: 18px;
        font-size: 34px;

    }

    @media only screen and (min-width: 1920px)  {
        margin-bottom: 18px;
        font-size: 40px;

    }
    

`;

export const ParagraphText = styled.p`
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;


    line-height: 23px;
    /* or 156% */
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #595959;

    @media only screen and (min-width: 1280px)  {
        // line-height: 26px;
        // font-size: 20px;
        line-height: 38px;
        margin-bottom: 28px;
        font-size: 28px;  
    }

    @media only screen and (min-width: 1920px)  {
        line-height: 38px;
        margin-bottom: 28px;
        font-size: 28px; 
    }


`
export const HorizontalBar = styled.div`
    border: 1px solid #DFDFDF;
    width: 100%;

    margin-bottom: 32px;
    margin-top: ${props => props.marginTop ? props.marginTop: null};
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;


`;

export const InputBoxFrame = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;

`;

export const InnerInputBoxFrame = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1280px)  {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const Input = styled.input`

    width: 100%;
    background: #FFFFFF;
    border: none;
    height: 84px;
    margin-bottom: 20px;

    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 14px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
        padding-left: 10px;
        word-wrap: break-word;
        word-break: break-all;
    }

    @media only screen and (min-width: 1280px)  {
        height: 64px;
        width: ${props => props.width ? props.width : null};
        ::placeholder,
        ::-webkit-input-placeholder {
            font-size: 1rem;
            padding-left: 24px;
            letter-spacing: 1.8000001907348633px;
            
        }
    }

    @media only screen and (min-width: 1920px)  {
        height: 84px;
        
    }

    
`;


export const InputCheckBoxFrame = styled.div`

    display: flex;
    flex-direction: column;
    
    width: 100%;

    @media only screen and (min-width: 1280px)  {
        width: 80%;
        flex-direction: row;
        justify-content: space-between;
    }

    @media only screen and (min-width: 1920px)  {
        width: 65%;
    }
`;

export const CheckBoxText = styled.p`

    /* p / content */
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;


    @media only screen and (min-width: 1280px)  {
        font-size: 18px;
    }

    @media only screen and (min-width: 1920px)  {
        font-size: 20px;
    }


`;

export const ExplainText = styled.p`
    margin: 0;
    padding: 0;
    /* p / content */

    font-family: Museo Sans Cyrl;
    // font-weight: 700;
    font-size: 18px; 
    line-height: 23px;
    /* identical to box height, or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* Green */

    color: #6C8915;



`;

export const ButtonFrame = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    
`;

export const InputButton = styled.button`
    border: none;
    width: 100%;
    background: #6C8915;
    height: 80px;
    cursor: pointer;
    /* p / button */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    /* White */

    color: #FFFFFF;

    @media only screen and (min-width: 1280px)  {
        width: 48%;
    }
`;
