import React, {useState, useContext} from 'react';
import { Form, Info } from '../components'
import Facebook from '../images/Facebook2.svg'
import Google from '../images/Google.svg'
import {UserContext} from '../context/user'

//context
import { SignInSignUpContext } from '../context/signinsignup';

//api
import signUp from '../api/signup'
import continueWithGoogle from '../api/continuewithgoogle';
import continueWithFaceBook from '../api/continuewithfacebook';
//helpers
import validateForm from '../helpers/validateform'
// containers

import SpinnerContainer from './spinner'

export default function SignUpContainer(props){

    const { errorMessage } = useContext(SignInSignUpContext);
    const [error, setError] = errorMessage


    const [, setUser]= useContext(UserContext)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [emailAddress, setEmailAddress] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [password1, setPassword1] = useState()
    const [password2, setPassword2] = useState()
    const [success, setSuccess] = useState(false)
    const [ isSignUpLoading, sestIsSignUpLoading ] = useState(false)

    const handleExit = () => {
        props.setIsSignUpOpen(false);
        document.body.style.overflow = 'unset';
        setError(null)
        setSuccess(null)
        sestIsSignUpLoading(false)
    }

    const handleSignUp = (event) => {
        event.preventDefault();
        sestIsSignUpLoading(true)
        const doesFormValidate = validateForm(firstName, lastName, emailAddress, phoneNumber, password1, password2)

        if (!doesFormValidate){
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'submit'}).then(function(token) {
                    signUp(password1, password2, firstName, lastName, phoneNumber, emailAddress, setError, setPassword1, setPassword2, sestIsSignUpLoading, setSuccess, token)
                });
            });

        } else {
            setError(null)
            setError(doesFormValidate)
        }
        
    } 



    return (
        <>
            <Form height='900px' lapHeight='600px' medWidth = '40%' error={error}>
                {
                    !isSignUpLoading && !success ? 
                    <>
                        <Form.ExitFrame>
                    <Form.ExitBox onClick={handleExit}>
                        <Form.RightSlash></Form.RightSlash>
                        <Form.LeftSlash></Form.LeftSlash>
                    </Form.ExitBox>
                </Form.ExitFrame>
                <Form.Wrapper method="POST" onSubmit={handleSignUp}>
                    <Form.Title>Sign Up</Form.Title>
                    <Form.Input 
                        name = 'first_name'
                        placeholder='FIRST NAME'
                        value={firstName}
                        onChange={({target}) => setFirstName(target.value)}
                        height='30px'
                        marginBottom='10px'
                    />
                    { error ? error.firstNameError ? <Info.ResponseText color='#FF7276'>{error.firstNameError}</Info.ResponseText> : null : null}
                    <Form.Input
                        name='last_name'
                        placeholder='LAST NAME'
                        value={lastName}
                        onChange={({target}) => setLastName(target.value)}
                        height='30px'
                        marginBottom='10px'
                    />
                    { error ? error.lastNameError ? <Info.ResponseText color='#FF7276'>{error.lastNameError}</Info.ResponseText> : null : null}
                    <Form.Input
                        name = 'email' 
                        placeholder='EMAIL'
                        type='email'
                        value={emailAddress}
                        onChange={({target}) => setEmailAddress(target.value)}
                        height='30px'
                        marginBottom='10px'
                    />
                    { error ? error.emailAddressError ? <Info.ResponseText color='#FF7276'>{error.emailAddressError}</Info.ResponseText> : null : null}
                    <Form.Input
                        name='phonenumber' 
                        placeholder='PHONE'
                        value={phoneNumber}
                        onChange={({target}) => setPhoneNumber(target.value)}
                        height='30px'
                        marginBottom='10px'
                    />
                    { error ? error.phoneNumberError ? <Info.ResponseText color='#FF7276'>{error.phoneNumberError}</Info.ResponseText> : null : null}
                    <Form.Input
                        name='password' 
                        placeholder='PASSWORD' 
                        type="password"
                        value={password1}
                        onChange={({target}) => setPassword1(target.value)}
                        height='30px'
                        marginBottom='10px'
                    />
                    { error ? error.password1Error ? <Info.ResponseText color='#FF7276' fontSize = '14px'>{error.password1Error}</Info.ResponseText> : null : null}
                    <Form.Input
                        name='re_password' 
                        placeholder='REPEAT PASSWORD' 
                        type="password"
                        value={password2}
                        onChange={({target}) => setPassword2(target.value)}
                        height='30px'
                        marginBottom='10px'
                    />
                    { error ? error.password2Error ? <Info.ResponseText color='#FF7276'>{error.password2Error}</Info.ResponseText> : null : null}


                    <Form.Button height='30px' lapHeight='35px'>Sign Up</Form.Button>
                    <Form.Text>or</Form.Text>
                    { error ? error.faceBookError ? <Info.ResponseText color='#FF7276'>{error.faceBookError}</Info.ResponseText> : null : null}
                    <Form.SocialFrame background='#3778EA' margin='0 0 16px 0' height='30px' onClick={continueWithFaceBook}>
                        <Form.SocialIcon src={Facebook}/>
                        <Form.SocialText color='#FFFFFF'>CONTINUE WITH FACEBOOK</Form.SocialText>
                    </Form.SocialFrame>
                    { error ? error.googleError ? <Info.ResponseText color='#FF7276'>{error.googleError}</Info.ResponseText> : null : null}
                    <Form.SocialFrame background='#EFEFEF' margin='0 0 22px 0' height='30px' onClick={continueWithGoogle}>
                        <Form.SocialIcon src={Google}/>
                        <Form.SocialText color='#262626'>CONTINUE WITH GOOGLE</Form.SocialText>
                    </Form.SocialFrame>
                    <Form.ExtraFrame >
                        <Form.ExtraText color='#262626'>By signing up you agree to our</Form.ExtraText>
                        <Form.ExtraText 
                            color='#6C8915' 
                            textDecoration='underline'
                            onClick={() => {
                                props.setIsPrivacyPolicyOpen(true)
                                }
                            }
                        
                        
                        >Terms and Privacy Policy</Form.ExtraText>
                    </Form.ExtraFrame>
                    
                    
                    
                </Form.Wrapper>

                    </>
                    :
                    null
                }
                {
                    isSignUpLoading ?
                    <>
                         <Form.ExitFrame>
                            <Form.ExitBox onClick={handleExit}>
                                <Form.RightSlash></Form.RightSlash>
                                <Form.LeftSlash></Form.LeftSlash>
                            </Form.ExitBox>
                        </Form.ExitFrame>
                        <Form.Wrapper>
                            <Form.Title>Sign Up</Form.Title>
                            <SpinnerContainer/>
                        </Form.Wrapper>
                    </>
                    :
                    null
                }
                {
                    success ?
                    <>
                        <Form.ExitFrame>
                            <Form.ExitBox onClick={handleExit}>
                                <Form.RightSlash></Form.RightSlash>
                                <Form.LeftSlash></Form.LeftSlash>
                            </Form.ExitBox>
                        </Form.ExitFrame>
                        <Form.Wrapper>
                            <Form.Title>Sign Up</Form.Title>
                            <Form.SocialFrame background='#F2F5E7;' margin='40px 0 0 0'>
                                <Info.ResponseText color='#6C8915'>{success}</Info.ResponseText>
                            </Form.SocialFrame>
                        </Form.Wrapper>
                    </>
                    : 
                    null
                }
                
            </Form>

            {   
                props.children
            }
        
        </>
        
        
        )

}