import React from 'react'
import { Listing } from '../components';

import { newListingTimeFrame } from '../services/config'

export default function StatusContainer(props){

    // get todays current date in unix
    const today = Date.now()
    // get the date of the listing in unix
    let listingDate = new Date(props.listing.DateListed)
    // subtrac the dates and divide by milliseconds in day to get days since first listed 
    const timeElapsed = (today - listingDate.getTime()) / 86400000 

    // if less than x days old and not sold and not sale pending
    if(timeElapsed < newListingTimeFrame && props.listing.Status !== "Sold" && props.listing.Status !== "Sale Pending"){
        return (
            <Listing.StatusFrame background='#6C8915'>
                <Listing.Status>NEW</Listing.Status>
            </Listing.StatusFrame>
        )
    } else {
        
        switch(props.listing.Status){

            // not in all listing casses will there be a new status set...
            // like for mls which is why the above calculations have to be done
            case "New":
                return (
                    <Listing.StatusFrame background='#6C8915' width='35%'>
                        <Listing.Status>New</Listing.Status>
                    </Listing.StatusFrame>
                )

            case "Available":
                return (
                    <Listing.StatusFrame background='#6C8915' width='35%'>
                        <Listing.Status>Available</Listing.Status>
                    </Listing.StatusFrame>
                )

            case "Active":
                return (
                    <Listing.StatusFrame background='#6C8915' width='35%'>
                        <Listing.Status>Available</Listing.Status>
                    </Listing.StatusFrame>
                )

            case "Sale Pending":
                return (
                    <Listing.StatusFrame background='#DD6A6B' width='40%' medWidth="50%">
                        <Listing.Status>Sale Pending</Listing.Status>
                    </Listing.StatusFrame>
                )

            case "Sold":
                return(
                    <Listing.StatusFrame background='#DD6A6B'>
                        <Listing.Status>Sold</Listing.Status>
                    </Listing.StatusFrame>
                )

            default:
                return <Listing.StatusFrame/>
                
        }
    }
        

}