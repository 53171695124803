import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios';

// Components Containers
import ListingCardContainer from './listingcard'
import {Listing, ListingDetail, Map} from '../components';
import MapContainer from './map'
import DetailInfoContainer from './detailinfo'
import ContactFormContainer from './contactform';
import ListingTitleContainer from './listingtitle'

//apis
import {fetchAndSetImages} from '../api/fetchandsetimages';

// Services
import {URLS, ROUTES} from '../services/config';

// HELPER FUNCS
import { wrap } from '../helpers/wrap';

// proxy
import DetailImageProxy from '../containerproxy/detailimage'

import useListings from '../hooks/uselistings';

export default function ListingDetailContainer(props){


    // state
    const [detailedListing, setDetailedListing] = useState();
    const [listingPics, setListingPics] = useState(null);
    const [featuredListings, setFeaturedListings] = useState([]);


    // this is to be passed in the listing card container to set the featured listings at the bottom
    // again this might be better served as a context for less code
    const { listings, favoriteIds, setListings } = useListings('ListingDetail')



    const [ isContactFormOpen, setIsContactFormOpen ] = useState(false)
    // state for fetching more images
    const [totalPicCount, setTotalPicCount] = useState(null); // Total existing images of the item
    const [picIndex, setPicIndex ] = useState(0); // Index of the array from which the pics will display from 
    const [picOffset, setPicOffset] = useState(0); // sent to the server -- the offset of where images to start -- 
    const [shouldFetchMore, setShouldFetchMore] = useState(true) // when user changes images will the fetch be called or not
    let history = useHistory()

    useEffect(() => {
        const detailedListingID = localStorage.getItem('listingID')
        const detailedListingType = localStorage.getItem('type')

        switch(detailedListingType){
            case "Cook":
            // This gets the Single Cook listing details from the server
            axios.get(`${URLS.BASE_URL}/${URLS.LISTINGDETAIL}?listingid=${detailedListingID}`)
            .then( response => {
                    setDetailedListing(response.data[0])
                    // this is the display image that is returned from the server
                    const firstImage = {
                        id: response.data[0].id,
                        Images: response.data[0].DisplayImage
                    }

                    if(response.status === 200){
                         // This gets the images for the cook listing
                        axios.get(`${URLS.BASE_URL}/${URLS.LISTINGIMAGES}?listingid=${detailedListingID}`)
                        .then( response => {
                                
                                // added to the listing pics so that can be moved through on the front end
                                response.data.unshift(firstImage)

                                setListingPics(wrap(response.data))

                            }
                        )
                    }

                }    
            )
            axios.get(`${URLS.BASE_URL}/${URLS.LISTINGS}`)
            .then( response => setFeaturedListings(response.data))
            break;

            case "MLS":
                const searchParams = JSON.parse(sessionStorage.getItem('searchParams'))
                // if theres an issue check the listing helper and also might need to do something with session storage

                axios.get(`${URLS.BASE_URL}/${URLS.MLSLISTINGDETAIL}?listingid=${localStorage.getItem('listingID')}&type=${searchParams.propertyClass}`)
                .then(response => {
                    setDetailedListing(response.data)
                    // nested api call to the mls images because the client has to get the total picture count from the metadata above
                    // if that response is successful than make a call to the server containing the total number of photos that exist
                    if(response.status === 200 && response.data.PictureCount !== "0"){
                        setTotalPicCount(response.data.PictureCount)
                        // passing null here to show the function call that litingPics array does not yet exist
                        // this is because this is the first time the function will be run and the pictures get called
                        setShouldFetchMore(false)
                        fetchAndSetImages(setListingPics, setShouldFetchMore, listingPics, response.data.PictureCount, picOffset)
                        
                    }else {
                            console.log('no pics for this item')
                        }      
                    })
                break;

            default:
                history.push(ROUTES.HOME)
        }

        
    }, [localStorage.getItem('listingID'), localStorage.getItem('type')])


    // if the item exists in local storage than run the code
    // or else it will send the user back to the home page to select a property to view
    if (localStorage.getItem('listingID')){
        return (
            <>
    
                <ListingDetail>
                    <ListingDetail.ListingDetailFrameInner>

                        {/* Images frame */}
                        {/* this proxy will decide based upon the screen size which component to load */}
                        <DetailImageProxy
                            listingPics={listingPics}
                            setListingPics={setListingPics}
                            detailedListing={detailedListing}
                            setdetailedListing={setDetailedListing}
                            picIndex={picIndex}
                            setPicIndex={setPicIndex}
                            totalPicCount={totalPicCount}
                            shouldFetchMore={shouldFetchMore}
                            setShouldFetchMore={setShouldFetchMore}
                            picOffset={picOffset}
                            setPicOffset={setPicOffset}
                            // that has got to be bad practice and need to change in future
                            // maybe put this in the address bar
                        />

                        {/* info frame */}
                        <DetailInfoContainer detailedListing={detailedListing} isContactFormOpen={isContactFormOpen} setIsContactFormOpen={setIsContactFormOpen}>  
                            
                            {
                                isContactFormOpen ?
                                <ContactFormContainer
                                    isContactFormOpen = {isContactFormOpen}  
                                    setIsContactFormOpen={setIsContactFormOpen}
                                    detailedListing={detailedListing} 
                                    // listingId={localStorage.getItem('listingID')} 
                                    // listingName={detailedListing.Name} 
                                // might need to use location or find another way of getting that data because the map wont load
                                />
                                : 
                                null
                            }
                        </DetailInfoContainer>
                        
                        {/* Frame that contains the map */}
                        <ListingDetail.MapFrame>
                            <MapContainer 
                                searchResults={detailedListing ? detailedListing : null} 
                                height='400px' 
                                width='60%'
                                // individualListing={true}
                            />
                        </ListingDetail.MapFrame>
        
                        
                        <ListingTitleContainer title='Our Featured Listings' listings={listings} setListings={setListings}/>

                        
                        <Listing.ListingFrame width='100%'>
                            <ListingCardContainer 
                                refresh={true} 
                                listings={listings ? listings.slice(0, 4) : null}
                                favoriteIds={favoriteIds}
                            />
                        </Listing.ListingFrame>

                    </ListingDetail.ListingDetailFrameInner>
                </ListingDetail>
    
            </>
        )
    } else {
        // history.push(ROUTES.HOME)
        return null
    }

    
}