import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom';


export const BuyerFrame = styled.div`

    
    background: url(${props => props.url ? props.url : null});
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media only screen and (min-width: 1280px)  {

        height: 300px;

    }
`;


export const BackgroundIMGTextFrame = styled.div`
    width: 81.25%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px 0 30px 0;



    @media only screen and (min-width: 1280px)  {
        justify-content: center;
        height: 225px;
        
    }

`;

export const SmallText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;

    margin: 0;
    /* White */

    color: #FFFFFF;
    // border-bottom: 1px solid #FFFFFF;
    @media only screen and (min-width: 1280px)  {

        font-size: 1rem;

    }
`;

export const TitleText = styled.h2`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 47px;
    /* or 131% */

    margin: 10px 0 10px 0;
    text-transform: uppercase;

    /* White */

    color: #FFFFFF;

    @media only screen and (min-width: 1280px)  {
        margin-bottom: 16px;
        font-size: 2.5rem;
    }

`;

export const ContentText = styled.p`
    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height, or 128% */

    letter-spacing: 0.3px;
    margin: 0;
    /* White */

    color: #FFFFFF;

    @media only screen and (min-width: 1280px)  {

        font-size: 1.5rem;

    }
`

export const BackgroundWrapper = styled.div`

    width: 100%;
    background-color: white;
    // this is to make the space between background pic and the items... not sure why...
    overflow: hidden;   

`;

export const MainFrame = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 30px 0;
    background: #FFFFFF;

    @media only screen and (min-width: 1280px)  {
        margin: 50px 0 100px 0;

    }
`;

export const BlockFrame = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0;  
    
    @media only screen and (min-width: 1280px)  {
        margin: ${props => props.margin ? props.margin : null};
        width: 81.25%;
        flex-direction: row;
        
    }
`; 


export const BlockItem = styled.div`
    
    background: #F4F4F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 30px;
    margin-bottom: 20px;


    @media only screen and (min-width: 1280px)  {
        padding-top: 0;
        margin-bottom: 0;
        width: 100%;
        // width: 48%; if we ever want to add more items this is what wee need to un comment
        // height: 220px;
    }
`;

export const BlockTextFrame = styled.div`
    
    width: 87.36842105%;
    
    display: flex;
    flex-direction: column;

`;

export const BlockTitle = styled.h5`
    margin: 0px;
    padding: 0px;
    /* h5 */
    font-family: Museo Sans Cyrl;
    font-weight: 700;
    font-size: 22px;
    // line-height: 23px;
    /* identical to box height, or 105% */


    letter-spacing: 0.3px;
    text-transform: uppercase;

    color: #000000;

    @media only screen and (min-width: 1280px)  {
        margin-top: 37px;
    }

`;

export const BlockMainText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 17px;
    /* or 142% */

    
    /* black */

    color: #262626;

    

`

export const BlockLink = styled(ReactRouterLink)`

    text-decoration: none;
    cursor: pointer;
    
    width: 40%;

    @media only screen and (min-width: 1280px)  {
        width: 40%;
    }

    @media only screen and (min-width: 1920px)  {
        width: 15%;
    }
`

export const BlockLinkText = styled.p`
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 17px;
    /* identical to box height, or 142% */

    text-decoration-line: underline;

    /* Green */
    color: #6C8915;
    width: 100%;

    @media only screen and (min-width: 1920px)  {
        margin-bottom: 36px;
    }

`
export const ContactButton = styled(ReactRouterLink)`
    
    margin-top: 20px;
    text-decoration: none;
    width: 90%;
    background-color: #6C8915;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 1280px){
        width: 20%;
    }
`
export const ContactText = styled.p`
    margin: 10px;
    font-family: Museo Sans Cyrl;
    color: #FFFFFF;
    font-size: 2rem;
`