import React, {useState, useContext} from 'react'
import { Settings, Form } from '../components'
import { UserContext } from '../context/user'
import Profile from '../images/Profile.svg';

//api
import updateUserProfile from '../api/updateuserprofile'
import deleteUser from '../api/deleteuser'
//helper
import validatePassword from '../helpers/validatepassword'
// hooks
import useScript from '../hooks/useScript';

export default function SettingsContainer(){

    const [user, setUser] = useContext(UserContext)
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)

    const [ firstName, setFirstName ] = useState(user.first_name)
    const [ lastName, setLastName ] = useState(user.last_name)
    const [ emailAddress, setEmailAddress ] = useState(user.email)
    const [ phoneNumber, setPhoneNumber ] = useState(user.phone_number)
    const [ receiveEmail, setReceiveEmail] = useState(user.is_receive_email)
    const [ getContacted, setGetContacted ] = useState(user.is_get_contacted)

    const [ oldPassword, setOldPassword ] = useState(null)
    const [ newPassword, setNewPassword ] = useState(null)
    const [ reNewPassword, setReNewPassword] = useState(null)


    const [ isConfirmDeleteOpen, setIsConfirmDeleteOpen ] = useState(false);

    const handleSubmit = (e)=>{
        e.preventDefault()

        // had to wrap all of this so the value of the recaptcha token is loaded and then the rest of the code runs
        window.grecaptcha.ready(()=> {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'updateUser'})
        .then( (recaptchaT) => {
                setSuccess(null)
                setFailure(null)

                const token = localStorage.getItem('accessToken')

                let data 
                if (oldPassword && newPassword && reNewPassword){
                    
                    const passwordValidator = validatePassword(newPassword, reNewPassword)

                    if (passwordValidator.status){
                        
                        data = {
                            profileData: {
                                first_name: firstName,
                                last_name: lastName,
                                email: emailAddress,
                                phone_number: phoneNumber,
                                is_receive_email: receiveEmail,
                                is_get_contacted: getContacted,
                                recaptcha: recaptchaT
                            },
                            passwordData: {
                                old_password: oldPassword,
                                new_password1: newPassword,
                                new_password2: reNewPassword,
                            }
                            
                        }
                        
                        updateUserProfile(token, data, setSuccess, setFailure, setUser, setOldPassword, setNewPassword, setReNewPassword)
                        
                    } else{
                        setFailure(passwordValidator.message)
                    }
                    
                } else {
                    data = {
                        profileData: {
                            first_name: firstName,
                            last_name: lastName,
                            email: emailAddress,
                            phone_number: phoneNumber,
                            is_receive_email: receiveEmail,
                            is_get_contacted: getContacted,
                            recaptcha: recaptchaT
                        }
                        
                    }
                    updateUserProfile(token, data, setSuccess, setFailure, setUser)
                }
                    
            });
        });



    }

    const handleDelete = (e) =>{

        e.preventDefault();
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'submit'}).then(function(reCaptchaToken) {
                const data = {
                    recaptcha: reCaptchaToken
                }
                deleteUser(data, setSuccess, setFailure, setUser)
            });
        });

        
    }

    // custom hook
    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)


    return (
        <>
            <Settings>
              <Settings.SettingsBox method="POST" onSubmit={(e) => handleSubmit(e)}>

                <Settings.TitleBox>
                    <Settings.TitleBoxInner>
                        <Settings.TitleText>Profile Settings</Settings.TitleText>  
                    </Settings.TitleBoxInner>
                </Settings.TitleBox>

                    <Settings.ItemBox>
                        <Settings.ProfileBox>
                            <Settings.ProfileImg src={Profile}/>
                            <Settings.ProfileText>{user.first_name} {user.last_name}</Settings.ProfileText>
                        </Settings.ProfileBox>
                        <Settings.InputBox>
                            <Settings.Input 

                                name = 'first name' 
                                placeholder='First Name'
                                type='text'
                                value={firstName}
                                onChange={({target}) => setFirstName(target.value)}
                                height='30px'
                                marginBottom='10px'
                                disabled={user.is_social}
                                
                            />
                            <Settings.Input 
                                name = 'last name' 
                                placeholder='Last Name'
                                type='text'
                                value={lastName}
                                onChange={({target}) => setLastName(target.value)}
                                height='30px'
                                marginBottom='10px'
                                disabled={user.is_social}
                            />
                        </Settings.InputBox>

                        <Settings.InputBox>
                            <Settings.Input 

                                name = 'email' 
                                placeholder='EMAIL'
                                type='email'
                                value={emailAddress}
                                onChange={({target}) => setEmailAddress(target.value)}
                                height='30px'
                                marginBottom='10px'
                                disabled={true}
                            />
                            <Settings.Input 
                                name = 'phone number' 
                                placeholder='Phone Number'
                                type='text'
                                value={phoneNumber}
                                onChange={({target}) => setPhoneNumber(target.value)}
                                height='30px'
                                marginBottom='10px'
                            />
                        </Settings.InputBox>


                    </Settings.ItemBox>

                    <Settings.TitleBox>
                        <Settings.TitleBoxInner>
                            <Settings.TitleText>Account Settings</Settings.TitleText>
                        </Settings.TitleBoxInner>
                    </Settings.TitleBox>

                    <Settings.ItemBox margin='20px 0px'>
                        <Settings.PrivacyBox>
                            <Settings.PrivacyInput 
                                type= 'checkbox'
                                name = 'receive email' 
                                checked={receiveEmail}
                                onChange={(e) => setReceiveEmail(e.target.checked)}
                            />
                            <Settings.PrivacyText>Recieve Emails & Updates</Settings.PrivacyText>
                        </Settings.PrivacyBox>
                    
                    
                        <Settings.PrivacyBox>
                            <Settings.PrivacyInput 
                                type= 'checkbox'
                                name = 'get contacted' 
                                checked={getContacted}
                                onChange={(e) => setGetContacted(e.target.checked)}
                            />
                            <Settings.PrivacyText>Get Contacted by an Agent</Settings.PrivacyText>
                        </Settings.PrivacyBox>
                    
                        {/* <Settings.PrivacyBox>
                            <Settings.PrivacyInput 
                                type='checkbox'
                            />
                            <Settings.PrivacyText>Show your last name to everyone</Settings.PrivacyText>
                        </Settings.PrivacyBox> */}
                    </Settings.ItemBox>

                    {
                        !user.is_social ?
                            <>
                                <Settings.TitleBox>
                                    <Settings.TitleBoxInner>
                                        <Settings.TitleText>Change Password</Settings.TitleText>
                                    </Settings.TitleBoxInner>
                                </Settings.TitleBox>

                                <Settings.ItemBox margin='30px 0 40px 0'>
                                    <Settings.PasswordInput
                                        type='password'
                                        placeholder={ user.is_social ? 'Cannot Change Passoword as Social User' : 'Current Password'}
                                        margin='0 0 28px 0'
                                        name = 'old password' 
                                        value={oldPassword}
                                        onChange={({target}) => setOldPassword(target.value)}
                                        disabled={user.is_social}
                                    />
                                    <Settings.PasswordInput
                                        type='password'
                                        placeholder={ user.is_social ? 'Cannot Change Passoword as Social User' : 'New Password'}
                                        margin='0 0 28px 0'
                                        name = 'new password' 
                                        value={newPassword}
                                        onChange={({target}) => setNewPassword(target.value)}
                                        disabled={user.is_social}
                                    />
                                    <Settings.PasswordInput
                                        type='password'
                                        placeholder={ user.is_social ? 'Cannot Change Passoword as Social User' : 'Confirm New Password'}
                                        margin='0 0 28px 0'
                                        name = 'new password' 
                                        value={reNewPassword}
                                        onChange={({target}) => setReNewPassword(target.value)}
                                        disabled={user.is_social}
                                    />   
                                </Settings.ItemBox>
                            </>
                        :
                        null
                    }

                    
                    <Settings.SaveFrame justifyContent={success || failure}>

                        {
                            success ?

                            <Settings.SuccessFrame>
                                <Settings.SuccessText>{success}</Settings.SuccessText>
                            </Settings.SuccessFrame>
                            :
                            null
                        }
                        {
                            failure ?

                            <Settings.SuccessFrame backgroundColor={'#ffe8e7'}>
                                <Settings.SuccessText color={'#FF7276'}>{failure}</Settings.SuccessText>
                            </Settings.SuccessFrame>
                            :
                            null
                        }

                        <Settings.SaveButton>SAVE</Settings.SaveButton>
                    
                    </Settings.SaveFrame>

                    <Settings.DeleteButton marginBottom='40px' background='#FF7276' width='80%' onClick={()=> {
                            document.body.style.overflow = 'hidden'
                            setIsConfirmDeleteOpen(true)
                        }
                        }
                    >
                        <Settings.DeleteText>Delete Account</Settings.DeleteText>
                    </Settings.DeleteButton>

                    {
                        isConfirmDeleteOpen ?

                        <>
                            <Form height='400px' mobileHeight='200px' background='#262626'>
                                <Form.ExitFrame>
                                <Form.ExitBox onClick={() => {
                                    setIsConfirmDeleteOpen(false) 
                                    document.body.style.overflow = 'unset'
                                    }}>
                                    <Form.RightSlash></Form.RightSlash>
                                    <Form.LeftSlash></Form.LeftSlash>
                                    </Form.ExitBox>
                                </Form.ExitFrame>
                                <Form.Wrapper>
                                <Form.Title color='#FFFFFF'>Are you sure you want to delete your account?</Form.Title>
                                    <Settings.SaveFrame justifyContent="space-between">
                                        <Settings.DeleteButton marginBottom='20px' background='#FF7276' width='40%' onClick={()=> setIsConfirmDeleteOpen(true)}>
                                            <Settings.DeleteText onClick={ (e) => handleDelete(e)} >DELETE</Settings.DeleteText>
                                        </Settings.DeleteButton>
                                        <Settings.DeleteButton 
                                            marginBottom='20px'  
                                            width='40%' 
                                            onClick={
                                                ()=>{ setIsConfirmDeleteOpen(false)
                                                    document.body.style.overflow = 'unset'
                                                }}>
                                            <Settings.DeleteText>CANCEL</Settings.DeleteText>
                                        </Settings.DeleteButton>
                                    </Settings.SaveFrame>
                                </Form.Wrapper>
                            </Form>
                        
                        </>

                        :

                        null
                    }
                    
              </Settings.SettingsBox>  
            </Settings>  
        
        </>
        
        
    )
}


