import React from 'react';
//components
import {Favorite} from '../components'


//imgs
import ListingCardContainer from './listingcard';
//hooks
import useListings from '../hooks/uselistings'


export default function FavoriteContainer(){


    const { listings, favoriteIds, display, manipulateIsFavorite } = useListings("Favorite")


    return (
        <>
        
            <Favorite>
                <Favorite.FavoriteFrameInner>
                    <Favorite.FavoriteBox>
                        <Favorite.TitleFrame>
                            <Favorite.TitleTextFrame>
                                <Favorite.TitleText>Favorite Listings</Favorite.TitleText>
                            </Favorite.TitleTextFrame>
                        </Favorite.TitleFrame>
                        <Favorite.FavoriteGridInner display={display ? 'flex' : 'grid'} justifyContent={ display ? 'center' : null} alignItems={display ? 'flex-start' : null}>
                            <ListingCardContainer
                                grid='true' 
                                listings={listings} 
                                favoriteIds={favoriteIds} 
                                manipulateIsFavorite={manipulateIsFavorite}
                                mobileWidth={"100%"}
                             />
                        </Favorite.FavoriteGridInner>
                    </Favorite.FavoriteBox>
                </Favorite.FavoriteFrameInner>
            </Favorite>
        
        
        </>
        
        
        
        
    )

}