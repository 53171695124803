

const submitForm = async (e, props)=> {

    e.preventDefault();
    let response;
    // this variable returns add or edit if "Add" or "Edit" is found within the props.formTitle
    const category = categorize(props.formTitle)

    switch(category){

        // this is for posting a new listing
        case "Add":
            // returns the response data
            response = await props.postData()

            // if its a success then add it to the listings and then also unset the document so that way the user can scroll
            if(response){
                document.body.style.overflow = 'unset';
                props.setFormData(props.initialFormState)
                props.setTableData([...props.tableData, response])
            }
    
            // this is returned because some functions such as one in listing form need the id from the submissions
            return response
        
        // this is for editing a listings
        case "Edit":

            response = await props.patchData()
            if (response){
                document.body.style.overflow = 'unset';
                console.log('successful patch')
                // have to make a shallow copy here because cant mutate state
                let tableArray = [...props.tableData]

                // (0)n
                // loop through copy and if the selected id is in the state replace it
                for (let i = 0; i < tableArray.length; i++){
                    if (response.id === tableArray[i].id){
                        console.log("found")
                        // so this gets the index of the i and replaces that item with the current reponse data
                        tableArray[i] = response
                        props.setTableData(tableArray)
                    }
                }
            }

            return response

        default: 
            break
    }


    

}

// checks if the formtitle state includes add or edit
function categorize(string){
    if (string.includes('Add')){
      return 'Add';
    }
    if (string.includes('Edit')){
      return 'Edit';
    }
  }

export default submitForm;