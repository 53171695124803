import axios from 'axios'
import { URLS } from '../services/config'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// difference between this and password reset is that this is used in the settings page and container
// password reset is used for when the user forgets their password


export default function updatePassword(headers, data, setSuccess, setFailure, setOldPassword, setNewPassword, setReNewPassword){


    axios.patch(`${URLS.BASE_URL}/${URLS.UPDATEPASSWORD}`, data, headers)
    .then(response => {

        setSuccess('Your Changes Are Saved')
        setNewPassword(null)
        setOldPassword(null)
        setReNewPassword(null)
    })
    .catch(error => {

        if(error.response.data.old_password){
            setFailure(error.response.data.old_password)
        }

        if (error.response.data.new_password2){
            setFailure(error.response.data.new_password2)
        }

        if (error.response.data.non_field_errors){
            setFailure(error.response.data.non_field_errors)
        }

        setNewPassword(null)
        setOldPassword(null)
        setReNewPassword(null)
        
    })
}