import favoriteTheListing from '../api/favoritethelisting'

export default function handleTheFavoriteListing(listing){

    const data = {
        listing_id: listing.id,
        listing_name: listing.Name,
        Type: listing.Type,
        Class: listing.Class,
        city: listing.City,
        state: listing.State,
        zip_code: listing.ZipCode
    }

    favoriteTheListing(data)

    
}