import React from 'react';
//containers
import ListingCardContainer from './listingcard'
import ListingTitleContainer from './listingtitle';
//components
import { Listing } from '../components'

import WelcomeContainer from './welcome';

import useListings from '../hooks/uselistings'



export default function ListingContainer() {

    const { listings, setListings, manipulateIsFavorite} = useListings('Home')
    
    return (
        <>

            <Listing>

                <ListingTitleContainer 
                    title='Our Featured Listings ' 
                    listings={listings}
                    setListings={setListings}

                />
            
                <Listing.ListingFrame>
                    <ListingCardContainer  
                        listings={listings ? listings.slice(0, 4) : null} 
                        manipulateIsFavorite={manipulateIsFavorite}
                    />
                </Listing.ListingFrame>
                

                {/* welcomcontainer */}
                <WelcomeContainer/>

            </Listing>

        </>
        
        
        
        )

}