import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SideNav } from '../components_staff'
import CookLand from '../images/CookLandEmblem.png'
import { ROUTES } from '../services/config'

//api
import logout from '../api/logout';
//context
import { UserContext } from '../context/user';

export default function StaffSideNaveContainer(){

    let history = useHistory()
    const [ user, setUser ] = useContext(UserContext)

    const handleLogout = ()=>{
        logout(user, setUser)
        history.push(ROUTES.STAFFPAGES.STAFFLOGIN)
    }

    // every time the user changes than re run this
    useEffect(()=>{

    }, [user])

    // this function ensures that when the user refreshes the page they go back to the same page
    // it sets the clicked on page in session storage
    const handlePageClick = (page)=>{

        switch (page){
            case "Dashboard":
                history.push(ROUTES.STAFFPAGES.STAFFDASHBOARD)
                sessionStorage.setItem('page', ROUTES.STAFFPAGES.STAFFDASHBOARD)
                break;

            case "Users":
                history.push(ROUTES.STAFFPAGES.STAFFUSERS)
                sessionStorage.setItem('page', ROUTES.STAFFPAGES.STAFFUSERS)
                break;

            case "Listings":
                history.push(ROUTES.STAFFPAGES.STAFFLISTINGS)
                sessionStorage.setItem('page', ROUTES.STAFFPAGES.STAFFLISTINGS)
                break;

            case "Messages":
                history.push(ROUTES.STAFFPAGES.STAFFMESSAGES)
                sessionStorage.setItem('page', ROUTES.STAFFPAGES.STAFFMESSAGES)
                break;

            default:
                break
        }
        
    }

    return (
        
        
        <SideNav>
            <SideNav.LogoFrame>
                <SideNav.Logo src={CookLand} onClick={()=> history.push(ROUTES.HOME)} cursor="pointer"/>
                <SideNav.NavTitle>{user.first_name}</SideNav.NavTitle>
            </SideNav.LogoFrame>
            <SideNav.PageLinkFrame>
                <SideNav.PageLinkText onClick={() => handlePageClick('Dashboard')}>DashBoard</SideNav.PageLinkText>
                <SideNav.PageLinkText onClick={() => handlePageClick('Users')}>Users</SideNav.PageLinkText>
                <SideNav.PageLinkText onClick={() => handlePageClick('Listings')}>Listings</SideNav.PageLinkText>
                <SideNav.PageLinkText onClick={() => handlePageClick('Messages')}>Messages</SideNav.PageLinkText>
            </SideNav.PageLinkFrame>
            <SideNav.LogoutButtonFrame>
                <SideNav.LogoutButton onClick={handleLogout}>
                    <SideNav.PageLinkText>Logout</SideNav.PageLinkText>
                </SideNav.LogoutButton>
            </SideNav.LogoutButtonFrame>
        </SideNav>
        
        )
}