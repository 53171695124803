import React from 'react'

import { PrivacyFrame , PrivacyFrameInner, UpdatedText, PrivacyContentFrame, PrivacyTitle, PrivacyParagraph, PrivacyBullet,

        PrivacyFrameBottom, PrivacyBottomFrameInner, 
        PrivacyButton, ButtonText} from './styles/privacy'


export default function Privacy({children, ...restProps}){
    return <PrivacyFrame {...restProps}>{children}</PrivacyFrame>
}


Privacy.PrivacyFrameInner = function PrivacyPrivacyFrameInner({children, ...restProps}){
    return <PrivacyFrameInner {...restProps}>{children}</PrivacyFrameInner>
}

Privacy.UpdatedText = function PrivacyUpdatedText({children, ...restProps}){
    return <UpdatedText {...restProps}>{children}</UpdatedText>
}


Privacy.PrivacyContentFrame = function PrivacyPrivacyContentFrame({children, ...restProps}){
    return <PrivacyContentFrame {...restProps}>{children}</PrivacyContentFrame>
}

Privacy.PrivacyTitle = function PrivacyPrivacyTitle({children, ...restProps}){
    return <PrivacyTitle {...restProps}>{children}</PrivacyTitle>
}

Privacy.PrivacyParagraph = function PrivacyPrivacyParagraph({children, ...restProps}){
    return <PrivacyParagraph {...restProps}>{children}</PrivacyParagraph>
}

Privacy.PrivacyBullet = function PrivacyPrivacyBullet({children, ...restProps}){
    return <PrivacyBullet {...restProps}>{children}</PrivacyBullet>
}


//
Privacy.PrivacyFrameBottom = function PrivacyPrivacyFrameBottom({children, ...restProps}){
    return <PrivacyFrameBottom {...restProps}>{children}</PrivacyFrameBottom>
}

Privacy.PrivacyBottomFrameInner = function PrivacyPrivacyBottomFrameInner({children, ...restProps}){
    return <PrivacyBottomFrameInner {...restProps}>{children}</PrivacyBottomFrameInner>
}

Privacy.PrivacyButton = function PrivacyPrivacyButton({children, ...restProps}){
    return <PrivacyButton {...restProps}>{children}</PrivacyButton>
}


Privacy.ButtonText = function PrivacyButtonText({children, ...restProps}){
    return <ButtonText {...restProps}>{children}</ButtonText>
}



