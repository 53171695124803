import styled from 'styled-components';


export const Background = styled.div`

    display: flex;

`;


export const Frame = styled.div`


    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
`;


export const Container = styled.div`

    top: 50%;
    left: 50%;
    position: absolute:
    transform: translate(-50%, -50%)
`;

export const Animation = styled.div`

    border: 6px solid #ccc;
    width: 80px;
    // width: ${props => props.width ? props.width : 125}px;
    height: 80px;
    // height: ${props => props.height ? props.height : 125}px;
    border-radius: 50%;
    border-left-color: #6C8915;
    border-top-color: #6C8915;
    animation: spin 1s infinite ease-in-out;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media (min-width: 1920){
        width: 125px;
        height: 125px;
    }

`;