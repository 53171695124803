import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Search } from '../components'
// helpers
import {onSearch} from '../helpers/onsearch';
import handleClassDisplay from '../helpers/search/handleClassDisplay'
// imgs
import MagnifyingGlass from '../images/MagnifyingGlass.svg';
//data
import Cities from '../data/cities.json'
//Context
import { SearchParamsContext } from '../context/searchparams';

export default function SearchContainer(){
    

    const [searchParams, setSearchParams] = useContext(SearchParamsContext)

    let history = useHistory()
    // State

    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [, setTypeError] = useState();

    


    useEffect(()=>{


    }, [searchParams])

    if(searchParams){
        return (
            <Search>
                        
                        <Search.MLSSearchFrame>
                            <Search.LocationSearchBox>
                                <Search.MenuSelect
                                    name = "Location"
                                    display='none'
                                    options = {Cities}
                                    defaultVal={searchParams ? searchParams.location : null}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    >
                                </Search.MenuSelect>
                                <Search.MagnifyingGlassFrame>
                                    <Search.MagnifyingGlass src={MagnifyingGlass} />
                                </Search.MagnifyingGlassFrame>
                            </Search.LocationSearchBox>
                            
                            <Search.PriceSearchBox>
                                <Search.MenuSelect
                                    name = 'Property Class'
                                    options = { [
                                        { value: 'LD_2', label: 'Land' },
                                        { value: 'CI_3', label: 'Commercial' },
                                        { value: 'RE_1', label: 'Residential'},
                                        { value: "MF_4", label: "Multi Family"}
                                    ]}
                                    defaultVal={searchParams ? handleClassDisplay(searchParams) : null}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                ></Search.MenuSelect>
                            </Search.PriceSearchBox>
    
                            {/* <Search.AcreSqrFtFrame>
                                <Search.PriceSearchBox width='50%' mobileWidth='48%'>
                                    <Search.MenuSelect
                                        name='Acres'
                                    ></Search.MenuSelect>
                                </Search.PriceSearchBox>
    
                                <Search.PriceSearchBox width='50%' mobileWidth='48%'>
                                    <Search.MenuSelect
                                        name='Sqr Ft'
                                    ></Search.MenuSelect>
                                </Search.PriceSearchBox>
                            </Search.AcreSqrFtFrame> */}
    
                            {/* <Search.PriceSearchBox>
                                <Search.MenuSelect
                                    name = 'MinPrice'
                                    options = { [
                                        { value: 100000, label: '$100,000' },
                                                { value: 200000, label: '$200,000' },
                                                { value: 300000, label: '$300,000' },
                                                { value: 400000, label: '$400,000'},
                                                { value: 500000, label: '$500,000' },
                                                { value: 600000, label: '$600,000' },
                                                { value: 700000, label: '$700,000' },
                                                { value: 800000, label: '$800,000' },
                                                { value: 900000, label: '$900,000' },
                                                { value: 1000000, label: '$1,000,000' },
                                                { value: 2000000, label: '$2,000,000' },
                                                { value: 4000000, label: '$4,000,000'},
                                                { value: 5000000, label: '$5,000,000'},
                                    ]}
                                    setState={setMinPrice}
                                ></Search.MenuSelect>
                            </Search.PriceSearchBox>
    
                            <Search.PriceSearchBox>
                                <Search.MenuSelect
                                    name = 'MaxPrice'
                                    options = { [
                                            { value: 100000, label: '$100,000' },
                                                { value: 200000, label: '$200,000' },
                                                { value: 300000, label: '$300,000' },
                                                { value: 400000, label: '$400,000'},
                                                { value: 500000, label: '$500,000' },
                                                { value: 600000, label: '$600,000' },
                                                { value: 700000, label: '$700,000' },
                                                { value: 800000, label: '$800,000' },
                                                { value: 900000, label: '$900,000' },
                                                { value: 1000000, label: '$1,000,000' },
                                                { value: 2000000, label: '$2,000,000' },
                                                { value: 4000000, label: '$4,000,000'},
                                                { value: 5000000, label: '$5,000,000'},
                                    ]}
                                    setState={setMaxPrice}
                                ></Search.MenuSelect>
                            </Search.PriceSearchBox> */}
    
    
                            {/* <Search.PriceSearchBox>
                                <Search.SearchText>PRICE</Search.SearchText>
                            </Search.PriceSearchBox> */}
    
                            <Search.PriceSearchBox>
                                <Search.SearchButton onClick={ () => onSearch( history, minPrice, maxPrice, setTypeError, setSearchParams, searchParams)}>Search</Search.SearchButton>
                            </Search.PriceSearchBox>
                            
    
                        </Search.MLSSearchFrame>
                    
                    </Search>
        )
    } else {
        return null
    }
    
}