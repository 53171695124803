import {fetchAndSetImages} from '../api/fetchandsetimages.js'
import { URLS } from '../services/config'

export function handleImageChange(type, picIndex, setPicIndex, listingPics, 
    setListingPics, totalPicCount, shouldFetchMore, setShouldFetchMore, picOffset, setPicOffset){

    const len = listingPics.length


    
    if (picIndex + 3 > len && picIndex + 3 < totalPicCount ){
        return null
    } else {
        if (type==="DECREMENT"){
            if (((picIndex -2) * -1) > len ){
                setPicIndex(0)
            // else set the pic index - 1
            } else {
                setPicIndex(picIndex - 1)
                
            }
        // if the length of the array -2 is less than the current pic index + 1 set the index to 1
        } else if (type==="INCREMENT"){
            if (picIndex + 2 > len -1 ){
                setPicIndex(-1)
            } else {
                setPicIndex(picIndex + 1)
            }
        } else {
            return null
        }
    }
    

    // set fetch more to false until the response is successful
    // Basically checking to see if the number of items in the array are greater than the amount of pictures for the specific item
    // Prevents sending trying to fetch data to the server
    // AND
    // if shouldFetchMore is set to true then fetch more images
    if(listingPics.length < totalPicCount && shouldFetchMore){
        const newOffset = picOffset + 3
        setPicOffset(newOffset)
        fetchAndSetImages(setListingPics, setShouldFetchMore, listingPics, totalPicCount, newOffset)
    } else {
        return null
    }
    
}

// This function handles the display of the image to the pages
// takesn in an array of pictures, the current state of the picindex, and the type which signifies if it is the left middle or right image
// function isnt perfect as the middle will display the same image of the one after but its a makeshift solution for the time being
export const displayMLSImage = (listingPics, picIndex, type)=> {

    if (type === "left"){
        if(listingPics[picIndex-1].itemImage !== "No Image"){
            return `data:image/png;base64,${listingPics[picIndex-1].itemImage}`
        } else {
            return `data:image/png;base64,${listingPics[(picIndex-1) -1].itemImage}`
        }
    } else if(type === "middle") {
        if(listingPics[picIndex].itemImage !== "No Image"){
            return `data:image/png;base64,${listingPics[picIndex].itemImage}`
        } else {
            return `data:image/png;base64,${listingPics[picIndex + 1].itemImage}`
        }
    } else if (type === "right"){
        if(listingPics[picIndex+1].itemImage !== "No Image"){
            return `data:image/png;base64,${listingPics[picIndex + 1].itemImage}`
        } else {
            return `data:image/png;base64,${listingPics[(picIndex+1) + 1].itemImage}`
        }
    } else {
        return null
    }
    

}


export const displayCookImage = (listingPics, picIndex, type)=> {


    // this will need to be updated at some point only handling if there are 3 pics
    // but what if there are only 2 pics uploaded? how does this handler handle that?
    if(listingPics.length > 2){
        if (type === "left"){
            if(listingPics[picIndex-1].Images !== "No Image"){
                return `${URLS.BASE_URL}${listingPics[picIndex-1].Images}`
            } else {
                return `${URLS.BASE_URL}${listingPics[(picIndex-1) -1].Images}`
            }
        } else if(type === "middle") {
            if(listingPics[picIndex].Images !== "No Image"){
                return `${URLS.BASE_URL}${listingPics[picIndex].Images}`
            } else {
                return `${URLS.BASE_URL}${listingPics[picIndex + 1].Images}`
            }
        } else if (type === "right"){
            if(listingPics[picIndex+1].Images !== "No Image"){
                return `${URLS.BASE_URL}${listingPics[picIndex + 1].Images}`
            } else {
                return `${URLS.BASE_URL}${listingPics[(picIndex+1) + 1].Images}`
            }
        } else {
            return null
        }
        // this is for handling if there are no more than 2 pics
    } else {
        if (type === "left"){
            if(listingPics[picIndex].Images !== "No Image"){
                return `${URLS.BASE_URL}${listingPics[picIndex].Images}`
            } else {
                return `${URLS.BASE_URL}${listingPics[(picIndex)].Images}`
            }
        } else if(type === "middle") {
            if(listingPics[picIndex].Images !== "No Image"){
                return `${URLS.BASE_URL}${listingPics[picIndex].Images}`
            } else {
                return `${URLS.BASE_URL}${listingPics[picIndex].Images}`
            }
        } else if (type === "right"){
            if(listingPics[picIndex].Images !== "No Image"){
                return `${URLS.BASE_URL}${listingPics[picIndex].Images}`
            } else {
                return `${URLS.BASE_URL}${listingPics[(picIndex)].Images}`
            }
        } else {
            return null
        }
    }
    
    

}
