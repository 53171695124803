import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/user';
import axiosInstance from '../../api/axios/axios'

import { URLS } from '../../services/config';

import getCookie from '../../helpers/getcookie';


var FormData = require('form-data');
axiosInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosInstance.defaults.xsrfCookieName = "csrftoken";


// hook is called when teh listing form is opened
// takes in the current id of the selected item
export default function useExtraImages(id){

    const [ , setUser ] = useContext(UserContext)
    
    const [ error, setError ] = useState(null)
    const [ isConfirmDeleteOpen, setIsConfirmDeleteOpen ] = useState(false)

    const [ images, setImages ] = useState({
                                            currentImages: [],
                                            newImages: [],
                                            deletedImages: [],
                                            combinedImages: []
                                                })


    useEffect( ()=>{

        // this function will get the current extra images for the listing
        async function getData(){


            if(id){
               const headers={
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('accessToken')}`
                    }
               } 
               
                try {
                    const response = await axiosInstance.get(`${URLS.STAFFAPIS.LISTINGIMAGES}?listingid=${id}`, headers)
                    
                    // new state object
                    setImages({...images, currentImages: response.data})
                } catch (error){
                    console.log(error.response)

                    if (error.response.status === 401){
                        setUser(null)
                    }


                    setError(error.response)

                    
                }
                
                
            }
        }

        getData()

        

    },[])

    // this function only manages the state of the deletion it does not make any api calls
    // this handles removing of images from the form input
    // it will also set the array for removing images from the server
    const handleDelete = (index, image)=>{
        // going to need to also get key to send key to the server
        
        // current images
        let currentImagesArr = [...images.currentImages]
        currentImagesArr.splice(index, 1)
        // new images
        let newImagesArr = [...images.newImages]
        


        let deletedImagesArr = [...images.deletedImages]


        // if the image contains an id aka already posted on the server
        // this for for adding the deleted images to an array
        // if they are already images that have been saved on the server than they will be added to this array
        // if the image has an id which means it has been saved to the server before
        if(image.id){
            deletedImagesArr.push(image)
            setImages({
                    deletedImages: deletedImagesArr,
                    currentImages: currentImagesArr,
                    newImages: newImagesArr
                }
            )
        } else {
            // if not a pic from the server then splice from there
            newImagesArr.splice(images.currentImages.length - index, 1)
            // if none of the images were already posted on the server then just set the state here
            setImages({
                    deletedImages: deletedImagesArr,
                    currentImages: currentImagesArr, 
                    newImages: newImagesArr
                }
            )
        }

    }

    // this function actually handles the submission of the deleted extra images
    // this is because we want to prompt the user whether they actually want to delete or not
    async function submitDelete(imgArray){

        let data = new FormData();
        // looping over the images and creating form data that can be passed to the server
        for (let i = 0; i < imgArray.length; i++){
            data.append("id", imgArray[i].id)
        }

        const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
                    "X-CSRFTOKEN": getCookie('csrftoken'),
                    
                },
                data: data
            }
        
        try {
            const response = await axiosInstance.delete(URLS.STAFFAPIS.LISTINGIMAGES, config)

            return response.status
        } catch (error){
            console.log(error.response)
            setError(error.response)
        }
    }

    // this function is for submitting the extra images to the server
    async function submitExtraImages(associatedListingID){
        
        console.log('submit extra images')
        let data = new FormData();
        
        // making a copy of the state
        let imagesArr = [...images.newImages]
        // ALTHOUGH WE DONT WANT TO HANDLE SLICE WHEN EDDITING A LISTING SO NEED TO FIGURE THIS OUT
        // slicing the first item in the array of images because it is the initial state of 0
        // we want this removed as it is not an image
        // images = images.slice(1)
        // looping over the images and creating form data that can be passed to the server
        for (let i = 0; i < imagesArr.length; i++){
            data.append(associatedListingID, imagesArr[i])
        }
    
        const cookie = getCookie('csrftoken')
    
        try {
    
            const headers = {
                headers: {
                    "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
                    "X-CSRFTOKEN": cookie,
                    "Content-Type": 'application/json'
                }
            }
        
            const response = await axiosInstance.post(URLS.STAFFAPIS.LISTINGIMAGES, data, headers)
    
    
            console.log(response.data)
    
        } catch (error) {
    
    
            console.log(error.response)
        }
    }





    return {
            images, setImages, 
            error, submitExtraImages, submitDelete,
            isConfirmDeleteOpen, setIsConfirmDeleteOpen,
            handleDelete, 
            }
}