import React, {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
//Containers
import NavContainer from '../containers/nav';
import SearchResultContainer from '../containers/searchresult';
import FooterContainer from '../containers/footer';
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';
//Components
import { Wrapper } from '../components';
//Images
import CookLandRealty from '../images/CookLandRealty.svg';
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
// helpers
import handleUser from '../helpers/handleuser'

export default function SearchResult(){

    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])



    return (
        <>
            <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>
                <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>
                <SearchResultContainer/>
                <FooterContainer backgroundColor='#262626'/>
            </Wrapper>

            {
                isSignInOpen || isSignUpOpen ?
                <SignInSignUpProxyContainer 
            
                    isSignInOpen={isSignInOpen}
                    setIsSignInOpen={setIsSignInOpen}
                    isSignUpOpen={isSignUpOpen}
                    setIsSignUpOpen={setIsSignUpOpen}
                    isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                    setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                
                />
                :
                null
            }
            


        </>
    )


}