import React from 'react'

import { Buttons } from '../components_staff';
import Button from '../components_staff/buttons';

export default function ButtonsContainer(props){



    const handleOpenForm = (title)=>{

        // need to check if the title is edit or add
        if(title === "Edit"){
            // this is checking to see if there is a listing selected
            // if there is than not null will be the condition and the edit form can be opened
            if(props.formData.Name !== null){
                props.setIsFormOpen(true)
                props.setFormTitle(title + props.title)
                document.body.style.overflow = 'hidden';
                
            }
        } else{
            props.setFormData(props.initialFormState)
            props.setIsFormOpen(true)
            props.setFormTitle(title + props.title)
            document.body.style.overflow = 'hidden';
            
        }
        // this will reset the request status so the previous is not showing to the user
        props.setRequestStatus({
            loading: null,
            success: null, 
            failure: null,
            data: null,
            status: null,
            clientMessage: null,
            
        })
        
    }

    // opens the delete form
    // different from the other forms because the delete container is its own container with separate styling
    const handleDeleteOpen= ()=>{
        if(props.formData.id){
            document.body.style.overflow = 'hidden';
            props.setIsDeleteOpen(true)
        }
        // this will reset the request status so the previous is not showing to the user
        props.setRequestStatus({
            loading: null,
            success: null, 
            failure: null,
            data: null,
            status: null,
            clientMessage: null,
            
        })

        
    }

    return (

        <>

            <Buttons>
                <Button.ButtonFrameInner>
                    <Button.ButtonItem background='#6C8915' onClick={() => handleOpenForm('Add')}>
                        <Button.ButtonText>Add</Button.ButtonText>    
                    </Button.ButtonItem>
                    <Button.ButtonItem background='#FFD500'  onClick={() => handleOpenForm('Edit')}>
                        <Button.ButtonText>Edit</Button.ButtonText>    
                    </Button.ButtonItem> 
                    <Button.ButtonItem background='#FF7276'  onClick={handleDeleteOpen}>
                        <Button.ButtonText>Delete</Button.ButtonText>    
                    </Button.ButtonItem>     
                </Button.ButtonFrameInner>
                {
                    props.extraButton ?
                    <Button.ButtonFrameInner justifyContent='flex-end'>
                        <Button.ButtonItem background='#6C8915' width="45%" laptopWidth="55%" >
                            <Button.ButtonText>Create Auction</Button.ButtonText>    
                        </Button.ButtonItem>
                    </Button.ButtonFrameInner>
                    :
                    null
                }
            </Buttons>
        </>

    )


}