import { useEffect, useState, useContext} from 'react'
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
import { useHistory, useLocation } from "react-router-dom";
// helpers
import handleUser from '../helpers/handleuser'


export default function useUser(){

    const history = useHistory()
    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])

    return { user, history }
}

