import React, {useContext, useEffect, useState} from 'react'
import { useHistory, useLocation } from "react-router-dom";
// containers
import NavContainer from '../containers/nav';
import SettingsContainer from '../containers/settings';
import FooterContainer from '../containers/footer';
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';
// components
import { Wrapper } from '../components'
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
//images
import CookLandRealty from '../images/CookLandRealty.svg'
import { ROUTES } from '../services/config';

// helpers
import handleUser from '../helpers/handleuser'

export default function Favorite() {
    const history = useHistory()
    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            console.log('user not found?')
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])

    console.log(user)

    if(user){


        return (
        
            <>
    
                <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true'  : null}>
                    <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>
                    <SettingsContainer/>
                    <FooterContainer backgroundColor='#262626'/>
                </Wrapper>
                {
                    isSignInOpen || isSignUpOpen ?
                    <SignInSignUpProxyContainer 
                
                        isSignInOpen={isSignInOpen}
                        setIsSignInOpen={setIsSignInOpen}
                        isSignUpOpen={isSignUpOpen}
                        setIsSignUpOpen={setIsSignUpOpen}
                        isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                        setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                    
                    />
                    :
                    null
                }
                
    
                </>
            )
            
    } else {
        history.push(ROUTES.HOME)
        return (
            null
        )
    }

    
        
        
    


}

