import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom';

export const WelcomeFrameWrapper = styled.div`
    
    margin-top: 52px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;

    @media only screen and (min-width: 1280px)  {
        height: 294px;
        margin-top: 100px;
        margin-bottom: 106px;
    }
`;

export const WelcomeFrame = styled.div`
    width: 90%;

    background: linear-gradient(0deg, rgba(39, 39, 39, 0.1), rgba(39, 39, 39, 0.1)), url(${props => props.url});
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;


    @media only screen and (min-width: 1280px)  {
        flex-direction: row;
        justify-content: space-evenly;
        // align-items: flex-start;
        height: 294px;
    }
`;

export const WelcomeLeftFrame = styled.div`

    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 40px;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        justify-content: space-between;
        height: 163px;
        width: 40%;
    }

`;

export const LeftTextWrapper = styled.div`

    margin-top: 20px;

    @media only screen and (min-width: 1280px)  {
        margin: 0
    }

`;

export const LeftFrameText = styled.h2`

    /* h2 */
    padding: 0;
    margin: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 47px;
    /* or 131% */
    text-transform: uppercase;
    /* black */
    color: #262626;


`;

export const LeftFrameSubText = styled.p`

    /* p / content */
    margin: 0;
    padding: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* black */
    color: #262626;
`;

export const WelcomeRightFrame = styled.div`

    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    margin: 10px 0 50px 0;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        width: 40%;

    }


`;

export const Block = styled.div`
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    margin-top: 20px;

    @media only screen and (min-width: 1280px)  {
        margin-top: 24px;
    }

`;


export const BlockText = styled.h5`
    height: 20px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 23px;
    /* or 105% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-left: 32px;

    /* black */

    color: #262626;


`;

export const LinkA = styled(ReactRouterLink)`

    text-decoration: none;
`

export const Chevron = styled.img`
    height: 16px;
    margin-right: 34px;
    color: #6C8915;
    transform: matrix(1, 0, 0, -1, 0, 0);
    cursor: pointer;
`;