


export default function handleClassDisplay(searchParams){
    switch(searchParams.propertyClass){
        case "RE_1":
            return "Residential";
        case "LD_2":
            return "Land";
        case "CI_3":
            return "Commercial";
        case "MF_4":
            return "Multi Family"
        default:
            return null
    }
}