import React from 'react';
import { Background, Frame, Container, Animation } from './styles/loading'



export default function Loading({children, ...restProps}){
    return <Background {...restProps}>{children}</Background>
}

Loading.Frame = function({children, ...restProps}) {
    return <Frame {...restProps}>{children}</Frame>
}

Loading.Container = function({children, ...restProps}){
    return <Container {...restProps}>{children}</Container>
}

Loading.Animation = function({children,...restProps}){
    return <Animation {...restProps}>{children}</Animation>
}