import React from 'react'

import {AboutUsFrame, AboutUsInnerFrame, ImageFrame, MapFrame, ContentFrame, Image, 
    InnerContentFrame, Title, ParagraphFrame, ParagraphText, EndText} from './styles/aboutus';

export default function AboutUs({children, ...restProps}){

    return <AboutUsFrame {...restProps}>{children}</AboutUsFrame>

}

AboutUs.AboutUsInnerFrame= function AboutUsAboutUsInnerFrame({children, ...restProps}){
    return <AboutUsInnerFrame {...restProps}>{children}</AboutUsInnerFrame>
}

AboutUs.ImageFrame= function AboutUsImageFrame({children, ...restProps}){
    return <ImageFrame {...restProps}>{children}</ImageFrame>
}
AboutUs.MapFrame= function AboutUsMapFrame({children, ...restProps}){
    return <MapFrame {...restProps}>{children}</MapFrame>
}
AboutUs.ContentFrame= function AboutUsContentFrame({children, ...restProps}){
    return <ContentFrame {...restProps}>{children}</ContentFrame>
}

AboutUs.Image= function AboutUsImage({children, ...restProps}){
    return <Image {...restProps}>{children}</Image>
}

AboutUs.InnerContentFrame= function AboutUsInnerContentFrame({children, ...restProps}){
    return <InnerContentFrame {...restProps}>{children}</InnerContentFrame>
}

AboutUs.Title= function AboutUsTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

AboutUs.ParagraphFrame= function AboutUsParagraphFrame({children, ...restProps}){
    return <ParagraphFrame {...restProps}>{children}</ParagraphFrame>
}

AboutUs.ParagraphText= function AboutUsParagraphText({children, ...restProps}){
    return <ParagraphText {...restProps}>{children}</ParagraphText>
}

AboutUs.EndText= function AboutUsEndText({children, ...restProps}){
    return <EndText {...restProps}>{children}</EndText>
}

