import React, {useState, useEffect, useContext} from 'react';


// container and component
import LoadingContainer from './loading'
import {ListingDetail} from '../components';

// context
import { UserContext } from '../context/user';

// helpers
import {handleImageChange, displayMLSImage, displayCookImage} from '../helpers/handleimagechange'
import handleTheFavoriteListing from '../helpers/handlethefavoritelisting'

// images
import Right from '../images/Right.svg';
import Left from '../images/Left.svg';
import Favorite from '../images/Favorite.png';
import PinkHeart from '../images/PinkHear.svg';

// api
import { getUsersFavoriteListingsAndMatch } from '../api/userfavoritelistings';
import deleteFavorite from '../api/deletefavorite'

export default function DetailImageContainer(props){
    
    // const [ favoriteIds, setFavoriteIds ] = useState();
    const [ isFavorite, setIsFavorite ] = useState();
    const [ user, ] = useContext(UserContext);

    useEffect(() => {

        if (localStorage.getItem('user')){
            getUsersFavoriteListingsAndMatch(parseInt(localStorage.getItem('listingID')) , setIsFavorite)
        }   

        if(!user){
            setIsFavorite(false)
        }

    }, [user])


    if(props.listingPics && props.detailedListing){
        return (
                <>
                
                    <ListingDetail.ImageFrame>
                        {
                            localStorage.getItem('type') === "Cook" ?
                            <ListingDetail.HiddenImage url={props.listingPics ? displayCookImage(props.listingPics, props.picIndex, "left") : null} alt='pic'>
                                <ListingDetail.Elipse left='40px' right='auto' onClick={()=> handleImageChange('DECREMENT', props.picIndex, props.setPicIndex, props.listingPics, props.setListingPics, props.totalPicCount, props.shouldFetchMore, props.setShouldFetchMore, props.picOffset, props.setPicOffset)}>
                                    <ListingDetail.Arrow src={Left}/>
                                </ListingDetail.Elipse>    
                            </ListingDetail.HiddenImage>
                            :
                            // <ListingDetail.HiddenImage url={ props.listingPics ? `data:image/png;base64,${props.listingPics[props.picIndex-1].itemImage}` : null} alt='pic'>
                            <ListingDetail.HiddenImage url={ props.listingPics ? displayMLSImage(props.listingPics, props.picIndex, "left") : null} alt='pic'>
                                <ListingDetail.Elipse left='40px' right='auto' onClick={()=> handleImageChange('DECREMENT', props.picIndex, props.setPicIndex, props.listingPics, props.setListingPics, props.totalPicCount, props.shouldFetchMore, props.setShouldFetchMore, props.picOffset, props.setPicOffset)}>
                                    <ListingDetail.Arrow src={Left} />
                                </ListingDetail.Elipse>
                                {/* <h1 style={{color: 'white'}}>picID: {props.listingPics ? props.listingPics[props.picIndex - 1].imageID : null}</h1> */}
                            </ListingDetail.HiddenImage>
                        }

                        {
                            // If the detailed listing is a cook listing than use the baseurl to fetch the image
                            localStorage.getItem('type') === "Cook" ?
                            <ListingDetail.LargeImage url={props.listingPics ? displayCookImage(props.listingPics, props.picIndex, "middle") : null} alt='pic'>
                                 <ListingDetail.AttributeFrame>
                                    <ListingDetail.AttributeFrameInner>
                                    {
                                        user
                                        ?

                                            isFavorite ?
                                            <ListingDetail.Heart 
                                                src={PinkHeart}
                                                onClick={
                                                    ()=>{
                                                        setIsFavorite(false)
                                                        deleteFavorite(props.detailedListing.id)
                                                    }
                                                }
                                            />
                                            :
                                            <ListingDetail.Heart 
                                                src={Favorite}
                                                onClick={()=>{
                                                        setIsFavorite(true)
                                                        handleTheFavoriteListing(props.detailedListing)
                                                    }
                                                }
                                        
                                        />
                                        :
                                        null
                                    } 
                                    </ListingDetail.AttributeFrameInner>
                                </ListingDetail.AttributeFrame>
                            </ListingDetail.LargeImage>
                            // else it is an mls listing and use base64 to display image
                            :
                            // <ListingDetail.LargeImage url={ props.listingPics ? `data:image/png;base64,${props.listingPics[props.picIndex].itemImage}` : null} alt='pic'>
                            <ListingDetail.LargeImage url={ props.listingPics ? displayMLSImage(props.listingPics, props.picIndex, 'middle') : null} alt='pic'>
                                {/* <h1 style={{color: 'white'}}>picID: { props.listingPics ? props.listingPics[props.picIndex].imageID : null}</h1> */}
                                <ListingDetail.AttributeFrame>
                                    <ListingDetail.AttributeFrameInner>
                                    {
                                        user ?
                                        
                                        isFavorite ?
                                        <ListingDetail.Heart 
                                            src={PinkHeart}
                                            onClick={
                                                ()=>{
                                                    setIsFavorite(false)
                                                    deleteFavorite(props.detailedListing.id)
                                                }
                                            }
                                        />
                                        :
                                        <ListingDetail.Heart 
                                            src={Favorite}
                                            onClick={()=>{
                                                    setIsFavorite(true)
                                                    handleTheFavoriteListing(props.detailedListing.id, props.detailedListing.Type, props.detailedListing.Class)
                                                }
                                            }
                                        />
                                        :
                                        null
                                    } 
                                    </ListingDetail.AttributeFrameInner>
                                </ListingDetail.AttributeFrame>
                                
                                
                            
                            </ListingDetail.LargeImage>   
                            
                        }

                        {
                            localStorage.getItem('type') === "Cook" ?
                            <ListingDetail.HiddenImage url={props.listingPics ? displayCookImage(props.listingPics, props.picIndex, "right") : null} alt='pic'>
                                <ListingDetail.Elipse left='auto' right='40px' onClick={()=> handleImageChange('INCREMENT', props.picIndex, props.setPicIndex, props.listingPics,props.setListingPics, props.totalPicCount, props.shouldFetchMore, props.setShouldFetchMore, props.picOffset, props.setPicOffset)}>
                                    <ListingDetail.Arrow src={Right}/>
                                </ListingDetail.Elipse>
                            </ListingDetail.HiddenImage>
                            :
                            // <ListingDetail.HiddenImage url={ props.listingPics ?  `data:image/png;base64,${ props.listingPics[props.picIndex + 1].itemImage}` : null} alt='pic'>
                            <ListingDetail.HiddenImage url={ props.listingPics ? displayMLSImage(props.listingPics, props.picIndex, 'right') : null} alt='pic'>
                                {/* <h1 style={{color: 'white'}}>picID: {props.listingPics ? props.listingPics[props.picIndex + 1].imageID : null}</h1> */}
                                <ListingDetail.Elipse 
                                    left='auto' 
                                    right='40px'
                                    onClick={()=> handleImageChange('INCREMENT', props.picIndex, props.setPicIndex, props.listingPics,props.setListingPics, props.totalPicCount, props.shouldFetchMore, props.setShouldFetchMore, props.picOffset, props.setPicOffset)}
                                >
                                    <ListingDetail.Arrow src={Right} />
                                </ListingDetail.Elipse>
                                
                            </ListingDetail.HiddenImage>
                        }

                        </ListingDetail.ImageFrame>
                </>
        )
    } else {
        return (
            <ListingDetail.ImageFrame>
                <ListingDetail.HiddenImage >
                    <LoadingContainer/>  
                </ListingDetail.HiddenImage>
                <ListingDetail.LargeImage>
                    <LoadingContainer/> 
                </ListingDetail.LargeImage>
                <ListingDetail.HiddenImage>
                    <LoadingContainer/> 
                </ListingDetail.HiddenImage>
            </ListingDetail.ImageFrame>
    )
    }
    
}