import React from 'react';
import Select from 'react-select'
import {SearchFrame, MLSSearchFrame, LocationSearchBox, MagnifyingGlassFrame, MagnifyingGlass,
    PriceSearchBox, SearchText, SearchButton, AcreSqrFtFrame} from './styles/search';



export default function Search({children, ...restProps}){
    return <SearchFrame {...restProps}>{children}</SearchFrame>
}

Search.MLSSearchFrame = function SearchMLSSearchFrame({children, ...restProps}){
    return <MLSSearchFrame {...restProps}>{children}</MLSSearchFrame>
}

Search.LocationSearchBox = function SearchLocationSearchBox({children, ...restProps}){
    return <LocationSearchBox {...restProps}>{children}</LocationSearchBox>
}

Search.MagnifyingGlassFrame = function SearchMagnifyingGlassFrame({children, ...restProps}){
    return <MagnifyingGlassFrame {...restProps}>{children}</MagnifyingGlassFrame>
}

Search.MagnifyingGlass = function SearchMagnifyingGlass({children, ...restProps}){
    return <MagnifyingGlass {...restProps}>{children}</MagnifyingGlass>
}



Search.SearchText = function SearchSearchText({children, ...restProps}){
    return <SearchText {...restProps}>{children}</SearchText>
}




Search.PriceSearchBox = function SearchPriceSearchBox({children, ...restProps}){
    return <PriceSearchBox {...restProps}>{children}</PriceSearchBox>
}



Search.SearchButton = function SearchSearchButton({children, ...restProps}){
    return <SearchButton {...restProps}>{children}</SearchButton>
}

Search.MenuSelect = function HomeSearchMenuSelect({children, ...restProps}){

    let display = null
    if (restProps.display){
        display = 'none'
    } else {
        display = 'flex'
    }

    const color = restProps.typeError

    const customStyles = {

        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            color: state.selectProps.menuColor,
            padding: 10,
          }),

        input: (provided)=> ({
            ...provided,
            height: '60px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: "18px",
        }),

        valueContainer: (provided, state)=> ({
            ...provided,
            height: '60px',

        }),

        container: (provided)=>({
            ...provided,
            width: '95%;',
            border: `2px solid ${color}`
        }),

        dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
            height: "40px;",
            display: display,
            alignItems: 'center;',
            fontSize: "100px;"
        }),

        indicatorSeparator: () => ({
        }),

        control: (provided) => ({
            ...provided,
            border: 'none;'
        }),

        singleValue: (provided) => ({
            ...provided,
            color: "black",
            fontSize: '20px',
            fontWeight: "700"
        })
    
    }

    const handleChange = (e) => {



        let newParams;

        // checks to see the name prop
        // this helps distinguish which state to change in the context
        switch(restProps.name){
            
            case "Property Class":
                newParams = restProps.searchParams
                newParams.propertyClass = e.value
                restProps.setSearchParams(newParams)

                break;

            case "Location":
                newParams = restProps.searchParams
                newParams.location = e.value
                restProps.setSearchParams(newParams)

                break;
            
            default:

                return null;
        }
    }

    

    
    
    return <Select 
                defaultValue={restProps.defaultVal ? { label: restProps.defaultVal, value: restProps.defaultVal} : { label: restProps.name, value: '' }} 
                options={restProps.options} 
                styles={customStyles} 
                onChange={handleChange}
            >
                {children}
            </Select>
}

Search.AcreSqrFtFrame = function SearchAcreSqrFtFrame({children, ...restProps}){
    return <AcreSqrFtFrame {...restProps}>{children}</AcreSqrFtFrame>
}