import React, {useState, useEffect, useContext} from 'react'
//components
import {Listing} from '../components'
//helpers
import {wrap} from '../helpers/wrap'
// context
import { SearchParamsContext } from '../context/searchparams';

export default function PageNumberContainer(props){

    const [searchParams, setSearchParams] = useContext(SearchParamsContext)
    const [pageArray, setPageArray] = useState(null)
    

   

    useEffect (()=>{
        

        if (props.listingCount){
            // from the total listings count divide by 8 displayed per page and get the total number of pages
            const totalPages = Math.ceil(props.listingCount / 8)
            // loop over that and create an array of numbers that will be displayed as the page numbers
            let pageArr = []
            for (let i = 1; i<=totalPages; i++){
                pageArr.push(i)
            }
            // wrap the array for negative indexing
            pageArr = wrap(pageArr)
            setPageArray(pageArr)

            // this will set the search params upon load
            // the state is being used so the page number can use the value in the next page
            let testParams = sessionStorage.getItem('searchParams')
            testParams = JSON.parse(testParams)
            setSearchParams(testParams)
        }
        

    }, [props.listingCount])


    const nextPage = (offset, offsetMultiplier)=>{
        const offsetter = offset + (8*offsetMultiplier)
        let newOffSet
        let newPageCountIndex

        // getting the searchParams set above
        let testParams = sessionStorage.getItem('searchParams')
        testParams = JSON.parse(testParams)

        // if the offset plus or minus 8 is greater than or equal and less than the listing count then run below
        if(offsetter >= 0 && offsetter <= props.listingCount){
            if(offsetter > 0){
                // setting the offset here will fire the use effect on the search results and recall the api to the server
                newOffSet = offsetter
                newPageCountIndex = searchParams.pageCountIndex + offsetMultiplier
            } else{
                newOffSet = 0
                newPageCountIndex = 0
            }

            // new search params are created from the params up there and then stored in session
            // searchParams has to be a state above for page changing
            const newSearchParams = { ...testParams, offset: newOffSet, pageCountIndex: newPageCountIndex}
            sessionStorage.setItem('searchParams', JSON.stringify(newSearchParams))
            setSearchParams(newSearchParams)
            // takes user back to the top of the page
            window.scrollTo(0, 0);
        } else {
            return null
        }
        
    }
    

    if(pageArray){
        return(
            <>
                <Listing.ListingPageNum>
                    <Listing.ListingPageNumInner>
                        {//only display the back arrow if not on first page
                            searchParams.offset + (8*-1) >= 0 ?
                            <Listing.ListingPageNumText onClick={()=> nextPage(searchParams.offset, -1)}>&#8592;</Listing.ListingPageNumText>
                            :
                            //returning this so everything stays centered
                            <Listing.ListingPageNumText></Listing.ListingPageNumText>
                        }
                        <Listing.ListingPageNumText>{pageArray[searchParams.pageCountIndex]}</Listing.ListingPageNumText>
                        <Listing.ListingPageNumText>/</Listing.ListingPageNumText>
                        <Listing.ListingPageNumText>{Math.ceil(props.listingCount / 8)}</Listing.ListingPageNumText>
                        {// only display the forward arrow if not on the last page
                            searchParams.offset + (8*1) < props.listingCount ?
                            <Listing.ListingPageNumText onClick={()=> nextPage(searchParams.offset, 1)}>&#8594;</Listing.ListingPageNumText>
                            :
                            //returning this so everything stays centered
                            <Listing.ListingPageNumText></Listing.ListingPageNumText>
                        }
                        
                    </Listing.ListingPageNumInner>
                </Listing.ListingPageNum>
            </>
        )
    } else {
        return null
    }
    
}


// saving this code for the day i can get it working
/* <>
                <Listing.ListingPageNum>
                    <Listing.ListingPageNumInner>
                        <Listing.ListingPageNumText background='#F4F4F5'>{pageArray[pageCountIndex]}</Listing.ListingPageNumText>
                        <Listing.ListingPageNumText onClick={()=> nextPage(props.offset, 2)}>{pageArray[pageCountIndex+1]}</Listing.ListingPageNumText>
                        <Listing.ListingPageNumText onClick={()=> nextPage(props.offset, 3)}>{pageArray[pageCountIndex+2]}</Listing.ListingPageNumText>
                        <Listing.ListingPageNumText onClick={()=> nextPage(props.offset, 4)}>{pageArray[pageCountIndex+3]}</Listing.ListingPageNumText>
                        <Listing.ListingPageNumText onClick={()=> nextPage(props.offset, 5)}>{pageArray[pageCountIndex+4]}</Listing.ListingPageNumText>
                        {pageArray.length > 4 ?
                            <Listing.ListingPageNumText>...</Listing.ListingPageNumText>
                            :
                            null
                        }
                        
                    </Listing.ListingPageNumInner>
                </Listing.ListingPageNum>
            </> */