import React from 'react';

import { BlockGrid, BlockItem, ItemBlockFrame, Icon, ItemBlockBottomFrame, ValueText, LabelText} from './styles/block'


export default function Block({children, ...restProps}){
    return <BlockGrid {...restProps}>{children}</BlockGrid>
}

Block.BlockItem = function BlockBlockItem({children, ...restProps}){
    return <BlockItem {...restProps}>{children}</BlockItem>
}

Block.ItemBlockFrame = function BlockItemBlockFrame({children, ...restProps}){
    return <ItemBlockFrame {...restProps}>{children}</ItemBlockFrame>
}

Block.Icon = function BlockIcon({children, ...restProps}){
    return <Icon {...restProps}>{children}</Icon>
}

Block.ItemBlockBottomFrame = function BlockItemBlockBottomFrame({children, ...restProps}){
    return <ItemBlockBottomFrame {...restProps}>{children}</ItemBlockBottomFrame>
}

Block.ValueText = function BlockValueText({children, ...restProps}){
    return <ValueText {...restProps}>{children}</ValueText>
}

Block.LabelText = function BlockLabelText({children, ...restProps}){
    return <LabelText {...restProps}>{children}</LabelText>
}