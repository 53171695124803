import styled from 'styled-components';

export const TableFrame = styled.table`

    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    min-height: 600px;

    margin-bottom: ${ props => props.marginBottom ? props.marginBottom : "75px"};
    margin-top: ${ props => props.marginTop ? props.marginTop : null};
    display: flex;
    flex-direction: column;
`;

export const HeaderFrame = styled.thead`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #585A60;
    height: 60px;
`;

export const CheckBox = styled.input``

export const HeaderText = styled.th`

    width: ${props => props.width ? props.width : "5%"};
    font-family: Microsoft Sans Serif;
    font-size: 16px;
    text-align: ${props => props.textAlign ? props.textAlign : "center"};

    color: #FFFFFF;
    font-weight: normal;




`
export const DataTable = styled.tbody`

    width: 100%;
    display: flex;
    flex-direction: column;

    

`

export const DataTableRow = styled.tr`

    width: 100%;
    
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    
    &:nth-child(even){
        background: #F8F8F8;
      }

    &:nth-child(odd){
        background: #ffffff;
    }
    
`

export const DataRow = styled.tr`


    width: 100%;
        
    display: flex;
    justify-content: space-evenly;
    align-items: center;


`;

export const DataTableText = styled.td`
    margin: 8px 0;
    text-align: ${props => props.textAlign ? props.textAlign : "center"};
    width: ${props => props.width ? props.width : "5%"};
    font-family: Microsoft Sans Serif;
    font-size: 14px;
    color: #000000;
    font-weight: normal;

    @media screen and (min-width: 1920px){
        font-size: 16px;
    }

`

export const TableFooter = styled.tfoot`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #585A60;
    height: 60px;

`;