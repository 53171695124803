import React from 'react';
// component
import { Info } from '../components'
// urls
import Combine2 from '../images/Combine2.svg'
// Hooks
// import useContact from '../'
import useContact from '../hooks/usecontact';
import useScript from '../hooks/useScript';


export default function WhatSetsUsApartContactContainer(){

    const formHandler = useContact('partialForm')
    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)
    

    return (
        <>
            <Info>
                <Info.BottomFrame mobileBottomWidth='90%' width='81.25%' marginTop='0px'>
                    <Info.BottomLeftFrame>
                        <Info.ContactUsForm>
                            <Info.ContactUSWrapper>    
                                {/* need to wrap right here */}
                                <Info.TitleFrame>
                                    <Info.AboutUSTitle marginLeft='50px'>CONTACT US</Info.AboutUSTitle>
                                    {
                                        formHandler.formSuccess ?
                                        <Info.ResponseText color='#6C8915'>Your Contact Request has been submitted!</Info.ResponseText>
                                        :
                                        null
                                    }

                                    {
                                        formHandler.formErrors ?
                                        <Info.ResponseTextFrame>
                                            {
                                                formHandler.formErrors.length > 0 ?
                                                formHandler.formErrors.map(formError => (
                                                    
                                                        <Info.ResponseText color='#FF7276'>{formError.error}</Info.ResponseText>
                                                    
                                                ))
                                                :
                                                <Info.ResponseText color='#FF7276'>{formHandler.formErrors}</Info.ResponseText>
                                            }
                                        </Info.ResponseTextFrame>
                                        :
                                        null
                                    }
                                </Info.TitleFrame>
                                <Info.OuterInputDiv>
                                    <Info.InputDiv>
                                        <Info.Input placeholder='FIRST NAME' setState={formHandler.setFirstName}/>
                                        <Info.Input placeholder='LAST NAME' setState={formHandler.setLastName}/>
                                    </Info.InputDiv>
                                    <Info.InputDiv>
                                        <Info.Input placeholder='EMAIL' setState={formHandler.setEmail}/>
                                        <Info.Input placeholder='PHONE NUMBER' setState={formHandler.setPhoneNumber}/>
                                    </Info.InputDiv>
                                </Info.OuterInputDiv>
                                    <Info.InputDiv>
                                        <Info.CheckMenu>
                                            <Info.CheckLink>
                                                <Info.CheckBox type='checkbox' setState={formHandler.setSell} checked={formHandler.sell}/>
                                                <Info.AboutUSParagraph>I want to sell a property</Info.AboutUSParagraph>
                                            </Info.CheckLink>
                                            <Info.CheckLink>
                                                <Info.CheckBox type='checkbox' setState={formHandler.setBuy} checked={formHandler.buy}/>
                                                <Info.AboutUSParagraph>I want to buy a property</Info.AboutUSParagraph>
                                            </Info.CheckLink>
                                        </Info.CheckMenu>
                                        <Info.SubmitButton onClick={e=> formHandler.handleSubmit(e)}>SEND</Info.SubmitButton>
                                    </Info.InputDiv>
                                </Info.ContactUSWrapper>
                            </Info.ContactUsForm>
                        </Info.BottomLeftFrame>
                        <Info.IMGFrame url={Combine2} width='30.82835184%'/>
                    </Info.BottomFrame>
                </Info>
        </>
    )
}