import React from 'react'
import {BuyerFrame, BackgroundIMGTextFrame , SmallText, TitleText, ContentText, BackgroundWrapper, MainFrame, 
    BlockFrame, BlockItem, BlockTextFrame, BlockTitle, BlockMainText, BlockLink, BlockLinkText, ContactButton, ContactText

} from './styles/buyerseller'


export default function BuyerSeller({children, ...restProps}){
    return <BuyerFrame {...restProps}>{children}</BuyerFrame>
}

BuyerSeller.BackgroundIMGTextFrame = function BuyerBackgroundIMGTextFrame({children, ...restProps}){
    return <BackgroundIMGTextFrame {...restProps}>{children}</BackgroundIMGTextFrame>
}

BuyerSeller.SmallText = function BuyerSmallText({children, ...restProps}){
    return <SmallText {...restProps}>{children}</SmallText>
}

BuyerSeller.TitleText = function BuyerTitleText({children, ...restProps}){
    return <TitleText {...restProps}>{children}</TitleText>
}

BuyerSeller.ContentText = function BuyerContentText({children, ...restProps}){
    return <ContentText {...restProps}>{children}</ContentText>
}

BuyerSeller.BackgroundWrapper = function BuyerBackgroundWrapper({children, ...restProps}){
    return <BackgroundWrapper {...restProps}>{children}</BackgroundWrapper>
}

BuyerSeller.MainFrame = function BuyerMainFrame({children, ...restProps}){
    return <MainFrame {...restProps}>{children}</MainFrame>
}

BuyerSeller.BlockFrame = function BuyerBlockFrame({children, ...restProps}){
    return <BlockFrame {...restProps}>{children}</BlockFrame>
}

BuyerSeller.BlockItem = function BuyerBlockItem({children, ...restProps}){
    return <BlockItem {...restProps}>{children}</BlockItem>
}

BuyerSeller.BlockTextFrame = function BuyerBlockTextFrame({children, ...restProps}){
    return <BlockTextFrame {...restProps}>{children}</BlockTextFrame>
}

BuyerSeller.BlockTitle = function BuyerBlockTitle({children, ...restProps}){
    return <BlockTitle {...restProps}>{children}</BlockTitle>
}

BuyerSeller.BlockMainText = function BuyerBlockMainText({children, ...restProps}){
    return <BlockMainText {...restProps}>{children}</BlockMainText>
}


BuyerSeller.BlockLinkText = function BuyerBlockLinkText({children, ...restProps}){

    return(
        <>
            <BlockLink to={restProps.to}>
                <BlockLinkText {...restProps}>{children}</BlockLinkText>
            </BlockLink>
        </>
    ) 
    
}

BuyerSeller.ContactButton = function BuyerContactButton({children, ...restProps}){
    return (
        <>
            <ContactButton to={restProps.to}>
                <ContactText {...restProps}>{children}</ContactText>
            </ContactButton>
        </>
    )
}


