import React from 'react';

import {ContactUsFrame,ContactUsFrameInner, InfoFrame, InfoWrapper, MapFrame, 
    ContentFrame, InnerContentFrame, Title, ParagraphText, HorizontalBar, Form, InputBoxFrame, InnerInputBoxFrame, 
     Input, InputCheckBoxFrame, CheckBoxText, ExplainText, ButtonFrame, InputButton} from './styles/contactus'

export default function ContactUs({children, ...restProps}){


    return <ContactUsFrame {...restProps}>{children}</ContactUsFrame>
}

ContactUs.ContactUsFrameInner= function ContactContactUsFrameInner({children, ...restProps}){
    return <ContactUsFrameInner {...restProps}>{children}</ContactUsFrameInner>
}
ContactUs.InfoFrame= function ContactUsInfoFrame({children, ...restProps}){
    return <InfoFrame {...restProps}>{children}</InfoFrame>
}

ContactUs.InfoWrapper= function ContactUsInfoWrapper({children, ...restProps}){
    return <InfoWrapper {...restProps}>{children}</InfoWrapper>
}

ContactUs.MapFrame= function ContactUsMapFrame({children, ...restProps}){
    return <MapFrame {...restProps}>{children}</MapFrame>
}

ContactUs.ContentFrame= function ContactUsContentFrame({children, ...restProps}){
    return <ContentFrame {...restProps}>{children}</ContentFrame>
}

ContactUs.InnerContentFrame= function ContactUsInnerContentFrame({children, ...restProps}){
    return <InnerContentFrame {...restProps}>{children}</InnerContentFrame>
}
ContactUs.Title= function ContactUsTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

ContactUs.ParagraphText= function ContactUsParagraphText({children, ...restProps}){
    return <ParagraphText {...restProps}>{children}</ParagraphText>
}

ContactUs.HorizontalBar= function ContactUsHorizontalBar({children, ...restProps}){
    return <HorizontalBar {...restProps}>{children}</HorizontalBar>
}

ContactUs.Form= function ContactUsForm({children, ...restProps}){
    return <Form {...restProps}>{children}</Form>
}

ContactUs.InputBoxFrame= function ContactUsInputBoxFrame({children, ...restProps}){
    return <InputBoxFrame {...restProps}>{children}</InputBoxFrame>
}

ContactUs.InnerInputBoxFrame= function ContactUsInnerInputBoxFrame({children, ...restProps}){
    return <InnerInputBoxFrame {...restProps}>{children}</InnerInputBoxFrame>
}

ContactUs.Input= function ContactUsInput1({children, ...restProps}){
    return <Input {...restProps}>{children}</Input>
}

ContactUs.InputCheckBoxFrame= function ContactUsInputCheckBoxFrame({children, ...restProps}){
    return <InputCheckBoxFrame {...restProps}>{children}</InputCheckBoxFrame>
}

ContactUs.CheckBoxText= function ContactUsCheckBoxText({children, ...restProps}){
    return <CheckBoxText {...restProps}>{children}</CheckBoxText>
}

ContactUs.ExplainText= function ContactUsExplainText({children, ...restProps}){
    return <ExplainText {...restProps}>{children}</ExplainText>
}

ContactUs.ButtonFrame= function ContactUsButtonFrame({children, ...restProps}){
    return <ButtonFrame {...restProps}>{children}</ButtonFrame>
}

ContactUs.InputButton= function ContactUsInputButton({children, ...restProps}){
    return <InputButton {...restProps}>{children}</InputButton>
}