import axios from 'axios'
import {URLS} from '../services/config'


export default function continueWithFaceBook() {
    
    axios.get(`${URLS.BASE_URL}/${URLS.SOCIALAUTH}facebook/?redirect_uri=${URLS.BASE_URL}/facebook`)
    .then(response => {
        window.location.replace(response.data.authorization_url);
    })  
};