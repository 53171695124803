import axios from 'axios';
import {URLS} from '../services/config'
import getCookie from '../helpers/getcookie';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function logInV2(emailAddress, password, reCaptchaToken){

    console.log('getting here')

    const csrf = getCookie('csrftoken')
    const headers = {
        headers: {
            "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
            "X-CSRFTOKEN": `${csrf}`
        }
    }

    const loginResponse = axios.post(`${URLS.BASE_URL}/${URLS.LOGIN}`, { 
        email: emailAddress,
        password: password, 
        recaptcha: reCaptchaToken
    }, headers)

    
    return loginResponse



}