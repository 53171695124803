import styled from 'styled-components'


export const AboutUsFrame = styled.div`
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    background: #FFFFFF;

`;

export const AboutUsInnerFrame = styled.div`


    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 1280px)  {
        display: grid;
        align-items: stretch;
        width: 81.25%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-row-gap: 30px;
        grid-column-gap: 30px;
        grid-template-areas: 
        "ImageFrame ContentFrame ContentFrame"
        "MapFrame ContentFrame ContentFrame"
        "MapFrame ContentFrame ContentFrame";
        margin-top: 60px;
        margin-bottom: 100px;
    }
`;

export const ImageFrame = styled.div`
    order: 1;
    background: linear-gradient(0deg, rgba(39, 39, 39, 0.1), rgba(39, 39, 39, 0.1)), url(${props => props.url});
    -webkit-background-size: cover;
    background-position: center center;
    margin: 0;

    @media only screen and (min-width: 1280px)  {
        
        
        width: auto;
        grid-area: ImageFrame;
    }
`;

export const Image = styled.img`

    width: 100%;

    @media only screen and (min-width: 1280px)  {
        
        display: none;
    }

`;

// have to explicitly state the height or the react map wont render
export const MapFrame = styled.div`
    order: 3; 

    margin-top: 30px;
    margin-bottom: 30px;
    height: 471px;
    width: 90%;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        height: auto;
        width: auto;
        grid-area: MapFrame;   
    }
`;


export const ContentFrame = styled.div`
    order: 2;
    width: 90%;
    margin-top: 30px;
    background: #F4F4F5;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
        width: auto;
        height: auto;
        grid-area: ContentFrame;  
    }

`;

export const InnerContentFrame = styled.div`
    width: 90%;
    margin-top: 30px;
    margin-bottom: 38px;

    @media only screen and (min-width: 1280px)  {
        margin-top: 50px;
        margin-bottom: 46px;
    }
`;

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    /* h2 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    /* or 131% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* black */

    color: #262626;

    @media only screen and (min-width: 1280px)  {
        font-size: 34px; 
    }

    @media only screen and (min-width: 1920px)  {
        font-size: 40px; 
    }


`;

export const ParagraphFrame = styled.div`

    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;

    @media only screen and (min-width: 1280px)  {
        margin-top: 13px;
        margin-bottom: 24px; 
    }

    @media only screen and (min-width: 1920px)  {
        margin-top: 33px;
        margin-bottom: 34px; 
    }
`;

export const ParagraphText = styled.p`
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    

    line-height: 23px;
    /* or 156% */
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #595959;

    @media only screen and (min-width: 1280px)  {
        
        line-height: 26px;
        font-size: 20px; 
    }

    @media only screen and (min-width: 1920px)  {
        line-height: 38px;
        font-size: 28px; 
        margin-bottom: 24px;
    }

`

export const EndText = styled.p`
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #6C8915;


    @media only screen and (min-width: 1280px)  {
        font-size: 24px;
    }
`
