import React, { useEffect, useState} from 'react';

// this is the container that will set above the tables to show the user that they have successfully updated the item

export default function SuccessContainer(props){

    const divStyles={
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        margin: "0px",
        padding: "0px",
        // border: "2px solid pink",
        marginBottom: "10px",
        background: "#6C8915"
    }


    const pStyles = {
        fontSize: "18px", 
        color: "#FFFFFF", 
        fontFamily: "Microsoft Sans Serif",
        margin: "5px",
        letterSpacing: "2px",
        textAlign: "left",
        width: "20%"
    }

    if (props.requestStatus.status === 201){
        return (

            <Expire delay="5000" >
                <div style={divStyles}>
                    <p style={pStyles}>{props.title} Created/Udpated</p>
                </div>
            </Expire>
            
        )
    } else if (props.requestStatus.status === 204){
        return (
            <Expire  delay="5000">
                <div style={divStyles}>
                    <p style={pStyles}>{props.title} Deleted</p>
                </div>
            </Expire>
            )  
    } else if (props.requestStatus.status === 400){
        return (
            <Expire  delay="5000">
                <div style={divStyles}>
                    <p style={pStyles}>Error in form submission</p>
                </div>
            </Expire>
            )  
    }
        else {
        return null
    }
    

}

// this wraps the component and when the time is passed the component will be removed from the DOM
const Expire = props => {
    const [visible, setVisible] = useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setVisible(false);
      }, props.delay);
    }, [props.delay]);
  
    return visible ? <div style={{width: "100%"}}>{props.children}</div> : <div />;
  };