import React from 'react'
import { BulletFrame, BulletFrameInner, VerticalBar, ItemFrame, ItemTitle, List, ListItem } from './styles/bullet'

export default function Bullet({children, ...restProps}){
    return <BulletFrame {...restProps}>{children}</BulletFrame>
}


Bullet.BulletFrameInner = function BulletBulletFrameInner({children, ...restProps}){
    return <BulletFrameInner {...restProps}>{children}</BulletFrameInner>
}


Bullet.VerticalBar = function BulletVerticalBar({children, ...restProps}){
    return <VerticalBar {...restProps}>{children}</VerticalBar>
}


Bullet.ItemFrame = function BulletItemFrame({children, ...restProps}){
    return <ItemFrame {...restProps}>{children}</ItemFrame>
}

Bullet.ItemTitle = function BulletItemTitle({children, ...restProps}){
    return <ItemTitle {...restProps}>{children}</ItemTitle>
}

Bullet.List = function BulletList({children, ...restProps}){
    return <List {...restProps}>{children}</List>
}

Bullet.ListItem = function BulletListItem({children, ...restProps}){
    return <ListItem {...restProps}>{children}</ListItem>
}