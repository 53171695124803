import React, { useContext , useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import NavContainer from '../containers/nav';
import ContactUsContainer from '../containers/contactus';
import FooterContainer from '../containers/footer';
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';
// components
import { Wrapper } from '../components'
//images
import CookLandRealty from '../images/CookLandRealty.svg'
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
// helpers
import handleUser from '../helpers/handleuser'

export default function ContactUs(props){

    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])

    return (
        <>
            <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>
                <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>
                <ContactUsContainer/>
                <FooterContainer backgroundColor='#262626'/>
            </Wrapper>
            <SignInSignUpProxyContainer 
            
                isSignInOpen={isSignInOpen}
                setIsSignInOpen={setIsSignInOpen}
                isSignUpOpen={isSignUpOpen}
                setIsSignUpOpen={setIsSignUpOpen}
                isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                
            />

        </>
        
        
    )
}