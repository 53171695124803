
import axios from 'axios'
import getCookie from '../helpers/getcookie';
import { URLS } from '../services/config'

//api
import updatePassword from '../api/updatepassword'


axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function updateUserProfile(token, data, setSuccess, setFailure, setUser, setOldPassword, setNewPassword, setReNewPassword){


    const csrf = getCookie('csrftoken')
    const headers = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${token}`,
            "X-CSRFTOKEN": `${csrf}`
        }
    }



    axios.patch(`${URLS.BASE_URL}/${URLS.UPDATEUSER}`, data.profileData, headers)
    .then(response =>{
        if(response.status === 201){

            if(data.passwordData){
                updatePassword(headers, data.passwordData, setSuccess, setFailure, setOldPassword, setNewPassword, setReNewPassword)
            } else {
                setSuccess('Your Changes Have Saved')
            }

            if(setUser){
                setUser(response.data)
            }        
        }
        
    })
    .catch(err =>{
        setFailure(err.response.data)
    })
}

