import styled from 'styled-components'


export const PrivacyFrame = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background: #FFFFFF;
    height: 600px;

    @media only screen and (min-width: 1280px)  {
        height: 500px;

    }
    @media only screen and (min-width: 1600px)  {
        height: 700px;
    }

    @media only screen and (min-width: 1920px)  {
        
        height: 650px;

    }


`

export const PrivacyFrameInner = styled.div`
    width: 88%;
    display: flex;
    flex-direction: column;
    height: 300px;

    @media only screen and (min-width: 1280px)  {
        height: 400px;

    }

    @media only screen and (min-width: 1600px)  {
        height: 620px;

    }
    
`

export const UpdatedText = styled.p`
    /* p / content */
    margin: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height, or 128% */
    margin-bottom: 20px;
    letter-spacing: 0.3px;

    /* Grey / 1 */

    color: #9A9A9A;

`

export const PrivacyContentFrame = styled.div`
    margin-bottom: 22px;
`


export const PrivacyTitle = styled.h6`

    /* h6 */
    margin: 0;
    padding: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */
    text-transform: uppercase;

    color: #000000;

`

export const PrivacyParagraph = styled.p`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* GreyIcon */

    color: #4E4E4E;

`

export const PrivacyBullet = styled.li`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;

    /* GreyIcon */

    color: #4E4E4E;


`;


// bottom frame stuff
export const PrivacyFrameBottom = styled.div`
    width: 100%;
    border: 1px solid #DFDFDF;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
`;

export const PrivacyBottomFrameInner = styled.div`
    width: 88%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    margin: 5px 0;
    
    width: 100%;

    @media only screen and (min-width: 1280px)  {
        height: 100px;
        width: 88%;
    }

    @media only screen and (min-width: 1600px)  {
        // height: 160px;
        // border: 2px solid yellow;
    }

    @media only screen and (min-width: 1920px)  {
        
        height: 200px;

    }

`;


export const PrivacyButton = styled.div`
    margin: 0;
    padding: 0;
    /* Green */
    width: 60%;
    background: #6C8915;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    
    height: 60px;
    

    @media only screen and (min-width: 1280px)  {
        height: 82px;
        width: 35%;
    }
`;

export const ButtonText = styled.p`

    /* p / button */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    // line-height: 22px;
    /* identical to box height */
    
    letter-spacing: 1.8px;
    text-transform: uppercase;

    color: #FFFFFF;

    @media only screen and (min-width: 1280px)  {
        font-size: 18px;

    }

`