import React from 'react'
import { StaffWrapper } from '../components_staff';


export default function StaffWrapperContainer({children}){

return (
    <>

        <StaffWrapper>
            {children}
        </StaffWrapper>

    </>
)

}