import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const MapFrame = styled.div`

    display: ${props => props.display ? props.display : 'none'};

    height: ${props => props ? props.height : null};

    width: 100%;

    @media only screen and (min-width: 1280px)  {
        display: block;
        width: ${props => props ? props.width : null};
    }
`;




export const MapElement = styled.div`

    height: ${props => props ? props.height : null};
    width: ${props => props ? props.width : null};
`;





export const ItemAmount = styled.p`

    /* p / geotag */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: ${props => props.fontSize ? props.fontSize : '18px'};
    line-height: 23px;
    /* identical to box height, or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;


`


export const MapItem = styled.div`



    /* Auto Layout */

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    width: ${props => props.width ? props.width : '134px'};
    height: ${props => props.height ? props.height : '50px'};
    left: 201px;
    top: 273px;

    background: ${props => props.background ? props.background : "#FFFFFF"};
    /* Grey / 3 */

    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(16, 16, 18, 0.31);
    border-radius: 60px;

    cursor: pointer;


    &:hover{
        background: #DD6A6B;
        box-shadow: 0px 1px 1px rgba(16, 16, 18, 0.31);
        border-radius: 60px;
    }

    &:hover ${ItemAmount}{
        color: #FFFFFF;
    }

`;

export const MapEmblem = styled.img`

    height: 30px;
    width: 30px;

`;

export const MapPopout = styled.div`

    // margin-top: 1rem;
    // margin-left: 5rem;
    position: relative;
    display: ${props => props.display ? props.display : 'none'};
    align-items: center;
    width: 400px;
    height: 180px;  
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(16, 16, 18, 0.31);
    z-index: 1;

    @media only screen and (min-width: 1500px)  {
        width: 625px;
        height: 200px; 
    }
`;

export const ImageLinkWrapper = styled(ReactRouterLink)`

    width: 45%;




`


export const Image = styled.div`

    height: 180px;
    width: 100%;
    background: linear-gradient(0deg, rgba(39, 39, 39, 0.1), rgba(39, 39, 39, 0.1)), url(${props => props.url});
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-position: center center;

    @media only screen and (min-width: 1500px)  {
        height: 200px; 
    }
`;

export const DetailFrame = styled.div`

    height: 210px;
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const DetailInnerFrame = styled.div`

    width: 95%;
    height: 190px;


    @media only screen and (min-width: 1280px)  {

        height: 160px;

    }

    @media only screen and (min-width: 1920px)  {

        height: 190px;

    }

`
export const InfoBox = styled.div`
    display: flex;
    align-items: center;

`;


// these are also in the listing file but for simplicity they are also in here 
// a bit of WET code but it will save from complication later on
export const CardTitle = styled.div`
    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 23px;
    /* or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* black */

    color: #262626;
    // min-height: 3rem;


    @media only screen and (min-width: 1280px)  {

        font-size: .8rem;


    }

    @media only screen and (min-width: 1920px)  {

        font-size: 1rem;


    }

`

export const LocationBox = styled.div`

    display: flex;
    justyify-content: space-between;
    align-items: center;
    cursor: pointer;
    min-height: 45px;


`


export const LocationIcon = styled.img`
    width: 20px;
`;

export const LocationText = styled.p`
    width: 75%
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 6px;
    /* Grey / 1 */
    
    color: #9A9A9A;

    &:hover{
        border-bottom: 1px solid #6C8915;
    }
`;

export const TypeText = styled.p`

    /* p / small */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;

    /* Grey / 1 */
    
    color: #9A9A9A;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 12px 0px;


    @media only screen and (min-width: 1280px)  {

        margin: 6px;
        font-size: 8px;

    }

    @media only screen and (min-width: 1920px)  {

        margin: 12px;
        font-size: 12px;

    }

`;

export const Dot = styled.div`
    /* Ellipse 31 */
    position: static;
    width: 2px;
    height: 2px;
    left: 0px;
    top: 0px;

    background: #C4C4C4;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 5px;

    @media only screen and (min-width: 1280px)  {

        margin: 6px 1px;

    }

    @media only screen and (min-width: 1920px)  {

        margin: 0;

    }
`;


export const Amount = styled.h6`

    /* h6 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 26px;
    /* identical to box height, or 130% */

    text-transform: uppercase;

    color: #6C8915;


    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;

    // border: 2px solid green;

    @media only screen and (min-width: 1280px)  {

        font-size: 1rem;

    }

    @media only screen and (min-width: 1920px)  {

        font-size: 1.5rem;

    }
`;
