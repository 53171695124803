import React from 'react'
import {Nav} from '../components'
import {ROUTES} from '../services/config'
import RealtyLogo from '../images/CookLandRealtyFooter.jpeg' 
import TwitterIcon from '../images/Twitter.svg'
import FacebookIcon from '../images/Facebook.svg'
import InstagramIcon from '../images/Instagram.svg'


export default function FooterContainer(props){

    return (
        
        <>
            <Nav height='180px' flexDirection='column' justifyContent='center' footerHeight='60px' backgroundColor={props.backgroundColor} >
                <Nav.Frame1 width='90%' justifyContent='space-evenly;'>
                    <Nav.LogoFrame width='10%'>
                        <Nav.Logo src={RealtyLogo}/>
                    </Nav.LogoFrame>
                    <Nav.Frame1Inner>
                        <Nav.LinkFrame display='flex'>
                            <Nav.LinkText to={ROUTES.OURLISTINGS}>OUR LISTINGS</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.SEARCHRESULT}>MLS Search</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.BUYER}>BUYER</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.BUYER}>SELLER</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.ABOUTUS}>ABOUT US</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.CONTACTUS}>CONTACT US</Nav.LinkText>
                        </Nav.LinkFrame>
                    </Nav.Frame1Inner>
                    <Nav.SocialMediaBox>
                        <Nav.SocialIcon src={TwitterIcon}/>
                        <Nav.SocialIcon src={FacebookIcon}/>
                        <Nav.SocialIcon src={InstagramIcon}/>
                    </Nav.SocialMediaBox>
                </Nav.Frame1>
                <Nav.HorizontalBar/>
                <Nav.CopyRightText>© Cook Realty 2021, All Rights Reserved</Nav.CopyRightText>
            </Nav>

        </>
        
        
        )


} 