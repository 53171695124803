import axios from 'axios';
import {URLS, ROUTES} from '../services/config'


export default function verifyAccount(uid, token, history){


    axios.post(`${URLS.BASE_URL}/${URLS.USERACTIVATE}`, {
        uid: uid,
        token: token
    })
    .then(response=>{

        history.push(ROUTES.HOME)
    })
    .catch(error => {

    })
}