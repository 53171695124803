import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
//Context
import { SearchParamsContext } from '../context/searchparams';
// apis
import getFeaturedListings from '../api/listing'
import getUsersFavoriteListings from '../api/userfavoritelistings'
//services
import { URLS } from '../services/config'

// helpers
import addIsFavorite from '../helpers/addisfavorite';
import sortByNew from '../helpers/sortbynew';


export default function useListings(page, ){

    // state for managing listings
    const [ listings, setListings ] = useState()

    // this state is for styling purposes
    // i believe it handles displaying flex or grid depending on the page
    const [ display, setDisplay ] = useState(true)
    
    
    const [ searchParams, ] = useContext(SearchParamsContext)
    const [, setIsLoading ] = useState()
    const [ connectionError, setConnectionError ] = useState(null)




    useEffect(()=>{

        let isComponentMounted = true;

        async function getData(){

            // this is the variable that contains the data from the response of the servers listing data
            let listingResponse;


            if (isComponentMounted){
                // check to see what page the container is originating from
                switch(page){

                    case "SearchResults":

                        setListings(null)
                        // if search results already exist there is no reason to make an api fetch to the server
                        setIsLoading(true)
                        // if there are no search results that currently exist in context than fetch search results
                        // this is helpful when the user goes to the detail page and then they go back to the search results page
                        if(!listings || searchParams.offset >= 0){
                            if(searchParams || JSON.parse(sessionStorage.getItem('searchParams'))){
                                try {
                                    listingResponse = await axios.get(`${URLS.BASE_URL}/${URLS.MLSLISTINGALLDATA}?count=8&location=${searchParams.location}&minprice=${searchParams.minPrice}&maxprice=${searchParams.maxPrice}&class=${searchParams.propertyClass}&offset=${searchParams.offset}`)
                                }catch (error){
                                    setConnectionError('Check Your Interent Connection')  
                                }
                                
                                // if they do not then just get the first 8
                            } else{   
                                try {
                                    listingResponse = await axios.get(`${URLS.BASE_URL}/${URLS.MLSLISTINGALLDATA}?count=8&offset=${searchParams.offset}`)
                                } catch (error){
                                    setConnectionError('Check Your Interent Connection')   
                                    
                                }
                            }
                        } else {
                            setIsLoading(false)
                            return null
                        }
                        
                        break;
                    
                    // if on the home page then only get the featured listings
                    case "Home":

                        try {
                            setConnectionError(null)
                            listingResponse = await getFeaturedListings()
                            // set the response data to the sortbynew function
                            // this sorts the array from new - available - salePending - sold
                            listingResponse.data = await sortByNew(listingResponse.data)
                        } catch (error){
                            setConnectionError('Check your Internet Connection')
                        }
                        
                        break;


                    case "ListingDetail":

                        // get featured listings because they go at the bottom of the page
                        try {
                            setConnectionError(null)
                            listingResponse = await getFeaturedListings()
                        } catch (error){
                            setConnectionError('Check your Internet Connection')
                        }
                        break;
                    
                    // if on the our lisings page then get all listings
                    case "OurListings":


                        try {
                            setConnectionError(null)
                            listingResponse = await axios.get(`${URLS.BASE_URL}/${URLS.LISTINGS}`)
                            // set the response data to the sortbynew function
                            // this sorts the array from new - available - salePending - sold
                            listingResponse.data = await sortByNew(listingResponse.data)
                        } catch (error) {
                            console.log(error)
                            setConnectionError('Check your Internet Connection')
                        }

                        break;
                    
                    case "Favorite":

                        const accessToken = localStorage.getItem('accessToken')
                        try {
                            const headers = {
                                // cancelToken: cancelToken.token,
                                headers: {
                                    Authorization: `JWT ${accessToken}`
                                }
                            }
                        
                            listingResponse = await axios.get(`${URLS.BASE_URL}/${URLS.FAVORITES}`, headers)
                            
                        } catch (error){
                            console.log(error)
                            setConnectionError('Check Your Internet Connection')
                        }
                        break;

                    default:
                        setIsLoading(false)
                        setConnectionError('There was an error proccessing your request')
                        return null 
                }

                // this handles getting the favorite listings from the user
                // it will then call the add is favorite function which will create an array of the merged data
                // then it will be set in the listing state
                // if no user is found then this wont run
                if (localStorage.getItem('user')){
                    const favoriteIdResponse = await getUsersFavoriteListings()
                    const mergedListings = addIsFavorite(listingResponse.data, favoriteIdResponse.data)
                    // setting the new listings as the merged state
                    setListings(mergedListings)
                    setIsLoading(false)
                    setDisplay(false)
                // if there is no user than just set listings
                // this will set listings with out any favorites
                } else {
                    setListings(listingResponse.data)
                    setIsLoading(false)
                    setDisplay(false)
                }


            }
        }
        
        getData()
        

        return () => {
            isComponentMounted = false;
            setListings(null)
          }
    // this is an interim solution putting the user in the reload param arr
    // i need the user to refresh so get user favorite listings will call
    // the problem is js's ansychrnonous nature wont wait until the user is loaded on intitial call
    // so when the user context changes and updated in the nav, the entire container re loads
    // this causes the server to get hit extra times to load everyting again
    // this also causes the search results and listings to be hit twice
    // ADDING THE LOCAL STORAGE SEEMED TO HAVE HELPED
    }, [ localStorage.getItem('user'),  sessionStorage.getItem('searchParams'), searchParams])


    // handles when the user clicks on the favorite icon
    // depending on the type will either set it to favorite or null
    function manipulateIsFavorite(id, type){

        let listingArr = [...listings]

        for (let i =0; i < listingArr.length ; i ++){
    
            if (listingArr[i].id === id){

                switch (type){

                    case "Add":
                        listingArr[i].is_favorite = true
                        break

                    case "Remove":
                        listingArr[i].is_favorite = null
                        break

                    default:
                        break
                }
                
            }
        }
    
        setListings(listingArr)
    }



    return { 
            listings, setListings, 
            connectionError, 
            display, 
            manipulateIsFavorite,
        }




}