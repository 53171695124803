import React, { useState, useContext, useEffect } from 'react';
import { Form, Info } from '../components';
//context
import { UserContext } from '../context/user'

// api
import { contactUsListingDetail } from '../api/contactus'
// hooks
import useScript from '../hooks/useScript';


export default function ContactFormContainer(props){

    
    const [ user, ] = useContext(UserContext)
    
    
    const [ success, setSuccess ] = useState()
    const [ error, setError ] = useState()

    const [ firstName, setFirstName ] = useState()
    const [ lastName, setLastName ] = useState()
    const [ emailAddress, setEmailAddress ] = useState()
    const [ phoneNumber, setPhoneNumber ] = useState()
    const [ message, setMessage ] = useState()


    const handleSubmit = (e)=>{
        e.preventDefault();

        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'contact'}).then(function(token) {
                contactUsListingDetail(firstName, lastName, phoneNumber, emailAddress, message, props.detailedListing, setSuccess, setError, token)
            });
        });
    }

    const handleExit = () => {
        // props.setIsSignUpOpen(false);
        // need to figure out how i am going to handle the state of the background blur
        // maybe make this a global context that can be accessed anywhere in the app ?
        // background: rgba(255, 255, 255, 0.67);
        props.setIsContactFormOpen(false)
        document.body.style.overflow = 'unset';
        setError(null)
        setSuccess(null)
    }

    useEffect(()=>{

        if(user){
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmailAddress(user.email)
            setPhoneNumber(user.phone_number)
        }

        return ()=> null
    }, [user])

    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)

    if (props.isContactFormOpen){
        return(
            <>
    
                <Form height='750px' smallHeight='500px'>
                    <Form.ExitFrame>
                        <Form.ExitBox onClick={handleExit}>
                            <Form.RightSlash></Form.RightSlash>
                            <Form.LeftSlash></Form.LeftSlash>
                        </Form.ExitBox>
                    </Form.ExitFrame>
                    <Form.Wrapper method="POST" onSubmit={(e) => handleSubmit(e)}>
                        <Form.Title>To Jeff Angold from</Form.Title>
                        <Form.Input 
                            name = 'first_name'
                            placeholder='FIRST NAME'
                            value={firstName}
                            onChange={({target}) => setFirstName(target.value)}
                            height='30px'
                            marginBottom='10px'
                            disabled={user}
                        />
                        { error ? error.firstNameError ? <Info.ResponseText color='#FF7276'>{error.firstNameError}</Info.ResponseText> : null : null}
                        <Form.Input
                            name='last_name'
                            placeholder='LAST NAME'
                            value={lastName}
                            onChange={({target}) => setLastName(target.value)}
                            height='30px'
                            marginBottom='10px'
                            disabled={user}
                        />
                        { error ? error.lastNameError ? <Info.ResponseText color='#FF7276'>{error.lastNameError}</Info.ResponseText> : null : null}
                        <Form.Input
                            name = 'email' 
                            placeholder='EMAIL'
                            type='email'
                            value={emailAddress}
                            onChange={({target}) => setEmailAddress(target.value)}
                            height='30px'
                            marginBottom='10px'
                            disabled={user}
                        />
                        <Form.Input
                            name = 'phonenumber' 
                            placeholder='Phone Number'
                            value={phoneNumber}
                            onChange={({target}) => setPhoneNumber(target.value)}
                            height='30px'
                            marginBottom='10px'
                            disabled={user}
                        />
                        { error ? error.emailAddressError ? <Info.ResponseText color='#FF7276'>{error.emailAddressError}</Info.ResponseText> : null : null}
                        <Form.TextBoxWrapper>
                            <Form.TextBox
                                name='message' 
                                placeholder='Message'
                                value={message}
                                onChange={({target}) => {setMessage(target.value)}}
                            />
                        </Form.TextBoxWrapper>
    
                        { error ? error.message ? <Info.ResponseText color='#FF7276'>{error.message}</Info.ResponseText> : null : null}
                        
    
    
                        <Form.Button height='30px' smallHeight='55px' marginBottom='20px'>Send</Form.Button>
            
                        {
                            success ?
                            <Form.SocialFrame background='#F2F5E7;' margin='40px 0 0 0' laptopMargin='0px'>
                                <Info.ResponseText color='#6C8915'>{success}</Info.ResponseText>
                            </Form.SocialFrame>
                            : 
                            null
                        }
                        {
                            error ?
                            // the components come from the info component
                            <Info.ResponseTextFrame marginBottom = '10px'>
                                {
                                    error.map(formError => (
                                        
                                            <Info.ResponseText color='#FF7276'>{formError.error}</Info.ResponseText>
                                        
                                    ))
                                }
                            </Info.ResponseTextFrame>
                            :
                            null
                        }
                        
                        
                    </Form.Wrapper>
                </Form>
    
            </>
        )
    } else {
        return null
    }
    



}