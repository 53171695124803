import styled from 'styled-components';


export const ButtonFrame = styled.div`

    margin: 50px 0 20px 0;
    width: 95%;
    display: flex;
    justify-content: space-between;
`;

export const ButtonFrameInner = styled.div`
 
    width: 45%;

    display: flex;
    justify-content: ${ props => props.justifyContent ? props.justifyContent : "space-between"};

    @media screen & (min-width: 1920px){
        width: 35%;
    }
`;

export const ButtonItem = styled.div`
    cursor: pointer;
    width: ${ props => props.laptopWidth ? props.laptopWidth : "30%"};
    background: ${ props => props.background ? props.background :null};
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
        filter: brightness(0.8);
      }

    @media screen and (min-width: 1920px){
        width: ${ props => props.width ? props.width : "25%"};
    }
`;


export const ButtonText = styled.p`

    font-family: Microsoft Sans Serif;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 1.75px;

    @media screen and (min-width: 1920px){
        font-size: 18px;
    }

`

export const ButtonFrameExtra = styled.div`

    width: 

`