import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom';

export const UserDropDownFrame = styled.div`
    top: 105px;
    position: fixed;


    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    /* shadow / dropdown */
    z-index: 999;
    box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.08);


    @media screen and (min-width: 1280px){
        width: 20%;
    }

    @media screen and (min-width: 1600px){
        width: 15%;
    }
    `;

export const DropDownFrameInner = styled.div`

    width: 95%;

    display: flex;
    flex-direction: column;
`;

export const DropDownItemFrame = styled.div`

    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const DropDownIcon = styled.img`

    width: 30%;
    height: 20px;

`;

export const TextLink = styled(ReactRouterLink)`

    text-decoration: none;

`

export const DropDownText = styled.p`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* black */

    color: #262626;
    margin-left: 20px;
    
`