
// these function handle opening and closing of the map popout

// basically just updates the array of listings with whatever object setting it to isDisplayed
// sets a new array state


// This function handles the logic for the item popout on the map
// it handels both opening and closing
export const handleDisplayPopout = (searchResults, setListings, result, type) => {   
    let newArr = [...searchResults]

    // if open is passed the run below code
    if (type === "open"){
        setNewItem(newArr, result, 'flex')
        setListings(newArr)
    // if close is passed then run below code
    } else if (type === 'close'){
        setNewItem(newArr, result, null)
        setListings(newArr)
    }


  }

// function is used in the handle display popout to change the new array 
const setNewItem = (newArr, result, change)=>{
        result.isDisplayed = change
        // loop over the new array ie search results and when the search result id has matched the specific result id run
        for( let i =0; i<newArr.length; i++){
          if(newArr[i].id === result.id){
            // get the index of the match
            let newIndexOf = newArr.indexOf(newArr[i])
            //remove the index at item and put in place the new result
            newArr.splice(newIndexOf, 1, result)
            break
          }
        }
}