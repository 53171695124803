import React from 'react';

import { Title } from '../components_staff'


export default function StaffTitleContainer(props){

    return ( 
        <>
            <Title>
                <Title.TitleText>{props.title}</Title.TitleText>
            </Title>
        </>
    )
}