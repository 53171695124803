export const listingHeaders=[
        
    {
        name: "Name",
        width: "16%",
        textAlign: "left"
    },
    {
        name: "Address",
        width: "17%"
    },
    {
        name: "City",
        width:"10%"
    },
    {
        name: "Class",
        width:"10%"
    },
    {
        name: "Status",
        width: "6%"
    },
    {
        name: "DateListed",
        width:"10%"
    },
    {
        name: "Asking Price",
        width: "10%"
    },
    {
        name: "isAuction",
        width: "8%"
    }
        
]


export const createBlockData = (listingStats, GreenCheck, Pending, Sold)=> [
    {
        blockTitle: "Total Available",
        value: listingStats ? listingStats.available : null,
        icon: GreenCheck
    },
    {
        blockTitle: "Total Sale Pending",
        value: listingStats ? listingStats.sale_pending : null,
        icon: Pending
    },
    {
        blockTitle: "Total Sold",
        value: listingStats ? listingStats.sold : null,
        icon: Sold
    },
]


export const favoriteHeaders=[
    {
        name: "ListingName",
        width: "14%"
    },
    {
        name: "Class",
        width:"10%"
    },
    {
        name: "Type",

    },
    {
        name: "User",
        width: "15%"
    },
    {
        name: "User Phone Number",
        width: "15%"
    },
    {
        name: "User Email",
        width: "17%"
    },
    {
        name: "ListingID",

    },
    {
        name: "City",
       
    },
    {
        name: "State",
        
    }
        
]


export const createFavoriteBlockData = (favoritedListingsStats, GreenCheck, Pending, Sold)=> [
    {
        blockTitle: "Total Favorited",
        value: favoritedListingsStats ? favoritedListingsStats.total : null,
        icon: GreenCheck
    },
    {
        blockTitle: "Cook Favorited",
        value: favoritedListingsStats ? favoritedListingsStats.cook : null,
        icon: Pending
    },
    {
        blockTitle: "MLS Favorited",
        value: favoritedListingsStats ? favoritedListingsStats.mls : null,
        icon: Sold
    },
]