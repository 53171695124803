import React from 'react'
import { List } from '../components'


export default function ListContainer(props){

    return (
        <>

            <List>
                <List.ListFrameInner marginBottom={props.marginBottom}>
                    <List.ListTitle>{props.listText.title}</List.ListTitle>
                    {
                        props.listText.data.map((item)=>(
                            <List.ListItemFrame id={item.id}>
                                <List.ListItemHeader>{item.header}</List.ListItemHeader>
                                <List.ListItemParagraph>{item.paragraph}</List.ListItemParagraph>
                            </List.ListItemFrame>
                        ))
                    }
                </List.ListFrameInner>
            </List>

        </>
    )


}