import React from 'react'
import GoogleMapReact from 'google-map-react';
//components
import {AboutUs, Info, Map } from '../components'
//images
import CookRealtySign from '../images/CookRealtySign.png';
import Phone from '../images/Phone.svg';
import Mail from '../images/Mail.svg';
// services
import {GOOGLEMAPAPI} from '../services/config';

import CookLandEmblem from '../images/CookLandEmblem.png'

export default function AboutUsContainer(){


    const defaultProps = {
        center: {
            lat: 38.390570,
            lng: -94.5
          },
          zoom: 8
      };

    return (
        <>

        <AboutUs>
            <AboutUs.AboutUsInnerFrame>
                
                <AboutUs.ImageFrame url={CookRealtySign}>
                    <AboutUs.Image src={CookRealtySign}/>
                </AboutUs.ImageFrame>
                    
                <AboutUs.MapFrame>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLEMAPAPI}}
                        center={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                    >
                        

                        {/* <Map.MapItem  width='20px' height='20px' background='#BD191A'>
                            
                        </Map.MapItem> */}

                        <Map.MapEmblem lat={38.390570} lng={-93.794110} src={CookLandEmblem} />

                    </GoogleMapReact>
                    
                </AboutUs.MapFrame>
                
                <AboutUs.ContentFrame>
                    <AboutUs.InnerContentFrame>
                        <AboutUs.Title>ABOUT US</AboutUs.Title>
                        <AboutUs.ParagraphFrame>

                            <AboutUs.ParagraphText>
                                While we are new in the real estate business, our family has been 
                                in the equipment sales and auction business for over 60 years. 
                                We are committed to offering the same quality of service to 
                                our real estate customers in the Missouri and Kansas areas.
                            </AboutUs.ParagraphText>

                            <AboutUs.ParagraphText>For sellers, we will use our diverse capabilities to market your property. 
                                If you are looking to buy a new home, piece of ground or a space to grow your business, we work diligently to 
                                find the best fit for your need. What ultimately sets us a part from other traditional real estate companies is 
                                our sphere of influence throughout the entire Midwest. Our listings will be featured on our real estate website 
                                with links to and from our equipment auction, they will be highlighted monthly at our on-site auctions, print 
                                advertising will be selectively placed in key locations to maximize visibility within the community and it will
                                be promoted on a number of traditional sites.</AboutUs.ParagraphText>
                        </AboutUs.ParagraphFrame>
                        <AboutUs.EndText>We look forward to going to work for you.</AboutUs.EndText>
                        <Info.AboutUsHorizontal/>
                        <Info.AboutUSDetail>203 NW 160 Clinton, MO 64735</Info.AboutUSDetail>
                        <Info.AboutUSDetailWrapper>
                            <Info.DetailIMG src={Phone}/>
                            <Info.AboutUSDetail>660-851-8877</Info.AboutUSDetail>
                        </Info.AboutUSDetailWrapper>
                        <Info.AboutUSDetailWrapper>
                            <Info.DetailIMG src={Mail}/>
                            <Info.AboutUSDetail>Jangold@cookrealtyco.com</Info.AboutUSDetail>
                        </Info.AboutUSDetailWrapper>
                    </AboutUs.InnerContentFrame>
                </AboutUs.ContentFrame>

                

            </AboutUs.AboutUsInnerFrame>
        </AboutUs>


        </>
        
)


}