import React from 'react';
import { Welcome } from '../components'
import Background from '../images/background.svg';
import RightChevron from '../images/rightchevron.svg'

import { ROUTES } from '../services/config'

export default function WelcomeContainer(){

    return (
        <>

            <Welcome>
                    <Welcome.WelcomeFrame url={Background}>

                        <Welcome.WelcomeLeftFrame>
                            <Welcome.LeftTextWrapper>
                                <Welcome.LeftFrameText>WELCOME TO</Welcome.LeftFrameText>
                                <Welcome.LeftFrameText>COOK LAND & REALTY</Welcome.LeftFrameText>
                            </Welcome.LeftTextWrapper>
                            <Welcome.LeftTextWrapper>
                                <Welcome.LeftFrameSubText>Cook Land and Realty is able to take care of any </Welcome.LeftFrameSubText>
                                <Welcome.LeftFrameSubText>real estate transaction in Missouri.</Welcome.LeftFrameSubText>
                            </Welcome.LeftTextWrapper>
                        </Welcome.WelcomeLeftFrame>

                        <Welcome.WelcomeRightFrame>
                            <Welcome.Block>
                                <Welcome.BlockText>FOR BUYER</Welcome.BlockText>
                                <Welcome.Chevron src={RightChevron} to={ROUTES.BUYER}/>
                            </Welcome.Block>
                            <Welcome.Block>
                                <Welcome.BlockText>FOR SELLER</Welcome.BlockText>
                                <Welcome.Chevron src={RightChevron} to={ROUTES.SELLER}/>
                            </Welcome.Block>
                        </Welcome.WelcomeRightFrame>
                    </Welcome.WelcomeFrame>
                </Welcome>

        </>
    )
}