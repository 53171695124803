import axios from 'axios';
import {URLS} from '../services/config'
// import updateUserProfile from './updateuserprofile'
import checkUser from './checkuser'
import getCookie from '../helpers/getcookie';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function logIn(emailAddress, password, handleExit, setUser, setError, setIsLoginLoading, setEmailAddress, setPassword, reCaptchaToken){

    setIsLoginLoading(true)

    const csrf = getCookie('csrftoken')
    const headers = {
        headers: {
            "Authorization": `JWT ${localStorage.getItem('accessToken')}`,
            "X-CSRFTOKEN": `${csrf}`
        }
    }

    axios.post(`${URLS.BASE_URL}/${URLS.LOGIN}`, { 
        email: emailAddress,
        password: password, 
        recaptcha: reCaptchaToken
    }, headers)

    
    .then((response) =>{
        const token = response.data.access

        const expirationDate = new Date(new Date().getTime() + 3600 * 1000)
        localStorage.setItem('accessToken', token)
        localStorage.setItem('refresh', response.data.refresh)
        localStorage.setItem('expirationDate', expirationDate)
        

        // const data = {
        //     is_social: false
        // }
        // used to update the social right after log in...
        // this is for the basic login which means they are not a social user
        // this is not necessarry but leaving in just in case for later
        // updateUserProfile(token, data, handleExit=handleExit)
        checkUser(setUser)  
        setIsLoginLoading(false)
        setError(null)
        // the sigin frame will exit here
        handleExit()
    })
    .catch((error) => {

        if (error.response.status === 400){
            console.log(error.response.data)
            setError(error.response.data.detail)     
        }

        if (error.response.status === 401){
            if (error.response.data.detail === "No active account found with the given credentials"){
                setError("Failed to load account with those credentials")
            }
        }

        

        setEmailAddress('')
        setPassword('')
        setIsLoginLoading(false)

    })
}

