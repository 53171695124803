import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';








export const TitleFrame = styled.div`

    display: flex;
    justify-content: center;
    width: 90%;

    @media only screen and (min-width: 1280px)  {
        width: 100%;
        padding-top: 99px;
        padding-bottom: 40px;
        justify-content: space-around;
    }
`;


export const TitleTextFrame = styled.div`
    width: 80%;
    display: flex;

    @media only screen and (min-width: 1280px)  {
        width: 40%;  
    }
`;


// had to create a second to offset for mobile
// really hacky but weird way
export const TitleTextFrame2 = styled.div`
    width: 10%;
    display: flex;
    justify-content: ${props => props.justify ? props.justify : null};
    align-items: ${props => props.align ? props.align : null};


    @media only screen and (min-width: 1280px)  {
        width: 30%;  

    }
`;

export const TitleText = styled.h2`
    
    font-family: Museo Sans Cyrl;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;

    @media only screen and (min-width: 1280px)  {
        font-size: 36px;

    }
`;
export const ViewMoreWrapper = styled.div`

    display: none;
    justify-content: space-between;

    @media only screen and (min-width: 1280px)  {

        display: flex;

    }
`;

export const CircleFrame = styled.div`
    display: flex;
    align-items: center;
    width: 30%;

    @media only screen and (min-width: 1280px)  {

        width: 50%;

    }
`;

export const Circle= styled.img`
    cursor: pointer;
    height: 2.5rem;
    padding-left: 10px;
`;

export const ViewMoreLink = styled(ReactRouterLink)`

    border: none;

`

export const ViewMore = styled.p`
    /* p / link */

    font-family: Museo Sans Cyrl;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    // width: 50%;
    color: #262626;
    cursor: pointer;

    // border: 2px solid green;
`
