import styled from 'styled-components';

export const TitleFrame = styled.div`

    margin: 35px 0;
    display: flex;
    justify-content: center;
    width: 80%;
    border-radius: 5px;
    background: #FFFFFF;


`;

export const TitleText = styled.h4`

    font-family: Microsoft Sans Serif;
    font-size: 32px;
    text-align: center;
    letter-spacing: 1px;
    color: #232229;

`;