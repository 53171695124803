import React, { useEffect, useContext, useState } from 'react';

import StaffWrapperContainer from '../../containers_staff/staffwrapper';
import StaffSideNaveContainer from '../../containers_staff/staffsidenav';
import StaffMainContainer from '../../containers_staff/staffmain';
import StaffTitleContainer from '../../containers_staff/stafftitle';
import StaffBlockContainer from '../../containers_staff/staffblock';
import FormContainer from '../../containers_staff/form';

//images
import AllUsers from '../../images/allusers.svg'
import MagnigyingGlass from '../../images/MagnifyingGlass.svg';
import PinkHeart from '../../images/PinkHear.svg';
import Mail from '../../images/Mail.svg';


import { ROUTES, URLS } from '../../services/config';

//hooks
import useData from '../../hooks/staff/usedata';
import useUser from '../../hooks/useuser';
import useForm from '../../hooks/staff/useform';

export default function StaffDashboard(){


    const { user, history } = useUser()
    const { isFormOpen, setIsFormOpen } = useForm()
    const [ userStats, userStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.USERSTATS}`);
    const [ favoritedListingsStats, favoritedListingsStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.FAVORITEDLISTINGSSTATS}`)
    const [ messageStats, messageStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.CONTACTMESSAGESSTATS}`);

    const blockData =[
        {
            blockTitle: "Users",
            value: userStats ? userStats.total_users : null,
            icon: AllUsers
        },
        {
            blockTitle: "Mls Searches",
            value: "No Data Yet",
            icon: MagnigyingGlass
        },
        {
            blockTitle: "Favorites",
            value: favoritedListingsStats ? favoritedListingsStats.total : null,
            icon: PinkHeart
        },
        {
            blockTitle: "Messages",
            value: messageStats ? messageStats.total : null,
            icon: Mail
        }
    ]
    
    if(user && user.is_staff){
        return (
        
            <StaffWrapperContainer>
                <StaffSideNaveContainer/>
                <StaffMainContainer>
                    <StaffTitleContainer title = 'Welcome to Cook Land and Realty Staff Page!'/>
                    <StaffBlockContainer blockData={blockData}/>
                    <FormContainer isFormOpen={isFormOpen} setIsFormOpen={setIsFormOpen}/>
                </StaffMainContainer>
                
            </StaffWrapperContainer>
        
        )
    } else {
        history.push(ROUTES.STAFFPAGES.STAFFLOGIN)
        return (
            null
        )
    }
    
}