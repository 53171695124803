import axios from 'axios'
import {URLS} from '../services/config'


export default function checkUser(setUser){


    const accessToken = localStorage.getItem('accessToken')
    if(accessToken){

        const config = {
            headers:{
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        const body  = JSON.stringify({token: accessToken})

        axios.post(`${URLS.BASE_URL}/${URLS.VERIFYUSER}`, body, config)
        .then(response => {

            if(response.status === 200){
                axios.get(`${URLS.BASE_URL}/${URLS.USERDATA}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${localStorage.getItem('accessToken')}`,
                        'Accept': 'application/json'
                    }
                })
                .then(response => {
                    if(response.status===200){
                        localStorage.setItem('user', 'true')
                        setUser(response.data)
                    } else {
                        removeUserFromLocalStorage()
                        setUser(false)
                    }
                })
            } else{
                removeUserFromLocalStorage()
                setUser(false)

            }
        })
        .catch(err => {
            removeUserFromLocalStorage()
            setUser(false)
        })

        
    } else {
        setUser(false)
        removeUserFromLocalStorage()
    }
}


function removeUserFromLocalStorage(){
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    localStorage.removeItem('refresh')
    localStorage.removeItem('expirationDate')
}
