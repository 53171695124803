

export const URLS = {
    
    BASE_URL: process.env.REACT_APP_BASE_URL,

    // User URLS
    LOGIN: 'users/api/v1/login/',
    SIGNUP: 'users/api/v1/signup/',
    USERDELETE: 'users/api/v1/delete/',
    // 'auth/users/',
    REFRESHTOKEN: 'auth/jwt/refresh',
    VERIFYUSER: 'auth/jwt/verify/',
    USERDATA: 'auth/users/me/',
    EMAILPASSWORDRESET: 'auth/users/reset_password/',
    PASSWORDRESETCONFIRM: 'auth/users/reset_password_confirm/',
    USERACTIVATE: 'auth/users/activation/',
    CONTACTUS: 'users/api/v1/contact-us/',
    SOCIALAUTH: 'auth/o/',
    UPDATEUSER: 'users/api/v1/update-user/',
    UPDATEPASSWORD: 'users/api/v1/change-password/',
    
    // Cook URLS
    LISTINGS: 'listing/api/v1/cook-featured-listings',
    LISTINGDETAIL: 'listing/api/v1/cook-detail-listing',
    LISTINGIMAGES: 'listing/api/v1/cook-listing-images',
    
    // MLS URLS
    MLSLISTINGDETAIL: 'listing/api/v1/mls-detail-listing',
    MLSLISTINGALLDATA: 'listing/api/v1/mls-listings-all-data',
    MLSITEMIMAGES: 'listing/api/v1/mls-item-images',
    MLSSEARCHCOUNT: 'listing/api/v1/mls-search-count',

    // Favorite ULRS
    FAVORITES: 'listing/api/v1/favorite-listings', 
    USERSFAVORITES: 'listing/api/v1/users-favorites',

    STAFFAPIS: {
        USERS: 'users/api/v1/staff/users/',
        USERSTATS: 'users/api/v1/staff/user-stats/',
        LISTINGS: "listing/api/v1/staff/cook-listings/",
        LISTINGIMAGES:"listing/api/v1/staff/cook-listings/images/",
        LISTINGSSTATS: 'listing/api/v1/staff/listings-stats/',
        ALLFAVORITEDLISTINGS: 'listing/api/v1/staff/all-favorite-listings/',
        FAVORITEDLISTINGSSTATS: 'listing/api/v1/staff/favorite-listings-stats/',
        CONTACTMESSAGES: 'users/api/v1/staff/contact-messages/',
        CONTACTMESSAGESSTATS: 'users/api/v1/staff/contact-messages-stats/',
    }

}

export const ROUTES = {
    HOME: '/',
    PROPERTY: '/property',
    SEARCHRESULT: '/searchresult',
    OURLISTINGS: '/our-listings',
    ABOUTUS: '/aboutus',
    CONTACTUS: '/contactus',
    FAVORITE: '/favorite',
    SETTINGS: '/settings',
    // below: not sure i will need this as a separate page
    RESETPASSWORD: '/reset-password',
    // below: this will be the link to the route from the password reset email
    CONFIRMPASSWORDRESET: '/password/reset/confirm/:uid/:token',
    ACTIVATE: '/activate/:uid/:token',
    PRIVACYPOLICY: "/privacy-policy",
    
    BUYER: '/buyer',
    BUYERPAGES: {
        AREYOUREADYTOBUY: '/are-you-ready-to-buy',
        PREPAREFORTHECLOSING: '/prepare-for-the-closing',
        REALESTATETERMSYOUSHOULDKNOW: '/real-estate-terms-you-should-know',
        BUYINGVSRENTING: '/buying-vs-renting',
        PREAPPROVAL: '/pre-approval'
    }, 

    SELLER: '/seller',
    SELLERPAGES: {
        GETTINGPROPERTYREADYTOSELL: '/getting-property-ready-to-sell',
        MAXIMIZELANDVALUE: '/maximize-land-value',
        WHATSETSUSAPART: '/what-sets-us-apart',
        TEN31: '/1031'
    },

    STAFFPAGES: {
        STAFFLOGIN: '/staff-login',
        STAFFDASHBOARD: '/staff',
        STAFFUSERS: '/staff-users',
        STAFFLISTINGS: '/staff-listings',
        STAFFMESSAGES: '/staff-messages'
    }
 
}

export const GOOGLEMAPAPI = process.env.REACT_APP_GOOGLE_APP_KEY

// this represents how the app filters listings by new
// if greater than x days then will be not new
export const newListingTimeFrame = 30