import React from 'react';
import { Loading } from '../components'


export default function LoadingContainer(props){
    return (
        
            <>
                <Loading>
                    <Loading.Frame>
                        <Loading.Container>
                            <Loading.Animation height={props.height} width={props.width}/>
                        </Loading.Container>
                    </Loading.Frame>
                </Loading>

            </>    

        
    )
}