import React from 'react'
import { SettingsFrame, SettingsBox, TitleBox, TitleBoxInner, TitleText, 
    ItemBox,  ProfileBox, ProfileImg, ProfileText, InputBox, 
    Input, PrivacyBox, PrivacyInput, PrivacyText, PasswordInput, SaveFrame, 
    SaveButton, DeleteButton, DeleteText, SuccessFrame, SuccessText} from './styles/settings'


export default function Settings({children, ...restProps}){

    return <SettingsFrame {...restProps}>{children}</SettingsFrame>
}


Settings.SettingsBox = function SettingsSettingsBox({children, ...restProps}){

    return <SettingsBox {...restProps}>{children}</SettingsBox>
}

Settings.TitleBox = function SettingsTitleBox({children, ...restProps}){

    return <TitleBox {...restProps}>{children}</TitleBox>
}

Settings.TitleBoxInner = function SettingsTitleBoxInner({children, ...restProps}){

    return <TitleBoxInner {...restProps}>{children}</TitleBoxInner>
}

Settings.TitleText = function SettingsTitleText({children, ...restProps}){

    return <TitleText {...restProps}>{children}</TitleText>
}

Settings.ItemBox = function SettingsItemBox({children, ...restProps}){

    return <ItemBox {...restProps}>{children}</ItemBox>
}


Settings.ProfileBox = function SettingsProfileBox({children, ...restProps}){

    return <ProfileBox {...restProps}>{children}</ProfileBox>
}

Settings.ProfileImg = function SettingsProfileImg({children, ...restProps}){

    return <ProfileImg {...restProps}>{children}</ProfileImg>
}

Settings.ProfileText = function SettingsProfileText({children, ...restProps}){

    return <ProfileText {...restProps}>{children}</ProfileText>
}

Settings.InputBox = function SettingsInputBox({children, ...restProps}){

    return <InputBox {...restProps}>{children}</InputBox>
}

Settings.Input = function SettingsInput({children, ...restProps}){

    return <Input {...restProps}>{children}</Input>
}

Settings.PrivacyBox = function SettingsPrivacyBox({children, ...restProps}){

    return <PrivacyBox {...restProps}>{children}</PrivacyBox>
}

Settings.PrivacyInput = function SettingsPrivacyInput({children, ...restProps}){

    return <PrivacyInput {...restProps}>{children}</PrivacyInput>
}

Settings.PrivacyText = function SettingsPrivacyText({children, ...restProps}){

    return <PrivacyText {...restProps}>{children}</PrivacyText>
}


Settings.PasswordInput = function SettingsPasswordInput({children, ...restProps}){

    return <PasswordInput {...restProps}>{children}</PasswordInput>
}

Settings.SaveFrame = function SettingsSaveFrame({children, ...restProps}){

    return <SaveFrame {...restProps}>{children}</SaveFrame>
}

Settings.SaveButton = function SettingsSaveButton({children, ...restProps}){

    return <SaveButton {...restProps}>{children}</SaveButton>
}

Settings.DeleteButton = function SettingsDeleteButton({children, ...restProps}){

    return <DeleteButton {...restProps}>{children}</DeleteButton>
}

Settings.DeleteText = function SettingsDeleteText({children, ...restProps}){

    return <DeleteText {...restProps}>{children}</DeleteText>
}




Settings.SuccessFrame = function SettingsSuccessFrame({children, ...restProps}){

    return <SuccessFrame {...restProps}>{children}</SuccessFrame>
}

Settings.SuccessText = function SettingsSuccessText({children, ...restProps}){

    return <SuccessText {...restProps}>{children}</SuccessText>
}




