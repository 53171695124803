import React, {useContext, useState, useEffect, useCallback} from 'react';
import GoogleMapReact from 'google-map-react';
// context
import { UserContext } from '../context/user';
//components
import {ContactUs, Info, Map} from '../components'
// services
import {GOOGLEMAPAPI} from '../services/config';
//images
import Phone from '../images/Phone.svg';
import Mail from '../images/Mail.svg';
import CookLandEmblem from '../images/CookLandEmblem.png';
//api
import createContactUs from '../api/contactus';
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';
// hooks
import useScript from '../hooks/useScript';


export default function ContactUsContainer(){
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [ user, ] = useContext(UserContext)

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [contactTime, setContactTime] = useState();
    const [buy, setBuy] = useState(false);
    const [sell, setSell] = useState(false);
    const [message, setMessage] = useState();
    const [formSuccess, setFormSuccess] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const [ reCaptchaToken , setReCaptchaToken] = useState();

    const defaultProps = {
        center: {
            lat: 38.390570,
            lng: -94.5
          },
          zoom: 8
      };

    const handleSubmit = (e)=>{
        e.preventDefault();
        // createContactUs(setFormSuccess, setFormErrors, firstName, lastName, phoneNumber, email, buy, sell, contactTime, message)
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'contact'}).then(function(token) {
              createContactUs(setFormSuccess, setFormErrors, firstName, lastName, phoneNumber, email, buy, sell, token, contactTime, message)
            });
        });


        
    }


    useEffect(()=>{

        if(user){
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
            setPhoneNumber(user.phone_number)
        }

        //cleanup
        return ()=> setReCaptchaToken(null)
    }, [user])

    // custom hook
    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)



    return (

        <>
            <ContactUs>
                <ContactUs.ContactUsFrameInner>

                    <ContactUs.InfoFrame>
                        <ContactUs.InfoWrapper>
                            <Info.AboutUSDetail>Cook Land & Realty Co.</Info.AboutUSDetail>
                            <Info.AboutUsHorizontal/>
                            <Info.AboutUSDetail>203 NW 160 Clinton, MO 64735</Info.AboutUSDetail>
                            <Info.AboutUSDetailWrapper>
                                <Info.DetailIMG src={Phone}/>
                                <Info.AboutUSDetail>660-851-8877</Info.AboutUSDetail>
                            </Info.AboutUSDetailWrapper>
                            <Info.AboutUSDetailWrapper>
                                <Info.DetailIMG src={Mail}/>
                                <Info.AboutUSDetail>Jangold@cookrealtyco.com</Info.AboutUSDetail>
                            </Info.AboutUSDetailWrapper>
                        </ContactUs.InfoWrapper>
                    </ContactUs.InfoFrame>

                    <ContactUs.MapFrame>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLEMAPAPI}}
                            center={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            <Map.MapEmblem lat={38.390570} lng={-93.794110} src={CookLandEmblem} />
                        </GoogleMapReact>
                    </ContactUs.MapFrame>

                    <ContactUs.ContentFrame>
                        <ContactUs.InnerContentFrame>
                            <ContactUs.Title>GET IN TOUCH</ContactUs.Title>
                            <ContactUs.ParagraphText>Do you have land or real estate you'd like to sell? A question about one of our properties? We'd be happy to hear from you. Just fill out the form, and we'll get back with you as soon as we can.</ContactUs.ParagraphText>
                            <ContactUs.HorizontalBar/>
                            
                            
                            {
                                // the components come from the info component
                                formSuccess ?
                                <Info.ResponseTextFrame>
                                    <Info.ResponseText color='#6C8915'>Your Contact Request has been submitted!</Info.ResponseText>
                                </Info.ResponseTextFrame>
                                :
                                null
                            }
                            

                            {
                                formErrors ?
                                // the components come from the info component
                                <Info.ResponseTextFrame marginBottom = '10px'>
                                    {
                                        formErrors.length > 1 ?
                                            formErrors.map(formError => (
                                                
                                                    <Info.ResponseText color='#FF7276'>{formError.error}</Info.ResponseText>
                                                
                                            ))
                                        :
                                        <Info.ResponseText color='#FF7276'>{formErrors}</Info.ResponseText>
                                    }
                                </Info.ResponseTextFrame>
                                :
                                null
                            }
                            <ContactUs.Form method = "POST" onSubmit={handleSubmit}>
                                
                                <ContactUs.InputBoxFrame>
                                    <ContactUs.InnerInputBoxFrame>
                                        <ContactUs.Input 
                                            placeholder='FIRST NAME' 
                                            width='48%' 
                                            onChange={({target}) => setFirstName(target.value)}
                                            value={firstName} 
                                            disabled={user}
                                        />
                                        <ContactUs.Input 
                                            placeholder='LAST NAME' 
                                            width='48%' 
                                            onChange={({target}) => setLastName(target.value)}
                                            value={lastName}
                                            disabled={user}
                                        />
                                    </ContactUs.InnerInputBoxFrame>
                                    <ContactUs.Input 
                                        placeholder="Email" 
                                        onChange={({target}) => setEmail(target.value)}
                                        value={email}
                                        disabled={user}    
                                    />
                                    <ContactUs.Input
                                        placeholder="Phone Number"
                                        onChange={({target}) => setPhoneNumber(target.value)}
                                        value={phoneNumber}
                                        disabled={phoneNumber}
                                    />
                                    <ContactUs.Input placeholder="BEST TIME OF DAY TO CONTACT YOU?" setState={setContactTime}/>

                                    <ContactUs.InputCheckBoxFrame>
                                        <Info.CheckLink>
                                        {/* onChange={({target}) => setSell(target.value)} */}
                                            <Info.CheckBox type='checkbox'checked={sell} setState={setSell}/>
                                            <ContactUs.CheckBoxText>I want to sell a property</ContactUs.CheckBoxText>
                                        </Info.CheckLink>
                                        <Info.CheckLink>
                                        {/* onChange={({target}) => setBuy(target.value)} */}
                                            <Info.CheckBox type='checkbox' checked={buy} setState={setBuy}/>
                                            <ContactUs.CheckBoxText>I want to buy a property</ContactUs.CheckBoxText>
                                        </Info.CheckLink>
                                    </ContactUs.InputCheckBoxFrame>
                                    <ContactUs.Input  placeholder="MESSAGE" onChange={({target}) => setMessage(target.value)}/>
                                </ContactUs.InputBoxFrame>


                                <ContactUs.HorizontalBar marginTop='12px'/>
                                <ContactUs.ExplainText>Please give us a brief description of your goals and objectives.</ContactUs.ExplainText>  
                                
                                {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT}>
                                    <YourReCaptchaComponent setReCaptchaToken={setReCaptchaToken}/>
                                </GoogleReCaptchaProvider> */}
                                <ContactUs.ButtonFrame>
                                    <ContactUs.InputButton data-action='contact-us' onClick={ e => handleSubmit(e) }>CONTACT</ContactUs.InputButton>
                                </ContactUs.ButtonFrame>
                                
                            </ContactUs.Form>
                            
                        </ContactUs.InnerContentFrame>
                    </ContactUs.ContentFrame>
                </ContactUs.ContactUsFrameInner>
            </ContactUs>

        </>
    )
}


// const YourReCaptchaComponent  = (props) => {
//     const { executeRecaptcha } = useGoogleReCaptcha();


//     const clickHandler = useCallback(async () => { 
//         const result = await executeRecaptcha('contactus');
//         props.setReCaptchaToken(result);

//       }, []);




//     return (
//         <ContactUs.ButtonFrame>
//             <ContactUs.InputButton onClick={clickHandler}>CONTACT</ContactUs.InputButton>
//         </ContactUs.ButtonFrame>
//     )
//   }
  
