import React, {useContext, useEffect, useState} from 'react'
import { useHistory, useLocation } from "react-router-dom";
// containers
import NavContainer from '../containers/nav';
import FavoriteContainer from '../containers/favorite'
import FooterContainer from '../containers/footer'
// proxy
import SignInSignUpProxyContainer from '../containerproxy/signinsignup';
// components
import { Wrapper } from '../components'
//context
import { SignInSignUpContext } from '../context/signinsignup';
import { UserContext } from '../context/user';
import { ROUTES } from '../services/config'
//images
import CookLandRealty from '../images/CookLandRealty.svg'
// helpers
import handleUser from '../helpers/handleuser'


export default function Favorite() {
    const history = useHistory()
    const [ user, setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{
        if(!user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])


    // this checks to see if a user exists
    // if this is true, the user can visist the favorites page
    // else route back to home
    if (user){
        return(
            <>
                <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>
                    <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>
                    <FavoriteContainer/>
                    <FooterContainer backgroundColor='#262626'/>
                </Wrapper>
                {
                    isSignInOpen || isSignUpOpen ?
                    <SignInSignUpProxyContainer 
                
                        isSignInOpen={isSignInOpen}
                        setIsSignInOpen={setIsSignInOpen}
                        isSignUpOpen={isSignUpOpen}
                        setIsSignUpOpen={setIsSignUpOpen}
                        isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                        setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                    
                    />
                    :
                    null
                }
    
            </>
        
        )
    } else {
        history.push(ROUTES.HOME)
        return (
            null
        )
    }
    

}