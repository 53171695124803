import React from 'react';



import { Form } from '../../components_staff';
// options
import displayCorrectOptions from '../../data/staff/listingoptions';

// hooks
import useExtraImages from '../../hooks/staff/useextraimages'
// helpers
import submitForm from '../../helpers/staff/submitform';
import splitPath from '../../helpers/splitpath';
import { URLS } from '../../services/config';



export default function ListingFormContainer({children, ...props}){


    const { images, setImages, error, submitExtraImages, handleDelete, submitDelete,
        setIsConfirmDeleteOpen, isConfirmDeleteOpen
    } = useExtraImages(props.formData.id)

    // this is a special function only used in this file
    // it calls other methods such as the delete images functions
    const handleSubmission = async (e)=>{
        
        e.preventDefault();

        // if the deleted images length is greater than 1 open this
        // REMEMBER this will only apply to images that have already been posted to the server
        if (images.deletedImages.length > 0){   
            setIsConfirmDeleteOpen(true)
        } else {
            try {
                const response = await submitForm(e, props)
                // if the response is successful then check the id and pass the the submit extra images
                if (response.id && images.newImages.length > 0){
                    console.log('submitting new images running')
                    // handles submission of the images
                    submitExtraImages(response.id)
                }
            } catch (error){
                console.log(error)
            }
            
        }
        
    }

    console.log(images)
    
    return (
        <>
            <Form.HTMLForm type="submit" onSubmit={(e) => handleSubmission(e)}>
                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Class</Form.InputName>
                        <Form.FormSelect
                            name='Class'
                            placeholder="Class"
                            options = { [
                                { value: 'LD_2', label: 'Land' },
                                { value: 'CI_3', label: 'Commercial' },
                                { value: 'RE_1', label: 'Residential'},
                                { value: "MF_4", label: "Multi Family"}
                            ]}
                            defaultValue={props.formData.Class ? {value: props.formData.Class, label: classHandler(props.formData.Class)} :  ""}
                            onChange={e => props.setFormData({...props.formData, Class: e.value, PropertyType: null})}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Class ? props.formErrors.Class : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Name</Form.InputName>
                        <Form.Input
                            name="Name"
                            onChange={e => props.setFormData({...props.formData, Name: e.target.value})}
                            value={props.formData.Name ? props.formData.Name : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Name ? props.formErrors.Name : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Description</Form.InputName>
                        <Form.TextArea
                            name="Description"
                            onChange={e => props.setFormData({...props.formData, Description: e.target.value})}
                            value={props.formData.Description ? props.formData.Description : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Description ? props.formErrors.Description : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Address</Form.InputName>
                        <Form.Input
                            name="Address"
                            onChange={e => props.setFormData({...props.formData, Address: e.target.value})}
                            value={props.formData.Address ? props.formData.Address : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Address ? props.formErrors.Address : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>City</Form.InputName>
                        <Form.Input
                            name="City"
                            onChange={e => props.setFormData({...props.formData, City: e.target.value})}
                            value={props.formData.City ? props.formData.City : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.City ? props.formErrors.City : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>State</Form.InputName>
                        <Form.Input
                            name="State"
                            onChange={e => props.setFormData({...props.formData, State: e.target.value})}
                            value={props.formData.State ? props.formData.State : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.State ? props.formErrors.State : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>County</Form.InputName>
                        <Form.Input
                            name="County"
                            onChange={e => props.setFormData({...props.formData, County: e.target.value})}
                            value={props.formData.County ? props.formData.County : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.State ? props.formErrors.State : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>ZipCode</Form.InputName>
                        <Form.Input
                            name="ZipCode"
                            onChange={e => props.setFormData({...props.formData, ZipCode: e.target.value})}
                            value={props.formData.ZipCode ? props.formData.ZipCode : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.ZipCode ? props.formErrors.ZipCode : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Location</Form.InputName>
                        <Form.Input
                            name="Location"
                            onChange={e => props.setFormData({...props.formData, Location: e.target.value})}
                            value={props.formData.Location ? props.formData.Location : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Location ? props.formErrors.Location : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Asking Price</Form.InputName>
                        <Form.Input
                            name="AskingPrice"
                            onChange={e => props.setFormData({...props.formData, AskingPrice: e.target.value})}
                            value={props.formData.AskingPrice ? props.formData.AskingPrice : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.AskingPrice ? props.formErrors.AskingPrice : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>SquareFeet</Form.InputName>
                        <Form.Input
                            name="SquareFeet"
                            onChange={e => props.setFormData({...props.formData, SquareFeet: e.target.value})}
                            value={props.formData.SquareFeet ? props.formData.SquareFeet : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.SquareFeet ? props.formErrors.SquareFeet : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Acres</Form.InputName>
                        <Form.Input
                            name="Acres"
                            onChange={e => props.setFormData({...props.formData, Acres: e.target.value})}
                            value={props.formData.Acres ? props.formData.Acres : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Acres ? props.formErrors.Acres : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Property Type</Form.InputName>
                        <Form.FormSelect
                            name = 'PropertyType'
                            placeholder="PropertyType"
                            // based off of what the class is the proper options will be shown 
                            options = {displayCorrectOptions(props.formData.Class)}
                            defaultValue={props.formData.PropertyType ? {value: props.formData.PropertyType, label: props.formData.PropertyType} :  ""}
                            onChange={e => props.setFormData({...props.formData, PropertyType: e.value})}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.PropertyType ? props.formErrors.PropertyType : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Video URL</Form.InputName>
                        <Form.Input
                            name="VideoURL"
                            onChange={e => props.setFormData({...props.formData, TourURL: e.target.value})}
                            value={props.formData.TourURL ? props.formData.TourURL : ""}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Acres ? props.formErrors.Acres : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop >
                        <Form.InputName>Display Image</Form.InputName>
                        <Form.InputFrameTop >
                            <Form.InputFrameWrapper flexDirection="column" style={{marginTop: "30px"}}>
                                    {
                                    props.formData.DisplayImage?
                                    <>

                                        {/* console.log(props.formData.DisplayImage ? props.formData.DisplayImage : null) */}
                                        <Form.InputFrameWrapper>
                                            <p style={{color: "white"}}>Current DisplayImage: {displayImageHandler(props.formData)}</p>
                                            { props.formData.DisplayImage ? <img src={`${URLS.BASE_URL}${props.formData.DisplayImage}`}  style={{height: "80px", width:"80px"}} alt="newImage"/> : null }
                                            <Form.Input
                                                name="DisplayImage" 
                                                type="file" 
                                                accept="image/*" 
                                                background="#262626"
                                                border="none" 
                                                color="#FFFFFF"
                                                onChange={e => props.setFormData({...props.formData, DisplayImage: e.target.files[0]})}
                                                width="40%"
                                            />
                                        </Form.InputFrameWrapper>
                                    </>
                                :
                                    <Form.Input
                                        name="DisplayImage" 
                                        type="file" 
                                        accept="image/*" 
                                        background="#262626"
                                        border="none" 
                                        color="#FFFFFF"
                                        onChange={e => props.setFormData({...props.formData, DisplayImage: e.target.files[0]})}
                                    />

                                }
                            </Form.InputFrameWrapper>
                            </Form.InputFrameTop>
                        
                        
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.DisplayImage ? props.formErrors.DisplayImage : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Is Displayed?</Form.InputName>
                        <Form.Input
                            name="isDisplay" 
                            type="checkbox" 
                            width="10%"
                            onChange={e => props.setFormData({...props.formData, isDisplay: e.target.checked})}
                            value={props.formData.isDisplay ? props.formData.isDisplay : ""}
                            checked={props.formData.isDisplay ? props.formData.isDisplay : null}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.isFeatured ? props.formErrors.isFeatured : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Is Featured?</Form.InputName>
                        <Form.Input
                            name="isFeatured" 
                            type="checkbox" 
                            width="10%"
                            onChange={e => props.setFormData({...props.formData, isFeatured: e.target.checked})}
                            value={props.formData.isFeatured ? props.formData.isFeatured : ""}
                            checked={props.formData.isFeatured ? props.formData.isFeatured : null}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.isFeatured ? props.formErrors.isFeatured : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Is Auction Listing?</Form.InputName>
                        <Form.Input
                            name="isAuction"
                            type="checkbox" 
                            width="10%"
                            onChange={e => props.setFormData({...props.formData, isAuction: e.target.checked})}
                            value={props.formData.isAuction ? props.formData.isAuction : ""}
                            checked={props.formData.isAuction ? props.formData.isAuction : null}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.isAuction ? props.formErrors.isAuction : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop>
                        <Form.InputName>Status</Form.InputName>
                        <Form.FormSelect
                            name = 'Status'
                            placeholder="Status"
                            options = { [
                                { value: 'Available', label: 'Available' },
                                { value: 'Sale Pending', label: 'Sale Pending'},
                                { value: 'Off Market', label: 'Off Market'},
                                { value: "Sold", label: "Sold"}
                            ]}
                            defaultValue={props.formData.Status ? {value: props.formData.Status, label: props.formData.Status}:  ""}
                            onChange={e => props.setFormData({...props.formData, Status: e.value})}
                        />
                    </Form.InputFrameTop>
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.Status ? props.formErrors.Status : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>
                <Form.InputFrame>
                    <Form.InputFrameTop width="100%">
                        <Form.InputName>Extra Images:</Form.InputName>
                        <Form.InputFrameTop width="60%">
                            <Form.InputFrameWrapper flexDirection="column" style={{marginTop: "30px"}}>
                                {/* As items get added, then they will be appended to the array of shown images */}
                                {   
                                    images.currentImages ?
                                        images.currentImages.concat(images.newImages).map((img, index) =>(
                                            
                                            <div key={index} style={{ margin: "10px 0", display: "flex", alignItems: "center", justifyContent:"space-between", width: "100%"}}>
                                                        
                                                {
                                                    img ?
                                                        <>
                                                            {/* checking to see if images from the server or from the input */}
                                                            {/* <Form.OrderInput /> */}
                                                            <p style={{color: img.name ? "#6C8915" : "#FFFFFF"}}>Image: {index + 1}). {img.Images ?  splitPath(img.Images).filePart.toString().slice(0,25) : img.name ? img.name.slice(0,25) : null}</p>
                                                            
                                                            { img.Images ? <img src={`${URLS.BASE_URL}${img.Images}`}  style={{height: "80px", width:"80px"}} alt="newImage"/> : null }
                                                            <Form.ExitBox background="#262626" onClick={()=>handleDelete(index, img)}>
                                                                <Form.RightSlash background="#FF7276"/>
                                                                <Form.LeftSlash background="#FF7276"/>
                                                            </Form.ExitBox>

                                                        </>
                                                :
                                                    null
                                                }
                                                        
                                            </div>
                                                
                                            
                                        ))
                                        :
                                    null
                                    
                                }

                                {/* Always display the input */}
                                <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between", width: "100%"}}>
                                    <Form.Input
                                        name="DisplayImage" 
                                        type="file" 
                                        accept="image/*" 
                                        background="#262626"
                                        border="none" 
                                        color="#FFFFFF"
                                        webkitdirectory="true"
                                        multiple
                                        onChange={e => {

                                            // if the staff user uploads more than 1 image, then the lenght of the files will be greater than 1
                                            // then iterate of the images and upload them to the images array
                                            if (e.target.files.length > 1){

                                                // create new array
                                                const newImages = [...images.newImages]
                                                // shallow copy of images from the input
                                                const files = e.target.files
                                                // itterate and append images
                                                // for some reason foreach method doesnt work...
                                                for (let i = 0; i<files.length; i++){
                                                    newImages.push(files[i])
                                                }
                                                setImages({...images, newImages: newImages})

                                            } else{
                                                setImages({...images, newImages: [...images.newImages, e.target.files[0]]})
                                            }
                                        }}
                                        width="100%"
                                    />
                                </div>
                                
                            </Form.InputFrameWrapper>

                        </Form.InputFrameTop>
                    </Form.InputFrameTop>
                    
                    <Form.InputFrameBottom>
                        <Form.ResponseText color="#FF7276">{props.formErrors.DisplayImage ? props.formErrors.DisplayImage : null}</Form.ResponseText>
                    </Form.InputFrameBottom>
                </Form.InputFrame>

                <Form.InputFrame>
                    <Form.InputFrameTop justifyContent="center">
                        <Form.SubmitButton>Submit</Form.SubmitButton>
                    </Form.InputFrameTop>
                </Form.InputFrame>

            {/* is the confirm delete images container displaying
            it will only display if there are images already on the server that have been selected to be deleted */}
            {React.cloneElement(children[1], 
                {
                    isConfirmDeleteOpen: isConfirmDeleteOpen,
                    setIsConfirmDeleteOpen: setIsConfirmDeleteOpen,
                    images: images,
                    setImages: setImages, 
                    submitDelete: submitDelete,
                     
            })}
                
            </Form.HTMLForm>
            {/* this child is the response to the request */}
            {children[0]}
      
        </>
    )
}




// this form is for handling the class for the display on the class react-select component
const classHandler = Class => {

    switch(Class){

        case "LD_2":
            return "Land"
        case "CI_3":
            return "Commercial"
        case "RE_1":
            return "Residential"
        case "MF_4":
            return "Mult Family"
        default:
            return null
    }

}



// this handles proper displaying of the display image
const displayImageHandler = (img)=> {

    if (img.DisplayImage){
        if(img.DisplayImage.name){
            return null
        } else {
            return img.DisplayImage
        }
    }
    

}