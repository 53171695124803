import React from 'react'

import { ListFrame, ListFrameInner, ListTitle, ListItemFrame, ListItemHeader, ListItemParagraph } from './styles/list'

export default function List({children, ...restProps}){
    return <ListFrame {...restProps}>{children}</ListFrame>
}

List.ListFrameInner = function ListListFrameInner({children, ...restProps}){
    return <ListFrameInner {...restProps}>{children}</ListFrameInner>
}

List.ListTitle = function ListListTitle({children, ...restProps}){
    return <ListTitle {...restProps}>{children}</ListTitle>
}

List.ListItemFrame = function ListListItemFrame({children, ...restProps}){
    return <ListItemFrame {...restProps}>{children}</ListItemFrame>
}

List.ListItemHeader = function ListListItemHeader({children, ...restProps}){
    return <ListItemHeader {...restProps}>{children}</ListItemHeader>
}

List.ListItemParagraph = function ListListItemParagraph({children, ...restProps}){
    return <ListItemParagraph {...restProps}>{children}</ListItemParagraph>
}



