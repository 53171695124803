import styled from 'styled-components'

export const MobileDetailImageFrame = styled.div`

    width: 100%;
    display: flex
    justify-content: center;
`;


export const ImageFrame = styled.div`
    
    height: 256px;
    background: url(${props => props.url});
    width: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-position: center center;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImageFrameInner = styled.div`

    width: 90%;
    height: 256px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`;



export const ImageFrameInnerTop = styled.div`

    width: 100%;
    display: flex;
    justify-content: flex-end;
    // height: 20px;
    // margin-bottom: 84px;

    position: relative;
    bottom: 33%;
`;


export const ImageFrameInnerBottom = styled.div`

    width: 100%;
    display: flex;
    
    justify-content: space-between;
`;



export const Elipse = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 2rem;
    box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`


export const Arrow = styled.img``;

export const Heart = styled.img`
    position: relative;
    z-index: 1;
    margin-top: 15px;
    height: 24px;
    width: 27px;
`;
