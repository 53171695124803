import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';

// container
import StatusContainer from './status'
// components
import { Listing } from '../components'

//context
import { UserContext } from '../context/user';
// helper
import {setDetailListingID} from '../helpers/listing';
import imageHandler from '../helpers/imagehandler';
import handleTheFavoriteListing from '../helpers/handlethefavoritelisting'
// Images
import Favorite from '../images/Favorite.png';
import PinkHeart from '../images/PinkHear.svg';
import Location from '../images/location.svg';
// Routes
import { ROUTES } from '../services/config';
//api
import deleteFavorite from '../api/deletefavorite'

export default function CardMarkup(props){

    const [reLoader, setReLoader ] = useState(true)
    let history = useHistory()
    const [user, ] = useContext(UserContext)

    const goToListingDetail = (history) => {
        history.push(ROUTES.PROPERTY)
    }



    if(props.listings){
    

        return (
            props.listings.map((listing) => (
                    
                <Listing.Card
                    mobileWidth = {props.mobileWidth}
                    key={listing.id} 
                    onClick={ (e) => {
                        e.preventDefault()
                        setDetailListingID(listing.id, listing.Type, props.refresh)}
                    } 
                    grid={props.grid}
                >
    
                    <Listing.Picture  url={() => imageHandler(listing)} alt='pic'>
                        <Listing.AttributeFrame>

                            {/* add the color to this if you want to add the new status or other information */}

                            {   
                                listing ?

                                <StatusContainer listing={listing}/>
                                :
                                null
                            }
                      
                            {
                                user ?
    
                                    listing.is_favorite ?
    
                                        <Listing.Heart 
                                            src={PinkHeart} 
                                            onClick={(e) => {
                                                    e.preventDefault()
                                                    deleteFavorite(listing.id)
                                                    props.manipulateIsFavorite(listing.id, 'Remove')
                                                    setReLoader(!reLoader)
                                                }
                                            }
                                        />
                                    : 
                                        <Listing.Heart 
                                            src={Favorite} 
                                            onClick={(e) => {
                                                    e.preventDefault()
                                                    handleTheFavoriteListing(listing)
                                                    props.manipulateIsFavorite(listing.id, 'Add')
                                                    setReLoader(!reLoader)
                                                }
                                            }
                                        />
                                :
                                null
                            }
                            
                        </Listing.AttributeFrame>
                    </Listing.Picture>
                    <Listing.CardInfo onClick={ ()=> goToListingDetail(history)}>
                        <Listing.CardInfoWrapper width='90%'>
                            <Listing.TitleWraper>
                                <Listing.CardTitle>{listing.Name.slice(0,20)}</Listing.CardTitle>
                                <Listing.CardTitle marginLeft='5px'>... see more</Listing.CardTitle>
                            </Listing.TitleWraper>
                            <Listing.LocationBox>
                                <Listing.LocationIcon src={Location}/>
    
                                {
                                    props.page === "SearchResults"
                                    ?
                                        <Listing.LocationText 
                                        >
                                            {listing.Address} {listing.City}, {listing.State} {listing.ZipCode}
                                        </Listing.LocationText>
                                        :
                                        <Listing.LocationText>           
                                            {listing.Address} {listing.City}, {listing.State} {listing.ZipCode}
                                        </Listing.LocationText>
                                }
                                
                                    
                            </Listing.LocationBox>
                            <Listing.HorizontalBar/>
                            <Listing.TypeFrame>
                                <Listing.TypeText>Type: {listing.Class}</Listing.TypeText>
                                
                                {
                                    listing.Acres ?
                                    <>
                                        <Listing.Dot/>
                                        <Listing.TypeText>acres: {listing.Acres ? listing.Acres : "No Data"}</Listing.TypeText>
                                    </>
                                    :
                                    null
                                }     
                                
                                
                                {   
                                    listing.SquareFeet ?
                                    <>
                                        <Listing.Dot/>
                                        <Listing.TypeText>Sqr ft: {listing.SquareFeet !== "" ? listing.SquareFeet : 'No Data'}</Listing.TypeText>     
                                    </>
                                    :
                                    null
                                }
                                                                                   
                            </Listing.TypeFrame>
                            <Listing.Amount>{ listing.Status === "Sold" ? "SOLD" : listing.AskingPrice > 0 ? `$${Math.round(listing.AskingPrice).toLocaleString()}` : "Contact for Price"}</Listing.Amount>
                        </Listing.CardInfoWrapper>
                    </Listing.CardInfo>
                
                
                </Listing.Card>
            ))
        )
    } else {
        return null
    }
    
}