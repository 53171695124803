import React from 'react';

import { Block } from '../components_staff';



export default function StaffBlockContainer(props){


    return (

        <>
            <Block>
                {
                    props.blockData ?
                        props.blockData.map((data, index)=>(
                            <div key={index}>
                                <Block.BlockItem>
                                    <Block.ItemBlockFrame>
                                        <Block.Icon src={data.icon}/>
                                    </Block.ItemBlockFrame>
                                    <Block.ItemBlockBottomFrame>
                                        <Block.ValueText>{data.value ? data.value : data.value}</Block.ValueText>
                                        <Block.LabelText>{data.blockTitle}</Block.LabelText>
                                    </Block.ItemBlockBottomFrame>
                                </Block.BlockItem>
                            </div>
                        ))
                        :
                        null
                } 

            </Block>
        </>


    )
}