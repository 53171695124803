import React from 'react'

import { Form } from '../components_staff';
import Button from '../components_staff/buttons';

import splitPath from '../helpers/splitpath';

export default function DeleteExtraImagesContainer({children, ...props}){

    // on exit of the form there are certain operations that are performed
    // on exit removes form data and the url from state
    const handleExit = ()=> {

        props.setIsConfirmDeleteOpen(false)
    }

    // handles the deleteion of the item
    const handleDelete = async ()=>{

        const response = await props.submitDelete(props.images.deletedImages)
        
        if (response === 204){
            props.setImages({...props.images, deletedImages: []})
            props.setIsConfirmDeleteOpen(false)
        
        }

    }


    // these are styles for the main wrapper div
    const frameStyles = {
        position: "absolute", 
        background: "#F8F8F8",
        top: "150%", 
        left: "18%",
        right: "25%",
        width: "65%",
        height: "450px",
        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        borderRadius: "4px"
    }



    if (props.isConfirmDeleteOpen){

        return (
            <>

                <div style={frameStyles}>
                    <Form.FormFrameInner>
                        <Form.ExitFrame>
                            <Form.ExitBox onClick={handleExit}>
                                <Form.LeftSlash/>
                                <Form.RightSlash/>
                            </Form.ExitBox>
                        </Form.ExitFrame>
                        <Form.DeleteFrame >
    
                            <Form.DeleteText style={{color: "black", margin: "25px", width: "100%", textAlign: "left"}}>Are you sure you want to delete {props.images.deletedImages.length} image(s)?</Form.DeleteText>
                            
                            {
                                props.images.deletedImages ?
                                props.images.deletedImages.map((img, index)=>(
                                    <Form.DeleteText key={img.id} style={{color: "black", fontSize:"16px", margin: "5px 0", textAlign: "left", width: "100%"}}>{index + 1}). {splitPath(img.Images).filePart.toString().slice(0,25)}</Form.DeleteText>
                                ))
                                :
                                null
                            }
                            
                            
                        
                            <Button.ButtonItem background='#FF7276'  onClick={handleDelete}>
                                <Button.ButtonText>Confirm</Button.ButtonText>    
                            </Button.ButtonItem>
                            
                            
    
                        </Form.DeleteFrame>
                        
                    </Form.FormFrameInner>
               
                
                </div>
    
            </>
        ) 
    } else {
        return null
    }
    
}

