import axios from 'axios'
import { URLS } from '../services/config'
import checkIfDetailFavorite from '../helpers/checkifdetailfavorite'

export default async function getUsersFavoriteListings(){

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('accessToken')}`
        }
    }

    
    try {
        const response = await axios.get(`${URLS.BASE_URL}/${URLS.USERSFAVORITES}`, config)
        return response
    } catch (error){
        return error
    }
    
    

}

// this differs from the above function in that it is used in the detail listing page
// it will call the imported helper method and check if the listing id matches any of the users listings in the array
export const getUsersFavoriteListingsAndMatch = (id, setIsFavorite) => {

    let responseData

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('accessToken')}`
        }
    }

    

    axios.get(`${URLS.BASE_URL}/${URLS.USERSFAVORITES}`, config)
    .then(response => {

        if(response.status === 200){
            checkIfDetailFavorite(response.data, id, setIsFavorite)
        }
    })
    .catch(err => {
        responseData = err.response
    })

}
