
export const createBlockData = (userStats, AllUsers, SocialUser, Profile)=> [
    {
        blockTitle: "Total Users",
        value: userStats ? userStats.total_users : null,
        icon: AllUsers
    },
    {
        blockTitle: "Total Social Users ",
        value: userStats ? userStats.social_users : null ,
        icon: SocialUser
    },
    {
        blockTitle: "Total Regular Users",
        value: userStats ? userStats.regular_users : null,
        icon: Profile
    },
]

export const listingHeaders=[
    
    {
        name: "Email",
        width: "17%"
    },
    {
        name: "First",
        
    },
    {
        name: "Last",
    },
    {
        name: "Phone",
        width: "8%"
    },
    {
        name: "Date Joined",
        width: "8%"
    },
    {
        name: "Last Login",
        width: "8%"

    },
    {
        name: "Is Active",
        width: "8%"
    },
    {
        name: "Social User",
        width: "8%"
    },
    {
        name: "Receive Email",
        width: "8%"
    },
    {
        name: "Get Contacted",
        width: "8%"
    },
    {
        name: "Staff"
    }
        
]