import React from 'react';
import {Info} from '../components';
import Tractor1 from '../images/Tractor1.png';
import Phone from '../images/Phone.svg';
import Mail from '../images/Mail.svg';
import Combine2 from '../images/Combine2.svg';

// hooks
import useScript from '../hooks/useScript';
import useContact from '../hooks/usecontact';


export default function InfoContainer(){

    const formHandler = useContact('partialForm')
    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)



    return (
            <>

                <Info>
                    <Info.WrapperFrame>
                        <Info.TopFrame>
                            <Info.IMGFrame url={Tractor1} width='47.13919727%'/>
                            <Info.AboutUSFrame>
                                <Info.AboutUsWrapper>
                                    <Info.AboutUSTitle>ABOUT US</Info.AboutUSTitle>
                                    <Info.AboutUSParagraph>
                                        While we are new in the real estate business, our family has been in the equipment sales and 
                                        auction business for over 60 years. We are committed to offering the same quality of service to 
                                        our real estate customers in the Missouri and Kansas areas.
                                    </Info.AboutUSParagraph>
                                    <Info.AboutUsHorizontal/>
                                    <Info.AboutUSDetail>203 NW 160 Clinton, MO 64735</Info.AboutUSDetail>
                                    <Info.AboutUSDetailWrapper>
                                        <Info.DetailIMG src={Phone}/>
                                        <Info.AboutUSDetail>660-851-8877</Info.AboutUSDetail>
                                    </Info.AboutUSDetailWrapper>
                                    <Info.AboutUSDetailWrapper>
                                        <Info.DetailIMG src={Mail}/>
                                        <Info.AboutUSDetail>Jangold@cookrealtyco.com</Info.AboutUSDetail>
                                    </Info.AboutUSDetailWrapper>
                                </Info.AboutUsWrapper>
                            </Info.AboutUSFrame>
                        </Info.TopFrame>

                        <Info.BottomFrame>
                            <Info.BottomLeftFrame>
                                <Info.ContactUsForm>
                                    <Info.ContactUSWrapper>
                                        
                                        {/* need to wrap right here */}
                                        <Info.TitleFrame>
                                            <Info.AboutUSTitle marginLeft='50px'>CONTACT US</Info.AboutUSTitle>
                                            {
                                                formHandler.formSuccess ?
                                                <Info.ResponseText color='#6C8915'>Your Contact Request has been submitted!</Info.ResponseText>
                                                :
                                                null
                                            }

                                            {
                                                formHandler.formErrors ?
                                                <Info.ResponseTextFrame>
                                                    {
                                                        formHandler.formErrors.length > 0 ?
                                                        formHandler.formErrors.map(formError => (
                                                            
                                                                <Info.ResponseText color='#FF7276'>{formError.error}</Info.ResponseText>
                                                            
                                                        ))
                                                        :
                                                        <Info.ResponseText color='#FF7276'>{formHandler.formErrors}</Info.ResponseText>
                                                    }
                                                </Info.ResponseTextFrame>
                                                :
                                                null
                                            }
                                            </Info.TitleFrame>
                                        <Info.OuterInputDiv>
                                            <Info.InputDiv>
                                                <Info.Input placeholder='FIRST NAME' setState={formHandler.setFirstName}/>
                                                <Info.Input placeholder='LAST NAME' setState={formHandler.setLastName}/>
                                            </Info.InputDiv>
                                            <Info.InputDiv>
                                                <Info.Input placeholder='EMAIL' setState={formHandler.setEmail}/>
                                                <Info.Input placeholder='PHONE NUMBER' setState={formHandler.setPhoneNumber}/>
                                            </Info.InputDiv>
                                        </Info.OuterInputDiv>
                                            <Info.InputDiv>
                                                <Info.CheckMenu>
                                                    <Info.CheckLink>
                                                        <Info.CheckBox type='checkbox' setState={formHandler.setSell} checked={formHandler.sell}/>
                                                        <Info.AboutUSParagraph>I want to sell a property</Info.AboutUSParagraph>
                                                    </Info.CheckLink>
                                                    <Info.CheckLink>
                                                        <Info.CheckBox type='checkbox' setState={formHandler.setBuy} checked={formHandler.buy}/>
                                                        <Info.AboutUSParagraph>I want to buy a property</Info.AboutUSParagraph>
                                                    </Info.CheckLink>
                                                </Info.CheckMenu>
                                                <Info.SubmitButton onClick={e=> formHandler.handleSubmit(e)}>SEND</Info.SubmitButton>
                                            </Info.InputDiv>
                                        
                                    </Info.ContactUSWrapper>
                                </Info.ContactUsForm>
                            </Info.BottomLeftFrame>
                            <Info.IMGFrame url={Combine2} width='30.82835184%'/>
                        </Info.BottomFrame>

                        
                    </Info.WrapperFrame>
                </Info>





            </>
        
        
        
        )

}