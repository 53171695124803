import styled from 'styled-components';

export const MainFrame = styled.main`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #DEE2E6;
    margin-left: 12rem;
    min-height: 100vh;

    filter: ${props => props.opacity ? 'brightness(50%)': null};
    
    @media screen and (min-width: 1920px){
        margin-left: 15rem;
    }
`;


export const MainFrameInner = styled.div`

    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

`;
