import React from 'react'
import { ParagraphFrame, ParagraphFrameInner, ParagraphTitle, ParagraphText } from './styles/paragraph'

export default function Paragraph({children, ...restProps}){
    return <ParagraphFrame {...restProps}>{children}</ParagraphFrame>
}

Paragraph.ParagraphFrameInner = function ParagraphParagraphFrameInner({children, ...restProps}){
    return <ParagraphFrameInner {...restProps}>{children}</ParagraphFrameInner>
}

Paragraph.ParagraphTitle = function ParagraphParagraphTitle({children, ...restProps}){
    return <ParagraphTitle {...restProps}>{children}</ParagraphTitle>
}

Paragraph.ParagraphText = function ParagraphParagraphText({children, ...restProps}){
    return <ParagraphText {...restProps}>{children}</ParagraphText>
}

