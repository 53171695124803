import React from 'react'
import { UserDropDownFrame, DropDownFrameInner, DropDownItemFrame, DropDownIcon, 
        TextLink, DropDownText } from './styles/userdropdown'

export default function UserDropDown({children, ...restProps}){
    return <UserDropDownFrame {...restProps}>{children}</UserDropDownFrame>
}

UserDropDown.DropDownFrameInner = function UserDropDownInnerFrame({children, ...restProps}){

    return <DropDownFrameInner {...restProps}>{children}</DropDownFrameInner>
}

UserDropDown.DropDownItemFrame = function UserDropDownFrameItem({children, ...restProps}){

    return (
        <>
            <TextLink to={restProps.to}>
                <DropDownItemFrame {...restProps}>{children}</DropDownItemFrame>
            </TextLink>
        </>
    )
}

UserDropDown.DropDownIcon = function UserDropDownDropDownIcon({children, ...restProps}){

    return <DropDownIcon {...restProps}>{children}</DropDownIcon>
}

UserDropDown.DropDownText = function UserDropDownDropDownText({children, ...restProps}){

    return (
                <DropDownText {...restProps}>{children}</DropDownText>

    ) 
}