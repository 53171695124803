import React from 'react';


import { Main } from '../components_staff';

export default function StaffMainContainer({children, ...restProps}){

    return (
        <>

            <Main opacity={restProps.opacity}>
                <Main.MainFrameInner>
                    {children}
                </Main.MainFrameInner>
            </Main>
        </>
        
        
    )


}