import axios from 'axios';
import {URLS} from '../services/config';





export default function signUp(password1, password2, firstName, lastName, phoneNumber, emailAddress, setError, setPassword1, setPassword2, sestIsSignUpLoading, setSuccess, reCaptchaToken){

    sestIsSignUpLoading(true)

    setError(null)
    if (password1 === password2) {

        const body = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            email: emailAddress,
            password: password1,
            re_password: password2,
            is_social: false,
            recaptcha: reCaptchaToken
        }


        axios.post(`${URLS.BASE_URL}/${URLS.SIGNUP}`, body)
        .then((response) => {

            
            // setSuccess('Account created! Check email for account activation.')
            if (response.status === 201){

                setSuccess('Account created! Check email for account activation.')
                sestIsSignUpLoading(false)
                }   
        })
        .catch(error =>{

            sestIsSignUpLoading(false)
            if (error.response.status === 400){
                

                // email error handling
                if(error.response.data.email){
                    
                    if(error.response.data.email[0] === "my user with this email address already exists."){
                        setError({emailAddressError : 'Email already in use.'})
                    }
                    else if (error.response.data.email[0] === "Enter a valid email address."){
                        setError({emailAddressError : 'Enter a valid email address.'})
                    }else{

                    setError({emailAddressError : 'Error'})
                    }
                }

                // password error handling
                if (error.response.data.password){


                    if (error.response.data.password[0] === "The password is too similar to the first name."){
                        setError({password1Error: 'Your password is too similar to your first name.'})
                    }else{
                        setError({password1Error: 'Password Error'})
                        }
                }
                
                
                

            } else{
                console.log(error.response.data.email)
                setError({emailAddressError : 'Error'})
            }})

            
        
        } else {
            setError({password1Error: 'Passwords do not match'})
            setPassword1('')
            setPassword2('')
        }
    
}


