import styled from 'styled-components';


export const SettingsFrame = styled.div`

    width: 100%;

    display: flex;
    justify-content: center;

`;


export const SettingsBox = styled.form`

    width: 90%;
    margin: 30px 0 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


    @media screen and (min-width: 1280px){
        width: 60%;
    }
`;

export const TitleBox = styled.div`

    /* Grey / 2 */

    background: #F4F4F5;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const TitleBoxInner = styled.div`

    width: 95%;
    // margin-top: 16px;
    // margin-bottom: 16px;
`;

export const TitleText = styled.h6`
    /* h6 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* black */

    color: #262626;

`


export const ItemBox = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin: ${props => props.margin ? props.margin : null };
    
`;


export const ProfileBox = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
`;

export const ProfileImg = styled.img`

    width: 8%;

`;


export const ProfileText = styled.p`
    /* p / link */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    letter-spacing: 0.3px;
    text-decoration-line: underline;

    /* Green */

    color: #6C8915;

    margin-left: 20px;

`
export const InputBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const Input = styled.input`

    width: 49%;
    height: 80px;
    background: #FFFFFF;
    /* Grey / 3 */
    /* p / fields */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 164% */

    letter-spacing: 1.8px;
    text-transform: uppercase;
    padding-left: 1.2rem;
    /* black */

    color: #262626;

    border: 2px solid #DFDFDF;
    box-sizing: border-box;
    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
        padding-left: 1.2rem;

        
    }

`

export const PrivacyBox = styled.div`

    width: 100%;
    display: flex;
    align-items: center;

`;

export const PrivacyInput = styled.input``;

export const PrivacyText = styled.p`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height, or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #000000;
    margin-left: 12px;

`;

export const PasswordInput = styled.input`

    width: 100%;
    height: 80px;
    background: #FFFFFF;
    /* Grey / 3 */
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 164% */

    letter-spacing: 1.8px;
    text-transform: uppercase;
    padding-left: 1.2rem;
    /* black */

    color: #262626;

    border: 2px solid #DFDFDF;
    box-sizing: border-box;
    margin: ${props => props.margin ? props.margin : null};

    border: 2px solid #DFDFDF;
    box-sizing: border-box;
    ::placeholder,
    ::-webkit-input-placeholder {
        //styleName: p / fields;
        font-family: Museo Sans Cyrl;
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
        padding-left: 1.2rem;

        
    }

`;

export const SaveFrame = styled.div`
    width: 95%;
    display: flex;
    margin-bottom: 50px;
    justify-content: ${props => props.justifyContent ? 'space-between' : 'flex-end'}
`


export const SaveButton = styled.button`
    width: 35%;
    background: ${props => props.background ? props.background : '#6C8915'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : null};
    width: ${props => props.width ? props.width : null};
    /* p / button */
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    height: 60px;
    color: #FFFFFF;
    
    border: none;
    cursor: pointer;
`;

export const DeleteButton = styled.div`
    width: 35%;
    background: ${props => props.background ? props.background : '#6C8915'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : null};
    width: ${props => props.width ? props.width : null};
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
`;

export const DeleteText = styled.p`

    /* p / button */
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    // line-height: 22px;
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #FFFFFF;


`


export const SuccessFrame = styled.div`

    width: 60%;
    background: ${ props => props.backgroundColor ? props.backgroundColor : '#F2F5E7' };
    display: flex;
    align-items: center;

`;

export const SuccessText = styled.p`
    padding-left: 1.2rem;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */


    /* Green */

    color: ${ props => props.color ? props.color : '#6C8915'};

`;