import axios from 'axios';
import {URLS} from '../services/config'
import checkUser from './checkuser'

export const googleAuthenticate = (state, code, setUser, setIsSignUpOpen, setError) =>  {
    // if there is state and code and no access token

    if (state && code && !localStorage.getItem('accessToken')){

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const details = {
            'state': state,
            'code': code
        };

        const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');


        axios.post(`${URLS.BASE_URL}/${URLS.SOCIALAUTH}google-oauth2/?${formBody}`, config)
        .then(response =>{


            localStorage.setItem('accessToken', response.data.access)
            localStorage.setItem('refresh', response.data.refresh)

            checkUser(setUser)
        })
        .catch(error => {

            // this may need to be changed later...
            // not all 500 server errors will be because there is already an associated account
            if (error.response.status === 500){

                document.body.style.overflow = 'hidden';
                setIsSignUpOpen(true)
                setError({googleError: 'Cannot login with Google. Email in use'})
            }
        })
        

       
    }
}