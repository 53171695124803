import styled from 'styled-components';

export const ListingBackground = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;

`;

export const ListingFrame = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.width ? props.width : null};
    // border: 2px solid green;

    @media only screen and (min-width: 1280px)  {
        justify-content: space-evenly;
        flex-direction: row;
        min-height: 400px;
    }
`;

// // Not really sure what this is so passing on it
export const Blur = styled.div`

    // position: absolute;
    width: 267px;
    height: 376px;
    // left: 267px;
    // top: 1192px;

    background: linear-gradient(270.6deg, #FFFFFF 0.56%, rgba(255, 255, 255, 0) 99.52%);
    transform: rotate(-180deg);


`;

// styled(ReactRouterLink)
export const CardLink = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.mobileWidth ? props.mobileWidth : "90%"};
    cursor: pointer;
    text-decoration: none;
    margin-top: 1rem;

    &:last-child {
        margin-bottom: 50px;
    }

    @media only screen and (min-width: 1280px)  {
        width: ${props => props.grid ? '100%': '22%'};

        &:last-child {
            margin-bottom: 0px;
        }
    }

    @media only screen and (min-width: 1920px)  {
        width: ${props => props.grid ? '100%': '20%'};
    }
`

export const Card = styled.div`

    display: flex;
    flex-direction: ${props => props.direction ? props.direction : 'column'};
    width: 100%;

    

    @media only screen and (min-width: 1280px)  {
        width: 100%;
    }
`;


export const Picture = styled.div`
    
    background: linear-gradient(0deg, rgba(39, 39, 39, 0.1), rgba(39, 39, 39, 0.1)), url(${props => props.url});
    height: ${props => props.height ? props.height : '200px'};
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AttributeFrame = styled.div`

    display: flex;
    justify-content: space-between;
    width: 94%;


`


export const StatusFrame = styled.div`
    margin-top: 15px;
    width: ${props => props.width ? props.width : '20%'};
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.background ? props.background : null};

    @media screen and (min-width: 1280px){
        width: ${props => props.medWidth ? props.medWidth : '30%'};
    }

    @media screen and (min-width: 1920px){
        width: ${props => props.width ? props.width : '20%'};
    }

`;

export const Status = styled.p`
    
    
    /* p / small */
    font-family: Museo Sans Cyrl;
    font-size: 1rem;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;

`;

export const Heart = styled.img`
    position: relative;
    z-index: 1;
    margin-top: 15px;
    height: 24px;
    width: 27px;
`;


export const Frequent = styled.p``;


export const CardInfo = styled.div`

    height: ${props => props.height ? props.height : '240px'};
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;

    @media only screen and (min-width: 1280px)  {

        
        height: ${props => props.height ? props.height : '225px'};

    }
`;

export const CardInfoWrapper = styled.div`

    width: ${props => props.width ? props.width : '80%'};
    display: flex;
    flex-direction: column;
`;

export const TitleWraper = styled.div`
    width: 100%;
    display: flex;

`;

export const CardTitle = styled.p`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 23px;
    /* or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* black */

    color: #262626;
    // min-height: 3rem;

    

    @media only screen and (min-width: 1280px)  {

        font-size: 1rem;
        margin-left: ${ props => props.marginLeft ? props.marginLeft : null};

    }
`;

export const LocationBox = styled.div`

    display: flex;
    justyify-content: space-between;
    align-items: center;
    cursor: pointer;
    min-height: 45px;
`;

export const LocationIcon = styled.img`
    width: 20px;
`;

export const LocationText = styled.p`
    width: 75%
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 6px;
    /* Grey / 1 */
    
    color: #9A9A9A;

    &:hover{
        border-bottom: 1px solid #6C8915;
    }
`;


export const HorizontalBar = styled.div`

    border: 1px solid #F4F4F5;
    width: 100%;
    margin: 5px 0 5px 0;
`;

export const TypeFrame = styled.div`



    display: flex;
    justify-content: space-evenly;
    align-items: center;
    justify-content: flex-start;
`;


export const TypeText = styled.p`

    /* p / small */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;

    /* Grey / 1 */
    
    color: #9A9A9A;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 12px 0px;


    @media only screen and (min-width: 1280px)  {

        
        font-size: 12px;

    }

`;

export const Dot = styled.div`
    /* Ellipse 31 */
    position: static;
    width: 2px;
    height: 2px;
    left: 0px;
    top: 0px;

    background: #C4C4C4;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 5px;
`;


export const Amount = styled.h6`

    /* h6 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 26px;
    /* identical to box height, or 130% */

    text-transform: uppercase;

    color: #6C8915;


    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;

`;


// Start of styling for the MLS Components

export const MLSFrame = styled.div`
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;

    // border: 2px solid orange;

    @media only screen and (min-width: 1280px)  {
        margin-bottom: 100px;
    }

`;


export const MLSMainFrame = styled.div`

    // display: flex;
    // justify-content: center;
    width: 100%;
    background: #FFFFFF;

    @media only screen and (min-width: 1280px)  {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
    
`;

export const MLSListingFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;


    @media only screen and (min-width: 1280px)  {
        width: 55%;
        margin-left: 48px;

    }
`;

export const ListingGrid = styled.div`
    // display: grid;
    
    
    
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1280px)  {
        display: ${props => props.display ? props.display : 'grid'};
        flex-direction: row;
        justify-content: ${props => props.justifyContent ? props.justifyContent : null};
        align-items: ${props => props.alignItems ? props.alignItems : null};
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        width: 100%;
        margin: 16px 0 40px 0;

    }

`;

export const SortFrame = styled.div`

    display: none;

    @media only screen and (min-width: 1280px)  {
        display: flex;
        justify-content: space-between;

    }
`;

export const SortFrameTextBox = styled.div`

    display: flex;
    width: 10%;
    justify-content: space-between;
    align-items: center;
`;

export const CountText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 23px;
    /* identical to box height, or 128% */

    text-transform: uppercase;

    /* Green */

    color: #6C8915;

`;

export const ResultsText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* black */

    color: #262626;

`;

export const RightText = styled.p`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height, or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;

`;

export const DownArrow = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
`;

export const Map = styled.div`
    width: 40%;
`;


export const MapImage = styled.img`

    width: 100%;

`;

export const ListingPageNum = styled.div`

    display: flex;
    justify-content: space-around;
    align-items: center;
    width: ${props => props.width ? props.width : '100%'};
    margin-top: 30px;
    
    @media only screen and (min-width: 1280px)  {
        margin: 0;
    }
`;


export const ListingPageNumInner = styled.div`

    display: flex;
    justify-content: space-evenly;
    width: 50%;


    @media only screen and (min-width: 1280px)  {
        width: ${props => props.width ? props.width : '20%'};
    }
`;

export const ListingPageNumText = styled.p`

    margin: 0;
    padding: 0;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* or 128% */
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1.8px;
    height: ${props => props.height ? props.height : '36px'};
    width: ${props => props.height ? props.height : '36px'};
    border-radius: 2rem;
    background: ${props => props.background ? props.background : null};
    color: #000000;
    cursor: pointer;

`

export const NoResultsFrame = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;

`;


export const NoResultsText = styled.h5`

    /* p / content */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height, or 128% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;


`;