import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
import {Form} from '../components'
import {ROUTES} from '../services/config'

//img
import CookLandRealty from '../images/CookLandRealty.svg'
//api
import passwordReset from '../api/passwordreset'


export default function PasswordResetContainer(props){
    let history = useHistory()
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const isInvalid = password1 === '' | password2 === '';
    const passwordMatch = password1 !== password2

    const handleSubmit = (e)=>{
        e.preventDefault();
        passwordReset(props.pathName.uid, props.pathName.token, password1, password2, history)
    }

    
    return (
        <>
            <Form height='623px'>

                
                <Form.CookLogoIMG src={CookLandRealty}/>
                

                <Form.Wrapper height='800px' onSubmit={e => handleSubmit(e)}>
                    <Form.Title>Reset Password </Form.Title>
                    <Form.Input 
                        placeholder='New Password'
                        value={password1}
                        onChange={({target}) => setPassword1(target.value)}
                        type="password"
                    />
                    <Form.Input 
                        placeholder='Confirm New Password' 
                        type="password"
                        value={password2}
                        onChange={({target}) => setPassword2(target.value)}
                    />
                    <Form.Button disabled = {isInvalid | passwordMatch}>RESET</Form.Button>
                    <Form.ExtraFrame>
                        <Form.ExtraText color='#262626'>Remember Your Password?</Form.ExtraText>
                        <Form.ExtraLinkText color='#6C8915' textDecoration='underline' to={ROUTES.HOME}>Log In</Form.ExtraLinkText>
                    </Form.ExtraFrame>
                </Form.Wrapper>
            </Form>
        </>
    )
}