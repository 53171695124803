import React from 'react';

import LogInContainer from '../containers/login'
import SignUpContainer from '../containers/signup'
import PrivacyPolicyContainer from '../containers/privacypolicy';

import useScript from '../hooks/useScript';


export default function SignInSignUpProxyContainer(props){

    // custom hook
    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)

    if (props.isSignInOpen){
        return(
            <LogInContainer setIsSignInOpen={props.setIsSignInOpen}/>
        )
    } else if (props.isSignUpOpen){
        return (
            <SignUpContainer setIsSignUpOpen={props.setIsSignUpOpen} setIsPrivacyPolicyOpen={props.setIsPrivacyPolicyOpen}>
                <PrivacyPolicyContainer isPrivacyPolicyOpen={props.isPrivacyPolicyOpen} setIsPrivacyPolicyOpen={props.setIsPrivacyPolicyOpen} setIsSignUpOpen={props.setIsSignUpOpen}/>
            </SignUpContainer>
        )
    } else {
        return null
    }


}