import React from 'react';
import { MapFrame, MapElement, MapItem, MapEmblem, ItemAmount, MapPopout, 
        Image, DetailFrame, DetailInnerFrame, InfoBox, ImageLinkWrapper,
        CardTitle, LocationBox, LocationIcon, LocationText, TypeText, Dot, Amount                                       
        } from './styles/map';


export default function Map({children, ...restProps}){

    return <MapFrame {...restProps}>{children}</MapFrame>

}

Map.MapElement = function MapMapElement({children, ...restProps}){

    return <MapElement {...restProps}>{children}</MapElement>
}


Map.MapItem = function MapMapItem({children, ...restProps}){

    return <MapItem {...restProps}>{children}</MapItem>
}

Map.MapEmblem = function MapMapEmblem({children, ...restProps}){

    return <MapEmblem {...restProps}>{children}</MapEmblem>
}

Map.ItemAmount = function MapItemAmount({children, ...restProps}){

    return <ItemAmount {...restProps}>{children}</ItemAmount>
}

Map.MapPopout = function MapMapPopout({children, ...restProps}){

    return <MapPopout {...restProps}>{children}</MapPopout>
}

Map.ImageLinkWrapper = function MapImageLinkWrapper({children, ...restProps}){

    return <ImageLinkWrapper {...restProps}>{children}</ImageLinkWrapper>
}

Map.Image = function MapImage({children, ...restProps}){

    return <Image {...restProps}>{children}</Image>
}

Map.DetailFrame = function MapDetailFrame({children, ...restProps}){

    return <DetailFrame {...restProps}>{children}</DetailFrame>
}

Map.DetailInnerFrame = function MapDetailInnerFrame({children, ...restProps}){

    return <DetailInnerFrame {...restProps}>{children}</DetailInnerFrame>
}

Map.InfoBox = function MapInfoBox({children, ...restProps}){

    return <InfoBox {...restProps}>{children}</InfoBox>
}



////
Map.CardTitle = function MapCardTitle({children, ...restProps}){

    return <CardTitle {...restProps}>{children}</CardTitle>
}

Map.LocationBox = function MapLocationBox({children, ...restProps}){

    return <LocationBox {...restProps}>{children}</LocationBox>
}

Map.LocationText = function MapLocationText({children, ...restProps}){

    return <LocationText {...restProps}>{children}</LocationText>
}

Map.TypeText = function MapTypeText({children, ...restProps}){

    return <TypeText {...restProps}>{children}</TypeText>
}

Map.Dot = function MapDot({children, ...restProps}){

    return <Dot {...restProps}>{children}</Dot>
}

Map.Amount = function MapAmount({children, ...restProps}){

    return <Amount {...restProps}>{children}</Amount>
}

Map.LocationIcon = function MapLocationIcon({children, ...restProps}){

    return <LocationIcon {...restProps}>{children}</LocationIcon>
}




