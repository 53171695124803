import React from 'react';


import { Table } from '../components_staff';

export default function TableContainer(props){

    
    return (

        <>

            <Table marginBottom={props.marginBottom} marginTop={props.marginTop}>
                <Table.HeaderFrame>
                    <td>
                        <Table.CheckBox type="checkbox"/>
                    </td>
                    {
                        props.headers?

                            props.headers.map((header, index) =>(
                                
                                <Table.HeaderText key={index} width={header.width} textAlign={header.textAlign}>{header.name}</Table.HeaderText>
                                
                            ))

                        :
                        null
                    }
                </Table.HeaderFrame>
                <Table.DataTable>
                    {
                        props.tableData ?

                        props.tableData.map((data, index) => (

                            <tr key={index}>
                                {React.cloneElement(props.children, data={data})}
                            </tr>
                        ))
                        :
                        null
                    }
                    
                </Table.DataTable>
            </Table>
        </>

    )

}