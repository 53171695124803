import React, {  useContext} from 'react';
import { UserDropDown } from '../components';

// context
import { UserContext } from '../context/user';
//api
import logout from '../api/logout'
// config
import { ROUTES } from '../services/config'
//imgs
import BlackFavorite from '../images/blackfavorite.svg';
import BlackSettings from '../images/blacksettings.svg';
import Logout from '../images/logout.svg';
import Staff from '../images/staff.svg';

export default function UserDropDownContainer(){
    
    const [ user, setUser ] = useContext(UserContext)

    return (
        <>

            <UserDropDown>
                <UserDropDown.DropDownFrameInner>
                    <UserDropDown.DropDownItemFrame to={ROUTES.FAVORITE}>
                        <UserDropDown.DropDownIcon src={BlackFavorite}/>
                        <UserDropDown.DropDownText >Favorite Listings</UserDropDown.DropDownText>
                    </UserDropDown.DropDownItemFrame>
                    <UserDropDown.DropDownItemFrame to={ROUTES.SETTINGS}>
                        <UserDropDown.DropDownIcon src={BlackSettings}/>
                        <UserDropDown.DropDownText >Account Settings</UserDropDown.DropDownText>
                    </UserDropDown.DropDownItemFrame>
                    <UserDropDown.DropDownItemFrame onClick={ ()=> logout(user, setUser)}>
                        <UserDropDown.DropDownIcon src={Logout}/>
                        <UserDropDown.DropDownText >log out</UserDropDown.DropDownText>
                    </UserDropDown.DropDownItemFrame>
                    {
                        user.is_staff ?
                        <UserDropDown.DropDownItemFrame to={ROUTES.STAFFPAGES.STAFFDASHBOARD} style={{background: "#6C8915", marginBottom: "10px"}}>
                            <UserDropDown.DropDownIcon src={Staff}/>
                            <UserDropDown.DropDownText>STAFF</UserDropDown.DropDownText>
                        </UserDropDown.DropDownItemFrame>
                        :
                        null
                    }
                    

                </UserDropDown.DropDownFrameInner>
            </UserDropDown>
        
        </>
        
    )
}