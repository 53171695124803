import { useState, useContext, useEffect } from 'react';

import logInV2 from '../api/loginv2';
import { UserContext } from '../context/user'

import checkUser from '../api/checkuser'

export default function useLogin(){

    const [ emailAddress, setEmailAddress ] = useState();
    const [ password, setPassword ] = useState();
    const [, setUser] = useContext(UserContext)

    const handleSubmit = (e)=> {
        e.preventDefault();
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT, {action: 'submit'}).then(function(token) {
                logInV2(emailAddress, password, token)
                .then((response) =>{
                    const accessToken = response.data.access
                    localStorage.setItem('accessToken', accessToken)
                    localStorage.setItem('refresh', response.data.refresh)
                    const expirationDate = new Date(new Date().getTime() + 3600 * 1000)
                    localStorage.setItem('expirationDate', expirationDate)
                    checkUser(setUser)

                })
                .catch((error) => {
            
                    if (error.response.status === 401){
                        if (error.response.data.detail === "No active account found with the given credentials"){
                            return "Failed to load account with those credentials"
                        }
                    }
                })
            });
        });
         
    }



    return { emailAddress, setEmailAddress, password, setPassword, handleSubmit}
    
}