import React, {useState, useContext } from 'react';
import {HomeSearch} from '../components'
import { useHistory } from 'react-router-dom'


//helpers
import {onSearch} from '../helpers/onsearch.js'
import handleClassDisplay from '../helpers/search/handleClassDisplay'
// context
import { SearchParamsContext } from '../context/searchparams';

//data
import Cities from '../data/cities.json'


export default function HomeSearchContainer(props){

    

    // context
    const [searchParams ,setSearchParams ] = useContext(SearchParamsContext)
    let history = useHistory()

    // State
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState(""); 
    const [typeError, setTypeError] = useState(null);


    return (
        <>

                <HomeSearch>
                    <HomeSearch.HomeSearchFrameInner>
                        <HomeSearch.HomeSearchBar>
                            <HomeSearch.InputFrame>

                                <HomeSearch.MenuFrame>
                                    <HomeSearch.MenuSelect
                                        name = "Location"
                                        display='none'
                                        options = {Cities}
                                        defaultVal={searchParams ? searchParams.location : null}
                                        searchParams={searchParams}
                                        setSearchParams={setSearchParams}
                                    />
                                    {/* <Search.SearchBox setLocation={setLocation}/> */}
                                </HomeSearch.MenuFrame>

                                {/* <Search.MagnifyGlass src={magnifyGlass}/> */}
                                {/* <Search.VerticalBar/> */}

                                <HomeSearch.MenuFrame>
                                    <HomeSearch.MenuSelect
                                        name = 'Property Class'
                                        options = { [
                                            { value: 'LD_2', label: 'Land' },
                                            { value: 'CI_3', label: 'Commercial' },
                                            { value: 'RE_1', label: 'Residential'},
                                            { value: "MF_4", label: "Multi Family"}
                                        ]}
                                        defaultVal={searchParams ? handleClassDisplay(searchParams) : null}
                                        searchParams={searchParams}
                                        setSearchParams={setSearchParams}
                                    />
                                </HomeSearch.MenuFrame>

                                {/* <Search.VerticalBar/> */}
                                
                                {/* <HomeSearch.MenuFrame>
                                    <HomeSearch.MenuSelect
                                        name = 'MinPrice'
                                        options = { [
                                            { value: 100000, label: '$100,000' },
                                            { value: 200000, label: '$200,000' },
                                            { value: 300000, label: '$300,000' },
                                            { value: 400000, label: '$400,000'},
                                            { value: 500000, label: '$500,000' },
                                            { value: 600000, label: '$600,000' },
                                            { value: 700000, label: '$700,000' },
                                            { value: 800000, label: '$800,000' },
                                            { value: 900000, label: '$900,000' },
                                            { value: 1000000, label: '$1,000,000' },
                                            { value: 2000000, label: '$2,000,000' },
                                            { value: 4000000, label: '$4,000,000'},
                                            { value: 5000000, label: '$5,000,000'},
                                        ]}
                                        setState={setMinPrice}
                                    />
                                </HomeSearch.MenuFrame> */}
                                {/* <Search.VerticalBar/> */}
                                {/* <HomeSearch.MenuFrame>
                                    <HomeSearch.MenuSelect
                                        name = 'MaxPrice'
                                        options = { [
                                            { value: 100000, label: '$100,000' },
                                            { value: 200000, label: '$200,000' },
                                            { value: 300000, label: '$300,000' },
                                            { value: 400000, label: '$400,000'},
                                            { value: 500000, label: '$500,000' },
                                            { value: 600000, label: '$600,000' },
                                            { value: 700000, label: '$700,000' },
                                            { value: 800000, label: '$800,000' },
                                            { value: 900000, label: '$900,000' },
                                            { value: 1000000, label: '$1,000,000' },
                                            { value: 2000000, label: '$2,000,000' },
                                            { value: 4000000, label: '$4,000,000'},
                                            { value: 5000000, label: '$5,000,000'},
                                        ]}
                                        setState={setMaxPrice}
                                        width="20%"
                                    />
                                </HomeSearch.MenuFrame> */}
                                <HomeSearch.HomeSearchButton onClick={ () => onSearch(history, minPrice, maxPrice, setTypeError, setSearchParams, searchParams)}>SEARCH</HomeSearch.HomeSearchButton>
                            </HomeSearch.InputFrame>

                        </HomeSearch.HomeSearchBar>
                    </HomeSearch.HomeSearchFrameInner>
                </HomeSearch>


        </>
    )



}