import React, {useState, useContext, useEffect} from 'react';
import UserDropDownContainer from '../containers/userdropdown'
import {Nav} from '../components'
import {ROUTES} from '../services/config'
//context
import { UserContext } from '../context/user';
import { SignInSignUpContext } from '../context/signinsignup';
//images
import Profile from '../images/Profile.svg';
import Settings from '../images/Settings.png';
import Favorite from '../images/Favorite.png';
import Logout from '../images/logout.svg';
// api
import logout from '../api/logout'

export default function NavContainer(props) {

    const [ user, setUser ] = useContext(UserContext)

    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [, setIsSignInOpen] = signIn
    const [, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage

    const [isShown, setIsShown] = useState(false);
    const [dropDown, setDropDown] = useState(null);
    const [isUserDropDown, setIsUserDropDown] = useState(false)

    const handleSignInClick = () => {
        document.body.style.overflow = 'hidden';
        setIsSignInOpen(true);       
    }

    const handleClickSignUp = () => {
        document.body.style.overflow = 'hidden';
        setIsSignUpOpen(true);
    }

    const handleDropDown = (dropDown) => {
        
        if (!dropDown){
            setDropDown('flex')
        } else {
            setDropDown(null)
        }
        
    }

    const handleUserDropDown = () =>{

        if(!isUserDropDown){
            setIsUserDropDown(true)

        } else{
            setIsUserDropDown(false)

        }
    }

    useEffect(()=>{

    }, [user])


    return (
        
        <>
            <Nav height='110px' justifyContent='space-between' backgroundColor={props.backgroundColor} >
                <Nav.Frame1>
                    <Nav.Frame1Inner>
                        <Nav.LogoFrame marginLeft='14px'>
                            <Nav.Logo src={props.src}/>
                        </Nav.LogoFrame>
                        
                        <Nav.LinkFrame borderBottom='true'>
                            <Nav.LinkText to={ROUTES.HOME} border={props.border} color={props.color}>HOME</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.OURLISTINGS} border={props.border} color={props.color}>OUR LISTINGS</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.SEARCHRESULT} border={props.border} color={props.color}>MLS Search</Nav.LinkText>
                            <Nav.LinkText
                                onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)}
                                to={ROUTES.BUYER}
                                border={props.border}
                                color={props.color}
                            >BUYER</Nav.LinkText>
                            {/* {isShown ? <Nav.DropDown onMouseEnter={() => setIsShown(true)}
                                        onMouseLeave={() => setIsShown(false)}>
                                <Nav.DropDownText>FINANCIAL PLANNER</Nav.DropDownText>
                                <Nav.DropDownText>GRAIN COMMODITY</Nav.DropDownText>
                                <Nav.DropDownText>UPCOMING TAX PLANNER</Nav.DropDownText>
                                <Nav.DropDownText>RENT RATES FOR LAND</Nav.DropDownText>
                            </Nav.DropDown> : null} */}
                            <Nav.LinkText to={ROUTES.SELLER} border={props.border} color={props.color}>SELLER</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.ABOUTUS} border={props.border} color={props.color}>ABOUT US</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.CONTACTUS} border={props.border} color={props.color}>CONTACT US</Nav.LinkText>
                        </Nav.LinkFrame>
                    </Nav.Frame1Inner>
                </Nav.Frame1>
                <Nav.Frame2>
                    {/* if the user exists in the user context than it displays the user first name 
                    else it display login and sign up */}
                    { user ?
                    <> 
                        <Nav.UserFrame justifyContent='space-evenly'>
                            <Nav.User color={props.profileColor}>{user.first_name}</Nav.User>
                            <Nav.VerticalDivider/>
                            <Nav.UserIMG src={Profile} onClick={handleUserDropDown}/>
                        </Nav.UserFrame>
                        {
                            isUserDropDown ?
                                <UserDropDownContainer />
                            :
                            null
                        }
                        
                    </>
                    :
                    <>
                        <Nav.UserFrame justifyContent='space-between'>
                            <Nav.ButtonText onClick={handleClickSignUp} border={props.border} color={props.color}>SIGN UP</Nav.ButtonText>
                            <Nav.VerticalDivider/>
                            <Nav.Button onClick={handleSignInClick}>
                                <Nav.ButtonText hoverColor={'white'} textAlign={'center'} border={'2px solid #6C8915'}>LOG IN</Nav.ButtonText>
                            </Nav.Button>
                        </Nav.UserFrame>
                    </>
                    }
                    
                </Nav.Frame2>
                { !dropDown ?

                    <Nav.HamburgerFrame onClick={()=> handleDropDown(dropDown)}>
                        <Nav.HamburgerLine color={props.color}/>
                        <Nav.HamburgerLine color={props.color}/>
                        <Nav.HamburgerLine color={props.color}/>
                    </Nav.HamburgerFrame>
                    :

                    <Nav.XFrame onClick={()=> handleDropDown(dropDown)}>
                        <Nav.XLineLeft color={props.color}/>
                        <Nav.XLineRight color={props.color}/>
                    </Nav.XFrame>

                }
                
            </Nav>
            
            {
                dropDown ?
                <Nav.NavBarMobile display={dropDown}>
                    <Nav.MobileInnerFrame>
                        <Nav.MobileLinkFrame borderBottom='true' display='flex'>
                            <Nav.LinkText to={ROUTES.HOME} >HOME</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.OURLISTINGS}>OUR LISTINGS</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.SEARCHRESULT}>MLS Search</Nav.LinkText>
                            <Nav.LinkText
                                onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)}
                                to={ROUTES.BUYER}
                            >BUYER</Nav.LinkText>
                            {isShown ? <Nav.DropDown onMouseEnter={() => setIsShown(true)}
                                        onMouseLeave={() => setIsShown(false)}>
                                <Nav.DropDownText>FINANCIAL PLANNER</Nav.DropDownText>
                                <Nav.DropDownText>GRAIN COMMODITY</Nav.DropDownText>
                                <Nav.DropDownText>UPCOMING TAX PLANNER</Nav.DropDownText>
                                <Nav.DropDownText>RENT RATES FOR LAND</Nav.DropDownText>
                            </Nav.DropDown> : null}
                            <Nav.LinkText to={ROUTES.SELLER}>SELLER</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.ABOUTUS}>ABOUT US</Nav.LinkText>
                            <Nav.LinkText to={ROUTES.CONTACTUS}>CONTACT US</Nav.LinkText>
                        </Nav.MobileLinkFrame>
                        {
                            !user ? 
                            <Nav.MobileButtonWrapper>
                                <Nav.MobileButtonFrame>
                                    <Nav.MobileButtonText onClick={handleClickSignUp} color={props.color}>SIGN UP</Nav.MobileButtonText>
                                    <Nav.VerticalDivider/>
                                    <Nav.MobileButton onClick={handleSignInClick}>
                                        <Nav.MobileButtonText hoverColor={'white'} textAlign={'center'} border={'2px solid #6C8915'}>LOG IN</Nav.MobileButtonText>
                                    </Nav.MobileButton>
                                </Nav.MobileButtonFrame>
                            </Nav.MobileButtonWrapper>

                            :

                            <Nav.MobileUserWrapper>
                                <Nav.MobileUserNameFrame>
                                    <Nav.UserIMG src={Profile} to={ROUTES.HOME}/>
                                    <Nav.MobileUserNameText>{user.first_name}</Nav.MobileUserNameText>
                                </Nav.MobileUserNameFrame>
                                <Nav.MobileExtraFrame>
                                    <Nav.MobileExtraItemContainer to={ROUTES.FAVORITE}>
                                        <Nav.MobileExtraItemImage src={Favorite}/>
                                        <Nav.MobileExtraItemText>Favorite Listings</Nav.MobileExtraItemText>
                                    </Nav.MobileExtraItemContainer>
                                    <Nav.MobileExtraItemContainer to={ROUTES.SETTINGS}>
                                        <Nav.MobileExtraItemImage src={Settings}/>
                                        <Nav.MobileExtraItemText>Account Settings</Nav.MobileExtraItemText>
                                    </Nav.MobileExtraItemContainer>
                                    <Nav.MobileExtraItemContainer>
                                        <Nav.MobileExtraItemImage src={Logout}/>
                                        <Nav.MobileExtraItemText onClick={ ()=> logout(user, setUser)}>log out</Nav.MobileExtraItemText>
                                    </Nav.MobileExtraItemContainer>
                                </Nav.MobileExtraFrame>
                                
                            </Nav.MobileUserWrapper>

                        }
                        

                    </Nav.MobileInnerFrame>
                </Nav.NavBarMobile>
                : 
                null
            }
            
        </>

    )

}