import React from 'react'

import { MobileDetailImageFrame, ImageFrame, ImageFrameInner, ImageFrameInnerTop,
        ImageFrameInnerBottom, Elipse, Arrow, Heart

    } from './styles/mobiledetailimage'



export default function MobileDetailImage({children, ...restProps}){

    return <MobileDetailImageFrame {...restProps}>{children}</MobileDetailImageFrame>
}

MobileDetailImage.ImageFrame = function MobileDetailImageFrame({children, ...restProps}){
    return <ImageFrame {...restProps}>{children}</ImageFrame>
}

MobileDetailImage.ImageFrameInner = function MobileDetailImageFrameInner({children, ...restProps}){
    return <ImageFrameInner {...restProps}>{children}</ImageFrameInner>
}
MobileDetailImage.ImageFrameInnerTop = function MobileDetailImageFrameInnerTop({children, ...restProps}){
    return <ImageFrameInnerTop {...restProps}>{children}</ImageFrameInnerTop>
}
MobileDetailImage.ImageFrameInnerBottom = function MobileDetailImageFrameInnerBottom({children, ...restProps}){
    return <ImageFrameInnerBottom {...restProps}>{children}</ImageFrameInnerBottom>
}
MobileDetailImage.Elipse = function MobileDetailElipse({children, ...restProps}){
    return <Elipse {...restProps}>{children}</Elipse>
}
MobileDetailImage.Arrow = function MobileDetailArrow({children, ...restProps}){
    return <Arrow {...restProps}>{children}</Arrow>
}

MobileDetailImage.Heart = function MobileDetailHeart({children, ...restProps}){
    return <Heart {...restProps}>{children}</Heart>
}

