import React from 'react';

import StaffLoginContainer from '../../containers_staff/stafflogin';

export default function StaffLogin(){

    return (

        <>
            <StaffLoginContainer/>
        </>


    )


}