import React, {useEffect } from 'react'
//components
import { Listing } from '../components'
//api
import getSearchCount from '../api/searchcount'



export default function CountAndSortContainer(props){



    useEffect(()=>{

        getSearchCount(props.setListingCount)

    }, [sessionStorage.getItem('searchParams')])


    return (
        <>
            {
                props.listingCount ?
                <Listing.SortFrame>
                    <Listing.SortFrameTextBox>
                        <Listing.CountText>{props.listingCount}</Listing.CountText>
                        <Listing.ResultsText> results</Listing.ResultsText>
                    </Listing.SortFrameTextBox>
                    <Listing.SortFrameTextBox>
                        {/* Removing this until I have sort functionality */}
                        {/* <Listing.RightText>Sort by</Listing.RightText>
                        <Listing.DownArrow src={DownArrow}/> */}
                    </Listing.SortFrameTextBox>
                </Listing.SortFrame>
                :
                null
            }
        
        
        </>

    )
} 