import React from 'react';
import { InfoBackground, WrapperFrame, TopFrame, IMGFrame, AboutUSFrame, AboutUsWrapper, AboutUSTitle,
        AboutUSParagraph, AboutUsHorizontal, AboutUSDetailWrapper, DetailIMG, AboutUSDetail, BottomFrame,
        BottomLeftFrame, ContactUsForm, ContactUSWrapper, OuterInputDiv, InputDiv, Input, CheckMenu, CheckLink, 
        CheckBox, SubmitButton, TitleFrame, ResponseText, ResponseTextFrame

    } from './styles/info'

export default function Info({children, ...restProps}){

    return <InfoBackground {...restProps}>{children}</InfoBackground>
}

Info.WrapperFrame = function InfoWrapperFrame({children, ...restProps}){
    return <WrapperFrame {...restProps}>{children}</WrapperFrame>
}

Info.TopFrame = function InfoTopFrame({children, ...restProps}){
    return <TopFrame {...restProps}>{children}</TopFrame>
}

Info.IMGFrame = function InfoIMGFrame({children, ...restProps}){
    return <IMGFrame {...restProps}>{children}</IMGFrame>
}


Info.AboutUSFrame = function InfoAboutUSFrame({children, ...restProps}){
    return <AboutUSFrame {...restProps}>{children}</AboutUSFrame>
}

Info.AboutUsWrapper = function InfoAboutUsWrapper({children, ...restProps}){
    return <AboutUsWrapper {...restProps}>{children}</AboutUsWrapper>
}

Info.AboutUSTitle = function InfoAboutUSTitle({children, ...restProps}){
    return <AboutUSTitle {...restProps}>{children}</AboutUSTitle>
}

Info.AboutUSParagraph = function InfoAboutUSParagraph({children, ...restProps}){
    return <AboutUSParagraph {...restProps}>{children}</AboutUSParagraph>
}

Info.AboutUsHorizontal = function InfoAboutUsHorizontal({children, ...restProps}){
    return <AboutUsHorizontal {...restProps}>{children}</AboutUsHorizontal>
}

Info.AboutUSDetailWrapper = function InfoAboutUSDetailWrapper({children, ...restProps}){
    return <AboutUSDetailWrapper {...restProps}>{children}</AboutUSDetailWrapper>
}

Info.DetailIMG = function InfoDetailIMG({children, ...restProps}){
    return <DetailIMG {...restProps}>{children}</DetailIMG>
}

Info.AboutUSDetail = function InfoAboutUSDetail({children, ...restProps}){
    return <AboutUSDetail {...restProps}>{children}</AboutUSDetail>
}

Info.BottomFrame = function InfoBottomFrame({children, ...restProps}){
    return <BottomFrame {...restProps}>{children}</BottomFrame>
}

Info.BottomLeftFrame = function InfoBottomLeftFrame({children, ...restProps}){
    return <BottomLeftFrame {...restProps}>{children}</BottomLeftFrame>
}

Info.ContactUsForm = function InfoContactUsForm({children, ...restProps}){
    return <ContactUsForm {...restProps}>{children}</ContactUsForm>
}

Info.ContactUSWrapper = function InfoContactUSWrapper({children, ...restProps}){
    return <ContactUSWrapper {...restProps}>{children}</ContactUSWrapper>
}

Info.OuterInputDiv = function InfoOuterInputDiv({children, ...restProps}){
    return <OuterInputDiv {...restProps}>{children}</OuterInputDiv>
}

Info.InputDiv = function InfoInputDiv({children, ...restProps}){
    return <InputDiv {...restProps}>{children}</InputDiv>
}

Info.Input = function InfoInput({children, ...restProps}){

    const handleChange = (e) => {
        restProps.setState(e.target.value);
      }
    
    return <Input {...restProps} onChange={handleChange}>{children}</Input>
}

Info.CheckMenu = function InfoCheckMenu({children, ...restProps}){
    return <CheckMenu {...restProps}>{children}</CheckMenu>
}

Info.CheckLink = function InfoCheckLink({children, ...restProps}){
    return <CheckLink {...restProps}>{children}</CheckLink>
}

Info.CheckBox = function InfoCheckBox({children, ...restProps}){
    const handleChange = (e) => {
        restProps.setState(!restProps.checked);
      }
    return <CheckBox {...restProps} onChange={handleChange} checked={restProps.checked} >{children}</CheckBox>
}

Info.SubmitButton = function InfoSubmitButton({children, ...restProps}){

    return <SubmitButton {...restProps}>{children}</SubmitButton>
}


Info.TitleFrame = function InfoTitleFrame({children, ...restProps}){

    return <TitleFrame {...restProps}>{children}</TitleFrame>
}

Info.ResponseTextFrame = function InfoResponseTextFrame({children, ...restProps}){

    return <ResponseTextFrame {...restProps}>{children}</ResponseTextFrame>
}

Info.ResponseText = function InfoResponseText({children, ...restProps}){

    return <ResponseText {...restProps}>{children}</ResponseText>
}












