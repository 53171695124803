import React, { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';
//container
import NavContainer from '../../containers/nav';
import BuyerSellerBackgroundContainer from '../../containers/buyersellerbackground'
import ParagraphContainer from '../../containers/paragraph';
import BulletContainer from '../../containers/bullet';
import ListContainer from '../../containers/list';
import FooterContainer from '../../containers/footer';
import { Wrapper } from '../../components'
// proxy
import SignInSignUpProxyContainer from '../../containerproxy/signinsignup';
//context
import { SignInSignUpContext } from '../../context/signinsignup';
import { UserContext } from '../../context/user';
// helpers
import handleUser from '../../helpers/handleuser'
// imgs
import CookLandRealty from '../../images/CookLandRealty.svg'
import Background from '../../images/whatsetsusapart.png'
// data
import backgroundtext from '../../data/seller/1031/backgroundtext.json';
import paragaphData from '../../data/seller/1031/paragraphdata.json';
import paragaphData2 from '../../data/seller/1031/paragraphdata2.json';
import listData from '../../data/seller/1031/listdata.json';
import bulletData from '../../data/seller/1031/bulletdata.json';
import bulletData2 from '../../data/seller/1031/bulletdata2.json';

export default function TEN31(){

    const [user , setUser ] = useContext(UserContext)
    const { signIn, signUp, errorMessage } = useContext(SignInSignUpContext)
    const [isSignInOpen, setIsSignInOpen] = signIn
    const [isSignUpOpen, setIsSignUpOpen] = signUp
    const [, setErrorMessage] = errorMessage
    const [ isPrivacyPolicyOpen, setIsPrivacyPolicyOpen ] = useState(false)

    let location = useLocation()
    useEffect(()=>{

        if(user){
            handleUser(location, setUser, setIsSignInOpen, setErrorMessage)
        }
        
    }, [location])

    return(
        
    
        <>
            <Wrapper opacity={isSignInOpen ? 'true' : isSignUpOpen ? 'true' : null}>
                <NavContainer backgroundColor='#FFFFFF' border={'2px solid #FFFFFF'} color={'#262626'} src={CookLandRealty} profileColor='#262626'/>
                <BuyerSellerBackgroundContainer Background={Background} text={backgroundtext[0]}/>
                <ParagraphContainer paragaphData={paragaphData}/>
                <ListContainer listText={listData} marginBottom={'20px'}/>
                <BulletContainer bulletData={bulletData} marginBottom='0px' mobileMarginBottom='0px'/>
                <ParagraphContainer paragaphData={paragaphData2}/>
                <BulletContainer bulletData={bulletData2}/>
                <FooterContainer backgroundColor='#262626'/>
            </Wrapper>

            {
                isSignInOpen || isSignUpOpen ?
                <SignInSignUpProxyContainer 
            
                    isSignInOpen={isSignInOpen}
                    setIsSignInOpen={setIsSignInOpen}
                    isSignUpOpen={isSignUpOpen}
                    setIsSignUpOpen={setIsSignUpOpen}
                    isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                    setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
                
                />
                :
                null
            }


        </>
        
        )
}