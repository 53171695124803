import styled from 'styled-components';


export const ParagraphFrame = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;

    
    // height: 800px;
`;

export const ParagraphFrameInner = styled.div`

    margin-top: 40px;
    margin-bottom: 43px;
    

    width: 90%;

    @media only screen and (min-width: 1280px)  {
        width: 81.25%;
        margin-top: 50px;
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '53px'};
    }
`;

export const ParagraphTitle = styled.h3`

    /* h3 */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */
    margin: 0;
    padding: 0;

    
    /* black */

    color: #262626;

`


export const ParagraphText = styled.p`

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    /* or 139% */

    letter-spacing: 0.3px;

    /* black */

    color: #262626;
    margin: 0;
    padding: 0;

    margin-top: 20px;


`