import axios from 'axios'
import getCookie from '../../helpers/getcookie';
import { URLS } from '../../services/config';



const axiosInstance = axios.create({
    baseURL: URLS.BASE_URL,
    timeout: 5000,
    headers: {
        Authorization: localStorage.getItem('accessToken')
        ? `JWT ${localStorage.getItem('accessToken')}`
        : null,
        "X-CSRFTOKEN": `${getCookie('csrftoken')}`,
        'Content-Type': 'application/json',
        accept: 'application/json'
    },
})

export default axiosInstance;