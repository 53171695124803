import React from 'react';
//components
import {Favorite} from '../components'


//imgs
import ListingCardContainer from './listingcard';


// hooks
import useListings from '../hooks/uselistings'


export default function OurListingsContainer(){



    const { listings, connectionError, display, manipulateIsFavorite } = useListings("OurListings")


    return (
        <>
        
            <Favorite>
                <Favorite.FavoriteFrameInner>
                    <Favorite.FavoriteBox>
                        <Favorite.TitleFrame>
                            <Favorite.TitleTextFrame>
                                <Favorite.TitleText>Cook Land & Realty's Listings</Favorite.TitleText>
                            </Favorite.TitleTextFrame>
                        </Favorite.TitleFrame>
                        <Favorite.FavoriteGridInner 

                            display={display ? 'flex' : 'grid'} 
                            justifyContent={ display ? 'center' : null} 
                            alignItems={display ? 'flex-start' : null}
                        >

                            <ListingCardContainer 
                                grid='true' 
                                listings={listings} 
                                connectionError={connectionError}
                                manipulateIsFavorite={manipulateIsFavorite}
                            />

                        </Favorite.FavoriteGridInner>
                    </Favorite.FavoriteBox>
                </Favorite.FavoriteFrameInner>
            </Favorite>
        
        
        </>
        
        
        
        
    )

}