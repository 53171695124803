import React from 'react'

import { Form } from '../../components_staff'

export default function ResponseContainer(props){


    return (
        <Form.InputFrame justifyContent="center">
            <Form.InputFrameTop justifyContent="center">
                {/* background="#6C8915" */}
                    {
                        props.requestStatus.success ?
                        <Form.ResponseFrame background="#FFFFFF">
                            <Form.ResponseText>{props.requestStatus.clientMessage}</Form.ResponseText>
                        </Form.ResponseFrame>
                        :
                        null
                    }
                    {  props.requestStatus.failure ?

                        <Form.ResponseText fontSize="30px">{props.requestStatus.clientMessage}</Form.ResponseText>
                        :
                        null
                    }
            </Form.InputFrameTop>
        </Form.InputFrame>
        
        )

}