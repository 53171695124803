import React from 'react';
import { useHistory } from 'react-router-dom';
import {Form} from '../components'

//img
import CookLandRealty from '../images/CookLandRealty.svg'
//api
import verifyAccount from '../api/verifyaccount';



export default function VerifyAccountContainer(props){

    let history = useHistory();

    const handleSubmit = (e)=>{
        e.preventDefault()

        verifyAccount(props.pathName.uid, props.pathName.token, history)
    }

    
    return (
        <>
            <Form height='623px'>   
                <Form.CookLogoIMG src={CookLandRealty}/>
                <Form.Wrapper height='800px' onSubmit={e => handleSubmit(e)}>
                    <Form.Title>Verify Account</Form.Title>
                    <Form.Button>VERIFY</Form.Button>
                </Form.Wrapper>
            </Form>
        </>
    )
}