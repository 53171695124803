import React from 'react'
import VerifyAccountContainer from '../containers/verifyaccount'


export default function VerifyAccount(props){
    return (
        <>
            <VerifyAccountContainer pathName={props.computedMatch.params}/>
        </>
        
        
    )
}