import React from 'react'

// components
import { ListingTitle } from '../components'
// services
import { ROUTES } from '../services/config'
//Images
import CircleRight from '../images/CircleRight.svg';
import CircleLeft from '../images/CircleLeft.svg';


export default function ListingTitleContainer(props){


    // increments through the list
    function incrementListingSlice(){

        // this mutates the state array so every time it is called need to copy and reset the state
        // not the most efficient but works
        let listingArr = [...props.listings]
        listingArr.unshift(listingArr.pop());
        props.setListings(listingArr)     
    }


    // decrements through the list
    function decrementListingSlice(){

        let listingArr = [...props.listings]
        listingArr.push(listingArr.shift());
        props.setListings(listingArr)
    }

    if (props.listings){
        return (
            
            <ListingTitle >
    
                <ListingTitle.TitleTextFrame>
                    <ListingTitle.TitleText>{props.title}</ListingTitle.TitleText>
                </ListingTitle.TitleTextFrame>
    
                <ListingTitle.TitleTextFrame2 justify={'flex-end'} align={'center'}>
                    <ListingTitle.ViewMoreWrapper>
                        <ListingTitle.ViewMore to={ROUTES.OURLISTINGS}>View All</ListingTitle.ViewMore>
                        {/* if there are more than 4 featured listings than add the ability to cycle */}
                        {   
                            props.listings.length > 4 ?
                            <ListingTitle.CircleFrame>
                                <ListingTitle.Circle src={CircleLeft} onClick={incrementListingSlice}/>
                                <ListingTitle.Circle src={CircleRight} onClick={decrementListingSlice}/>
                            </ListingTitle.CircleFrame>
                            :
                            null
                        }
                        
                    </ListingTitle.ViewMoreWrapper>
                </ListingTitle.TitleTextFrame2>
    
            </ListingTitle>
        )
    } else {
        return null
    }
   


    
}