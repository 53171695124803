import React from 'react'

import { Paragraph } from '../components'

export default function ParagraphContainer(props){

    return (
        <>
            <Paragraph>
                <Paragraph.ParagraphFrameInner marginBottom={props.marginBottom}>
                    <Paragraph.ParagraphTitle>{props.paragaphData.title ? props.paragaphData.title : null}</Paragraph.ParagraphTitle>
                    {
                        props.paragaphData.data ?

                        props.paragaphData.data.map((item)=>(
                            <Paragraph.ParagraphText id={item.id}>{item.text}</Paragraph.ParagraphText>
                        ))
                        :
                        null
                    }
                </Paragraph.ParagraphFrameInner>
            </Paragraph>

        </>
    )
}