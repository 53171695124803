import React, { useState } from 'react';

//components
import { Listing } from '../components';
// containers
import SearchContainer from './search'
import ListingCardContainer from './listingcard'
import MapContainer from './map';
import PageNumberContainer from './pagenumber';
import CountAndSortContainer from './countandsort';


import useListings from '../hooks/uselistings';

export default function SearchResultContainer(){
    

    const { listings, setListings, isLoading, manipulateIsFavorite} = useListings("SearchResults")
    // state
    const [listingCount, setListingCount]= useState(null);
    const [offset, setOffset] = useState(0);

 
    return (

        <>
        <Listing>
            <Listing.MLSFrame>

                <SearchContainer/>


                <Listing.MLSMainFrame >

                    <Listing.MLSListingFrame>
                        <CountAndSortContainer listingCount={listingCount} setListingCount={setListingCount}/>
                        <Listing.ListingGrid display={!listings ? 'flex' : 'grid'} justifyContent={ !listings ? 'center' : null} alignItems={!listings   ? 'flex-start' : null}>
                            {/* if is loading display loading container */}
                            
                            <ListingCardContainer 
                                grid='true'
                                listings={listings}
                                manipulateIsFavorite={manipulateIsFavorite}
                            /> 
                        </Listing.ListingGrid>

                        {
                            listings ?
                            
                            listings < 1 ?
                                <Listing.NoResultsFrame>
                                    <Listing.NoResultsText>No Results Found</Listing.NoResultsText>
                                </Listing.NoResultsFrame>
                                :
                                null
                            : 
                            null    
                        }
                        {
                            listingCount ?
                            <PageNumberContainer isLoading={isLoading} offset={offset} setOffset={setOffset} listingCount={listingCount}/>
                            :
                            null
                        }
                    </Listing.MLSListingFrame>
                    
                    {/* setSearchResults={setSearchResults}  */}
                    <Listing.Map>
                        <MapContainer searchResults={listings} setListings={setListings} isLoading={isLoading} width='100%' height='1250px'/>
                    </Listing.Map>
                    
                </Listing.MLSMainFrame>
                

            </Listing.MLSFrame>
            </Listing>
        </>
    )
}