import React from 'react';

import PasswordResetContainer from '../containers/passwordreset'


export default function PasswordReset(props){

    return (
        <>

            <PasswordResetContainer pathName={props.computedMatch.params}/>

        </>
    )
}