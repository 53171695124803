import { URLS } from '../services/config'
// images
import CookLandRealty from '../images/CookLandRealty.svg';

export default function imageHandler(favorite){

    if (favorite.Type === "Cook"){
        return `${URLS.BASE_URL}${favorite.DisplayImage}`
    } else {
        if(favorite.image !== 'No Preffered Image'){
            return `data:image/png;base64,${favorite.image}`
        }
        if(favorite.image === 'No Preffered Image'){
            return CookLandRealty
        }
    }

}

// this handles the displaying of the image on mobile... whether cook or mls
export const mobileImageHandler = (detailedListing, listingPics, picIndex) => {


    // it has to check for the detailed listing before running because the api call in the listingdetail.js is asynchronous and detailed listing might not be set yet
    if (detailedListing){
        if (detailedListing.Type === "Cook"){
            return `${URLS.BASE_URL}${listingPics[picIndex].Images}`
            
        } else { // else it is an MLS Listing
            if(listingPics !== "no pics for this item"){
                return `data:image/png;base64,${listingPics[picIndex].itemImage}`
            } else{
                // no pic
                return CookLandRealty
            }
                
        }
    } else {
        return null
    }
    


}