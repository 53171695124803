import React from 'react';
import GoogleMapReact from 'google-map-react';
// components
import { Map } from '../components';
// services
import { GOOGLEMAPAPI, ROUTES } from '../services/config';
// helpers
import {handleDisplayPopout} from '../helpers/mappopout';
import {setDetailListingID} from '../helpers/listing';
// images
import CookLandRealty from '../images/CookLandRealty.svg'
import Location from '../images/location.svg';


export default function MapContainer(props){



  // https://gis.stackexchange.com/questions/12120/calculate-midpoint-from-a-series-of-latitude-and-longitude-coordinates
  // to center the map look this up

  let defaultProps = {
    center: {
      lat: 38.390570,
      lng: -93.794110
    },
    zoom: 8
  };
  




  // {/* onClick={ () => setDetailListingID(featuredListing.id, "Cook", true)} to={ROUTES.PROPERTY} */}

  if (props.searchResults instanceof Array){
    // if the search result is an array then it will map over all the properties -- this is used in the searchresults container
    return (
      <Map height={props.height} width={props.width}>

          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLEMAPAPI}}
            center={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
          >
            {
                props.searchResults.map((result) => (
                  <div 
                    key={result.id} 
                    lat={result.Latitude} 
                    lng={result.Longitude} 
                    onMouseEnter={()=> handleDisplayPopout(props.searchResults, props.setListings, result, "open")}
                    onMouseLeave={()=> handleDisplayPopout(props.searchResults, props.setListings, result, "close")} 
                  >
                    <Map.MapItem>
                      <Map.ItemAmount>${Math.round(result.AskingPrice).toLocaleString()}</Map.ItemAmount>
                    </Map.MapItem>

                      <Map.MapPopout display={result.isDisplayed ? result.isDisplayed : null}>
                        <Map.ImageLinkWrapper  onClick={ () => setDetailListingID(result.id, "MLS", true)} to={ROUTES.PROPERTY}>    
                          <Map.Image url={ result.image !== "No Preffered Image" ? `data:image/png;base64,${ result.image}`: CookLandRealty } alt='pic'/>
                        </Map.ImageLinkWrapper>
                        <Map.DetailFrame>
                          <Map.DetailInnerFrame>
                            <Map.CardTitle>{result.Address} {result.City}, {result.State} {result.ZipCode}</Map.CardTitle>
                            <Map.LocationBox>
                              <Map.LocationIcon src={Location}/>
                              <Map.LocationText>Location</Map.LocationText>
                            </Map.LocationBox>
                            <Map.InfoBox>
                              <Map.TypeText>Type: {result.Class}</Map.TypeText>                          
                                {
                                    
                                    result.Acres ?
                                    <>
                                        <Map.Dot/>
                                        <Map.TypeText>acres: {result.Acres}</Map.TypeText>
                                    </>
                                    
                                    :
                                    null
                                }
                                
                                
                                {
                                        
                                        result.SquareFeet !== "" && result.SquareFeet !== "0" ?
                                    <>
                                        <Map.Dot/>
                                        <Map.TypeText>Sqr ft: {result.SquareFeet}</Map.TypeText>
                                    </>
                                    
                                    :
                                    null
                                }
                                
                            </Map.InfoBox>
                            <Map.Amount>${Math.round(result.AskingPrice).toLocaleString()}</Map.Amount>
                          </Map.DetailInnerFrame>
                        </Map.DetailFrame>

                    </Map.MapPopout>
                  </div>    
                ))         
            }
            

          </GoogleMapReact>

      </Map>
    );
  } else if (typeof props.searchResults === 'object' && props.searchResults !== null) { 
    // if the search result is an object that is not null display this -- it is used in the detailed listing container
    
    defaultProps = {
      center: {
        lat: props.searchResults.Latitude,
        lng: props.searchResults.Longitude
      },
      zoom: 8
    };
    
    return (
      <Map height={props.height} width={props.width} display={'true'}>

          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLEMAPAPI}}
            center={{center:{lat: props.searchResults.lat, lng: props.searchResults.lng}}}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
          >
            {
              
                <Map.MapItem key={props.searchResults.id} lat={props.searchResults.Latitude} lng={props.searchResults.Longitude} >
                  <Map.ItemAmount>{props.searchResults.AskingPrice}</Map.ItemAmount>
                </Map.MapItem>            
            }

          </GoogleMapReact>

      </Map>
    )


  } else {
    // if search results is null then it will display just the map
    return(
      

      <Map height={props.height} width={props.width}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLEMAPAPI}}
          center={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        />
      </Map>
    )
  }
  
  
  
    
}