


// this validation funtion is only used in the settings container
// it validates the users new password
export default function validatePassword(newPassword, reNewPassword){

    let message

    if (newPassword === reNewPassword){
        const passwordRE = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/
        if (passwordRE.test(newPassword)){

            message = {
                status: true,
                message: null
            }

            return message
        } else {

            message = {
                status: false,
                message: 'Password must have at least 8 characters, 1 number, 1 symbol, 1 lower, and 1 upper'
            }

            return message
        }
    } else {

        message = {
            status: false,
            message: 'Passwords do not match'
        }

        return message
    }
}
    

