import React, {useContext } from 'react';
import { useHistory } from "react-router-dom";
// component
import {Form, Info} from '../components'
//custom hooks
import useLogin from '../hooks/uselogin';
//img
import CookLandRealty from '../images/CookLandRealty.svg'
// hooks
import useScript from '../hooks/useScript';
import { UserContext } from '../context/user'


import { ROUTES } from '../services/config';

export default function StaffLoginContainer(){

    const [user, ] = useContext(UserContext)
    const history = useHistory()
    const { emailAddress, setEmailAddress, password, setPassword, handleSubmit} = useLogin()
    useScript(`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT}`)


    // If the user is logged in and they are staff than this will redirect them to the dashboard
    if(user && user.is_staff){
        // gets the current page in local storage so that way on reset it sends the user here instead of to the dashboard
        const page = sessionStorage.getItem('page')
        history.push( page || ROUTES.STAFFPAGES.STAFFDASHBOARD)
        return null
    } else {
            return (
            <>
                <Form height='623px'>   
                    <Form.CookLogoIMG src={CookLandRealty} onClick={()=> history.push(ROUTES.HOME)} cursor="pointer"/>
                    <Form.Wrapper height='800px' method="POST" onSubmit={handleSubmit}>
                        <Form.Title>Staff Login</Form.Title>
                            <Form.Input
                                name='email'
                                type='email' 
                                placeholder='EMAIL'
                                value={emailAddress}
                                onChange={({target}) => setEmailAddress(target.value)}
                            />
                                
                            <Form.Input
                                name='password' 
                                placeholder='PASSWORD' 
                                type="password"
                                value={password}
                                onChange={({target}) => setPassword(target.value)}
                            />

                            {
                                user && !user.is_staff ?
                                <Form.ExtraFrame marginTop={'20px'}>
                                    <Info.ResponseText color='#FF7276'>You Must have a staff account to access</Info.ResponseText>
                                </Form.ExtraFrame>
                                :
                                null
                            }


                        <Form.Button>Login</Form.Button>
                    </Form.Wrapper>
                </Form>
            </>
        )
    } 
}
        
