
// this sets the id of the clicked on cook listing
// sets in session storage and the id is used in the url param to query data about that listing
// if the refresh param is true then the page will be scrolled back to the top
export function setDetailListingID(id, type, refresh){
    localStorage.setItem('listingID', id)
    localStorage.setItem('type', type)

    if(refresh){
        // window.location.reload();
        window.scrollTo(0, 0);
    }
}


////// 3/21/2021 DONT REMEMBER WHY OR WHAT THIS IS USED FOR
// this function is used to filter the response array for listing detail pics
// it returns an array of two images... the first and last
export const filterPics = (picsArray) => {
    const displayPics = []
    const length = picsArray.length
    displayPics.push(picsArray[0])

    displayPics.push(picsArray[length - 1])
    return displayPics
}
