
// this smaller function is used to split the path of an image file


export default function splitPath(path) {
    var dirPart, filePart;
    path.replace(/^(.*\/)?([^/]*)$/, function(_, dir, file) {
      dirPart = dir; filePart = file;
    });
    return { dirPart: dirPart, filePart: filePart };
  }