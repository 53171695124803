import React from 'react';
import { SideNavFrame, LogoFrame, Logo, NavTitle, PageLinkFrame, PageLinkText, LogoutButtonFrame, LogoutButton} from './styles/sidenav'


export default function SideNav({children, ...restProps}){
    return <SideNavFrame {...restProps}>{children}</SideNavFrame>
}


SideNav.LogoFrame = function SideNavLogoFrame({children, ...restProps}){
    return <LogoFrame {...restProps}>{children}</LogoFrame>
}

SideNav.Logo = function SideNavLogo({children, ...restProps}){
    return <Logo {...restProps}>{children}</Logo>
}

SideNav.NavTitle = function SideNavNavTitle({children, ...restProps}){
    return <NavTitle {...restProps}>{children}</NavTitle>
}

SideNav.PageLinkFrame = function SideNavPageLinkFrame({children, ...restProps}){
    return <PageLinkFrame {...restProps}>{children}</PageLinkFrame>
}

SideNav.PageLinkText = function SideNavPageLinkText({children, ...restProps}){
    return <PageLinkText {...restProps}>{children}</PageLinkText>
}


SideNav.LogoutButtonFrame = function SideNavLogoutButtonFrame({children, ...restProps}){
    return <LogoutButtonFrame {...restProps}>{children}</LogoutButtonFrame>
}

SideNav.LogoutButton = function SideNavLogoutButton({children, ...restProps}){
    return <LogoutButton {...restProps}>{children}</LogoutButton>
}