import React from 'react';
import {NavBar, Frame1, Frame1Inner, LinkFrame, LinkA, LinkText, LogoFrame, Logo, Frame2,
        VerticalDivider, Button, ButtonText, HorizontalBar, CopyRightText, SocialMediaBox, SocialIcon,
        DropDown, DropDownText,UserFrame, User, UserIMG, HamburgerFrame, HamburgerLine, XFrame, XLineLeft, XLineRight, NavBarMobile,
        MobileLinkFrame, MobileInnerFrame, MobileButtonWrapper, MobileButtonFrame, MobileButtonText, MobileButton,
        MobileUserWrapper, MobileUserNameFrame, MobileUserNameText, MobileExtraFrame, MobileExtraItemContainer,
        MobileExtraItemImage, MobileExtraItemText
        } from './styles/nav'

export default function Nav({children, ...restProps}){
    return <NavBar {...restProps}>{children}</NavBar>
}

Nav.Frame1 = function NavFrame1({children, ...restProps}){

    return <Frame1 {...restProps}>{children}</Frame1>
}


Nav.Frame1Inner = function NavFrame1Inner({children, ...restProps}){

    return <Frame1Inner {...restProps}>{children}</Frame1Inner>
}

Nav.LogoFrame = function NavLogoFrame({children, ...restProps}){

    return <LogoFrame {...restProps}>{children}</LogoFrame>
}

Nav.Logo = function NavLogo({children, ...restProps}){

    return <Logo {...restProps}>{children}</Logo>
}

Nav.LinkFrame = function NavLinkFrame({children, ...restProps}){

    return <LinkFrame {...restProps}>{children}</LinkFrame>
}

Nav.LinkText = function NavLinkText({children, ...restProps}){
    return(   
        <>
            <LinkA to={restProps.to}>
                <LinkText {...restProps}>{children}</LinkText>
            </LinkA>
        </>
        )
}

Nav.Frame2 = function NavFrame2({children, ...restProps}){

    return <Frame2 {...restProps}>{children}</Frame2>
}

Nav.VerticalDivider = function NavVerticalDivider({children, ...restProps}){

    return <VerticalDivider {...restProps}>{children}</VerticalDivider>
}

Nav.Button = function NavButton({children, ...restProps}){

    return <Button {...restProps}>{children}</Button>
}

Nav.ButtonText = function NavButtonText({children, ...restProps}){

    return <ButtonText {...restProps}>{children}</ButtonText>
}


Nav.HorizontalBar = function NavHorizontalBar({children, ...restProps}){

    return <HorizontalBar {...restProps}>{children}</HorizontalBar>
}


Nav.CopyRightText = function NavCopyRightText({children, ...restProps}){

    return <CopyRightText {...restProps}>{children}</CopyRightText>
}

Nav.SocialMediaBox = function NavSocialMediaBox({children, ...restProps}){

    return <SocialMediaBox {...restProps}>{children}</SocialMediaBox>
}


Nav.SocialIcon = function NavSocialIcon({children, ...restProps}){

    return <SocialIcon {...restProps}>{children}</SocialIcon>
}

Nav.DropDown = function NavDropDown({children, ...restProps}){

    return <DropDown {...restProps}>{children}</DropDown>
}

Nav.DropDownText = function NavDropDownText({children, ...restProps}){

    return <DropDownText {...restProps}>{children}</DropDownText>
}

Nav.UserFrame = function NavUserFrame({children, ...restProps}){
    return <UserFrame {...restProps}>{children}</UserFrame>
}

Nav.User = function NavUser({children, ...restProps}){
    return <User {...restProps}>{children}</User>
}

Nav.UserIMG = function NavUserIMG({children, ...restProps}){
    return(
         <>
            <LinkA>
                <UserIMG {...restProps}>{children}</UserIMG>
            </LinkA>
        </>
        )
}

Nav.HamburgerFrame = function NavHamburgerFrame({children, ...restProps}){
    return <HamburgerFrame {...restProps}>{children}</HamburgerFrame>
}

Nav.HamburgerLine = function NavHamburgerLine({children, ...restProps}){
    return <HamburgerLine {...restProps}>{children}</HamburgerLine>
}

// Start of the mobil nav bar
Nav.NavBarMobile = function NavNavBarMobile({children, ...restProps}){

    return <NavBarMobile {...restProps}>{children}</NavBarMobile>
}

Nav.MobileInnerFrame = function NavMobileInnerFrame({children, ...restProps}){

    return <MobileInnerFrame {...restProps}>{children}</MobileInnerFrame>
}

Nav.MobileLinkFrame = function NavMobileLinkFrame({children, ...restProps}){

    return <MobileLinkFrame {...restProps}>{children}</MobileLinkFrame>
}

Nav.MobileButtonWrapper = function NavMobileButtonWrapper({children, ...restProps}){

    return <MobileButtonWrapper {...restProps}>{children}</MobileButtonWrapper>
}

Nav.MobileButtonFrame = function NavMobileButtonFrame({children, ...restProps}){

    return <MobileButtonFrame {...restProps}>{children}</MobileButtonFrame>
}

Nav.MobileButtonText = function NavMobileButtonText({children, ...restProps}){

    return <MobileButtonText {...restProps}>{children}</MobileButtonText>
}

Nav.MobileButton = function NavMobileButtonFrame({children, ...restProps}){

    return <MobileButton {...restProps}>{children}</MobileButton>
    
}



Nav.MobileUserWrapper = function NavMobileUserWrapper({children, ...restProps}){

    return <MobileUserWrapper {...restProps}>{children}</MobileUserWrapper>
}

Nav.MobileUserNameFrame = function NavMobileUserNameFrame({children, ...restProps}){

    return <MobileUserNameFrame {...restProps}>{children}</MobileUserNameFrame>
}

Nav.MobileUserNameText = function NavMobileUserNameText({children, ...restProps}){

    return <MobileUserNameText {...restProps}>{children}</MobileUserNameText>
}

Nav.MobileExtraFrame = function NavMobileExtraFrame({children, ...restProps}){

    return <MobileExtraFrame {...restProps}>{children}</MobileExtraFrame>
}

Nav.MobileExtraItemContainer = function NavMobileExtraItemContainer({children, ...restProps}){

    return ( <LinkA to={restProps.to}>
                <MobileExtraItemContainer {...restProps}>{children}</MobileExtraItemContainer>
            </LinkA>
        )
}

Nav.MobileExtraItemImage = function NavMobileExtraItemImage({children, ...restProps}){

    return <MobileExtraItemImage {...restProps}>{children}</MobileExtraItemImage>
}

Nav.MobileExtraItemText = function NavMobileExtraItemText({children, ...restProps}){

    return <MobileExtraItemText {...restProps}>{children}</MobileExtraItemText>
}


Nav.XLineLeft = function NavXLineLeft({children, ...restProps}){

    return <XLineLeft {...restProps}>{children}</XLineLeft>
}

Nav.XLineRight = function NavXLineRight({children, ...restProps}){

    return <XLineRight {...restProps}>{children}</XLineRight>
}

Nav.XFrame = function NavXFrame({children, ...restProps}){

    return <XFrame {...restProps}>{children}</XFrame>
}
