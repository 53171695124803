import React from 'react';

import StaffWrapperContainer from '../../containers_staff/staffwrapper';
import StaffSideNaveContainer from '../../containers_staff/staffsidenav';
import StaffMainContainer from '../../containers_staff/staffmain';
import StaffTitleContainer from '../../containers_staff/stafftitle';
import StaffBlockContainer from '../../containers_staff/staffblock';
import ButtonsContainer from '../../containers_staff/buttons';
import TableContainer from '../../containers_staff/table';
import { ContactMessageRowContainer } from '../../containers_staff/tablerows';
import FormContainer from '../../containers_staff/form';

//images
import Mail from '../../images/Mail.svg';
//custom hooks
import useData from '../../hooks/staff/usedata';
import useUser from '../../hooks/useuser';
import useForm from '../../hooks/staff/useform';

//services
import { ROUTES, URLS } from '../../services/config';

export default function StaffMessages(){

    const { user, history } = useUser()
    const { isFormOpen, setIsFormOpen } = useForm()
    const [ messages, error ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.CONTACTMESSAGES}`);
    const [ messageStats, messageStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.CONTACTMESSAGESSTATS}`);

    const blockData =[
        {
            blockTitle: "Total Messages",
            value: messageStats ? messageStats.total : null,
            icon: Mail
        },
        {
            blockTitle: "Listing Based Messages",
            value: messageStats ? messageStats.listingBasedMessages : null,
            icon: Mail
        },
        {
            blockTitle: "Non - Listing Based Messages",
            value: messageStats ? messageStats.nonListingBasedMessages : null,
            icon: Mail
        },
    ]

    const messageHeaders=[
        {
            name: "Name",
            width: "10%"
        },
        {
            name: "Phone Number",
            width: "12%"
        },
        {
            name: "Email",
            width: "12%"
        },
        {
            name: "Message",
            width: "15%"
        },
        {
            name: "Listing Name",
            width: "14%"
        },
        {
            name: "Buy",
            width:"8%"
        },
        {
            name: "Sell",
            width:"8%"
        },
        {
            name: "Date",
            width: "10%"
        },
        
        {
            name: "Contact Time",
            width: "6%"
        }
        
            
    ]

    if(user && user.is_staff){
        return (
            
                <StaffWrapperContainer>
                    <StaffSideNaveContainer/>
                    <StaffMainContainer>
                        <StaffTitleContainer title = 'Manage Contact Messages'/>
                        <StaffBlockContainer blockData={blockData}/>
                        {/* <ButtonsContainer setIsFormOpen={setIsFormOpen}/> */}
                        <TableContainer 
                            headers={messageHeaders} 
                            tableData={messages}
                            marginTop="50px"
                        >
                                <ContactMessageRowContainer/>
                        </TableContainer>
                        <FormContainer isFormOpen={isFormOpen} setIsFormOpen={setIsFormOpen}/>
                    </StaffMainContainer>
                </StaffWrapperContainer>
            
            )
        } else {
            history.push(ROUTES.STAFFPAGES.STAFFLOGIN)
            return (
                null
            )
        } 
}