import React, { useEffect} from 'react';

// containers
import StaffWrapperContainer from '../../containers_staff/staffwrapper';
import StaffSideNaveContainer from '../../containers_staff/staffsidenav';
import StaffMainContainer from '../../containers_staff/staffmain';
import StaffTitleContainer from '../../containers_staff/stafftitle';
import StaffBlockContainer from '../../containers_staff/staffblock';
import ButtonsContainer from '../../containers_staff/buttons';
import TableContainer from '../../containers_staff/table';
import { ListingTableRowContainer, AllFavoritedListingTableRowContainer }  from '../../containers_staff/tablerows';
import FormContainer from '../../containers_staff/form';
import ListingFormContainer from '../../containers_staff/forms/listingform';
import ResponseContainer from '../../containers_staff/forms/response';
import SuccessContainer from '../../containers_staff/success';
import DeleteContainer from '../../containers_staff/delete';
import DeleteExtraImagesContainer from '../../containers_staff/deleteextraimages'

//images
import GreenCheck from '../../images/greencheck.svg';
import Pending from '../../images/pending.svg';
import Sold from '../../images/sold.svg';

//static
import {listingHeaders, createBlockData, favoriteHeaders, createFavoriteBlockData } from '../../static/stafflistingheaders'

//custom hooks
import useData from '../../hooks/staff/usedata';
import useUser from '../../hooks/useuser';
import useForm from '../../hooks/staff/useform';
//services
import {ROUTES, URLS } from '../../services/config';


export default function StaffListings(){

    // this will initialize the form to contain this data
    const initialFormState = {
        id: null,
        Class:null,
        Name: null,
        Description: null,
        Address: null,
        City: null,
        State: null,
        County: null,
        ZipCode: null,
        Location: null,
        AskingPrice: null,
        SquareFeet: null,
        Acres: null,
        PropertyType: null,
        TourURL: null,
        DisplayImage: null,
        isDisplay: null,
        isFeatured: false,
        isAuction: false,
        Status: null
    }

    const { user, history } = useUser()


    // all of the state and functions for the form
    const { isFormOpen, setIsFormOpen,
            isDeleteOpen, setIsDeleteOpen, 
            formTitle, setFormTitle,

            formData: listingFormData, 
            setFormData: listingSetFormData,

            postData, patchData, deleteData,
            requestStatus, setRequestStatus, 
            formErrors, setFormErrors 
            } = useForm(URLS.STAFFAPIS.LISTINGS, initialFormState)

    const [ listings, listingError, setListings ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.LISTINGS}`, true);
    const [ listingStats, listingStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.LISTINGSSTATS}`);
    const [ favoritedListings, favoritedListingsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.ALLFAVORITEDLISTINGS}`);
    const [ favoritedListingsStats, favoritedListingsStatsError ] = useData(`${URLS.BASE_URL}/${URLS.STAFFAPIS.FAVORITEDLISTINGSSTATS}`)


    
    // these funcs are not useState()
    // they are from the static files dir that take in state variables and images to create the block data
    const blockData = createBlockData(listingStats, GreenCheck, Pending, Sold) 
    const favoriteBlockData = createFavoriteBlockData(favoritedListingsStats, GreenCheck, Pending, Sold)


    console.log(listings)


    if(user && user.is_staff){
        return (
            
                <StaffWrapperContainer>
                    <StaffSideNaveContainer/>
                    <StaffMainContainer >


                        <StaffTitleContainer title = 'Manage Listings'/>
                        <StaffBlockContainer blockData={blockData}/>
                        <ButtonsContainer 
                            extraButton={true} 
                            setIsFormOpen={setIsFormOpen}
                            setIsDeleteOpen={setIsDeleteOpen}
                            setFormTitle={setFormTitle}
                            title={" Listing"}
                            formData={listingFormData}
                            setFormData={listingSetFormData}
                            initialFormState={initialFormState}
                            setRequestStatus={setRequestStatus}
                        />
                        <SuccessContainer requestStatus={requestStatus} title="Listing"/>
                        <TableContainer 
                            headers={listingHeaders}
                            tableData={listings}
                            marginBottom="20px"
                            isFormOpen={isFormOpen}
                        >
                            <ListingTableRowContainer 
                                setFormData={listingSetFormData}
                                initialFormState={initialFormState}
                            />
                        </TableContainer>
                        
                        

                        <StaffTitleContainer title = 'Favorited Listings'/>
                        <StaffBlockContainer blockData={favoriteBlockData}/>

                        {/* <ButtonsContainer
                            extraButton={false}
                            setIsFormOpen={setIsFormOpen}  
                            setFormTitle={setFormTitle}
                            title={" Favorite Listing"}
                            setRequestStatus={setRequestStatus}
                        /> */}

                        <TableContainer
                            headers={favoriteHeaders} 
                            tableData={favoritedListings}
                            marginTop="50px"
                        >
                                <AllFavoritedListingTableRowContainer/>
                        </TableContainer>

                        {/* have it like this so that way the form will mount and unmount
                            this is so there is explicit re-setting of state variables
                        */}

                        { 
                            isFormOpen ?
                            <FormContainer 
                                isFormOpen={isFormOpen} 
                                setIsFormOpen={setIsFormOpen}
                                title={formTitle}
                                setFormData={listingSetFormData}
                                setRequestStatus={setRequestStatus}
                                setFormErrors={setFormErrors}
                                initialFormState={initialFormState}
                            >

                                <ListingFormContainer
                                    formTitle={formTitle}
                                    formData={listingFormData}
                                    setFormData={listingSetFormData}
                                    postData={postData}
                                    patchData={patchData}
                                    formErrors={formErrors}
                                    tableData={listings}
                                    setTableData={setListings}
                                    requestStatus={requestStatus}
                                    initialFormState={initialFormState}
                                >
                                    <ResponseContainer requestStatus={requestStatus} />
                                    <DeleteExtraImagesContainer/>
                                </ListingFormContainer>

                            </FormContainer>
                            :
                            null    
                        }

                        {/* checking to see if both isdeleteopen and there is a listing clicked on */}
                        {
                            isDeleteOpen && listingFormData.Name?
                            <DeleteContainer
                                tableData={listings}
                                setTableData={setListings}
                                formData={listingFormData}
                                setIsDeleteOpen={setIsDeleteOpen}
                                deleteData={deleteData}
                                requestStatus={requestStatus}
                                setRequestStatus={setRequestStatus}
                                setFormErrors={setFormErrors}
                                initialFormState={initialFormState}
                                item={listingFormData.Name}
                                title={`Listing`}  
                            >
                                <ResponseContainer requestStatus={requestStatus} />
                            </DeleteContainer>
                            :
                            null
                        }
                        
                        
                    </StaffMainContainer>
                </StaffWrapperContainer>
            
            )
        } else {
            history.push(ROUTES.STAFFPAGES.STAFFLOGIN)
            return (
                null
            )
        } 
}