import { useEffect } from 'react';

export default function useScript (url) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      const elements = document.getElementsByClassName('grecaptcha-badge')
      while (elements.length > 0) elements[0].remove();
      // also need to remove the children by class
    }
  }, [url]);
};

