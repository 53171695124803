import React from 'react';
import { Form, Privacy } from '../components'
import PrivacyPolicy from '../data/privacypolicy/privacypolicy.json'


export default function PrivacyPolicyContainer(props){




    if (props.isPrivacyPolicyOpen){
        return(
            <>
    
                <Form height='900px' lapHeight='600px' width='60%'>
                    <Form.ExitFrame>
                        <Form.ExitBox onClick={() => {
                            props.setIsPrivacyPolicyOpen(false)
                            props.setIsSignUpOpen(false)
                            document.body.style.overflow = 'unset';
                            }}>
                            <Form.RightSlash></Form.RightSlash>
                            <Form.LeftSlash></Form.LeftSlash>
                        </Form.ExitBox>
                    </Form.ExitFrame>
                    
                    <Privacy>
                        <Privacy.PrivacyFrameInner>
                            <Form.Title>Privacy Policy</Form.Title>
                            <Privacy.UpdatedText>Last updated: March 31, 2021</Privacy.UpdatedText>
                            {
                                PrivacyPolicy ?
                                PrivacyPolicy.map((privacy)=>(
                                    <>
                                        <Privacy.PrivacyContentFrame>
                                            <Privacy.PrivacyTitle>{privacy.Title}</Privacy.PrivacyTitle>
                                            <Privacy.PrivacyParagraph>{privacy.Content}</Privacy.PrivacyParagraph>
                                            {privacy.Bullet?
                                                privacy.Bullet.map((bullet)=>(
                                                    <Privacy.PrivacyBullet>{bullet}</Privacy.PrivacyBullet>
                                                ))
                                                :
                                                null
                                            }
                                        </Privacy.PrivacyContentFrame>
                                    </>
                                ))
                                :
                                null
                            }
                        </Privacy.PrivacyFrameInner>

                    </Privacy>
                    <Privacy.PrivacyFrameBottom>
                        <Privacy.PrivacyBottomFrameInner>
                            <Privacy.PrivacyButton onClick={ () => props.setIsPrivacyPolicyOpen(false)}>
                                <Privacy.ButtonText>Back To Sign Up</Privacy.ButtonText>
                            </Privacy.PrivacyButton>
                        </Privacy.PrivacyBottomFrameInner>
                    </Privacy.PrivacyFrameBottom>


                    
                </Form>
    
    
            </>
        )
    } else {
        return null
    }
   


}