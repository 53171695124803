import styled from 'styled-components';


export const SearchFrame = styled.div`
    

    display: flex;
    flex-direction: column;
    width: 90%;
    @media only screen and (min-width: 1280px)  {
        width: 94.583333%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;


export const MLSSearchFrame = styled.div`

    display: flex;
    flex-direction: column;
    font-family: Museo Sans Cyrl;

    width: 100%;

    @media only screen and (min-width: 1280px)  {
        flex-direction: row;
        height: 60px;
        width: 94.583333%;
        margin-bottom: 45px;
        background: #FFFFFF;
        justify-content: space-between;
        border: 1px solid #DFDFDF;
        
        color: #262626;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 1.8000001907348633px;
        text-align: left;
    }
`;

export const LocationSearchBox = styled.div`
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border: 1px solid #DFDFDF;

    @media only screen and (min-width: 1280px)  {
        border: none;
        // width: 26%;
        width: 40%;
        margin: 0;
    }

`;

export const MagnifyingGlassFrame = styled.div`

    width: ${props => props.width ? props.width : '10%'}; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;

    @media only screen and (min-width: 1280px)  {
        margin: 0;
    }
`;

export const MagnifyingGlass = styled.img`

    width: ${props => props.width ? props.width : '55%'}; 
    cursor: pointer;
`;


export const SearchText = styled.p`


    /* p / fields */

    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 23px;
    /* identical to box height, or 164% */
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    /* black */
    
    color: #262626;

`



export const PriceSearchBox = styled.div`
    background: #FFFFFF;
    
    box-sizing: border-box;
    width: ${props => props.mobileWidth ? props.mobileWidth : '100%'};
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border: 1px solid #DFDFDF;
    margin-bottom: 16px;

        
    @media only screen and (min-width: 1280px)  {
        border: none;
        margin: 0;
        // width: ${props => props.width ? props.width : '16%'};
        width: ${props => props.width ? props.width : '40%'};
    }
`;








export const SearchButton = styled.button`

    background: #6C8915;
    width: 20%;
    height: 60px;
    font-family: Museo Sans Cyrl;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    width: 100%;
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    cursor: pointer;

`

export const AcreSqrFtFrame = styled.div`

    
    
    display: flex;
    justify-content: space-between;

    @media only screen and (min-width: 1280px)  {

        width: 32%;
        
    }


`;


