import { ROUTES } from '../services/config'

export const onSearch = ( history, minPrice, maxPrice, setTypeError, setSearchParams, searchParams) => {
    
    // checks to make sure there is a property class which is essential in the first form of the search algo on the backend
    if(searchParams.propertyClass){

        setTypeError(null)
        let searchParamData = {
            
            location: searchParams.location,
            propertyClass: searchParams.propertyClass,
            minPrice: minPrice,
            maxPrice: maxPrice,
            pageCountIndex: 0,
            offset: 0
            
        }

        searchParamData = JSON.stringify(searchParamData)

        sessionStorage.setItem('searchParams', searchParamData )
        setSearchParams(JSON.parse(searchParamData))

        history.push(ROUTES.SEARCHRESULT)
    }
    else {
        setTypeError('#FF7276')
    }

}