import { useState, useEffect } from 'react';
import fetchData from '../../api/staff/fetchdata';



export default function useData(url, sort){

    const [ data, setData ] = useState(null);
    const [ error, setError ] = useState(null);

    useEffect(() => {

        fetchData(url)
        .then((response) =>{

            // if the sort param is passed a true, then sort the data
            // currently sorts the data by date descending
            // used particularly in the date to sort by newest
            if(sort){
                const sortedData = response.data.sort( (a, b) => new Date(b.DateListed) - new Date (a.DateListed))
                setData(sortedData)
            } else {
                setData(response.data)
            }


            
        })
        .catch((error) => {

            console.log(error.response)

            if (error.response.status === 401 || error.response.status === 403){
                setError("Error you are not an authorized accessor")
            }

            if (error.response.staus === 500){
                setError("Error Internal Server Error")
            }
        })

        return ()=> {
            setData(null)
            setError(null)
        }
        
    }, [])
        
            



    return [data, error, setData]
    
}